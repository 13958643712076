import { useState, useEffect } from 'react';
import { getIndependenceLevelOptions } from 'api/independenceLevels';
import { DropdownSelectOption } from 'components/Reusable/DropdownSelect';

export const useIndependenceLevels = () => {
	const [levelOptions, setLevelOptions] = useState<DropdownSelectOption[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		setLoading(true);
		getIndependenceLevelOptions()
			.then((levels) => {
				const formattedLevels = levels.map((level) => ({
					value: level.id,
					label: level.label,
				}));
				setLevelOptions(formattedLevels);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
			});
	}, []);

	return { levelOptions, loading, error };
};
