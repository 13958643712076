import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import PageTitle from 'components/Reusable/PageTitle';
import { Button, TextField } from '@mui/material';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';

export default function ResetPassword() {
	const navigate = useNavigate();
	const [submittedEmail, setSubmittedEmail] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const [emailError, setEmailError] = useState('');

	// Regular expression for basic email validation
	const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

	function handleChange(event: { target: { value: string } }) {
		setEmailError('');
		setEmailAddress(event.target.value);
	}

	async function handleSubmit() {
		// Prevent the API call if the email is not valid
		if (!emailRegex.test(emailAddress)) {
			setEmailError('Please enter a valid email address');
			return;
		}

		try {
			const response = await axiosClient.post('/user/forgot_password', {
				email: emailAddress,
			});
			if (response.status === 200) {
				setSubmittedEmail(true);
			} else {
				console.log('Email did not send');
			}
		} catch (error) {
			console.log('Error sending email:', error);
			setEmailError('Failed to send email, please try again');
		}
	}

	return (
		<WithHeaderLayout menuButtonVisible={false} className="px-6 pb-8">
			{!submittedEmail ? (
				<div className="mt-12 w-full max-w-sm space-y-6">
					<PageTitle
						title="Reset your password"
						subtitle="Oops! It happens. Enter the email you associated with your Hearth
							account and we’ll send you a link to reset your password."
						className=""
					/>
					<TextField
						label="Email"
						type="email"
						name="email"
						value={emailAddress}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={Boolean(emailError)}
						helperText={emailError}
					/>
					<Button onClick={handleSubmit} variant="contained" fullWidth>
						Send Email
					</Button>
				</div>
			) : (
				<div className="mt-12 w-full max-w-sm space-y-6">
					<PageTitle
						title="Email sent!"
						subtitle="You should receive a link to reset your password within the next 5 minutes. Note – you will not receive an email if you created your account with SSO."
					/>

					<p className="text-xxs flex">
						Still didn't receive an email?
						<button
							className="text-xxs text-rebrand-teal underline ml-1"
							onClick={handleSubmit}>
							Send again
						</button>
					</p>

					<Button
						onClick={() => navigate('/login')}
						variant="contained"
						fullWidth>
						Back to Login
					</Button>
				</div>
			)}
		</WithHeaderLayout>
	);
}
