import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { isMobile } from 'react-device-detect';

interface DraggableRoutineStepProps {
	index: number;
	children: React.ReactNode;
	onStepClick: () => void;
}

export const DraggableRoutineStep: React.FC<DraggableRoutineStepProps> = ({
	index,
	children,
	onStepClick,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 });

	const [{ isDragging, clientOffset }, drag, preview] = useDrag({
		type: 'routineStep',
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
			clientOffset: monitor.getClientOffset(),
		}),
	});

	useEffect(() => {
		if (isDragging && clientOffset) {
			setDraggingPosition({ x: clientOffset.x, y: clientOffset.y });
		}
	}, [isDragging, clientOffset]);

	drag(ref);
	preview(ref);

	return (
		<div ref={ref} style={{ cursor: 'move' }} onClick={onStepClick}>
			{children}
			{isDragging && isMobile && (
				<DragPreview draggingPosition={draggingPosition}>
					{children}
				</DragPreview>
			)}
		</div>
	);
};

interface DroppableRoutineStepProps {
	index: number;
	moveRoutineStep: (dragIndex: number, hoverIndex: number) => void;
	children: React.ReactNode;
}

export const DroppableRoutineStep: React.FC<DroppableRoutineStepProps> = ({
	index,
	moveRoutineStep,
	children,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const [, drop] = useDrop({
		accept: 'routineStep',
		hover(item: { index: number }, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();

			if (!clientOffset) {
				return;
			}

			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveRoutineStep(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
	});

	drop(ref);

	return <div ref={ref}>{children}</div>;
};

interface IDragPreviewProps {
	draggingPosition: { x: number; y: number };
	children: React.ReactNode;
	className?: string;
}

export const DragPreview = ({
	draggingPosition,
	children,
	className,
}: IDragPreviewProps) => {
	const style: CSSProperties = {
		position: 'fixed',
		pointerEvents: 'none',
		left: 0,
		top: draggingPosition.y,
		transform: 'translate(0%, -50%)',
		zIndex: 1000,
		width: '100%',
	};

	return (
		<div className={`${className}`} style={style}>
			{children}
		</div>
	);
};
