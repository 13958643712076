export const MAX_FILE_SIZE = 25 * 1024 * 1024; // 1MB = 1024 * 1024 bytes

//  Synced with is_valid_image_file helper in backend/apps/upload/helpers.py
export const ACCEPTED_FORMATS = [
	'bmp',
	'gif',
	'jpg',
	'jpeg',
	'png',
	'webp',
	'mpeg',
	'mpg',
	'webm',
	'aiff',
	'caf',
	'heic',
];

export const isValidFormat = (file: File) => {
	const extension = file.name.split('.').pop()?.toLowerCase();

	if (!extension) return false;
	return ACCEPTED_FORMATS.includes(extension);
};

export const isAboveMaxFileSize = (file: File) => {
	return file.size >= MAX_FILE_SIZE;
};

export const bytesToMB = (bytes: number) => {
	return bytes / 1024 / 1024;
};

export const getUploadProgressPercentage = (progressEvent: ProgressEvent) => {
	// Converts Axios onUploadProgress event to percentage value (0-100)
	return Math.round((progressEvent.loaded * 100) / progressEvent.total);
};
