import { useContext, useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import axiosClient from 'utils/axiosClient';
import {
	IAPIFamilyMember,
	ITimezoneObject,
	IAPIRecurrenceDetails,
} from 'utils/types';
import useSelectedDate from 'stores/selectedDate';
import useCurrentUser from 'stores/currentUser';
import analytics from 'utils/segmentClient';
import { useNavigate } from 'react-router-dom';
import { formatEventTime } from 'utils/dateTimeHelper';
import { fetchMembers } from 'utils/eventHelper';
import EventDialog from './EventDialog';
import { UpdateNotificationContext } from 'contexts/NotificationContext';

export default function AddEvent() {
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);
	const [disabledSaveButton, setDisableSaveButton] = useState<boolean>(false);

	// Set initial date from stores and get current user with timezone
	const selectedDate = useSelectedDate((store) => store.selectedDate);
	const { user, isAuthenticated } = useCurrentUser((store) => ({
		user: store.user,
		isAuthenticated: store.isAuthenticated,
	}));

	// Manage state for members and attendees
	const [members, setMembers] = useState<IAPIFamilyMember[]>([]);
	const [currentUserTimeZone, setCurrentUserTimeZone] =
		useState<ITimezoneObject>();

	useEffect(() => {
		if (isAuthenticated) {
			fetchMembers(setMembers);
		}
	}, [isAuthenticated]);

	const roundToNearest30Minutes = (date: Dayjs) => {
		let minutes = date.get('minute');
		let roundedMinutes = Math.ceil(minutes / 30) * 30;
		if (minutes > 30) {
			roundedMinutes = Math.ceil(minutes / 60) * 60;
		}
		return date.set('minute', roundedMinutes);
	};

	async function handleCreateEvent(
		eventForm: any,
		startDateTime: Dayjs,
		endDateTime: Dayjs,
		isAllDayEvent: boolean,
		selectedAttendees: number[],
		selectedColor: string,
		recurrenceDetails?: IAPIRecurrenceDetails,
	) {
		setDisableSaveButton(true);
		await axiosClient
			.post('/web/event', {
				subject: eventForm.title,
				description: eventForm.notes,
				is_all_day_event: isAllDayEvent,
				start_at: formatEventTime(startDateTime, isAllDayEvent),
				start_timezone: currentUserTimeZone?.timezone,
				end_at: formatEventTime(endDateTime, isAllDayEvent, true),
				end_timezone: currentUserTimeZone?.timezone,
				color: selectedColor,
				attendee_list: members
					.filter((member) => selectedAttendees.includes(member.id))
					.map((m) => ({ user_id: m.id })),
				recurrence_details: !!recurrenceDetails
					? {
							freq: recurrenceDetails.freq,
							interval: recurrenceDetails.interval,
							dt_start: formatEventTime(startDateTime, isAllDayEvent),
							by_day: recurrenceDetails.by_day,
							by_month_day: recurrenceDetails.by_month_day,
							count: recurrenceDetails.count,
							until: recurrenceDetails.until,
					  }
					: undefined,
			})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					navigate('/calendar');

					analytics.track('Event created', {
						from: 'web app',
						userId: `user-${user?.id}`,
						isRecurring: !!recurrenceDetails,
					});
					analytics.track('Event attendees', {
						adults: members
							.filter(({ id }) => selectedAttendees)
							.filter((member) => member.is_responsible_adult)
							.map((m) => ({ user_id: m.id })).length,
						kids: members
							.filter(({ id }) => selectedAttendees)
							.filter((member) => !member.is_responsible_adult)
							.map((m) => ({ user_id: m.id })).length,
						userId: `user-${user?.id}`,
					});
				} else {
					updateNotification({
						message:
							'Event could not be created. Make you have selected attendees.',
						showNotification: true,
					});
					setDisableSaveButton(false);
					console.log(
						'Error: Event could not be created. Make you have selected attendees.',
					);
				}
			})
			.catch(() => {
				updateNotification({
					message: 'Event could not be created',
					showNotification: true,
				});
				setDisableSaveButton(false);
				console.log('Error: Event could not be created');
			});
	}

	return (
		<EventDialog
			defaultStart={roundToNearest30Minutes(selectedDate)}
			defaultEnd={roundToNearest30Minutes(selectedDate).add(1, 'hour')}
			defaultRecurrenceDetails={{
				freq: 'weekly',
				interval: 1,
				dt_start: roundToNearest30Minutes(selectedDate).toString(),
				by_day: roundToNearest30Minutes(selectedDate)
					.format('dd')
					.toUpperCase(),
				by_month_day: undefined,
				count: undefined,
				until: undefined,
			}}
			handleCancel={() => navigate('/calendar')}
			handleSubmit={handleCreateEvent}
			disableSaveButton={disabledSaveButton}
			currentUserTimeZone={currentUserTimeZone}
			setCurrentUserTimeZone={setCurrentUserTimeZone}
			members={members}
		/>
	);
}
