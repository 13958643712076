import FooterButton from 'components/Buttons/FooterButton';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { useState } from 'react';
import { IProfileUser } from 'utils/types';

interface IProps {
	handleUserAssign: (profile: IProfileUser) => void;
	assignedUser: IProfileUser;
	profiles: Map<number | null, IProfileUser>;
}

interface IAssignMemberCalendarDrawerProps {
	open: boolean;
	onClose: () => void;
	assignedUser: IProfileUser;
	profiles: Map<number | null, IProfileUser>;
	handleUserAssign: (profile: IProfileUser) => void;
}

export function AssignMemberCalendarDrawer({
	open,
	onClose,
	assignedUser,
	profiles,
	handleUserAssign,
}: IAssignMemberCalendarDrawerProps) {
	const [selectedProfile, setSelectedProfile] =
		useState<IProfileUser>(assignedUser);
	return (
		<SlideUpDrawer open={open} onClose={() => onClose()}>
			<div className="m-6">
				<div className="mb-1 flex justify-between">
					<h6 className="font-medium">Reassign Calendar</h6>
					<button onClick={() => onClose()}>
						<p className="text-xs text-rebrand-teal font-bold">Cancel</p>
					</button>
				</div>
				<p className="mb-4">
					All events on this calendar will show up under this profile.
				</p>
				<div className="flex w-full space-x-4 overflow-x-auto">
					{Array.from(profiles.values())
						.filter((profile) => profile.user_id)
						.map((profile) => (
							<div
								className="flex flex-col items-center"
								key={profile.user_id}
								onClick={() => setSelectedProfile(profile)}>
								<MemberAvatar
									className={`w-12 h-12 ${
										profile.user_id !== selectedProfile.user_id
											? 'opacity-50'
											: 'opacity-100'
									}`}
									avatarFilename={profile.avatar_file_name}
									showCheckmark={profile.user_id === selectedProfile.user_id}
								/>
								{profile.first_name}
							</div>
						))}
				</div>
				<div className="mt-6">
					<FooterButton
						text="Save"
						handleClick={() => {
							handleUserAssign(selectedProfile);
							onClose();
						}}
					/>
				</div>
			</div>
		</SlideUpDrawer>
	);
}

export default function AssignMemberCalendar({
	handleUserAssign,
	assignedUser,
	profiles,
}: IProps) {
	const [changeAssignedUserModal, setChangeAssignedUserModal] =
		useState<boolean>(false);
	return (
		<div>
			<div
				className="w-full mt-4 flex justify-between"
				onClick={() => setChangeAssignedUserModal(true)}>
				<div>Assigned to</div>
				<div>
					<MemberAvatar
						className="w-8 h-8"
						avatarFilename={assignedUser.avatar_file_name}
					/>
				</div>
			</div>
			<AssignMemberCalendarDrawer
				open={changeAssignedUserModal}
				assignedUser={assignedUser}
				onClose={() => setChangeAssignedUserModal(false)}
				profiles={profiles}
				handleUserAssign={handleUserAssign}
			/>
		</div>
	);
}
