interface ITextContainer {
	// Design related parameters
	isFilled?: boolean;
	// TODO: Discuss action item color schemes
	fontColor?: 'primary' | 'secondary' | 'error' | 'neutral';
	//fontSize?: "button" | "submit";
	textTitle?: string;
	textBody?: string;
}

export default function TextContainer({ textTitle, textBody }: ITextContainer) {
	return (
		<div>
			<p className="font-semibold text-lg mb-2 lg:font-semibold lg:text-lg">
				{textTitle}
			</p>
			<p className="text-base lg:text-sm lg:font-normal mb-6 break-words">
				{textBody}
			</p>
		</div>
	);
}
