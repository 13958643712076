import dayjs from 'dayjs';

import { IAPIFamilyMember, IAPITask } from 'utils/types';
import ViewAssignee from './Assignees/ViewAssignee';
import Notes from 'components/Forms/Notes';
import { DueDateTag } from 'components/CompanionApp/Todos/Schedule/DueDateTag';
import { isTaskScheduled } from 'utils/todoHelpers';
import { scheduledTodosFeatureFlag } from 'utils/variables';
import RecurrenceTag from 'components/CompanionApp/Recurrence/RecurrenceTag';
import StreakTag from 'components/CompanionApp/Recurrence/StreakTag';
import DeleteTodo from 'components/CompanionApp/Todos/DeleteTodo';
import { ReactComponent as PriorityStar } from 'assets/rebrand/star.svg';
import { Button } from '@mui/material';

interface IPropTypes {
	task: IAPITask;
	members: IAPIFamilyMember[];
	handleToggleEdit: () => void;
}

export default function ViewTodo({
	task,
	members,
	handleToggleEdit,
}: IPropTypes) {
	const assignedMember = members.find((member) => member.id === task.user_id);
	const shouldShowDueDate = scheduledTodosFeatureFlag && isTaskScheduled(task);
	return (
		<div className="py-6">
			<div className="px-4 w-full">
				<div className="pb-2 flex items-start">
					{task.is_priority && (
						<PriorityStar className="h-6 w-6 mr-2 fill-current text-rebrand-gold" />
					)}
					<h6 className="font-semibold text-rebrand-teal-text">
						{task.subject}
					</h6>
				</div>

				{shouldShowDueDate && <DueDateTag dueAt={dayjs(task.due_at)} />}

				{task.recurrence_details && (
					<div>
						<RecurrenceTag recurrenceDetails={task.recurrence_details} />
						<StreakTag
							completionStreak={task.completion_streak}
							className="my-2"
						/>
					</div>
				)}

				<div className="py-2">
					{assignedMember && (
						<ViewAssignee
							size={48}
							avatar_file_name={assignedMember.avatar_file_name}
							first_name={assignedMember.first_name}
						/>
					)}
				</div>

				{task.description && (
					<div className="flex flex-col">
						<p className="text-xs font-semibold text-blackGreen">Notes</p>
						<Notes value={task.description} disabled={true} />
					</div>
				)}
			</div>

			<hr className="h-2 my-2 w-full border-t-0.5 border-grey-medium" />

			<div className="mt-2 px-4 items-center justify-between flex w-full">
				<DeleteTodo task={task} />
				<Button variant="outlined" className="h-8" onClick={handleToggleEdit}>
					Edit
				</Button>
			</div>
		</div>
	);
}
