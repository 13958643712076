import { FC, useState } from 'react';
import ReusableModal from 'components/Modals/ReusableModal';

const IndependenceTooltip: FC = () => {
	const [showIndependenceModal, setShowIndependenceModal] = useState(false);
	const toggleIndependenceModal = () =>
		setShowIndependenceModal(!showIndependenceModal);

	return (
		<>
			<p className="text-xxs mt-2">
				Why are we asking about this?
				<button
					className="ml-1 text-rebrand-teal underline"
					onClick={toggleIndependenceModal}>
					Learn More
				</button>
			</p>

			{showIndependenceModal && (
				<ReusableModal
					handleToggleModal={toggleIndependenceModal}
					open={showIndependenceModal}
					title="Why are we asking about independence level?"
					showCloseButton>
					<p className="pb-4">
						We understand that every family has different needs! While this may
						not surface in your routines quite yet, we’re exploring how to build
						in features that take independence and assistance into account.
					</p>
				</ReusableModal>
			)}
		</>
	);
};

export default IndependenceTooltip;
