import { IAPIEvent, IAPIExternalCalendar, IAPIFamilyMember } from 'utils/types';
import { buildEventStyles } from 'utils/eventHelper';
import { ReactComponent as RecurringIcon } from 'assets/rebrand/recurring.svg';

interface IProps {
	event: IAPIEvent;
	calendars: IAPIExternalCalendar[];
	members: IAPIFamilyMember[];
	onShowEventDetails: () => void;
}

export default function AllDayEventCell({
	event,
	calendars,
	members,
	onShowEventDetails,
}: IProps) {
	const { opacity, attendeeResponseStatus, backgroundColor, textDecoration } =
		buildEventStyles(calendars, event, members);

	return (
		<div
			style={{
				opacity: opacity,
			}}
			className="flex h-8 w-full mb-1 rounded relative">
			<button onClick={onShowEventDetails} className="absolute w-full h-full">
				<div
					style={{
						backgroundColor:
							attendeeResponseStatus === 'accepted' ? backgroundColor : 'white',
						borderColor: backgroundColor,
						borderWidth: 2,
					}}
					className="flex h-full w-full rounded items-center justify-between">
					<p
						style={{
							color:
								attendeeResponseStatus === 'accepted'
									? 'white'
									: backgroundColor,
							fontWeight: attendeeResponseStatus === 'accepted' ? 700 : 400,
							textDecoration: textDecoration,
						}}
						className="overflow-hidden whitespace-nowrap h-6 text-small ml-1">
						{event.subject}
					</p>
					{!!event.recurrence_rule_id && (
						<RecurringIcon className="w-6 h-6 fill-current text-white mr-1" />
					)}
				</div>
			</button>
		</div>
	);
}
