import React from 'react';

interface IChipProps {
	label: string;
	icon?: JSX.Element;
	className?: string;
}

const Chip: React.FC<IChipProps> = ({ label, icon, className }) => (
	<div
		className={`py-1 px-3 flex flex-row justify-center items-center bg-grey-teal space-x-2 rounded-3xl inline-flex text-grey-green ${className}`}>
		{icon}
		<p>{label}</p>
	</div>
);

export default Chip;
