import Cookies from 'js-cookie';

//This Hook to determine if the user is accessing the app from a webview
//in the companion app. It does this by checking the existance of a cookie
//set by the webview. It can be used to conditionally render components.

export const useIsCompanionAppAccess = () => {
	const accessingFromCompanionApp: boolean = !!Cookies.get('isCompanionApp');
	return accessingFromCompanionApp;
};

export const usePostMessageToCompanionApp = () => {
	const postMessageToCompanionApp = (message: string) => {
		(window as any)?.ReactNativeWebView?.postMessage(message);
	};
	return postMessageToCompanionApp;
};
