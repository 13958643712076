import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRedirectScreenBasedOnAuth } from 'hooks/useRedirectScreenBasedOnAuth';
import Menu from 'screens/CompanionApp/Menu';
import useCurrentUser from 'stores/currentUser';
import useFeatures from 'stores/feature';
import analytics from 'utils/segmentClient';

export default function CompanionAppLayout() {
	function AuthCheck() {
		const navigate = useNavigate();
		const location = useLocation();
		const { user, isAuthenticated, isCurrentUserFetched, fetchCurrentUser } =
			useCurrentUser((store) => ({
				user: store.user,
				isAuthenticated: store.isAuthenticated,
				isCurrentUserFetched: store.isCurrentUserFetched,
				fetchCurrentUser: store.fetchCurrentUser,
			}));
		const redirectScreen = useRedirectScreenBasedOnAuth(isAuthenticated);
		const fetchFeatures = useFeatures((store) => store.fetchFeatures);

		useEffect(() => {
			if (isCurrentUserFetched) {
				if (!isAuthenticated) {
					redirectScreen();
				} else if (user?.isOnboarding) {
					navigate('/setup');
				} else {
					analytics.track('Screen navigation', {
						url: `${location.pathname}`,
						userId: `user-${user?.id}`,
					});
				}
			}
		}, [isCurrentUserFetched, isAuthenticated, redirectScreen]);

		useEffect(() => {
			fetchCurrentUser();
			fetchFeatures();
		}, [location.pathname]);
		return null;
	}

	return (
		<div className="w-full h-screen flex lg:flex-row">
			<div className="hidden lg:block">
				<Menu />
			</div>

			<div className="flex-grow overflow-auto">
				<AuthCheck />
				<Outlet />
			</div>
		</div>
	);
}
