import { ReactComponent as AppStore } from 'assets/Images/app-store.svg';
import { appStoreUrl } from 'utils/variables';

export default function CompanionAppStoreButton() {
	return (
		<a href={appStoreUrl} target={'_blank'} rel={'noopener noreferrer'}>
			<AppStore className="lg:ml-12 mt-2 ml-6 fill-current text-rebrand-grey-medium" />
		</a>
	);
}
