import { useState, ChangeEvent, useContext } from 'react';
import ReusableModal from 'components/Modals/ReusableModal';
import { Button, TextField } from '@mui/material';
import { UpdateNotificationContext } from 'contexts/NotificationContext';

interface IProps {
	currentCalendarName: string;
	handleCloseModal: () => void;
	handleNameChange: (arg0: string) => void;
}

export default function UpdateCalendarNameModal({
	currentCalendarName,
	handleCloseModal,
	handleNameChange,
}: IProps) {
	const [calendarName, setCalendarName] = useState<string>(currentCalendarName);
	const [showError, setShowError] = useState<boolean>(false);
	const updateNotification = useContext(UpdateNotificationContext);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setShowError(false);
		setCalendarName(event.target.value);
	}

	function handleSave() {
		if (calendarName.length > 0) {
			handleNameChange(calendarName);
		} else {
			setShowError(true);
			updateNotification({
				message: 'Calendar name cannot be blank!',
				showNotification: true,
			});
		}
	}

	return (
		<ReusableModal handleToggleModal={handleCloseModal} open title="">
			<div className="pt-6">
				<TextField
					className="w-full"
					name="calendarName"
					label="Calendar Name"
					placeholder="Enter name"
					value={calendarName}
					onChange={handleChange}
					error={showError}
					helperText={showError ?? 'Name required'}
				/>

				<div className="flex flex-row justify-between my-4">
					<Button variant="text" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button variant="outlined" onClick={handleSave}>
						Save
					</Button>
				</div>
			</div>
		</ReusableModal>
	);
}
