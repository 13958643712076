import { useState } from 'react';
import dayjs from 'dayjs';

import RepeatsOn from 'components/CompanionApp/Recurrence/RepeatsOn';
import { IAPIRecurrenceDetails } from 'utils/types';
import Frequency from 'components/CompanionApp/Recurrence/Frequency';
import Ends from 'components/CompanionApp/Recurrence/Ends';
import { DEFAULT_RECURRENCE_DETAILS } from 'utils/recurrenceHelpers';
import Scrim from 'components/Modals/Scrim';
import { Button } from '@mui/material';

var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface IPropTypes {
	handleSave: (arg0: IAPIRecurrenceDetails) => void;
	initialRecurrenceDetails?: IAPIRecurrenceDetails;
	start: dayjs.Dayjs;
}

export default function Repeats({
	handleSave,
	initialRecurrenceDetails = DEFAULT_RECURRENCE_DETAILS,
	start,
}: IPropTypes) {
	const [viewRule, setViewRule] = useState<boolean>(false);

	const [recurrenceDetails, setRecurrenceDetails] =
		useState<IAPIRecurrenceDetails>(initialRecurrenceDetails);

	function handleChange(name: string, value: string | number | undefined) {
		setRecurrenceDetails((state) => ({
			...state,
			[name]: value,
		}));
	}

	function onCancel() {
		setViewRule(false);
		setRecurrenceDetails(initialRecurrenceDetails);
	}

	function onSave() {
		setViewRule(false);
		handleSave(recurrenceDetails);
	}

	return (
		<>
			{viewRule && (
				<Scrim>
					<div
						style={{
							boxShadow:
								'0px 2px 10px rgba(26, 163, 155, 0.12), 0px 4px 20px rgba(26, 163, 155, 0.1)',
						}}
						className="bg-white flex flex-col w-10/12 p-4 rounded-sm drop-shadow-lg shadow-grey-dark shadow-lg lg:w-1/3">
						<p className="text-md text-blackGreen font-semibold h-6">Repeats</p>
						<Frequency
							handleChange={handleChange}
							freq={recurrenceDetails.freq}
							interval={recurrenceDetails.interval}
							byDay={recurrenceDetails.by_day}
							byMonthDay={recurrenceDetails.by_month_day}
							start={start}
						/>

						<Ends
							handleChange={handleChange}
							start={dayjs(initialRecurrenceDetails.dt_start)}
							until={
								!!recurrenceDetails.until
									? dayjs(recurrenceDetails.until)
									: start.add(1, 'month')
							}
							count={
								!!recurrenceDetails.count
									? recurrenceDetails.count
									: !!initialRecurrenceDetails.count
									? initialRecurrenceDetails.count
									: 5
							}
							never={!recurrenceDetails.until && !recurrenceDetails.count}
							on={!!recurrenceDetails.until && !recurrenceDetails.count}
							after={!recurrenceDetails.until && !!recurrenceDetails.count}
						/>

						<div className="flex justify-end mt-3">
							<button onClick={onCancel}>
								<p className="text-xs font-semibold mr-5">Cancel</p>
							</button>
							<Button onClick={onSave} variant="contained">
								<p className="text-md font-bold">Save</p>
							</Button>
						</div>
					</div>
				</Scrim>
			)}
			<RepeatsOn
				freq={initialRecurrenceDetails.freq}
				interval={initialRecurrenceDetails.interval}
				dtStart={dayjs(initialRecurrenceDetails.dt_start)}
				byDay={initialRecurrenceDetails.by_day}
				byMonthDay={initialRecurrenceDetails.by_month_day}
				count={initialRecurrenceDetails.count}
				until={initialRecurrenceDetails.until}
				handleClick={() => {
					setViewRule(!viewRule);
					setRecurrenceDetails(initialRecurrenceDetails);
				}}
			/>
		</>
	);
}
