import { useCallback } from 'react';
import { AvatarSrc } from 'utils/types';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import DragAndDropItem from 'components/DragAndDrop/DragAndDropItem';
import { ReactComponent as DnDSVG } from 'assets/Icons/dnd.svg';
import { IProfile } from 'utils/customOrderHelpers';

interface IDragAndDropProfilesProps {
	profiles: IProfile[];
	setProfiles: (arg0: IProfile[]) => void;
}

const DragAndDropProfiles: React.FC<IDragAndDropProfilesProps> = ({
	profiles,
	setProfiles,
}) => {
	const handleDragAndDrop = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = profiles[dragIndex];
			const hoverItem = profiles[hoverIndex];
			const updatedProfiles = [...profiles];
			updatedProfiles[dragIndex] = hoverItem;
			updatedProfiles[hoverIndex] = dragItem;
			// Swap places of dragItem and hoverItem in the profiles array
			setProfiles(updatedProfiles);
		},
		[profiles],
	);

	return (
		<>
			{profiles.map((profile, index) => (
				<DragAndDropItem
					key={!!profile.user_id ? profile.user_id : 0}
					index={index}
					handleDragAndDrop={handleDragAndDrop}
					style="py-2 px-4 my-2 w-full justify-between">
					<div className="flex items-center">
						<MemberAvatar
							avatarFilename={profile.avatar_file_name}
							avatarSrc={AvatarSrc.REMOTE}
							className="h-12 w-12 mr-3"
						/>
						<p className="text-blackGreen text-sm">{profile.first_name}</p>
					</div>
					<DnDSVG />
				</DragAndDropItem>
			))}
		</>
	);
};

export default DragAndDropProfiles;
