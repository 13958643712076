import { useNavigate } from 'react-router-dom';
import ErrorLayout from 'components/Layouts/ErrorLayout';

export default function Error404() {
	const navigate = useNavigate();

	const title = '404 - Page not found';
	const subtitle =
		'Hmm, something doesn’t look right here. Let’s take you back to the right place!';
	const buttonLabel = 'Back to Home';

	return (
		<ErrorLayout
			title={title}
			subtitle={subtitle}
			buttonLabel={buttonLabel}
			onClick={() => navigate('/')}
		/>
	);
}
