import exitIcon from 'assets/buttons/Cancel.png';

interface IPropTypes {
	onExit: () => void;
}

export default function Exit({ onExit }: IPropTypes) {
	return (
		<div className="w-full flex justify-end mt-8 lg:mt-0 lg:h-0 bg-red z-10">
			<button onClick={onExit}>
				<img style={{ height: 24, width: 24 }} src={exitIcon} alt="exit icon" />
			</button>
		</div>
	);
}
