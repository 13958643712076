import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IAPIRecurrenceDetails } from 'utils/types';
import Frequency from 'components/CompanionApp/Recurrence/Frequency';
import RecurrenceStart from 'components/CompanionApp/Recurrence/RecurrenceStart';
import Ends from 'components/CompanionApp/Recurrence/Ends';
import {
	DEFAULT_RECURRENCE_DETAILS,
	calculateInstanceDueAt,
	handleDtStartChange,
	handleDueAtChange,
} from 'utils/recurrenceHelpers';
import { Button } from '@mui/material';

interface IPropTypes {
	closeRecurrencePopUp: () => void;
	setRecurrenceDetails: (arg0: IAPIRecurrenceDetails) => void;
	setDueAt: (arg0: dayjs.Dayjs) => void;
	recurrenceDetails?: IAPIRecurrenceDetails;
	dueAt: dayjs.Dayjs;
}

export default function RecurrencePopUp({
	closeRecurrencePopUp,
	setRecurrenceDetails,
	setDueAt,
	recurrenceDetails = DEFAULT_RECURRENCE_DETAILS,
	dueAt,
}: IPropTypes) {
	const [selectedRecurrenceDetails, setSelectedRecurrenceDetails] =
		useState<IAPIRecurrenceDetails>(recurrenceDetails);
	const [selectedDueAt, setSelectedDueAt] = useState<dayjs.Dayjs>(dueAt);

	// repeats
	const freq = selectedRecurrenceDetails.freq;
	const interval = selectedRecurrenceDetails.interval;
	const byDay = selectedRecurrenceDetails.by_day;
	const byMonthDay = selectedRecurrenceDetails.by_month_day;

	// starts
	const dtStart = dayjs(selectedRecurrenceDetails.dt_start);

	// ends
	const count = !!selectedRecurrenceDetails.count
		? selectedRecurrenceDetails.count
		: !!recurrenceDetails.count
		? recurrenceDetails.count
		: 5;
	const until = !!selectedRecurrenceDetails.until
		? dayjs(selectedRecurrenceDetails.until)
		: dtStart.add(1, 'month');
	const never =
		!selectedRecurrenceDetails.until && !selectedRecurrenceDetails.count;
	const on =
		!!selectedRecurrenceDetails.until && !selectedRecurrenceDetails.count;
	const after =
		!selectedRecurrenceDetails.until && !!selectedRecurrenceDetails.count;

	// due
	const firstInstanceDueAt = calculateInstanceDueAt(
		selectedRecurrenceDetails,
		dtStart,
	);
	const nextInstanceDueAt = calculateInstanceDueAt(
		selectedRecurrenceDetails,
		selectedDueAt.startOf('day'),
	);
	const isFirstInstance = firstInstanceDueAt.isSame(nextInstanceDueAt);
	const notificationType = isFirstInstance ? 'First' : 'Next';
	const notificationDate = isFirstInstance
		? firstInstanceDueAt
		: nextInstanceDueAt;
	const dueAtNotification = `${notificationType} to-do due ${notificationDate.format(
		'MMM D, YYYY',
	)}`;
	const dueAtNotificationVisible = isFirstInstance
		? !dtStart.isSame(firstInstanceDueAt)
		: !selectedDueAt.startOf('day').isSame(nextInstanceDueAt);

	function handleChange(name: string, value: any) {
		setSelectedRecurrenceDetails((state) => ({
			...state,
			[name]: value,
		}));
	}

	function onCancel() {
		closeRecurrencePopUp();
	}

	function onSave() {
		if (selectedRecurrenceDetails.by_day === '') {
			const currentDay = dayjs().format('dd').toUpperCase();
			setSelectedRecurrenceDetails((state) => ({
				...state,
				by_day: currentDay,
			}));
		}
		setRecurrenceDetails(selectedRecurrenceDetails);
		setDueAt(nextInstanceDueAt);
		closeRecurrencePopUp();
	}

	useEffect(() => {
		// update byMonthDay or byDay for monthly rule when dtStart changes
		if (freq === 'monthly') {
			handleDtStartChange(handleChange, dtStart, byMonthDay);
		}
		// update dueAt to first instance after dtStart when dtStart changes
		if (selectedRecurrenceDetails.dt_start !== recurrenceDetails.dt_start) {
			setSelectedDueAt(firstInstanceDueAt);
		}
	}, [dtStart.date()]);

	// update dtStart if dueAt already selected
	useEffect(() => {
		if (recurrenceDetails === DEFAULT_RECURRENCE_DETAILS) {
			handleDueAtChange(handleChange, dueAt);
		}
	}, [dueAt.date()]);

	return (
		<div>
			<p className="text-md text-blackGreen font-semibold h-6">Repeats</p>
			<Frequency
				handleChange={handleChange}
				freq={freq}
				interval={interval}
				byDay={byDay}
				byMonthDay={byMonthDay}
				start={dtStart}
			/>

			{dueAtNotificationVisible && (
				<p className="text-xs text-grey-green mt-3">{dueAtNotification}</p>
			)}

			<RecurrenceStart handleChange={handleChange} dtStart={dtStart} />

			<Ends
				handleChange={handleChange}
				start={dtStart}
				until={until}
				count={count}
				never={never}
				on={on}
				after={after}
			/>

			<div className="flex justify-between items-center mt-3">
				<button onClick={onCancel}>
					<p className="text-xs font-semibold text-rebrand-teal">Cancel</p>
				</button>
				<Button onClick={onSave} variant="outlined" className="h-8">
					Save
				</Button>
			</div>
		</div>
	);
}
