import { useEffect, useState, useContext, Fragment, useMemo } from 'react';
import { ReactComponent as EditIcon } from 'assets/Icons/edit.svg';
import { ReactComponent as PersonIcon } from 'assets/Icons/person.svg';
import { ReactComponent as ColorIcon } from 'assets/Icons/color.svg';
import { ReactComponent as GarbageIcon } from 'assets/Icons/garbage.svg';
import { Link, useNavigate } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import useCurrentUser from 'stores/currentUser';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import PageTitle from 'components/Reusable/PageTitle';
import { getCalendarColor } from 'utils/colorHelpers';
import {
	ICalendar,
	CalendarProviderTypes,
	ICalendarUpdateParams,
	IProfileUser,
	IProfileCalendar,
	ICalendarUpdateType,
	NotificationType,
	IAPIFamilyMember,
} from 'utils/types';
import CalendarEntry, {
	CalendarHeader,
} from 'components/CompanionApp/ManageCalendars/CalendarEntry';
import CalendarDetailsPopup from 'components/Modals/ManageCalendars/CalendarDetailsPopup';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { Button } from '@mui/material';
import EditDrawer from 'components/Reusable/EditDrawer';
import UpdateCalendarNameModal from 'components/Modals/ManageCalendars/UpdateCalendarNameModal';
import ColorPicker from 'components/Modals/ColorPicker';
import { AssignMemberCalendarDrawer } from 'components/Modals/ManageCalendars/AssignMemberCalendar';
import UnsubscribeModal from 'components/Modals/ManageCalendars/UnsubscribeModal';
import analytics from 'utils/segmentClient';
import { useFamilyMemberList } from 'contexts/FamilyContext';
import {
	usePostMessageToCompanionApp,
	useIsCompanionAppAccess,
} from 'hooks/companionAppHooks';

enum DrawerAction {
	NAME = 'name',
	COLOR = 'color',
	ASSIGN = 'assign',
	UNSUBSCRIBE = 'unsubscribe',
}

export default function ManageCalendars() {
	const navigate = useNavigate();
	const familyMembers = useFamilyMemberList();
	const { user, isAuthenticated, isCurrentUserFetched } = useCurrentUser(
		(store) => ({
			user: store.user,
			isAuthenticated: store.isAuthenticated,
			isCurrentUserFetched: store.isCurrentUserFetched,
		}),
	);
	const [profileMap, setProfileMap] = useState<
		Map<number | null, IProfileUser>
	>(new Map());
	const [selectedCalendarId, setSelectedCalendarId] = useState<number | null>();
	const [drawerCalendarId, setDrawerCalendarId] = useState<number | null>();
	const [drawerAction, setDrawerAction] = useState<DrawerAction | null>();

	const userMember: IAPIFamilyMember | undefined = useMemo(
		() => familyMembers.find((member) => member.id === user?.id),
		[familyMembers, user],
	);
	const calendars: ICalendar[] = useMemo(
		() =>
			Array.from(profileMap, ([_, profile]) =>
				profile.calendars?.map((calendar) => ({
					id: calendar.id,
					includeEventDetails: calendar.include_event_details,
					isPublic: calendar.is_public,
					isUsedInHearth: calendar.is_used_in_hearth,
					name: calendar.name,
					publicKey: calendar.public_key,
					userId: calendar.user_id,
					assignedUserId: calendar.assigned_user_id,
					color: calendar.color,
					provider: calendar.provider,
				})),
			).flat(),
		[profileMap],
	);

	const updateNotification = useContext(UpdateNotificationContext);
	const postMessageToCompanionApp = usePostMessageToCompanionApp();
	const isCompanionApp = useIsCompanionAppAccess();

	useEffect(() => {
		if (isCurrentUserFetched && !isAuthenticated) {
			navigate('/login');
		}
	}, [isCurrentUserFetched, isAuthenticated]);

	async function updateCalendar(params: ICalendarUpdateParams) {
		const {
			type,
			id,
			name,
			isPublic,
			checked: isUsedInHearth,
			includeEventDetails,
			assignedUserId,
			color,
		} = params;
		await axiosClient
			.put<IProfileCalendar>(`/web/calendar/${id}`, {
				name,
				is_used_in_hearth: isUsedInHearth,
				include_event_details: includeEventDetails,
				color: color,
				assigned_user_id: assignedUserId,
			})
			.then(() => {
				postMessageToCompanionApp('calendars_updated');
				const successMessageMap = {
					[ICalendarUpdateType.name]: 'Calendar name updated',
					[ICalendarUpdateType.color]: 'Calendar color updated',
					[ICalendarUpdateType.assign]: 'Calendar assignee updated',
					[ICalendarUpdateType.toggle]: 'Calendar toggled',
				};

				updateNotification({
					message: successMessageMap[type] || 'Calendar updated',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
			})
			.catch(() => {
				const errorMessageMap = {
					[ICalendarUpdateType.name]: 'Cannot update calendar name',
					[ICalendarUpdateType.color]: 'Calendar name cannot be blank!',
					[ICalendarUpdateType.assign]: 'Cannot assign calendar to user',
					[ICalendarUpdateType.toggle]: 'Cannot toggle calendar visibility',
				};

				updateNotification({
					message: errorMessageMap[type] || 'Unable to update calendar',
					showNotification: true,
				});
			});

		updateState({
			id,
			name,
			isPublic,
			isUsedInHearth,
			includeEventDetails,
			color,
			assignedUserId,
		});

		let value;
		switch (type) {
			case ICalendarUpdateType.name:
				value = name;
				break;
			case ICalendarUpdateType.color:
				value = color;
				break;
			case ICalendarUpdateType.assign:
				value = assignedUserId;
				break;
			case ICalendarUpdateType.toggle:
				value = includeEventDetails;
				break;
			default:
				value = null;
		}
		await analytics.track('family_calendar_updated', {
			from: 'web app',
			action: type,
			value,
			calendar_id: id,
			userId: `user-${user?.id}`,
			family_id: userMember?.family_id,
			is_adult: userMember?.is_responsible_adult,
			is_kid: !userMember?.is_responsible_adult,
		});
	}

	async function updateHearthCalendarVisibility(params: ICalendarUpdateParams) {
		const {
			id,
			name,
			isPublic,
			checked: isUsedInHearth,
			includeEventDetails,
			color,
		} = params;
		const calendarData = await axiosClient.post(
			`/web/calendar/${id}/visibility`,
			{
				is_public: isPublic,
			},
		);

		if (calendarData.status === 200) {
			postMessageToCompanionApp('calendars_updated');
		}

		const publicKey = calendarData.data.public_key;

		updateState({
			id,
			name,
			isPublic,
			isUsedInHearth,
			includeEventDetails,
			color,
			publicKey,
			assignedUserId: null,
		});
	}

	const updateCalendarMap = {
		[CalendarProviderTypes.HEARTH]: updateHearthCalendarVisibility,
		[CalendarProviderTypes.GOOGLE]: updateCalendar,
		[CalendarProviderTypes.ICAL]: updateCalendar,
		[CalendarProviderTypes.ICS]: updateCalendar,
		[CalendarProviderTypes.OUTLOOK]: updateCalendar,
	};

	async function unsubscribeCalendar(id: number) {
		await axiosClient
			.delete(`/web/external_calendar/ical/${id}`)
			.then(() => {
				const calendarUser = getSelectedCalendarProfile(id);
				const profile = profileMap.get(calendarUser!.user_id);
				profile?.calendars.splice(
					profile?.calendars.findIndex((calendar) => calendar.id === id),
					1,
				);
				setProfileMap(new Map(profileMap.set(profile!.user_id, profile!)));
			})
			.then(async () => {
				postMessageToCompanionApp('calendars_updated');
				updateNotification({
					message: 'Calendar removed',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
				await analytics.track('family_calendar_updated', {
					from: 'web app',
					action: 'unsubscribe',
					value: id,
					calendar_id: id,
					family_id: userMember?.family_id,
					is_adult: userMember?.is_responsible_adult,
					is_kid: !userMember?.is_responsible_adult,
				});
			})
			.catch(() => {
				updateNotification({
					message: 'Unable to unsubscribe from calendar',
					showNotification: true,
				});
			})
			.finally(() => {
				setSelectedCalendarId(null);
			});
	}

	function updateState({
		id,
		name,
		isPublic,
		isUsedInHearth,
		includeEventDetails,
		color,
		assignedUserId,
		publicKey,
	}: {
		id: number;
		name: string;
		isPublic: boolean;
		isUsedInHearth: boolean;
		includeEventDetails: boolean;
		color: string | null;
		publicKey?: string;
		assignedUserId: number | null;
	}) {
		const calendarUser = getSelectedCalendarProfile(id);
		const calendarIndex = calendarUser!.calendars.findIndex(
			(calendar) => calendar.id === id,
		);
		const calendar: IProfileCalendar = {
			...calendarUser!.calendars[calendarIndex],
			name,
			is_public: isPublic,
			is_used_in_hearth: isUsedInHearth,
			include_event_details: includeEventDetails,
			color: color,
			public_key: publicKey,
			assigned_user_id: assignedUserId,
		};

		if (assignedUserId && assignedUserId !== calendarUser?.user_id) {
			calendarUser!.calendars.splice(calendarIndex, 1);
			const assignedUser = profileMap.get(assignedUserId);
			assignedUser?.calendars.push(calendar);
			setProfileMap(
				new Map(
					profileMap
						.set(calendarUser!.user_id, calendarUser!)
						.set(assignedUserId, assignedUser!),
				),
			);
		} else {
			calendarUser!.calendars[calendarIndex] = calendar;
			setProfileMap(
				new Map(profileMap.set(calendarUser!.user_id, calendarUser!)),
			);
		}
	}

	async function getAndSetCalendars() {
		if (isAuthenticated) {
			try {
				const response = await axiosClient.get<IProfileUser[]>(
					`/web/calendar`,
					{
						params: {
							for_family: true,
							with_profile: true,
						},
					},
				);
				const profiles = response.data;
				setProfileMap(
					new Map(profiles.map((profile) => [profile.user_id, profile])),
				);
			} catch (error) {
				console.error('Could not get calendars');
			}
		}
	}

	useEffect(() => {
		if (isAuthenticated) {
			getAndSetCalendars();
		}
	}, [isAuthenticated]);

	const getSelectedCalendar = (selectedCalendarId: number) => {
		return calendars.find((calendar) => calendar.id === selectedCalendarId);
	};
	const getSelectedCalendarProfile = (selectedCalendarId?: number | null) => {
		return Array.from(profileMap.values()).find((profile) =>
			profile.calendars.some((calendar) => calendar.id === selectedCalendarId),
		);
	};
	const clearDrawer = () => {
		setDrawerCalendarId(null);
		setDrawerAction(null);
	};
	const getCalendarActions = (selectedCalendarId?: number | null) => {
		if (!selectedCalendarId) {
			return [];
		}
		const editAction = {
			icon: <EditIcon />,
			label: 'Rename',
			onClick: () => setDrawerAction(DrawerAction.NAME),
		};
		const colorAction = {
			icon: <ColorIcon />,
			label: 'Change Color',
			onClick: () => setDrawerAction(DrawerAction.COLOR),
		};
		const assignAction = {
			icon: <PersonIcon />,
			label: 'Reassign',
			onClick: () => setDrawerAction(DrawerAction.ASSIGN),
		};
		const unsubscribeAction = {
			icon: <GarbageIcon />,
			label: 'Unsubscribe',
			color: 'text-error',
			onClick: () => setDrawerAction(DrawerAction.UNSUBSCRIBE),
		};
		const actionMap = {
			[CalendarProviderTypes.HEARTH]: [],
			[CalendarProviderTypes.GOOGLE]: [colorAction, assignAction],
			[CalendarProviderTypes.ICAL]: [
				editAction,
				colorAction,
				assignAction,
				unsubscribeAction,
			],
			[CalendarProviderTypes.ICS]: [
				editAction,
				colorAction,
				assignAction,
				unsubscribeAction,
			],
			[CalendarProviderTypes.OUTLOOK]: [
				editAction,
				colorAction,
				assignAction,
				unsubscribeAction,
			],
		};
		const selectedCalendar = getSelectedCalendar(selectedCalendarId);
		return actionMap[selectedCalendar?.provider as CalendarProviderTypes];
	};

	const getCalendarsOfUser = (userId: number | null) => {
		const profile = profileMap.get(userId);
		return (
			<div className="flex flex-col space-y-2">
				{profile?.calendars.map((calendar) => {
					const currentColor = getCalendarColor(
						calendar.color,
						profile?.color,
						user?.id,
					);

					return (
						<CalendarEntry
							key={calendar.id}
							calendarName={calendar.name}
							color={currentColor}
							hasEllipsis={userId !== null}
							onClick={() => setSelectedCalendarId(calendar.id)}
							onEllipsisClick={() => setDrawerCalendarId(calendar.id)}
						/>
					);
				})}
			</div>
		);
	};

	const selectedCalendar = selectedCalendarId
		? getSelectedCalendar(selectedCalendarId)
		: null;
	const drawerCalendar = drawerCalendarId
		? getSelectedCalendar(drawerCalendarId)
		: null;

	const actions = useMemo(
		() => getCalendarActions(drawerCalendarId),
		[drawerCalendarId],
	);

	return (
		<WithHeaderLayout className="pb-24">
			<div className="mx-6 mt-10">
				<PageTitle title="Manage Calendars" />
			</div>
			<div className="flex justify-center mt-6 mx-6">
				<Link to={'/connect-calendars'} className="w-full">
					<Button variant="outlined" fullWidth>
						Connect Calendars
					</Button>
				</Link>
			</div>

			{Array.from(profileMap, ([userId, profile]) => {
				if (profile.calendars.length > 0) {
					return (
						<Fragment key={userId}>
							<CalendarHeader avatarFilename={profile.avatar_file_name}>
								{profile.first_name}
							</CalendarHeader>
							<div className="mt-2 mx-6">
								{getCalendarsOfUser(profile.user_id)}
							</div>
						</Fragment>
					);
				} else {
					return (
						<Fragment key={userId}>
							<CalendarHeader avatarFilename={profile.avatar_file_name}>
								{profile.first_name}
							</CalendarHeader>
							<div className="mt-1 mx-6 px-2 py-4 bg-grey-teal text-rebrand-gray-dark rounded text-center">
								No connected calendars.
							</div>
						</Fragment>
					);
				}
			})}

			{calendars.length === 0 && (
				<div className="mt-1 mx-6 py-4 bg-grey-teal rounded">
					<p className="text-grey-dark text-center">
						You haven’t connected any calendars. These calendars are attached to
						your profile only.{' '}
						<button
							className="text-grey-dark underline"
							onClick={() =>
								window.open(
									'https://hearthdisplay.kustomer.help/en_us/best-practices-for-setting-up-your-calendars-HJr_gkVh2',
									'_blank',
								)
							}>
							Learn More
						</button>
					</p>
				</div>
			)}

			<SlideUpDrawer
				open={!!selectedCalendar}
				onClose={() => setSelectedCalendarId(null)}>
				{!!selectedCalendar && (
					<div className={`py-4 ${isCompanionApp ? 'pb-10' : ''}`}>
						<CalendarDetailsPopup
							handleCloseModal={() => setSelectedCalendarId(null)}
							calendar={selectedCalendar}
							updateCalendar={
								updateCalendarMap[
									selectedCalendar.provider as CalendarProviderTypes
								]
							}
							user={user}
							profiles={profileMap}
							assignedUser={getSelectedCalendarProfile(selectedCalendarId)}
							unsubscribeCalendar={unsubscribeCalendar}
						/>
					</div>
				)}
			</SlideUpDrawer>

			<EditDrawer
				open={!!drawerCalendar}
				onClose={() => setDrawerCalendarId(null)}
				actions={actions}
			/>

			{drawerCalendar && drawerAction === DrawerAction.NAME && (
				<UpdateCalendarNameModal
					currentCalendarName={drawerCalendar.name}
					handleCloseModal={() => setDrawerAction(null)}
					handleNameChange={(name) =>
						updateCalendarMap[drawerCalendar.provider as CalendarProviderTypes](
							{
								type: ICalendarUpdateType.name,
								name,
								id: drawerCalendar.id,
								isPublic: drawerCalendar.isPublic,
								checked: drawerCalendar.isUsedInHearth,
								color: drawerCalendar.color,
								includeEventDetails: drawerCalendar.includeEventDetails,
								assignedUserId: drawerCalendar.assignedUserId,
							},
						).then(clearDrawer)
					}
				/>
			)}
			{drawerCalendar && drawerAction === DrawerAction.COLOR && (
				<SlideUpDrawer
					open={drawerAction === DrawerAction.COLOR}
					onClose={clearDrawer}>
					<ColorPicker
						handleCancel={() => setDrawerAction(null)}
						handleSave={() => undefined}
						handleSelect={(color: string) =>
							updateCalendarMap[
								drawerCalendar.provider as CalendarProviderTypes
							]({
								type: ICalendarUpdateType.color,
								color,
								id: drawerCalendar.id,
								name: drawerCalendar.name,
								isPublic: drawerCalendar.isPublic,
								checked: drawerCalendar.isUsedInHearth,
								includeEventDetails: drawerCalendar.includeEventDetails,
								assignedUserId: drawerCalendar.assignedUserId,
							}).then(clearDrawer)
						}
						selectedColor={getCalendarColor(
							drawerCalendar.color,
							profileMap.get(drawerCalendar.assignedUserId)?.color,
							user?.id,
						)}
						isNewLayout
					/>
				</SlideUpDrawer>
			)}

			{drawerCalendar && drawerAction === DrawerAction.ASSIGN && (
				<AssignMemberCalendarDrawer
					open={drawerAction === DrawerAction.ASSIGN}
					onClose={clearDrawer}
					assignedUser={getSelectedCalendarProfile(drawerCalendarId)!}
					profiles={profileMap}
					handleUserAssign={(profile: IProfileUser) =>
						updateCalendarMap[drawerCalendar.provider as CalendarProviderTypes](
							{
								type: ICalendarUpdateType.assign,
								id: drawerCalendar.id,
								name: drawerCalendar.name,
								isPublic: drawerCalendar.isPublic,
								checked: drawerCalendar.isUsedInHearth,
								includeEventDetails: drawerCalendar.includeEventDetails,
								color: null,
								assignedUserId: profile.user_id,
							},
						).then(clearDrawer)
					}
				/>
			)}

			{drawerCalendar && drawerAction === DrawerAction.UNSUBSCRIBE && (
				<UnsubscribeModal
					handleCloseModal={() => setDrawerAction(null)}
					handleUnsubscribe={() => {
						unsubscribeCalendar(drawerCalendarId!);
						clearDrawer();
					}}
				/>
			)}
		</WithHeaderLayout>
	);
}
