const {
	REACT_APP_API_HOST,
	REACT_APP_API_ROOT,
	REACT_APP_SENTRY_URI,
	REACT_APP_UPLOAD_BASE_URL,
	REACT_APP_SEGMENT_WRITE_KEY,
	REACT_APP_RECURRING_EVENTS_FF,
	REACT_APP_ROUTINES_FF,
	REACT_APP_PROFILE_COLOR_FF,
	REACT_APP_ICAL_SUBSCRIPTION_FF,
	REACT_APP_SCHEDULED_TODOS_FF,
	REACT_APP_SCREENSAVER_FF,
	REACT_APP_COMPANION_APP_STORE_LINK,
} = process.env;

export const apiHost: string = `${REACT_APP_API_HOST}`;
export const apiRoot: string = `${REACT_APP_API_ROOT}`;

export const uploadBaseUrl: string = `${REACT_APP_UPLOAD_BASE_URL}`;
export const avatarUrl: string = `${REACT_APP_UPLOAD_BASE_URL}/avatars`;
export const sentryUri: string = `${REACT_APP_SENTRY_URI}`;

export const iconPath: string = '/assets/icons';
export const appStoreUrl: string = 'https://apps.apple.com/us/app/id6498717775';
export const segmentWriteKey: string = `${REACT_APP_SEGMENT_WRITE_KEY}`;

export const recurringEventsFeatureFlag: boolean =
	`${REACT_APP_RECURRING_EVENTS_FF}` === 'true';
export const routinesFeatureFlag: boolean =
	`${REACT_APP_ROUTINES_FF}` === 'true';
export const profileColorFeatureFlag: boolean =
	`${REACT_APP_PROFILE_COLOR_FF}` === 'true';
export const iCalSubscriptionFeatureFlag: boolean =
	`${REACT_APP_ICAL_SUBSCRIPTION_FF}` === 'true';
export const scheduledTodosFeatureFlag: boolean =
	`${REACT_APP_SCHEDULED_TODOS_FF}` === 'true';
export const screensaverFeatureFlag: boolean =
	`${REACT_APP_SCREENSAVER_FF}` === 'true';
export const companionAppStoreLink: boolean =
	`${REACT_APP_COMPANION_APP_STORE_LINK}` === 'true';
