import { ReactNode } from 'react';
import MemberName from 'components/CompanionApp/Todos/Assignees/MemberName';
import { ReactComponent as CheckCircle } from 'assets/rebrand/check-circle-white.svg';

interface IPropTypes {
	children: ReactNode;
	firstName?: string;
	isSelected?: boolean;
	showCheck?: boolean;
}

export const SelectAvatarLayout = ({
	children,
	firstName,
	isSelected = false,
	showCheck = true,
}: IPropTypes) => (
	<div className="flex flex-col items-center justify-center">
		<div
			className={`relative transition-opacity ${
				isSelected ? 'opacity-100' : 'opacity-40'
			}`}>
			{children}
			{showCheck && isSelected && (
				<div className="absolute -right-1 -bottom-1">
					<CheckCircle className="fill-current text-rebrand-teal h-5 w-5" />
				</div>
			)}
		</div>
		{firstName && <MemberName first_name={firstName} />}
	</div>
);
