import { ReactComponent as CheckCircleIcon } from 'assets/rebrand/check-circle-white.svg';
import { FC } from 'react';

interface ICheckmarkTypes {
	className?: string;
}

const Checkmark: FC<ICheckmarkTypes> = ({
	className = 'bg-rebrand-teal w-5 h-5',
}) => <CheckCircleIcon className={`rounded-full ${className}`} />;

export default Checkmark;
