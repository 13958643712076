import React, { useState } from 'react';
import sortIcon from 'assets/Icons/Todos/sortIcon.svg';
import { SORT_OPTIONS, getProfileType } from 'utils/todoHelpers';
import { IAPIFamilyMember } from 'utils/types';
import analytics from 'utils/segmentClient';
import useCurrentUser from 'stores/currentUser';
import { useFetchTasks, useSaveSortBy, useSortBy } from 'contexts/TodosContext';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { TealRadioButton } from '../Recurrence/Radio';

interface IPropTypes {
	members: IAPIFamilyMember[];
}

export default function SortTodos({ members }: IPropTypes) {
	const currentUser = useCurrentUser((store) => store.user);
	const fetchTasks = useFetchTasks();
	const sortBy = useSortBy();
	const saveSortBy = useSaveSortBy();
	const [showOptions, setShowOptions] = useState<boolean>(false);

	function handleSelectOption(option: string) {
		saveSortBy(option);
		setShowOptions(false);
		fetchTasks();
		analytics.track('todo_sorted', {
			from: 'web app',
			user_id: `user-${currentUser?.id}`,
			profile_type: getProfileType(members, currentUser?.id),
			sort_date: sortBy === 'Date',
			sort_newest: sortBy === 'Newest',
			sort_priority: sortBy === 'Priority',
		});
	}

	return (
		<>
			{/* SORT BUTTON */}
			<button
				onClick={() => setShowOptions(!showOptions)}
				className="bg-rebrand-gray-light mb-1 py-1 px-3 rounded-full w-max flex items-center">
				<img src={sortIcon} alt="sort" />
				<p className="text-sm text-rebrand-gray-dark">
					{sortBy === '' ? 'Sort' : sortBy}
				</p>
			</button>
			{/* SORT OPTIONS POP UP */}
			<SlideUpDrawer open={showOptions} onClose={() => setShowOptions(false)}>
				<div className="p-6">
					<h6 className="text-md text-rebrand-teal-text font-semibold">
						Sort by
					</h6>
					{SORT_OPTIONS.map((option) => (
						<div
							key={`sort-option-${option}`}
							className="flex w-full items-center justify-between">
							<p className="text-sm text-blackGreen">{option}</p>
							<TealRadioButton
								checked={sortBy === option}
								onClick={() => handleSelectOption(option)}
								name="radio-button"
								inputProps={{ 'aria-label': `Sort by ${option}` }}
							/>
						</div>
					))}
					<button
						onClick={() => setShowOptions(false)}
						className="mt-4 text-xs font-bold text-rebrand-teal">
						Cancel
					</button>
				</div>
			</SlideUpDrawer>
		</>
	);
}
