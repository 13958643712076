import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { sentryUri } from 'utils/variables';
import WebAppRouter from 'routers/WebAppRouter';
import { NotificationProvider } from 'contexts/NotificationContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import { defaultTheme } from 'styles/mui-themes';
import { ThemeProvider } from '@mui/material';

if (!!sentryUri && sentryUri !== '') {
	try {
		Sentry.init({
			dsn: sentryUri,
			integrations: [new Integrations.BrowserTracing()],
			tracesSampleRate: 1.0,
		});
		console.log('Sentry initialized');
	} catch (error) {
		console.error('Sentry initialization failed');
	}
}

function App() {
	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
					<NotificationProvider>
						<WebAppRouter />
					</NotificationProvider>
				</DndProvider>
			</ThemeProvider>
		</>
	);
}

export default App;
