interface IPropTypes {
	color?: string;
	width?: number;
	className?: string;
}

export function DividingLine({
	color = 'grey-medium',
	width = 0.5,
	className = '',
}: IPropTypes) {
	return (
		<hr
			className={`h-2 mt-2 w-full border-t-${width} border-${color} ${className}`}
		/>
	);
}
