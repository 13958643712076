import { useState } from 'react';

import CodeInput from 'components/Forms/CodeInput';

import axiosClient from 'utils/axiosClient';
import PageTitle from '../../../components/Reusable/PageTitle';
import { Button } from '@mui/material';
import SetupContainer from 'components/Layouts/SetupContainer';
import useCurrentUser from 'stores/currentUser';
import { useSetupContext } from '../SetupLayout';

enum VerifyEmailErrorStates {
	REQUIRED = 'Required',
	INCORRECT_CODE = 'Incorrect code',
}

export default function VerifyEmailAddress() {
	const { user, fetchCurrentUser } = useCurrentUser((store) => ({
		user: store.user,
		fetchCurrentUser: store.fetchCurrentUser,
	}));
	const { nextStep } = useSetupContext();

	const [otp, setOtp] = useState(new Array(6).fill(''));
	const [verifyEmailErrorState, setVerifyEmailErrorState] =
		useState<VerifyEmailErrorStates>();

	async function handleSubmit() {
		if (otp.join('').length < 6) {
			setVerifyEmailErrorState(VerifyEmailErrorStates.REQUIRED);
			return;
		}

		try {
			const response = await axiosClient.post('/onboarding/user/verify_email', {
				email: user!.email,
				code: otp.join(''),
			});
			if (response.status === 200 && response.data.status === 'verified') {
				await fetchCurrentUser();
				nextStep();
			}
		} catch {
			setVerifyEmailErrorState(VerifyEmailErrorStates.INCORRECT_CODE);
		}
	}

	async function handleResend() {
		const response = await axiosClient.post(
			'/onboarding/user/resend_email_code',
			{
				email: user!.email,
			},
		);
		if (response.status === 200) {
			alert('Code resent');
		}
	}

	return (
		<SetupContainer>
			<PageTitle title="Please verify your email" />
			<p className="mt-2">We've sent a verification code to</p>
			<p className="font-semibold">{user?.email}</p>

			<form onSubmit={handleSubmit}>
				<CodeInput
					code={otp}
					setCode={setOtp}
					errorText={verifyEmailErrorState}
				/>
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Continue
				</Button>
			</form>

			<div className="text-xxs text-center mt-4">
				Didn't receive the email?&nbsp;
				<button
					onClick={handleResend}
					className="font-medium text-rebrand-teal underline">
					Resend email
				</button>
			</div>
		</SetupContainer>
	);
}
