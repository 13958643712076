import { ICurrentUser, IFamilyMember, IRoutineStep } from 'utils/types';
import { IRoutineForm } from 'components/Routines/RoutineForm';

export const generateRoutinePayload = (
	currentUser: ICurrentUser | undefined,
	routineUsers: IFamilyMember[],
	routineFormData: IRoutineForm,
	routineSteps: IRoutineStep[],
	templateName?: string,
) => {
	const { recurrenceDetails } = routineFormData;
	const { freq, byDay } = recurrenceDetails || { freq: '', byDay: '' };

	const isChildIncluded = routineUsers.some((user) => !user.isResponsibleAdult);

	return {
		from: 'web app',
		userId: currentUser ? `user-${currentUser?.id}` : 'anonymous',
		is_daily: freq === 'daily',
		is_weekly: freq === 'weekly',
		is_monthly: freq === 'monthly',
		is_yearly: freq === 'yearly',
		frequency: freq,
		occurs_on: byDay,
		non_recurring: Boolean(recurrenceDetails),
		created_by: currentUser,
		created_for: isChildIncluded,
		routine_users: routineUsers,
		number_of_steps: routineSteps.length,
		template_type: templateName,
		independence_level: routineUsers.map(
			(user) => INDEPENDENCE_LEVELS[user.independenceLevelId],
		),
	};
};

const INDEPENDENCE_LEVELS: { [key: number]: string } = {
	1: 'can finish tasks independently',
	2: 'can finish tasks with supervision',
	3: 'needs assistance with their tasks',
};
