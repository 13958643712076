import { Dispatch } from 'react';

interface ICodeInput {
	code: Array<string>;
	setCode: Dispatch<Array<string>>;
	errorText?: string;
}

const MULTIPLE_NUMERIC_REGEX = /^[0-9]+$/;
const SINGLE_NUMERIC_REGEX = /^[0-9]$/;

export default function CodeInput({ code, setCode, errorText }: ICodeInput) {
	const handleChange = (event: any, index: number) => {
		// Handle code autofill for iOS
		if (
			event.target.value.length === code.length &&
			MULTIPLE_NUMERIC_REGEX.test(event.target.value)
		) {
			setCode(event.target.value.split(''));
		} else {
			const newValue = SINGLE_NUMERIC_REGEX.test(event.target.value)
				? event.target.value
				: '';

			const codeCopy = [...code];
			codeCopy[index] = newValue;
			setCode(codeCopy);

			if (!!newValue && event.target.nextSibling) {
				event.target.nextSibling.focus();
			}
		}
	};

	const inputClassName =
		'mx-0.5 w-12 px-4 py-6 border-2 border-rebrand-grey-medium focus:border-rebrand-teal rounded-lg focus:outline-none';

	return (
		<div className="mt-6 mb-7">
			<div className="flex flex-row flex-1 w-full space-x-2 sm:space-x-8">
				{code.map((data, index) => (
					<input
						className={inputClassName}
						type="text"
						name="otp"
						inputMode="numeric"
						key={index}
						value={data}
						onChange={(event) => handleChange(event, index)}
						onFocus={(event) => event.target.select()}
						autoFocus={index === 0}
						aria-label={'Enter code ' + (index + 1)}
						autoComplete="one-time-code"
					/>
				))}
			</div>
			{!!errorText && (
				<div className="text-xxs ml-4 my-1 text-error">{errorText}</div>
			)}
		</div>
	);
}
