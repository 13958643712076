import { ReactNode } from 'react';
import { ReactComponent as StarSVG } from 'assets/Icons/Membership/featureGateStar.svg';

interface IPropTypes {
	title: string;
	subtitle?: string;
	ctaButton?: ReactNode;
	children?: ReactNode;
	className?: string;
	isAvailable?: boolean;
}

export default function PageTitle({
	title,
	subtitle,
	ctaButton,
	children,
	className = '',
	isAvailable = true,
}: IPropTypes) {
	return (
		<div className={`flex flex-col w-full ${className}`}>
			<div className="flex flex-row justify-between">
				<h4 className="font-medium">{title}</h4>
				{ctaButton}
			</div>
			<div className="flex items-center mt-2 space-x-2">
				{subtitle && <p>{subtitle}</p>}
				{!isAvailable && <StarSVG />}
			</div>
			{children}
		</div>
	);
}
