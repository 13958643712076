import { useNavigate } from 'react-router-dom';
import HeaderProgressBar from 'components/ProgressBars/HeaderProgressBar';

interface ICancelHeaderProps {
	handleClick?: () => void;
	width?: number;
}

export default function CancelHeader({
	handleClick,
	width,
}: ICancelHeaderProps) {
	const navigate = useNavigate();

	const cancelClick = () => {
		handleClick ? handleClick() : navigate(-1);
	};

	return (
		<div className="w-full">
			<button
				onClick={cancelClick}
				className="mx-4 my-5 font-semibold text-rebrand-teal">
				Cancel
			</button>
			<HeaderProgressBar width={width ?? 0} />
		</div>
	);
}
