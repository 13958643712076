import { Drawer } from '@mui/material';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import React from 'react';

export interface IEmoji {
	native: string;
}

export interface EmojiDrawerProps {
	open: boolean;
	onClose: () => void;
	onEmojiSelect: (emoji: IEmoji) => void;
}

const EmojiDrawer: React.FC<EmojiDrawerProps> = ({
	open,
	onClose,
	onEmojiSelect,
}) => (
	<Drawer anchor="bottom" open={open} onClose={onClose}>
		<div className="w-full flex justify-center bg-grey-darkGreen">
			<Picker data={data} onEmojiSelect={onEmojiSelect} />
		</div>
	</Drawer>
);

export default EmojiDrawer;
