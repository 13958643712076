import Todo from 'components/CompanionApp/Todos/Todo';
import { IAPIFamilyMember, IAPITask } from 'utils/types';
import FormHeading from 'components/Forms/FormHeading';
import { getAnyoneAndMemberProfiles } from 'utils/customOrderHelpers';

interface IPropTypes {
	tasks: IAPITask[];
	members: IAPIFamilyMember[];
	fetchTasks: () => void;
	handleNotification: (arg0: string) => void;
}

export default function UncompletedTodos({
	tasks,
	members,
	fetchTasks,
	handleNotification,
}: IPropTypes) {
	const profiles = getAnyoneAndMemberProfiles(members);

	return (
		<>
			{profiles.map((profile) => {
				// uncompleted tasks assigned to anyone or member
				const profileTasks = tasks.filter(
					(task) =>
						!task.completed_at &&
						(!profile.user_id
							? !task.user_id
							: task.user_id === profile.user_id) &&
						task.is_filtered,
				);

				return (
					<div
						className="items-center flex flex-col w-full"
						key={`assigned-todos-of-profile-${profile.user_id}`}>
						{profileTasks.length > 0 && (
							<>
								<FormHeading
									label={profile.first_name}
									className="mb-2 mt-4 px-6"
								/>
								<hr className="w-full border-t-0.5 border-rebrand-grey-medium mb-4" />
							</>
						)}
						{profileTasks.map((task) => (
							<Todo
								key={`assigned-todo-${task.id}`}
								task={task}
								members={members}
								fetchTasks={fetchTasks}
								handleNotification={handleNotification}
							/>
						))}
					</div>
				);
			})}
		</>
	);
}
