import Cookies from 'js-cookie';

const onboardingURLCookieKey: string = 'hearth_last_onboarding_url';

export function setLastOnboardingURLCookie() {
	Cookies.set(onboardingURLCookieKey, window.location.href, { expires: 14 });
}

export function getLastOnboardingURLCookie() {
	return Cookies.get(onboardingURLCookieKey);
}

export function removeLastOnboardingURLCookie() {
	Cookies.remove(onboardingURLCookieKey);
}
