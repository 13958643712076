import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { isMobile } from 'react-device-detect';
import { DragPreview } from 'components/Reusable/DragAndDrop';

interface IDragAndDropItemProps {
	handleDragAndDrop: (dragIndex: number, hoverIndex: number) => void;
	index: number;
	children: ReactNode;
	isRemoving?: boolean;
	style?: string;
}

const DragAndDropItem: React.FC<IDragAndDropItemProps> = ({
	handleDragAndDrop,
	index,
	children,
	isRemoving = false,
	style = '',
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [draggingPosition, setDraggingPosition] = useState({ x: 0, y: 0 });

	// useDrag - the list item is draggable
	const [{ isDragging, clientOffset }, dragRef] = useDrag(
		() => ({
			type: 'item',
			item: { index },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
				clientOffset: monitor.getClientOffset(),
			}),
		}),
		[index],
	);

	useEffect(() => {
		if (isDragging && clientOffset) {
			setDraggingPosition({ x: 0, y: clientOffset.y });
		}
	}, [isDragging, clientOffset]);

	// useDrop - the list item is also a drop area
	const [, dropRef] = useDrop(
		{
			accept: 'item',
			hover: (item: { index: number }, monitor) => {
				if (!ref.current) return;
				const dragIndex = item.index;
				const hoverIndex = index;
				const hoverBoundingRect = ref.current?.getBoundingClientRect();
				const hoverMiddleY =
					(hoverBoundingRect?.bottom - hoverBoundingRect?.top) / 2;
				const clientOffset = monitor.getClientOffset();

				if (hoverBoundingRect && clientOffset) {
					const hoverClientY = clientOffset.y - hoverBoundingRect.top;

					if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
					if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

					handleDragAndDrop(dragIndex, hoverIndex);

					item.index = hoverIndex;
				}
			},
		},
		[index, handleDragAndDrop],
	);

	dragRef(dropRef(ref));
	const opacity = isDragging || isRemoving ? 0 : 1;
	const itemStyle = `${style} border flex items-center bg-white border border-secondary rounded-lg shadow-md`;

	return (
		<div ref={ref}>
			<div style={{ opacity }} className={itemStyle}>
				{children}
			</div>

			{isDragging && isMobile && (
				<DragPreview draggingPosition={draggingPosition} className={style}>
					<div className={itemStyle}>{children}</div>
				</DragPreview>
			)}
		</div>
	);
};

export default DragAndDropItem;
