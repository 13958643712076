import React, { FC } from 'react';
import ReusableModal from 'components/Modals/ReusableModal';

interface IDeleteModalProps {
	open: boolean;
	title: string;
	text?: string;
	handleCancel: () => void;
	handleDelete: () => void;
	children?: React.ReactNode;
}
const DeleteModal: FC<IDeleteModalProps> = ({
	open,
	title,
	text,
	handleCancel,
	handleDelete,
	children,
}) => (
	<ReusableModal open={open} title={title} handleToggleModal={handleCancel}>
		{text && <p className="text-sm">{text}</p>}
		{children}
		<div className="mt-2 flex flex-row justify-between">
			<button
				onClick={handleCancel}
				className="text-rebrand-grey-medium font-semibold">
				Cancel
			</button>
			<button onClick={handleDelete} className="text-rebrand-red font-semibold">
				Delete
			</button>
		</div>
	</ReusableModal>
);

export default DeleteModal;
