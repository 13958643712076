import React from 'react';
import { ReactComponent as UploadPictureIcon } from 'assets/Icons/Upload/upload-picture.svg';
import { ReactComponent as SmileIcon } from 'assets/Icons/smile.svg';
import { ReactComponent as TrashIcon } from 'assets/Icons/trash.svg';

interface IAvatarMenuProps {
	toggleMenu: () => void;
	handleChooseAvatar: () => void;
	handleUploadPhoto: () => void;
	handleRemovePhoto?: () => void;
}

const AvatarMenu = ({
	toggleMenu,
	handleChooseAvatar,
	handleUploadPhoto,
	handleRemovePhoto,
}: IAvatarMenuProps) => (
	<>
		<div className="fixed top-0 left-0 w-full h-full" onClick={toggleMenu} />
		<div className="absolute left-1/2 transform -translate-x-1/2 mt-136 w-44 bg-white shadow-lg z-20 rounded-xl border-0.5 border-secondary">
			<button
				className="flex flex-row p-4 py-2 w-full text-left hover:bg-rebrand-teal hover:text-white items-center rounded-t-xl"
				onClick={handleChooseAvatar}>
				<SmileIcon className="fill-current mr-3" />
				Choose Avatar
			</button>
			<button
				className={`flex flex-row px-4 py-2 w-full text-left hover:bg-rebrand-teal hover:text-white items-center ${
					handleRemovePhoto ? '' : 'rounded-b-xl'
				}`}
				onClick={handleUploadPhoto}>
				<UploadPictureIcon className="fill-current mr-3" />
				Upload Photo
			</button>
			{handleRemovePhoto && (
				<button
					className="flex flex-row px-4 py-2 w-full text-left text-rebrand-error hover:bg-rebrand-error hover:text-white items-center rounded-b-xl"
					onClick={handleRemovePhoto}>
					<TrashIcon className="fill-current mr-3" />
					Remove Photo
				</button>
			)}
		</div>
	</>
);

export default AvatarMenu;
