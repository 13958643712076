import React from 'react';

interface InputWrapperProps {
	children: React.ReactNode;
	label?: string;
	textBelow?: string;
	errorState?: boolean;
	isFocused?: boolean;
}

const InputWrapper = ({
	children,
	label,
	textBelow,
	isFocused,
	errorState,
}: InputWrapperProps) => {
	return (
		<div
			className={`relative flex mt-5 px-4 border rounded ${
				errorState ? 'border-error' : 'border-outline'
			} focus:border-primary`}>
			{label && (
				<div
					className={`z-10 absolute -top-2.5 left-3 px-1 bg-white text-xxs ${
						errorState
							? 'text-error'
							: isFocused
							? 'text-primary'
							: 'text-grey-dark'
					}`}>
					{label}
				</div>
			)}
			<div className="w-full h-56 flex items-center">{children}</div>
			{textBelow && (
				<span
					className={`text-xxs font-normal ml-4 my-1 text-${
						errorState ? 'error' : 'grey-dark'
					}`}>
					{textBelow}
				</span>
			)}
		</div>
	);
};

export default InputWrapper;
