import React, { useRef } from 'react';
import BirthdayInput from 'components/onboarding/AddFamilyMembers/BirthdayInput';
import RemoveMember from 'components/onboarding/AddFamilyMembers/RemoveMember';
import ProfileType from 'components/onboarding/AddFamilyMembers/ProfileType';
import EmailInput from 'components/onboarding/AddFamilyMembers/EmailInput';
import EmailOptionalInput from 'components/onboarding/AddFamilyMembers/EmailOptionalInput';
import NameInput from 'components/onboarding/AddFamilyMembers/NameInput';
import { AvatarSrc, IMemberProfile } from 'utils/types';
import UploadAvatar from 'components/Forms/Upload/UploadAvatar';
import UploadInput from 'components/Forms/Upload/UploadInput';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';

const NewProfile: React.FC<IMemberProfile> = ({
	member,
	index,
	handleChange,
	removeMember,
	type,
}: IMemberProfile) => {
	const fileUpload = useRef<HTMLInputElement>(null);

	const onFileUpload = (file?: File) => {
		handleChange(index, {
			profilePicture: {
				...member.profilePicture,
				rawImage: file,
			},
		});
	};

	const onAvatarChange = (
		dataUrl?: string,
		avatarSrc: AvatarSrc = AvatarSrc.DATA_URL,
	) => {
		handleChange(index, {
			profilePicture: {
				filename: dataUrl,
				avatarSrc: avatarSrc,
			},
		});
	};

	return (
		<div className="mb-12">
			<div className="h-128 justify-center flex flex-col items-center relative">
				{member.profileType === 'Kid' && type === 'web' ? (
					<>
						<UploadAvatar
							onUploadClick={() => fileUpload.current?.click()}
							onAvatarChange={onAvatarChange}
							profilePicture={member.profilePicture}
						/>

						<UploadInput
							ref={fileUpload}
							rawImage={member.profilePicture?.rawImage}
							onFileChange={onFileUpload}
							onDataUrlChange={onAvatarChange}
						/>
					</>
				) : (
					<MemberAvatar
						avatarFilename={member.profilePicture?.filename}
						className="h-128 w-128"
					/>
				)}

				<RemoveMember index={index} handleRemoveMember={removeMember} />
			</div>

			<div className="space-y-4">
				<ProfileType
					index={index}
					type={member.profileType}
					handleChange={handleChange}
					shouldShowError={
						member.shouldShowError && !!member.errors?.profileType
					}
				/>
				<NameInput
					name={member.name}
					index={index}
					handleChange={handleChange}
					shouldShowError={member.shouldShowError && !!member.errors?.name}
					textBelow={member.shouldShowError ? member.errors?.name : ''}
				/>

				{member.profileType === 'Kid' && (
					<>
						<BirthdayInput
							index={index}
							handleChange={handleChange}
							shouldShowError={
								member.shouldShowError && !!member.errors?.birthday
							}
							textBelow={member.shouldShowError ? member.errors?.birthday : ''}
						/>

						<EmailInput
							email={member.email}
							index={index}
							handleChange={handleChange}
							shouldShowError={member.shouldShowError && !!member.errors?.email}
							textBelow={member.shouldShowError ? member.errors?.email : ''}
							required={false}
						/>
					</>
				)}

				{member.profileType === 'Adult' && (
					<EmailInput
						email={member.email}
						index={index}
						handleChange={handleChange}
						shouldShowError={member.shouldShowError && !!member.errors?.email}
						textBelow={member.shouldShowError ? member.errors?.email : ''}
						infoPopUpVisible={false}
					/>
				)}
			</div>
		</div>
	);
};

export default NewProfile;
