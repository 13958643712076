import React, { useContext, useRef, useState } from 'react';
import { iconPath, uploadBaseUrl } from 'utils/variables';
import { LinearProgress, TextField } from '@mui/material';
import UploadInput from 'components/Forms/Upload/UploadInput';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { IRoutineForm } from 'components/Routines/RoutineForm';
import { updateRoutineCoverImage } from 'utils/routineHelpers';
import { NotificationType } from 'utils/types';
import { UpdateNotificationContext } from 'contexts/NotificationContext';

interface IRoutineHeaderProps {
	image: string;
	register: UseFormRegister<IRoutineForm>;
	setValue: UseFormSetValue<IRoutineForm>;
	errors: FieldErrors;
}

const DEFAULT_COVER_IMAGES = [
	'customCover',
	'dayCover',
	'afternoonCover',
	'nightCover',
];

const RoutineHeader: React.FC<IRoutineHeaderProps> = ({
	image,
	register,
	setValue,
	errors,
}) => {
	const updateNotification = useContext(UpdateNotificationContext);
	const fileUpload = useRef<HTMLInputElement>(null);
	const [picture, setPicture] = useState<any>('');
	const [dataUrl, setDataUrl] = useState<string>('');
	const [uploading, setUploading] = useState<boolean>(false);

	const onFileChange = (file?: any) => {
		if (!file) return;
		setPicture(URL.createObjectURL(file));
	};

	const displayImage = (): string => {
		if (dataUrl) return dataUrl;

		if (!image) return `${iconPath}/customCover.svg`;

		if (DEFAULT_COVER_IMAGES.includes(image)) {
			return `${iconPath}/${image}.svg`;
		}

		return `${uploadBaseUrl}/routines/${image}`;
	};

	const handleDataUrlChange = (dataUrl?: string) => {
		if (!dataUrl) return;

		setUploading(true);

		updateRoutineCoverImage(dataUrl)
			.then((res) => {
				setValue('image', res.data.filename);
				setUploading(false);
			})
			.catch(() => {
				updateNotification({
					message:
						'Problem uploading cover image. Please try a smaller picture.',
					type: NotificationType.ERROR,
					showNotification: true,
				});
				setUploading(false);
			});

		setDataUrl(dataUrl);
	};

	return (
		<div className="mx-6 mt-4">
			<div
				className="mb-4 flex flex-row items-center cursor-pointer"
				onClick={() => fileUpload.current?.click()}>
				<img
					src={displayImage()}
					alt="Routine Cover Photo"
					className="h-80 mr-4 cursor-pointer rounded"
				/>
				<button className="text-grey-dark">Edit cover photo</button>
			</div>
			{uploading && (
				<div className="w-full mb-1">
					<LinearProgress />
				</div>
			)}
			<UploadInput
				ref={fileUpload}
				rawImage={picture}
				onFileChange={onFileChange}
				onDataUrlChange={handleDataUrlChange}
				editorSettings={{
					circle: false,
					scaleToCanvas: false,
					aspectRatio: 1295 / 730, // width / height
				}}
			/>
			<TextField
				variant="standard"
				{...register('name', { required: true })}
				placeholder="Enter title"
				error={Boolean(errors.name)}
				helperText={Boolean(errors.name) ? 'Title required' : ''}
				InputProps={{
					disableUnderline: true,
					style: { fontSize: 24, fontWeight: '700' },
				}}
			/>
		</div>
	);
};

export default RoutineHeader;
