import { Routes, Route } from 'react-router-dom';
import React from 'react';
import ListRoutines from 'screens/CompanionApp/Routines/ListRoutines';
import RoutineTemplate from 'screens/CompanionApp/Routines/RoutineBuilder/RoutineTemplate';
import RoutineUsers from 'screens/CompanionApp/Routines/RoutineBuilder/RoutineUsers';
import CreateRoutine from 'screens/CompanionApp/Routines/RoutineBuilder/CreateRoutine';
import RoutineRecommendations from 'screens/CompanionApp/Routines/RoutineBuilder/RoutineRecommendations';
import EditRoutine from 'screens/CompanionApp/Routines/RoutineBuilder/EditRoutine';
import Error404 from 'screens/Errors/Error404';

const RoutinesSubRouter: React.FC = () => (
	<Routes>
		<Route path="" element={<ListRoutines />} />

		<Route path="builder">
			<Route path="template" element={<RoutineTemplate />} />
			<Route path="assign" element={<RoutineUsers />} />
			<Route path="create" element={<CreateRoutine />} />
			<Route path="recommended" element={<RoutineRecommendations />} />
		</Route>
		<Route path="edit" element={<EditRoutine />} />
		<Route path="*" element={<Error404 />} />
	</Routes>
);

export default RoutinesSubRouter;
