import { useEffect, useState } from 'react';
import axiosClient from 'utils/axiosClient';
import { ReactComponent as LinkSVG } from 'assets/Icons/Arrows/link-arrow.svg';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import PageTitle from 'components/Reusable/PageTitle';

interface IAccountDetails {
	accountEmail?: string;
	membershipStatus?: string;
	membershipInfo?: string;
	membershipLink?: string;
	membershipRenewal?: string;
	supportEmail?: string;
}

export default function Account() {
	const [accountDetails, setAccountDetails] = useState<IAccountDetails>();

	async function fetchAccountDetails() {
		await axiosClient
			.get('/web/account/membership')
			.then((response) => {
				setAccountDetails({
					accountEmail: response.data.account_email,
					membershipStatus: response.data.membership_status,
					membershipInfo: response.data.membership_info,
					membershipLink: response.data.membership_link,
					membershipRenewal: response.data.membership_renewal,
					supportEmail: response.data.support_email,
				});
			})
			.catch(() => {
				console.log('error fetching account details');
			});
	}

	useEffect(() => {
		fetchAccountDetails();
	}, []);

	return (
		<WithHeaderLayout>
			<div className="mx-6 mt-10">
				<PageTitle title="Account" subtitle="Membership" />
			</div>
			<div className="flex flex-col items-center w-full">
				<hr className="w-full mt-2" />
				<div className="pt-4 px-6 w-full">
					{accountDetails?.accountEmail && (
						<p className="text-rebrand-gray-dark whitespace-nowrap truncate">
							{accountDetails?.accountEmail}
						</p>
					)}
					{accountDetails?.membershipStatus && (
						<div className="border rounded-lg mt-3 px-4 py-3">
							<p className="font-bold">Family Membership</p>
							<p>{accountDetails.membershipStatus}</p>
							{accountDetails.membershipRenewal && (
								<p>{accountDetails.membershipRenewal}</p>
							)}
						</div>
					)}
					{accountDetails?.membershipInfo && (
						<p className="mt-4">
							{accountDetails.membershipInfo}
							{accountDetails?.supportEmail && (
								<a className="underline">{accountDetails.supportEmail}</a>
							)}
						</p>
					)}
					{accountDetails?.membershipLink && (
						<div className="flex items-center mt-3">
							<a
								href={accountDetails.membershipLink}
								target="_blank"
								className="underline pr-2">
								Manage Membership
							</a>
							<LinkSVG />
						</div>
					)}
				</div>
			</div>
		</WithHeaderLayout>
	);
}
