import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	AvatarSrc,
	IAPIFamilyMember,
	IProfilePicture,
	IUserData,
	NotificationType,
} from 'utils/types';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import BackButton from 'components/Buttons/BackButton';
import EmailInput from 'components/onboarding/AddFamilyMembers/EmailInput';
import axiosClient from 'utils/axiosClient';
import BirthdayInput from 'components/onboarding/AddFamilyMembers/BirthdayInput';
import UploadAvatar from 'components/Forms/Upload/UploadAvatar';
import UploadInput from 'components/Forms/Upload/UploadInput';
import {
	handleProfilePictureChange,
	updateProfilePicture,
} from 'utils/avatars';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import ProfileColor from 'components/onboarding/AddFamilyMembers/ProfileColor';
import { profileColorFeatureFlag } from 'utils/variables';
import { COLORS } from 'styles/colors';
import DeleteModal from 'components/Modals/DeleteModal';
import { InputAdornment, TextField } from '@mui/material';
import { InputPopupProvider } from 'components/Reusable/InputPopupContext';
import { ReactComponent as StarSVG } from 'assets/Icons/Membership/featureGateStar.svg';
import useFeatures from 'stores/feature';
import { UpdateFeatureGateContext } from 'contexts/FeatureGateContext';
import { FeatureGateType } from 'components/CompanionApp/Membership/FeatureGateModal';
import { usePostMessageToCompanionApp } from 'hooks/companionAppHooks';

interface LocationState {
	state: IAPIFamilyMember;
}

interface IFormState {
	first_name: string;
	last_name: string;
	color: string;
	birthday?: Date;
	notes?: string;
	phone?: {
		value: string;
	};
	email?: string;
	shouldShowError: boolean;
	profilePicture: IProfilePicture;
}

export default function EditFamilyMember() {
	const updateNotification = useContext(UpdateNotificationContext);
	const updateFeatureGate = useContext(UpdateFeatureGateContext);
	const features = useFeatures((store) => store.features);
	const postMessageToCompanionApp = usePostMessageToCompanionApp();

	// Navigation
	const navigate = useNavigate();
	const location = useLocation() as LocationState;
	const { ...member } = location.state;

	function navigatePageBack() {
		navigate('/manage-family');
	}

	const fileUpload = useRef<HTMLInputElement>(null);

	const defaultPhone = member.is_responsible_adult
		? member.contacts[
				member.contacts?.findIndex((obj) => obj.contact_type == 'phone')
		  ]
		: { value: '' };
	const defaultEmail = member.is_responsible_adult
		? member.contacts[
				member.contacts?.findIndex((obj) => obj.contact_type == 'email')
		  ]
		: { value: '' };
	const defaultColor = member.color
		? member.color
		: COLORS.filter((color) => color !== '#1AA39B')[member.id % 15];

	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const [uploadedFilename, setUploadedFilename] = useState<string>();
	const [formState, setFormState] = useState<IFormState>({
		first_name: member.first_name,
		last_name: member.last_name,
		color: defaultColor,
		birthday: member.birthday,
		notes: !member.is_responsible_adult ? member.notes : '',
		phone: defaultPhone,
		email: defaultEmail.value,
		shouldShowError: false,
		profilePicture: {
			rawImage: undefined,
			filename: member.avatar_file_name,
			image: undefined,
			avatarSrc: AvatarSrc.REMOTE,
		},
	});

	function toggleModal() {
		setModalVisible(!modalVisible);
	}

	async function saveFamilyMemberApi() {
		const userData: IUserData = {
			first_name: formState.first_name,
			last_name: formState.last_name,
			color: formState.color,
		};

		//Adult
		if (member.is_responsible_adult) {
			if (formState?.phone?.value) {
				userData['phone_number'] = formState.phone;
			}
			userData['email'] = formState.email;
		}

		//Child
		if (!member.is_responsible_adult) {
			userData['notes'] = formState.notes;
			userData['birthday'] = formState.birthday;
			if (formState.email != '') {
				userData['email'] = formState.email;
			}
		}

		const { filename, avatarSrc } = formState.profilePicture;

		if (avatarSrc === AvatarSrc.LOCAL && filename) {
			userData['avatar_file_name'] = filename;
		}

		if (avatarSrc === AvatarSrc.DATA_URL && filename && uploadedFilename) {
			userData.avatar_file_name = uploadedFilename;
		}

		const response = await axiosClient.put(`/web/user/${member.id}`, userData);

		if (response.status === 200) {
			navigatePageBack();
			postMessageToCompanionApp('family_members_updated');
		} else {
			updateNotification({
				message: 'Failed to save family member',
				showNotification: true,
			});
			console.log('Failed to save family member');
		}
	}

	function handleChange(index: number, updatedValues: Partial<any>) {
		setFormState({
			...formState,
			...updatedValues,
		});
	}

	const updatePicture = (newProfilePicture: IProfilePicture) => {
		const { filename, avatarSrc } = newProfilePicture;

		if (avatarSrc === AvatarSrc.DATA_URL && filename) {
			updateProfilePicture(member.id, filename).then((response) => {
				if (response.data.avatar_file_name) {
					setUploadedFilename(response.data.avatar_file_name);
				}
			});
		}

		setFormState((state) => ({
			...state,
			profilePicture: newProfilePicture,
		}));
	};

	const dataUrlChange = (dataUrl?: string) => {
		if (dataUrl) {
			handleProfilePictureChange(dataUrl, updatePicture, member.id);
		}
	};

	async function deleteFamilyMemberApi() {
		await axiosClient
			.delete(`/web/family/member/${member.id}`)
			.then((response) => {
				if (response.status === 200) {
					navigatePageBack();
					updateNotification({
						message: `Successfully deleted ${member.first_name}`,
						type: NotificationType.SUCCESS,
						showNotification: true,
					});
					postMessageToCompanionApp('family_members_updated');
				} else {
					updateNotification({
						message: 'Failed to delete family member',
						showNotification: true,
					});
				}
			})
			.catch((error) => {
				updateNotification({
					message: 'Failed to delete family member',
					showNotification: true,
				});
				console.log(error);
			});
	}

	return (
		<InputPopupProvider>
			<WithHeaderLayout menuButtonVisible className="pb-8 px-6">
				<DeleteModal
					open={modalVisible}
					title="Delete Profile"
					handleCancel={toggleModal}
					handleDelete={deleteFamilyMemberApi}>
					<p>
						Are you sure you want to delete this profile? You cannot undo this.
					</p>
				</DeleteModal>

				<BackButton backRoute="/manage-family" className="mt-5" />

				<div className="flex w-full justify-center">
					<UploadAvatar
						onUploadClick={() => fileUpload.current?.click()}
						onAvatarChange={(avatar) =>
							updatePicture({
								filename: avatar,
								avatarSrc: AvatarSrc.LOCAL,
							})
						}
						profilePicture={formState.profilePicture}
						firstName={member?.first_name}
						userId={member?.id}
						editButton={true}
					/>
				</div>

				<div className="mt-4 w-full space-y-4">
					<TextField
						className="w-full"
						value={formState.first_name}
						placeholder={member.first_name}
						onChange={(e: { target: { value: any } }) =>
							handleChange(0, { first_name: e.target.value })
						}
						error={!Boolean(formState.first_name)}
						label="Name"
					/>
					{member.is_responsible_adult && (
						<TextField
							className="w-full"
							value={formState.phone?.value}
							onChange={(e) => handleChange(0, { phone: e.target.value })}
							placeholder={
								member.contacts[
									member.contacts?.findIndex(
										(obj) => obj.contact_type == 'phone',
									)
								]?.value
							}
							label="Phone Number"
							disabled={member.is_responsible_adult}
						/>
					)}
					{!member.is_responsible_adult && (
						<BirthdayInput
							index={0}
							birthday={member.birthday}
							handleChange={handleChange}
						/>
					)}
					{(Boolean(formState.email) || member.is_responsible_adult) && (
						<EmailInput
							email={formState.email}
							label="Email"
							index={0}
							shouldShowError={false}
							handleChange={handleChange}
							isDisabled={true}
							infoPopUpVisible={!member.is_responsible_adult}
						/>
					)}
					{profileColorFeatureFlag && (
						<ProfileColor
							color={formState.color}
							handleSetColor={(newColor: string) =>
								handleChange(0, { color: newColor })
							}
							isResponsibleAdult={member.is_responsible_adult}
							isAvailable={features.profileColor.isAvailable}
						/>
					)}
					<UploadInput
						ref={fileUpload}
						rawImage={formState.profilePicture.rawImage}
						onFileChange={(file) => updatePicture({ rawImage: file })}
						onDataUrlChange={dataUrlChange}
					/>
					{!member.is_responsible_adult && (
						<>
							<TextField
								className="w-full"
								multiline
								onChange={(e) => handleChange(0, { notes: e.target.value })}
								onClick={() =>
									!features.profileNotes.isAvailable &&
									updateFeatureGate({
										type: FeatureGateType.DEFAULT,
										showFeatureGate: true,
									})
								}
								value={formState.notes}
								defaultValue=" "
								label="Notes"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{!features.profileNotes.isAvailable && <StarSVG />}
										</InputAdornment>
									),
								}}
							/>

							{!formState.email && (
								<div className="flex-row flex">
									<p className="mt-4 flex text-xxs font-normal text-center mx-auto">
										Want to connect your kid’s email?
										<br />
										<a
											className="flex self-center ml-1 text-rebrand-teal underline text-center"
											href="https://hearthdisplay.kustomer.help/en_us/kids-calendars-SyBjkpqo"
											target="_blank"
											rel="noopener noreferrer">
											Learn How
										</a>
									</p>
								</div>
							)}
						</>
					)}
				</div>
				<div className="flex justify-center w-full mt-6 lg:mt-8 pb-8">
					<div className="w-full lg:w-1/3">
						<button
							onClick={saveFamilyMemberApi}
							className="w-full py-3 bg-rebrand-teal text-white rounded-full">
							Save
						</button>
						{!member.is_responsible_adult && (
							<button
								className="mt-4 font-bold text-xs text-rebrand-grey-medium text-center w-full"
								onClick={toggleModal}>
								Delete Profile
							</button>
						)}
					</div>
				</div>
			</WithHeaderLayout>
		</InputPopupProvider>
	);
}
