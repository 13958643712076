import CalendarNameAndColor from 'components/CompanionApp/Event/CalendarNameAndColor';
import analytics from 'utils/segmentClient';

interface IProfileColor {
	handleSetColor: (arg1: string) => void;
	color: string;
	isResponsibleAdult: boolean;
	isAvailable?: boolean;
}

export default function ProfileColor({
	color,
	handleSetColor,
	isResponsibleAdult,
	isAvailable = true,
}: IProfileColor) {
	return (
		<CalendarNameAndColor
			color={color}
			handleColorChange={(newColor: string) => {
				analytics.track('custom_profile_color', {
					is_adult: isResponsibleAdult,
					is_kid: !isResponsibleAdult,
					color: newColor,
				});
				handleSetColor(newColor);
			}}
			name="Color"
			isAvailable={isAvailable}
		/>
	);
}
