import { SelectAvatarLayout } from 'components/CompanionApp/Avatars/SelectAvatarLayout';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import { IAPIFamilyMember } from 'utils/types';
import { getAnyoneAndMemberProfiles, IProfile } from 'utils/customOrderHelpers';

interface IPropTypes {
	members: IAPIFamilyMember[];
	selection: any | any[];
	setSelection: (arg0?: any | any[]) => void;
	isSingleSelection?: boolean; // only one assignee can be selected at a time when editing to-do
	className?: string;
}

export default function AssigneeGroup({
	members,
	selection,
	setSelection,
	isSingleSelection = false,
	className,
}: IPropTypes) {
	const profiles = getAnyoneAndMemberProfiles(members, selection);

	function select(userId: number) {
		if (isSingleSelection) {
			setSelection(userId);
		} else {
			setSelection([...selection, userId]);
		}
	}

	function unselect(userId: number) {
		if (isSingleSelection) {
			setSelection();
		} else {
			setSelection(
				selection.filter((selectedUserId: number) => selectedUserId !== userId),
			);
		}
	}

	function handleSelectionChange(isSelected?: boolean, userId?: number) {
		if (!userId) {
			if (isSingleSelection) {
				setSelection();
			} else {
				setSelection([]);
			}
		} else {
			if (!isSelected) {
				select(userId);
			} else {
				unselect(userId);
			}
		}
	}

	return (
		<div
			className={`flex w-full pb-4 px-5 space-x-4 overflow-x-auto ${className}`}>
			{profiles.map((profile: IProfile) => (
				<button
					key={`assignee-avatar-of-profile-${profile.user_id}`}
					onClick={() =>
						handleSelectionChange(profile.is_selected, profile.user_id)
					}>
					<SelectAvatarLayout
						firstName={profile.first_name}
						isSelected={profile.is_selected}>
						<MemberAvatar
							avatarFilename={profile.avatar_file_name}
							className="w-12 h-12"
						/>
					</SelectAvatarLayout>
				</button>
			))}
		</div>
	);
}
