import React, { useState } from 'react';
import { IMemberError } from 'utils/types';
import { TextField } from '@mui/material';
import InfoPopup from './InfoPopup';

interface IEmailInput {
	email?: string;
	index: number;
	handleChange: (
		index: number,
		data: {
			email?: string;
			errors?: IMemberError;
			shouldShowError?: boolean;
		},
	) => void;
	infoPopUpVisible?: boolean;
	shouldShowError: boolean;
	placeHolder?: string;
	label?: string;
	isDisabled?: boolean;
	textBelow?: string;
	required?: boolean;
}

export default function EmailInput({
	email,
	index,
	handleChange,
	shouldShowError,
	placeHolder = 'Email Address',
	required = true,
	isDisabled,
	textBelow,
	infoPopUpVisible = true,
}: IEmailInput) {
	const [showTooltip, setShowTooltip] = useState(false);

	const toggleTooltip = () => {
		setShowTooltip(!showTooltip);
	};

	if (!required) {
		placeHolder = placeHolder + ' (Optional)';
	}

	return (
		<div className="relative">
			<TextField
				label="Email"
				required={required}
				placeholder={placeHolder}
				fullWidth
				value={email || ''}
				onChange={(event) => handleChange(index, { email: event.target.value })}
				helperText={shouldShowError ? textBelow : ''}
				error={shouldShowError}
				disabled={isDisabled}
				variant="outlined"
			/>
			<div className={`absolute top-0 w-5 h-full right-0`}>
				{infoPopUpVisible && (
					<InfoPopup
						info="Add your kid’s email if you’d like to connect their existing calendars to Hearth."
						popup={showTooltip}
						handleClick={toggleTooltip}
					/>
				)}
			</div>
		</div>
	);
}
