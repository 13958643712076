import { createTheme } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material';

export const defaultTheme = createTheme({
	palette: {
		primary: {
			main: '#008492',
		},
		text: {
			primary: '#003648',
		},
		secondary: {
			main: '#A9B3B3',
			dark: '#707F7F',
		},
		action: {
			selected: '#008492',
		},
		error: {
			main: '#EE0004',
		},
	},
	typography: {
		fontFamily: 'Civil Premium, sans-serif',
		button: {
			textTransform: 'none',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 600,
					paddingLeft: 16,
					paddingRight: 16,
					paddingTop: 12,
					paddingBottom: 12,
					borderRadius: 50,
					lineHeight: '24px',
					boxShadow: 'none',
				},
			},
			variants: [
				{
					props: { variant: 'outlined' },
					style: {
						borderColor: 'currentColor',
					},
				},
			],
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: '#A9B3B3',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: '#008492',
					},
					'&.Mui-error .MuiOutlinedInput-notchedOutline': {
						borderColor: '#EE0004',
					},
					'&.Mui-disabled': {
						backgroundColor: '#EFF5F6',
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: '#A9B3B3',
						},
					},
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					height: 14,
					borderRadius: 32,
					[`&.${linearProgressClasses.colorPrimary}`]: {
						backgroundColor: (theme: {
							palette: { grey: { [x: string]: any }; mode: string };
						}) =>
							theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
					},
					[`& .${linearProgressClasses.bar}`]: {
						borderRadius: 32,
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					color: '#707F7F',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					height: 56,
					':hover': {
						backgroundColor: '#D9EDEF',
					},
					'&.Mui-selected': {
						backgroundColor: '#D9EDEF',
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					padding: '12px',
				},
				underline: {
					'&&&:before': {
						borderBottom: 'none',
					},
					'&&:after': {
						borderBottom: 'none',
					},
				},
			},
		},
	},
});
