export const primaryColor = '#008492'; // Teal

export const COLORS = [
	'#EC001E', // Red
	'#FF4C00', // Orange
	'#FF6A6F', // Coral
	'#FF805A', // Peach

	'#FEBA00', // Gold
	'#CAED6A', // Light Green
	'#00A03B', // Green
	'#00D0CD', // Teal blue

	'#60D2F4', // Sky blue
	'#009AED', // Light Blue
	'#3B5AFA', // Blue
	'#C89DFD', // Light purple

	'#A501D8', // Purple
	'#FF32C0', // Magenta
	'#FFA5E3', // Pink
	primaryColor,
];
