import axiosClient from 'utils/axiosClient';
import { AxiosResponse } from 'axios';
import { EventName, trackEvent } from 'utils/segmentClient';

interface IndependenceLevelApiResponse {
	independence_levels: IAPIIndependenceLevel[];
}

export interface IAPIIndependenceLevel {
	id: number;
	name: string;
	label: string;
}

export const getIndependenceLevelOptions = () =>
	axiosClient
		.get('/web/family/independence_levels')
		.then(
			(response: AxiosResponse<IndependenceLevelApiResponse>) =>
				response.data.independence_levels,
		)
		.catch((error) => {
			console.error('Error fetching independence levels:', error);
			throw error;
		});

export const saveUserIndependenceLevel = (
	userId: number,
	selectedLevelId: number,
) =>
	axiosClient
		.put(`/web/family/family_member/${userId}`, {
			independence_level_id: selectedLevelId,
		})
		.then(() => {
			trackEvent(EventName.IndependenceLevelChanged, {
				userId,
				selectedLevelId,
			});
		})
		.catch((error) => {
			console.error('Error saving independence level', error);
			throw error;
		});
