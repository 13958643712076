import { ReactNode } from 'react';

import Header from 'components/Headers/Header';
import AnimatedContainer from 'components/Reusable/AnimateContainer';
import { useIsCompanionAppAccess } from 'hooks/companionAppHooks';

interface IPropTypes {
	children: ReactNode;
	menuButtonVisible?: boolean;
	className?: string;
}

export default function WithHeaderLayout({
	children,
	menuButtonVisible,
	className = 'pb-8',
}: IPropTypes) {
	const isCompanionApp = useIsCompanionAppAccess();
	return (
		<div className="flex flex-col items-center h-screen w-full">
			{!isCompanionApp && (
				<div className="w-full lg:hidden">
					<Header menuButtonVisible={menuButtonVisible} />
				</div>
			)}
			<AnimatedContainer
				className={`flex flex-col flex-grow overflow-y-auto w-full ${className}`}>
				{children}
			</AnimatedContainer>
		</div>
	);
}
