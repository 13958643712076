import { useEffect } from 'react';
import { apiHost, apiRoot } from 'utils/variables';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import PageTitle from 'components/Reusable/PageTitle';
import GoogleButton from 'components/Buttons/GoogleButton';

interface IPropTypes {
	showErrorText?: boolean;
}

export default function ConnectGoogleCalender({
	showErrorText = false,
}: IPropTypes) {
	const authLink = `${apiHost}${apiRoot}/web/user/link_google_account`;

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	return (
		<OnboardingStepsLayout progress={(12 / 16) * 100}>
			<PageTitle
				title="Connect your Google Calendar?"
				subtitle="Sync your Google calendars to Hearth for easy family communication. (You’ll be able to update and connect additional calendars later!)"
				className="mb-6"
			/>

			{showErrorText && (
				<p className="text-base pb-4 text-red-700">
					It seems that you have already authenticated with Hearth Display.
					Please{' '}
					<a
						href="https://myaccount.google.com/u/0/permissions?pli=1"
						target="_blank"
						className="underline">
						remove existing Google authentication for Hearth Display
					</a>{' '}
					and try again.
				</p>
			)}

			<div className="pb-0 w-full mt-8">
				<GoogleButton googleAuthLink={authLink} />
			</div>
		</OnboardingStepsLayout>
	);
}
