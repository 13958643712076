import React from 'react';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import IndependenceTooltip from 'components/Routines/IndependenceLevels/IndependenceTooltip';
import { IFamilyMember } from 'utils/types';
import { actionTypes, useRoutinesDispatch } from 'contexts/RoutinesContext';
import { saveUserIndependenceLevel } from 'api/independenceLevels';
import { DropdownSelect } from 'components/Reusable/DropdownSelect';
import { useIndependenceLevels } from 'hooks/useIndependenceLevels';
import useDefaultIndependenceLevel from 'hooks/useDefaultIndependenceLevel';
import { Button } from '@mui/material';

interface IIndependenceLevelDrawerProps {
	routineUsers: IFamilyMember[];
	shouldShowOpenOnMount: boolean;
}

const IndependenceDrawer: React.FC<IIndependenceLevelDrawerProps> = ({
	routineUsers,
	shouldShowOpenOnMount,
}) => {
	const dispatch = useRoutinesDispatch();
	const { levelOptions } = useIndependenceLevels();
	const defaultUser = routineUsers[0];

	const [selectedLevel, setSelectedLevel] =
		useDefaultIndependenceLevel(defaultUser);

	const onSave = () => {
		saveUserIndependenceLevel(defaultUser.id, selectedLevel).then(() => {
			dispatch({
				type: actionTypes.UPDATE_INDEPENDENCE_LEVEL,
				payload: { userId: defaultUser.id, level: selectedLevel },
			});
		});
	};

	return (
		<SlideUpDrawer open={shouldShowOpenOnMount}>
			<div className="p-4">
				<p className="text-md font-semibold">
					Please set {defaultUser?.firstName || 'this child'}'s independence
					level
				</p>
				<IndependenceTooltip />
				<div className="mt-4 mb-6">
					<DropdownSelect
						options={levelOptions}
						selectedValue={selectedLevel}
						onChange={(value: number | string) =>
							setSelectedLevel(Number(value))
						}
					/>
				</div>

				<Button variant="outlined" onClick={onSave}>
					Save
				</Button>
			</div>
		</SlideUpDrawer>
	);
};

export default IndependenceDrawer;
