import { Tooltip } from '@mui/material';
import CalendarDetailsPopupLayout from './CalendarDetailsPopupLayout';
import { getCalendarColor } from 'utils/colorHelpers';
import {
	ICalendar,
	ICalendarUpdateParams,
	ICalendarUpdateType,
	IProfileUser,
} from 'utils/types';
import { apiHost, apiRoot } from 'utils/variables';
import { useState } from 'react';
import { ReactComponent as PaperclipIcon } from 'assets/rebrand/paperclip.svg';

interface IProps {
	handleCloseModal: () => void;
	updateCalendar: (params: ICalendarUpdateParams) => Promise<void>;
	calendar: ICalendar;
	profiles: Map<number | null, IProfileUser>;
}

export default function HearthCalendarDetailsPopup({
	handleCloseModal,
	updateCalendar,
	calendar,
	profiles,
}: IProps) {
	const [copyTooltip, setShowCopyTooltip] = useState<string | boolean>(false);
	const currentColor = getCalendarColor();
	const hearthICalUrl = `${apiHost}${apiRoot}/web/calendar/public/${calendar.publicKey}/hearth.ics`;

	const handleToggleSwitch = () =>
		updateCalendar({
			type: ICalendarUpdateType.toggle,
			id: calendar.id,
			name: calendar.name,
			isPublic: !calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: calendar.includeEventDetails,
			color: calendar.color,
			assignedUserId: calendar.assignedUserId,
		});

	return (
		<CalendarDetailsPopupLayout
			handleCloseModal={handleCloseModal}
			calendarName={calendar.name}
			currentColor={currentColor}
			profiles={profiles}
			handleToggleSwitch={handleToggleSwitch}
			isToggledOn={calendar.isPublic}
			switchTitle="Make Public">
			<p className="text-xxs mt-4">
				Creates a view-only calendar link that you or others can subscribe to
				elsewhere.
				<a
					className="pl-1 text-rebrand-teal underline"
					href="https://hearthdisplay.kustomer.help/en_us/hearth-calendar-syncing-SJYerLid3"
					rel="nonreferrer"
					target="_blank">
					Learn More
				</a>
			</p>
			{calendar.isPublic && (
				<div className="flex w-full min-w-0 mt-2 border border-outline rounded items-center justify-between">
					<input
						className="text-rebrand-grey-medium text-xs flex-1 p-2 overflow-hidden"
						value={hearthICalUrl}
						onClick={(e) => (e.target as HTMLInputElement).select()}
						readOnly
					/>
					<span className="whitespace-nowrap px-1">
						<Tooltip
							title={copyTooltip === true ? 'Copied!' : copyTooltip}
							open={!!copyTooltip}
							leaveDelay={1000}
							arrow
							PopperProps={{
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [0, -10],
										},
									},
								],
							}}
							onClose={() => setShowCopyTooltip(false)}>
							<button
								type="button"
								className="px-4 py-4 text-rebrand-teal text-xs"
								onClick={() => {
									if (navigator.clipboard) {
										navigator.clipboard.writeText(hearthICalUrl);
										setShowCopyTooltip(true);
									} else {
										setShowCopyTooltip('Failed to copy');
									}
								}}>
								<div className="flex flex-row items-center">
									<PaperclipIcon className="fill-current mr-1" />
									Copy Link
								</div>
							</button>
						</Tooltip>
					</span>
				</div>
			)}
		</CalendarDetailsPopupLayout>
	);
}
