import { FormControl, FormGroup, FormHelperText } from '@mui/material';
import React from 'react';

export const WEEK_DAYS: string[] = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

interface WeeklyFrequencyAnimatedProps {
	byDay: string;
	errorMessage?: string;
	onDayClick: (dayString: string) => void;
	dayOptions?: string[];
}

const WeeklyFrequencyAnimated: React.FC<WeeklyFrequencyAnimatedProps> = ({
	byDay,
	errorMessage,
	onDayClick,
	dayOptions = WEEK_DAYS,
}) => (
	<FormControl component="fieldset">
		<FormGroup>
			<div className="flex flex-row space-x-3">
				{dayOptions.map((weekday) => (
					<div
						key={weekday}
						onClick={() => onDayClick(weekday)}
						className={`${
							byDay.includes(weekday)
								? 'bg-rebrand-teal-light'
								: 'bg-rebrand-grey-light'
						} rounded-full h-8 w-8 flex items-center justify-center cursor-pointer`}>
						<span className="text-sm">{weekday.charAt(0)}</span>
					</div>
				))}
			</div>
		</FormGroup>
		{errorMessage && (
			<FormHelperText error className="mt-1">
				{errorMessage}
			</FormHelperText>
		)}
	</FormControl>
);

export default WeeklyFrequencyAnimated;
