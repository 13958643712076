import React, {
	createContext,
	Dispatch,
	useContext,
	useEffect,
	useReducer,
} from 'react';
import {
	IFamilyMember,
	IRoutine,
	IRoutineStep,
	IRoutineTemplate,
} from 'utils/types';

interface IRoutinesProviderProps {
	children: React.ReactNode;
}

const initialState: RoutinesState = {
	memberRoutines: [],
	routine: undefined,
	routineUsers: [],
	routineTemplate: null,
	routineSteps: [],
};

const RoutinesStateContext = createContext<RoutinesState>(initialState);
const RoutinesDispatchContext = createContext<
	Dispatch<RoutinesAction> | undefined
>(undefined);

export const RoutinesProvider = ({ children }: IRoutinesProviderProps) => {
	const [state, dispatch] = useReducer(routinesReducer, initialState);

	useEffect(() => {
		const savedState = localStorage.getItem('routinesState');
		if (savedState) {
			dispatch({
				type: actionTypes.SET_STATE,
				payload: JSON.parse(savedState),
			});
		}
	}, []);

	useEffect(() => {
		localStorage.setItem('routinesState', JSON.stringify(state));
	}, [state]);

	return (
		<RoutinesStateContext.Provider value={state}>
			<RoutinesDispatchContext.Provider value={dispatch}>
				{children}
			</RoutinesDispatchContext.Provider>
		</RoutinesStateContext.Provider>
	);
};

interface RoutinesState {
	memberRoutines: IFamilyMember[];
	routine?: IRoutine;
	routineUsers: IFamilyMember[];
	routineTemplate: IRoutineTemplate | null;
	routineSteps: IRoutineStep[];
	routineType?: routineTypes;
}

export enum actionTypes {
	SET_MEMBER_ROUTINES = 'SET_MEMBER_ROUTINES',
	SET_ROUTINE = 'SET_ROUTINE',
	SET_ROUTINE_USERS = 'SET_ROUTINE_USERS',
	UPDATE_INDEPENDENCE_LEVEL = 'UPDATE_INDEPENDENCE_LEVEL',
	SET_ROUTINE_TEMPLATE = 'SET_ROUTINE_TEMPLATE',
	SET_ROUTINE_STEPS = 'SET_ROUTINE_STEPS',
	SET_ROUTINE_TYPE = 'SET_ROUTINE_TYPE',
	RESET_ROUTINE_CONTEXT = 'RESET_ROUTINE_CONTEXT',
	SET_STATE = 'SET_STATE',
}

export enum routineTypes {
	TEMPLATE = 'template',
	CUSTOM = 'custom',
}

interface ILevelPayload {
	userId: number;
	level: number;
}

type RoutinesAction =
	| { type: actionTypes.SET_MEMBER_ROUTINES; payload: IFamilyMember[] }
	| { type: actionTypes.SET_ROUTINE; payload: IRoutine }
	| { type: actionTypes.SET_ROUTINE_USERS; payload: IFamilyMember[] }
	| { type: actionTypes.SET_ROUTINE_TEMPLATE; payload: IRoutineTemplate }
	| { type: actionTypes.SET_ROUTINE_STEPS; payload: IRoutineStep[] }
	| { type: actionTypes.SET_ROUTINE_TYPE; payload: routineTypes }
	| { type: actionTypes.RESET_ROUTINE_CONTEXT }
	| { type: actionTypes.SET_STATE; payload: RoutinesState }
	| { type: actionTypes.UPDATE_INDEPENDENCE_LEVEL; payload: ILevelPayload };

function routinesReducer(
	state: RoutinesState,
	action: RoutinesAction,
): RoutinesState {
	switch (action.type) {
		case actionTypes.SET_MEMBER_ROUTINES:
			return { ...state, memberRoutines: action.payload };
		case actionTypes.SET_ROUTINE:
			return { ...state, routine: action.payload };
		case actionTypes.SET_ROUTINE_USERS:
			return { ...state, routineUsers: action.payload };
		case actionTypes.SET_ROUTINE_TEMPLATE:
			return { ...state, routineTemplate: action.payload };
		case actionTypes.SET_ROUTINE_STEPS:
			return { ...state, routineSteps: action.payload };
		case actionTypes.SET_ROUTINE_TYPE:
			return { ...state, routineType: action.payload };
		case actionTypes.SET_STATE:
			return { ...state, ...action.payload };
		case actionTypes.RESET_ROUTINE_CONTEXT:
			localStorage.removeItem('routinesState');
			return initialState;
		case actionTypes.UPDATE_INDEPENDENCE_LEVEL:
			return {
				...state,
				routineUsers: state.routineUsers.map((user) => {
					if (user.id === action.payload.userId) {
						return {
							...user,
							independenceLevelId: action.payload.level,
						};
					}
					return user;
				}),
			};
		default:
			return state;
	}
}

export const useRoutinesState = () => {
	const context = useContext(RoutinesStateContext);
	if (context === undefined) {
		throw new Error(
			'useRoutinesState must be used within a RoutinesStateProvider',
		);
	}
	return context;
};

export const useRoutinesDispatch = () => {
	const context = useContext(RoutinesDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useRoutinesDispatch must be used within a RoutinesDispatchProvider',
		);
	}
	return context;
};
