import { IRoutine } from 'utils/types';
import { FC } from 'react';

import { ReactComponent as Ellipsis } from 'assets/Icons/Routines/ellipsis.svg';
import Card, { CardHeader } from 'components/Reusable/Card';
import {
	StepProgressBar,
	WeekProgressBar,
} from 'components/Routines/RoutineProgressBars';
import dayjs from 'dayjs';

interface IRoutineDetailsProps {
	routine: IRoutine;
	onCardClick?: () => void;
	onEllipsisClick: (event: React.MouseEvent) => void;
}

const RoutineDetailCard: FC<IRoutineDetailsProps> = ({
	routine: { name, completedSteps, pastProgress, steps },
	onCardClick,
	onEllipsisClick,
}) => {
	const today = dayjs().format('dd').toUpperCase();
	const stepsToday = steps.filter((step) =>
		step.recurrenceDetails?.byDay.includes(today),
	);
	return (
		<Card onClick={onCardClick}>
			<CardHeader
				headerText={name}
				textAlignment="left"
				headerButton={<EllipsisButton onClick={onEllipsisClick} />}
			/>

			<StepProgressBar
				completedSteps={completedSteps}
				totalSteps={stepsToday.length}
			/>
			<WeekProgressBar pastProgress={pastProgress} />
		</Card>
	);
};

interface IEllipsisButtonProps {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const EllipsisButton: FC<IEllipsisButtonProps> = ({ onClick }) => (
	<button
		className="pl-5 py-3"
		onClick={(event) => {
			event.stopPropagation();
			onClick(event);
		}}>
		<Ellipsis className="w-4 fill-current text-rebrand-teal-text" />
	</button>
);

export default RoutineDetailCard;
