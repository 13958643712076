import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import { IAPIEvent, IAPIRecurrenceRule } from 'utils/types';
import EditEvent from 'components/CompanionApp/Event/EditEvent';
import EventDetails from 'components/CompanionApp/Event/EventDetails';
import WithoutHeaderLayout from 'components/Layouts/WithoutHeaderLayout';

interface LocationState {
	state: IAPIEvent;
}

export default function Event() {
	const location = useLocation() as LocationState;

	const event = location.state;
	const [edit, setEdit] = useState<boolean>(false);

	const [recurrenceRule, setRecurrenceRule] = useState<IAPIRecurrenceRule>();

	function fetchRecurrenceRule() {
		axiosClient
			.get(`/web/event/recurrence_rule/${event.recurrence_rule_id}`)
			.then((res) => {
				setRecurrenceRule(res.data);
			});
	}

	useEffect(() => {
		if (event.recurrence_rule_id) {
			fetchRecurrenceRule();
		}
	}, []);

	return (
		<WithoutHeaderLayout>
			{!edit ? (
				<EventDetails
					event={event}
					recurrenceRule={recurrenceRule}
					handleEdit={() => setEdit(true)}
				/>
			) : (
				<EditEvent
					event={event}
					recurrenceRule={recurrenceRule}
					handleCancel={() => setEdit(false)}
				/>
			)}
		</WithoutHeaderLayout>
	);
}
