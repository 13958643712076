import Button from 'components/Buttons/Button';
import Link from 'components/Links/Link';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import { useSetupContext } from 'components/setup/SetupLayout';
import useCurrentUser from 'stores/currentUser';
import { Button as MuiButton, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import PasswordInput from 'components/Forms/PasswordInput';
import PageTitle from 'components/Reusable/PageTitle';
import { useState, ChangeEvent, FocusEvent, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import { apiHost, apiRoot } from 'utils/variables';
import SetupContainer from 'components/Layouts/SetupContainer';

enum EmailErrorStates {
	INVALID = 'Invalid Email',
	REQUIRED = 'Required',
	ALREADY_USED = 'This email is already in use.',
}

enum PasswordErrorStates {
	REQUIRED = 'Required',
	LENGTH = 'Password needs to be between 8-40 characters.',
}

enum SetupAccountAction {
	CREATE_ACCOUNT_EMAIL_PASS = 'create-account-email-pass',
	CREATE_ACCOUNT_GOOGLE = 'create-account-google',
	EXISTING_LOGIN = 'existing-login',
}

interface IFormState {
	email: string;
	password: string;
}

function CreateAccountEmailPass({
	token,
	back,
}: {
	token: string;
	back: () => void;
}) {
	const { nextStep } = useSetupContext();
	const { currentUser, fetchCurrentUser } = useCurrentUser((store) => ({
		currentUser: store.user,
		fetchCurrentUser: store.fetchCurrentUser,
	}));
	const [formState, setFormState] = useState<IFormState>({
		email: currentUser?.email || '',
		password: '',
	});
	const [emailErrorState, setEmailErrorState] = useState<EmailErrorStates>();
	const [passwordErrorState, setPasswordErrorState] =
		useState<PasswordErrorStates>();
	const navigate = useNavigate();

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState((state) => ({
			...state,
			[event.target.name]: event.target.value,
		}));
		if (event.target.name === 'email') {
			setEmailErrorState(undefined);
		} else {
			setPasswordErrorState(undefined);
		}
	}

	function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
		if (event.target.name === 'email' && formState.email === '') {
			setEmailErrorState(EmailErrorStates.REQUIRED);
		} else if (event.target.name === 'password' && formState.password === '') {
			setPasswordErrorState(PasswordErrorStates.REQUIRED);
		}
	}

	async function handleSubmitForCreateUser() {
		return axiosClient.post('/setup/web/register_with_email', {
			email: formState.email,
			password: formState.password,
			onboarding_token: token,
		});
	}

	async function handleSubmitForUpdateUser() {
		return axiosClient.post('/onboarding/user/my', {
			email: formState.email,
			password: formState.password,
		});
	}

	async function handleSubmit() {
		if (formState.password.length < 8 || formState.password.length > 40) {
			setPasswordErrorState(PasswordErrorStates.LENGTH);
			return;
		}

		try {
			if (!currentUser) {
				await handleSubmitForCreateUser();
			} else {
				await handleSubmitForUpdateUser();
			}
			await fetchCurrentUser();
			nextStep();
		} catch (e) {
			const errorStatus = (e as AxiosError)?.response?.status;

			if (errorStatus === 422) {
				setEmailErrorState(EmailErrorStates.INVALID);
			} else if (errorStatus === 400) {
				setEmailErrorState(EmailErrorStates.ALREADY_USED);
			} else {
				navigate('/error');
			}
		}
	}

	return (
		<SetupContainer backOverride={back}>
			<PageTitle title="Create an account" className="mb-6" />
			<form onSubmit={handleSubmit} className="space-y-6">
				<TextField
					label="Email"
					type="email"
					name="email"
					value={formState.email}
					onChange={handleChange}
					onBlur={handleOnBlur}
					fullWidth
					error={!!emailErrorState}
					helperText={emailErrorState}
					variant="outlined"
					required
				/>
				<PasswordInput
					value={formState.password}
					onChange={handleChange}
					error={Boolean(passwordErrorState)}
					onBlur={handleOnBlur}
					helperText={
						!!passwordErrorState
							? passwordErrorState
							: PasswordErrorStates.LENGTH
					}
				/>
				<MuiButton
					variant="contained"
					fullWidth
					onClick={handleSubmit}
					disabled={!!emailErrorState || !!passwordErrorState}>
					Agree and continue
				</MuiButton>
			</form>
		</SetupContainer>
	);
}

function CreateAccountGoogle({
	token,
	back,
}: {
	token: string;
	back: () => void;
}) {
	const [error, setError] = useState<string | null>(null);
	useEffect(() => {
		// we make two requests, first to verify that we can be redirected
		// then we manually redirect if the first request is successful
		// to avoid CORS issues
		const url = `${apiHost}${apiRoot}/setup/authenticate_with_google?ot=${token}`;
		fetch(url, { redirect: 'manual' }).then(async (response) => {
			if (response.status >= 400) {
				console.error({ message: 'Failed to get google auth url' });
				setError('Failed to get Google auth, please try again.');
			} else {
				window.location.href = url;
			}
		});
	}, []);

	if (error) {
		return (
			<SetupContainer backOverride={back}>
				<div>{error}</div>
			</SetupContainer>
		);
	} else {
		return (
			<SetupContainer backOverride={back}>
				<div>Loading</div>
			</SetupContainer>
		);
	}
}

function Landing({
	setupAction,
}: {
	setupAction: (action: SetupAccountAction) => void;
}) {
	let pageData = {
		header: 'Set up your family',
		subheader:
			'Now it’s time to create your family account. Only one account can be connected to your Display at one time.',
		accountLink: '/setup/account',
	};
	return (
		<WithHeaderLayout menuButtonVisible={false}>
			<div className="px-6 flex flex-col justify-center h-full w-full items-center max-w-lg mx-auto">
				<div>
					<h4 className="font-medium text-center my-2">{pageData.header}</h4>
					<p className="text-center mb-7">{pageData.subheader}</p>
				</div>
				<Button
					label="Continue with Google"
					isFilled={false}
					isBorder={true}
					image={true}
					onClick={() => setupAction(SetupAccountAction.CREATE_ACCOUNT_GOOGLE)}
				/>
				<Button
					label="Continue with email"
					isFilled={true}
					isBorder={false}
					image={false}
					onClick={() =>
						setupAction(SetupAccountAction.CREATE_ACCOUNT_EMAIL_PASS)
					}
				/>
				<button
					className="text-rebrand-teal text-xs font-bold"
					onClick={() => setupAction(SetupAccountAction.EXISTING_LOGIN)}>
					Already have an account?
				</button>
			</div>
			<div className="w-full text-center text-xxs mb-6">
				<span>
					By clicking Continue with Google or Email, you agree to
					<br />
					Hearth Display’s{' '}
				</span>
				<Link
					linkTo="https://hearthdisplay.com/pages/privacy-policy"
					label="Privacy Policy"
				/>
				<span>&nbsp;&amp;&nbsp;</span>
				<Link
					linkTo="https://hearthdisplay.com/pages/terms-of-service"
					label="Terms of Service."
				/>
			</div>
		</WithHeaderLayout>
	);
}

export default function SetupAccount() {
	const { onboardingToken } = useSetupContext();
	const [setupAction, setSetupAction] = useState<SetupAccountAction | null>(
		null,
	);

	switch (setupAction) {
		case SetupAccountAction.CREATE_ACCOUNT_EMAIL_PASS:
			return (
				<CreateAccountEmailPass
					token={onboardingToken}
					back={() => setSetupAction(null)}
				/>
			);
		case SetupAccountAction.CREATE_ACCOUNT_GOOGLE:
			return (
				<CreateAccountGoogle
					token={onboardingToken}
					back={() => setSetupAction(null)}
				/>
			);

		case SetupAccountAction.EXISTING_LOGIN:
			return <Navigate to="/device-login" />;

		default:
			return (
				<Landing setupAction={(setupAction) => setSetupAction(setupAction)} />
			);
	}
}
