import PageTitle from 'components/Reusable/PageTitle';
import StepwiseLayout from 'components/Layouts/StepwiseLayout';
import { CardHeader, SelectionCard } from 'components/Reusable/Card';
import { useNavigate } from 'react-router-dom';
import {
	actionTypes,
	routineTypes,
	useRoutinesDispatch,
	useRoutinesState,
} from 'contexts/RoutinesContext';

export default function RoutineTemplate() {
	const navigate = useNavigate();

	const state = useRoutinesState();
	const dispatch = useRoutinesDispatch();
	const routineType = state.routineType;

	const updateRoutineType = (type: routineTypes) => {
		dispatch({ type: actionTypes.SET_ROUTINE_TYPE, payload: type });
	};

	const onCancel = () => {
		dispatch({ type: actionTypes.RESET_ROUTINE_CONTEXT });
		navigate('/routines');
	};

	return (
		<StepwiseLayout
			progress={20}
			enableFooter={routineType && !!routineType.length}
			onFooterClick={() => navigate('/routines/builder/assign')}
			onCancelClick={onCancel}>
			<div className="mt-8 mx-6 text-center">
				<PageTitle title="Build a Routine" className="mb-6" />
				<SelectionCard
					isSelected={routineType === routineTypes.TEMPLATE}
					onClick={() => updateRoutineType(routineTypes.TEMPLATE)}
					className="py-10 border-1 p-4 border-rebrand-grey-light ">
					<CardHeader headerText="Guide me through it" />
					<p>We’ll provide recommendations for</p>
					<p>the most common kids’ routines.</p>
				</SelectionCard>
				<p className="my-8">or</p>
				<SelectionCard
					isSelected={routineType === routineTypes.CUSTOM}
					onClick={() => updateRoutineType(routineTypes.CUSTOM)}
					className="py-10 border-1 p-4 border-rebrand-grey-light ">
					<CardHeader headerText="Build routine from scratch" />
					<p>Already have routines in mind?</p>
					<p>Skip ahead to get started.</p>
				</SelectionCard>
			</div>
		</StepwiseLayout>
	);
}
