import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/Buttons/Button';
import { apiHost, apiRoot } from 'utils/variables';
import Link from 'components/Links/Link';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';

export default function AuthenticateWithGoogle() {
	const location = useLocation();
	const navigate = useNavigate();

	let pageData = {
		header: 'Set up your family',
		subheader:
			'Now it’s time to create your family account. Only one account can be connected to your Display at one time.',
		authLink: `${apiHost}${apiRoot}/onboarding/session/authenticate_with_google?ot=${window.localStorage.getItem(
			'ot',
		)}`,
		createAccountLink: '/onboard/create-account',
	};

	if (location.pathname.startsWith('/join-family')) {
		pageData = {
			header: 'Join your family',
			subheader:
				"You're one step closer to having your family all in one place. Get started below by creating an account.",
			authLink: `${apiHost}${apiRoot}/onboarding/session/invite/authenticate_with_google?it=${window.localStorage.getItem(
				'it',
			)}`,
			createAccountLink: '/join-family/create-account',
		};
	} else if (location.pathname.startsWith('/re-auth')) {
		pageData = {
			header: 'Reauthenticate with Google',
			subheader: '',
			authLink: `${apiHost}${apiRoot}/user/reauthenticate_with_google`,
			createAccountLink: '',
		};
	}

	return (
		<WithHeaderLayout menuButtonVisible={false}>
			<div className="px-6 flex flex-col justify-center h-full w-full items-center max-w-lg mx-auto">
				<div>
					<h4 className="font-medium text-center my-2">{pageData.header}</h4>
					<p className="text-center mb-7">{pageData.subheader}</p>
				</div>
				<Button
					label="Continue with Google"
					linkTo={pageData.authLink}
					isFilled={false}
					isBorder={true}
					image={true}
				/>
				{!location.pathname.startsWith('/re-auth') && (
					<Button
						label="Continue with email"
						linkTo={pageData.createAccountLink}
						isFilled={true}
						isBorder={false}
						image={false}
					/>
				)}
				{!location.pathname.startsWith('/re-auth') && (
					<button
						className="text-rebrand-teal text-xs font-bold"
						onClick={() => navigate('/device-login')}>
						Already have an account?
					</button>
				)}
			</div>
			<div className="w-full text-center text-xxs mb-6">
				<span>
					By clicking Continue with Google or Email, you agree to
					<br />
					Hearth Display’s{' '}
				</span>
				<Link
					linkTo="https://hearthdisplay.com/pages/privacy-policy"
					label="Privacy Policy"
				/>
				<span>&nbsp;&amp;&nbsp;</span>
				<Link
					linkTo="https://hearthdisplay.com/pages/terms-of-service"
					label="Terms of Service."
				/>
			</div>
		</WithHeaderLayout>
	);
}
