import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import PageTitle from 'components/Reusable/PageTitle';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';

export default function NewPasswordSuccess() {
	const navigate = useNavigate();

	return (
		<WithHeaderLayout menuButtonVisible={false} className="px-6">
			<div className="mt-12 w-full mb-6 max-w-sm">
				<PageTitle
					title={'Success!'}
					subtitle="Your password was successfully changed."
					className="mb-6"
				/>
				<Button
					variant="contained"
					onClick={() => navigate('/login')}
					fullWidth>
					Back to Login
				</Button>
			</div>
		</WithHeaderLayout>
	);
}
