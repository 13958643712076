import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
	IAPIEvent,
	IAPIFamilyMember,
	IAPIRecurrenceDetails,
	ITimezoneObject,
} from 'utils/types';
import { calculateEventLength } from 'utils/dateTimeHelper';
import DatePickerRow from './DatePickerRow';
import TitleInput from '../../Forms/TitleInput';
import Toggle from '../../Buttons/Toggle';
import CalendarNameAndColor from './CalendarNameAndColor';
import Notes from '../../Forms/Notes';
import TimezoneSelect from 'components/Forms/TimezoneSelect';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Repeats from 'components/Forms/Repeats';
import { recurringEventsFeatureFlag } from 'utils/variables';
import ExceptionConfirmation from 'components/Modals/ExceptionConfirmation';
import FormLayout from 'components/Forms/FormLayout';
import FormHeading, { FormRow } from 'components/Forms/FormHeading';
import Line from 'components/Reusable/Line';
import MemberAvatar from '../Todos/Assignees/MemberAvatar';
import { SelectAvatarLayout } from '../Avatars/SelectAvatarLayout';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IFormState {
	title: string;
	notes: string;
}

interface IErrorState {
	title: boolean | null;
	startDateTime: boolean | null;
	endDateTime: boolean | null;
	timezone: boolean | null;
	attendees: boolean | null;
	calendar: boolean | null;
	notes: boolean | null;
	recurrenceDetails: boolean | null;
}

interface IPropTypes {
	defaultStart: Dayjs;
	defaultEnd: Dayjs;
	defaultRecurrenceDetails: IAPIRecurrenceDetails;
	handleSubmit: any;
	disableSaveButton: boolean;
	currentUserTimeZone: ITimezoneObject | undefined;
	setCurrentUserTimeZone: (arg0: ITimezoneObject) => void;
	members: IAPIFamilyMember[];
	event?: IAPIEvent;
	eventAttendeeIds?: number[];
	handleCancel?: () => void;
}

export default function EventDialog({
	defaultStart,
	defaultEnd,
	defaultRecurrenceDetails,
	handleSubmit,
	disableSaveButton,
	currentUserTimeZone = undefined,
	setCurrentUserTimeZone,
	members,
	event,
	eventAttendeeIds,
	handleCancel,
}: IPropTypes) {
	const [formState, setFormState] = useState<IFormState>({
		title: event?.subject ?? '',
		notes: event?.description ?? '',
	});

	const [recurrenceDetails, setRecurrenceDetails] =
		useState<IAPIRecurrenceDetails>(defaultRecurrenceDetails);

	const [errors, setErrors] = useState<IErrorState>({
		title: null,
		startDateTime: null,
		endDateTime: null,
		timezone: null,
		attendees: null,
		calendar: null,
		notes: null,
		recurrenceDetails: null,
	});

	const [isAllDayEvent, setIsAllDayEvent] = useState<boolean>(
		event?.is_all_day_event ?? false,
	);
	const [isRecurring, setIsRecurring] = useState<boolean>(
		!!event?.recurrence_rule_id,
	);
	const [showExceptionConfirmation, setShowExceptionConfirmation] =
		useState<boolean>(false);
	const [isExcludedEvent, setIsExcludedEvent] = useState<boolean>(
		!!event?.recurrence_rule_id,
	);
	const [timeErrorState, setTimeErrorState] = useState<boolean>(false);

	// Sets start and end datetimes to selected date on calendar
	const [startDateTime, setStartDateTime] = useState<dayjs.Dayjs>(defaultStart);
	const [endDateTime, setEndDateTime] = useState<dayjs.Dayjs>(defaultEnd);

	const [selectedColor, setSelectedColor] = useState<string | undefined>(
		event?.color,
	);

	const [selectedAttendees, setSelectedAttendees] = useState<number[]>(
		eventAttendeeIds ?? [],
	);

	function handleTimeError(
		startDateTime: dayjs.Dayjs,
		endDateTime: dayjs.Dayjs,
	) {
		return endDateTime.isBefore(startDateTime);
	}

	function onInputChangeHandler(e: {
		currentTarget: { name: string; value: string };
	}) {
		setFormState({
			...formState,
			[e.currentTarget.name]: e.currentTarget.value,
		});

		setErrors({ ...errors, title: false });
	}

	function startTimeHandler(selectedDate: Dayjs, closePicker: () => void) {
		const timeDiff = calculateEventLength(startDateTime, endDateTime);

		//Sets new end time to start time plus the previous event length
		setStartDateTime(selectedDate);
		setEndDateTime(selectedDate.add(timeDiff));

		// Close Start Date-Time Modal
		closePicker();
	}

	function endTimeHandler(selectedDate: Dayjs, closePicker: () => void) {
		if (handleTimeError(startDateTime, selectedDate)) {
			setTimeErrorState(true);
		} else {
			setEndDateTime(selectedDate);
			setTimeErrorState(false);

			// Close End Date-Time Modal
			closePicker();
		}
	}

	function recurrenceStartHandler(
		eventStart: Dayjs,
		dtStart: Dayjs,
		until: Dayjs | undefined,
	) {
		const dayOfMonth = eventStart.date();
		const dayOfWeek = eventStart.format('dd').toUpperCase();

		let weekNumber = '1';
		if (dayOfMonth / 7 > 1 && dayOfMonth / 7 <= 2) {
			weekNumber = '2';
		} else if (dayOfMonth / 7 > 2 && dayOfMonth / 7 <= 3) {
			weekNumber = '3';
		} else if (dayOfMonth / 7 > 3 && dayOfMonth / 7 <= 4) {
			weekNumber = '4';
		} else if (dayOfMonth / 7 > 4) {
			weekNumber = '-1';
		}

		const eventStartDate = eventStart.format('YYYY-MM-DD');
		const recurrenceStartDate = dtStart.format('YYYY-MM-DD');

		const noRecurrenceStart = !event?.recurrence_rule_id;
		const invalidRecurrenceStart =
			dayjs(recurrenceStartDate).isAfter(eventStartDate);

		let newDtStart = dtStart
			.set('hour', eventStart.hour())
			.set('minute', eventStart.minute());
		if (noRecurrenceStart || invalidRecurrenceStart) {
			newDtStart = eventStart;
		}

		let newUntil = undefined;

		if (!!until) {
			const untilDate = until.format('YYYY-MM-DD');
			if (dayjs(untilDate).isBefore(eventStartDate)) {
				newUntil = eventStart.format('YYYY-MM-DDT23:59:59Z');
			} else {
				newUntil = until.format('YYYY-MM-DDT23:59:59Z');
			}
		}

		let newByDay = recurrenceDetails.by_day;
		let newByMonthDay = recurrenceDetails.by_month_day;
		if (recurrenceDetails.freq === 'weekly') {
			if (!recurrenceDetails.by_day?.includes(dayOfWeek)) {
				newByDay = eventStart.format('dd').toUpperCase();
				newByMonthDay = undefined;
			}
		} else if (recurrenceDetails.freq === 'monthly') {
			if (eventStart.date() < 29 && !recurrenceDetails.by_day) {
				newByDay = undefined;
				newByMonthDay = eventStart.format('D');
			} else {
				newByDay = `${weekNumber}${dayOfWeek}`;
				newByMonthDay = undefined;
			}
		} else {
			newByDay = undefined;
			newByMonthDay = undefined;
		}

		setRecurrenceDetails({
			...recurrenceDetails,
			dt_start: newDtStart.toString(),
			by_day: newByDay,
			by_month_day: newByMonthDay,
			until: newUntil,
		});
	}

	// attendance change from edit event
	function handleAttendanceChange(member: IAPIFamilyMember) {
		// remove error if there is one
		setErrors({ ...errors, attendees: false });

		if (selectedAttendees.includes(member.id)) {
			setSelectedAttendees(selectedAttendees.filter((id) => id !== member.id));
		} else {
			setSelectedAttendees([...selectedAttendees, member.id]);
		}
	}

	function validateForm() {
		let errorState = { ...errors };
		let errorsPresent = false;

		if (formState.title === '') {
			errorState.title = true;
			errorsPresent = true;
		}

		if (selectedAttendees.length === 0) {
			errorState.attendees = true;
			errorsPresent = true;
		}

		setErrors(errorState);

		return errorsPresent;
	}

	function submitEventForm() {
		setShowExceptionConfirmation(false);
		const errorsPresent = validateForm();

		if (!errorsPresent && !isRecurring) {
			handleSubmit(
				formState,
				startDateTime,
				endDateTime,
				isAllDayEvent,
				selectedAttendees,
				selectedColor,
			);
		} else if (!errorsPresent && isRecurring) {
			handleSubmit(
				formState,
				startDateTime,
				endDateTime,
				isAllDayEvent,
				selectedAttendees,
				selectedColor,
				recurrenceDetails,
				isExcludedEvent,
			);
		}
	}

	useEffect(() => {
		if (eventAttendeeIds) {
			setSelectedAttendees(eventAttendeeIds);
		}
	}, []);

	useEffect(() => {
		setStartDateTime(startDateTime.tz(currentUserTimeZone?.timezone));
		setEndDateTime(endDateTime.tz(currentUserTimeZone?.timezone));
	}, [currentUserTimeZone]);

	useEffect(() => {
		recurrenceStartHandler(
			startDateTime,
			dayjs(recurrenceDetails.dt_start).tz(currentUserTimeZone?.timezone),
			!!recurrenceDetails.until
				? dayjs(recurrenceDetails.until).tz(currentUserTimeZone?.timezone)
				: undefined,
		);
	}, [
		isAllDayEvent,
		startDateTime,
		isRecurring,
		recurrenceDetails.freq,
		recurrenceDetails.until,
		currentUserTimeZone,
	]);

	return (
		<FormLayout
			label={disableSaveButton ? 'Saved!' : 'Save'}
			handleClick={
				isExcludedEvent && isRecurring && recurringEventsFeatureFlag
					? () => setShowExceptionConfirmation(true)
					: submitEventForm
			}
			isDisabled={disableSaveButton}>
			<ExceptionConfirmation
				open={showExceptionConfirmation}
				action="Edit"
				isExcludedEvent={isExcludedEvent}
				handleSelect={(selected: boolean) => setIsExcludedEvent(selected)}
				handleCancel={() => setShowExceptionConfirmation(false)}
				handleConfirmEdit={submitEventForm}
			/>

			<div className="w-full px-6">
				<button
					className="text-rebrand-teal font-semibold"
					onClick={handleCancel}>
					Cancel
				</button>
			</div>

			<TitleInput
				handleInputChange={onInputChangeHandler}
				value={formState.title}
				error={errors.title}
			/>

			<Line />

			<FormHeading label="All-Day event">
				<Toggle
					isToggledOn={isAllDayEvent}
					handleToggleSwitch={() => {
						if (isAllDayEvent) {
							setEndDateTime(startDateTime.add(1, 'hour'));
						}
						setIsAllDayEvent(!isAllDayEvent);
					}}
				/>
			</FormHeading>

			<Line />

			<FormHeading>
				<DatePickerRow
					rowText="Start"
					showTimePicker={!isAllDayEvent}
					defaultDateTime={startDateTime}
					dateTime={startDateTime}
					onSubmit={(date, closePicker) => startTimeHandler(date, closePicker)}
					timeErrorState={timeErrorState}
				/>
			</FormHeading>

			<Line />

			<FormHeading>
				<DatePickerRow
					rowText="End"
					showTimePicker={!isAllDayEvent}
					defaultDateTime={endDateTime}
					dateTime={endDateTime}
					onSubmit={(date, closePicker) => endTimeHandler(date, closePicker)}
					timeErrorState={timeErrorState}
				/>
			</FormHeading>

			<Line />

			<FormHeading label="Time Zone">
				<div className="bg-grey-teal max-w-[70%] rounded-lg">
					<TimezoneSelect
						selectedTimezone={currentUserTimeZone}
						onChange={(timezone: ITimezoneObject) =>
							setCurrentUserTimeZone(timezone)
						}
						showBorder={false}
					/>
				</div>
			</FormHeading>

			{recurringEventsFeatureFlag && (
				<>
					<Line />

					<FormHeading label="Repeats">
						<Toggle
							isToggledOn={isRecurring}
							handleToggleSwitch={() => setIsRecurring(!isRecurring)}
						/>
					</FormHeading>
					{isRecurring && (
						<div className="px-6 w-full">
							<Repeats
								handleSave={(updatedRecurrenceDetails: IAPIRecurrenceDetails) =>
									setRecurrenceDetails(updatedRecurrenceDetails)
								}
								initialRecurrenceDetails={recurrenceDetails}
								start={startDateTime}
							/>
						</div>
					)}
				</>
			)}

			<Line />

			<>
				<FormHeading label="Attendees" error={errors.attendees} />
				<div className="flex w-full pb-4 px-5 space-x-4 overflow-x-auto">
					{members.map((member) => (
						<div
							key={`member-id-${member.id}`}
							className="flex justify-center"
							onClick={() => handleAttendanceChange(member)}>
							<SelectAvatarLayout
								firstName={member.first_name}
								isSelected={selectedAttendees.includes(member.id)}>
								<MemberAvatar
									avatarFilename={member.avatar_file_name}
									className="w-12 h-12"
								/>
							</SelectAvatarLayout>
						</div>
					))}
				</div>
			</>

			<Line />

			<FormHeading>
				<CalendarNameAndColor
					color={selectedColor ?? '#008492'}
					handleColorChange={setSelectedColor}
					showOutline={false}
				/>
			</FormHeading>

			<Line />

			<FormRow label="Notes">
				<Notes value={formState.notes} onInputChange={onInputChangeHandler} />
			</FormRow>
		</FormLayout>
	);
}
