import animationData from '../lotties/circular-loading-bar.json';
import TextContainer from 'components/onboarding/TextContainer';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import { useSetupContext } from './SetupLayout';
import SetupOnboarding from './SetupOnboarding';

interface ISetupState {
	onboarding_id: string;
	status: string;
}

export default function SetupBoot() {
	const navigate = useNavigate();
	const abortController = new AbortController();
	const [hasError, setHasError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [searchParams] = useSearchParams();
	const {
		onboardingToken,
		setOnboardingToken,
		setPayload,
		resetActiveSetupStep,
		completeSetup,
	} = useSetupContext();
	const fetchCurrentUser = useCurrentUser((store) => store.fetchCurrentUser);

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	useEffect(() => {
		const paramToken = searchParams.get('ot');

		setIsLoading(true);
		fetchCurrentUser()
			.then((res) => {
				if (res) {
					console.debug('User is already authenticated');

					if (!res.isOnboarding) {
						console.debug('Family already setup');
						setPayload({ is_login: true });
						completeSetup();
						return;
					}

					// Check if the onboarding token is different from the one in the URL
					// login the device with the new token
					if (paramToken && onboardingToken && paramToken !== onboardingToken) {
						console.debug('Signing in device');
						setOnboardingToken(paramToken);
						resetActiveSetupStep();
					}
					setHasError(false);
				} else {
					const ot = paramToken || onboardingToken;
					axiosClient
						.post<ISetupState>(
							'/setup/web',
							{
								token: ot,
							},
							{
								signal: abortController.signal,
							},
						)
						.then((response) => {
							setHasError(false);
							setOnboardingToken(ot);
							navigate('/setup', { replace: true });
						})
						.catch((error) => {
							console.error(error);
							setHasError(true);
						});
				}
			})
			.catch((error) => {
				console.error(error);
				setHasError(true);
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			abortController.abort('Request cancelled');
		};
	}, []);

	if (hasError) {
		return (
			<div className="absolute flex flex-col items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
				<TextContainer
					textTitle="Oops we have a problem"
					textBody="It seems you have not started the onboarding from a Hearth device"
				/>
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="absolute flex flex-col items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
				<div className="w-40">
					<Lottie
						options={defaultOptions}
						height={'100%'}
						width={'100%'}
						isClickToPauseDisabled={true}
					/>
				</div>

				<p className="text-sm font-normal">Loading..</p>
			</div>
		);
	}

	return <SetupOnboarding />;
}
