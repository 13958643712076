import React, { createContext, useCallback, useState } from 'react';
import Notification from 'components/Reusable/Notification';
import { INotification, NotificationType } from 'utils/types';

interface INotificationContext {
	notification: INotification;
}
type IUpdateNotificationContext = (notification: INotification) => void;

const defaultValue: INotificationContext = {
	notification: {
		message: '',
		type: NotificationType.ERROR,
		displayTime: 5000,
		showNotification: false,
	},
};

export const NotificationContext =
	createContext<INotificationContext>(defaultValue);

export const UpdateNotificationContext =
	createContext<IUpdateNotificationContext>(() => {});

interface INotificationProviderProps {
	children: React.ReactNode;
}

// Create a provider component that wraps your app and provides the error context value
export const NotificationProvider = ({
	children,
}: INotificationProviderProps) => {
	const [notification, setNotification] = useState(defaultValue.notification);

	const updateNotification = useCallback((newNotification: INotification) => {
		setNotification(newNotification);
	}, []);

	return (
		<UpdateNotificationContext.Provider value={updateNotification}>
			<NotificationContext.Provider value={{ notification }}>
				<Notification />
				{children}
			</NotificationContext.Provider>
		</UpdateNotificationContext.Provider>
	);
};
