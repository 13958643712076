import Chip from 'components/Reusable/Chip';
import { isValidStreak } from 'utils/todoHelpers';
import { ReactComponent as Lightning } from 'assets/rebrand/lightning.svg';

interface StreakTagProps {
	completionStreak?: number;
	className?: string;
}

const StreakTag: React.FC<StreakTagProps> = ({
	completionStreak = 0,
	className,
}) => (
	<div>
		{isValidStreak(completionStreak) && (
			<Chip
				className={`font-bold bg-rebrand-yellow-light text-rebrand-yellow-dark ${className}`}
				label={`${completionStreak}`}
				icon={<Lightning />}
			/>
		)}
	</div>
);

export default StreakTag;
