import { Dayjs } from 'dayjs';
import { useState } from 'react';
import DateTimePicker from '../../Forms/DateTimePicker';
import DateTimeDisplay from 'components/Forms/DateTimeDisplay';
import ReusableModal from 'components/Modals/ReusableModal';

interface IPropTypes {
	defaultDateTime?: Dayjs;
	onSubmit?: (arg0: Dayjs, arg1: () => void) => void;
	rowText?: string;
	showTimePicker: boolean;
	dateTime: Dayjs;
	timeErrorState?: boolean;
}

export default function DatePickerRow({
	dateTime,
	onSubmit = () => {},
	rowText = 'Select Date',
	showTimePicker,
	timeErrorState = false,
}: IPropTypes) {
	const [showDatePicker, setShowDatePicker] = useState(false);

	return (
		<>
			<div className="flex w-full justify-between items-center">
				<h6 className="w-max font-semibold">{rowText}</h6>
				<DateTimeDisplay
					handleClick={() => {
						setShowDatePicker(true);
					}}
					isAllDayEvent={!showTimePicker}
					dateTime={dateTime}
				/>
			</div>
			<ReusableModal
				open={showDatePicker}
				handleToggleModal={() => setShowDatePicker(false)}>
				<DateTimePicker
					onSubmit={(time, date) =>
						onSubmit(date, () => setShowDatePicker(false))
					}
					selectedTime={dateTime}
					onCancel={() => setShowDatePicker(false)}
					pickingStart={false}
					pickingEnd={true}
					startDate={dateTime}
					endDate={dateTime}
					timePickerVisible={showTimePicker}
					timeErrorState={timeErrorState}
				/>
			</ReusableModal>
		</>
	);
}
