import ReusableModal from 'components/Modals/ReusableModal';

interface IProps {
	handleCloseModal: () => void;
	handleUnsubscribe: () => void;
}

export default function UnsubscribeModal({
	handleCloseModal,
	handleUnsubscribe,
}: IProps) {
	return (
		<ReusableModal
			handleToggleModal={handleCloseModal}
			open
			title="Unsubscribe">
			<p>Are you sure you want to unsubscribe from this calendar?</p>
			<div className="flex flex-row justify-between mt-2">
				<button className="text-tertiary font-bold" onClick={handleCloseModal}>
					Cancel
				</button>
				<button
					className="flex flex-row items-center p-2 px-3 space-x-3"
					onClick={handleUnsubscribe}>
					<p className="text-rebrand-red font-semibold">Unsubscribe</p>
				</button>
			</div>
		</ReusableModal>
	);
}
