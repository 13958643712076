import { apiHost, apiRoot } from './variables';
import axios, { AxiosResponse } from 'axios';

const axiosClient = axios.create({
	baseURL: `${apiHost}${apiRoot}`,
	withCredentials: true,
});

axiosClient.defaults.timeout = 10000;

function handle2xxResponse(response: AxiosResponse<any>): AxiosResponse<any> {
	return response;
}

axiosClient.interceptors.response.use(handle2xxResponse);

export default axiosClient;
