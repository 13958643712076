import { Outlet, useOutletContext } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { ICurrentUser } from 'utils/types';

import VerifyEmailAddress from './steps/VerifyEmailAddress';
import ProfileInfo from './steps/ProfileInfo';
import SetupFamily from './steps/CreateFamily';
import AddPhoneNumber from './steps/AddPhoneNumber';
import VerifyPhoneNumber from './steps/VerifyPhoneNumber';
import AddMembers from './steps/AddMembers';
import SubscriptionActivation from './steps/SubscriptionActivation';
import AddTimeZone from './steps/AddTimezone';
import FamilyPersonalizationGoals from './steps/FamilyPersonalizationGoals';
import SetupComplete from './steps/SetupComplete';
import SetupAccount from 'screens/setup/SetupAccount';
import useCurrentUser from 'stores/currentUser';

export interface SetupStatus {
	status: string;
	state?: SetupStepType;
}

interface SetupProfileStep {
	title: string;
	noSave?: boolean;

	backTo?: SetupStepType;
	skipTo?: SetupStepType;

	component: (props?: any) => React.ReactElement;
	props?: React.PropsWithChildren<any>;

	onFirst?: () => Promise<any>;
	onLast?: () => Promise<any>;

	onNext?: () => void;
	onPrev?: () => void;
}

// Please becareful updating this enum, it is used to track the user's progress
// the values here are stored in the database. If a value is removed or changed
// the user's progress will be lost.
export enum SetupStepType {
	account = 'account',
	verify_email = 'verify_email',
	activation = 'activation',
	profile = 'profile',
	setup_family = 'setup_family',
	add_phone_number = 'add_phone_number',
	verify_phone_number = 'verify_phone_number',
	add_members = 'add_members',
	timezone = 'timezone',
	family_personalization = 'family_personalization',
	individual_personalization = 'individual_personalization',

	complete = 'complete',
}

export const PROFILE_STEPS: { [key in SetupStepType]: SetupProfileStep } = {
	[SetupStepType.account]: {
		title: 'Setup Account',
		component: SetupAccount,
	},
	[SetupStepType.verify_email]: {
		title: 'Verify Email Address',
		component: VerifyEmailAddress,
		backTo: SetupStepType.account,
	},
	[SetupStepType.activation]: {
		title: 'Activation',
		component: SubscriptionActivation,
	},
	[SetupStepType.profile]: {
		title: 'Profile',
		component: ProfileInfo,
	},
	[SetupStepType.setup_family]: {
		title: 'Create Family',
		component: SetupFamily,
	},
	[SetupStepType.add_phone_number]: {
		title: 'Phone Number',
		component: AddPhoneNumber,
		skipTo: SetupStepType.timezone,
	},
	[SetupStepType.verify_phone_number]: {
		title: 'Verify Phone Number',
		component: VerifyPhoneNumber,
		noSave: true,
	},
	[SetupStepType.timezone]: {
		title: 'Timezone',
		component: AddTimeZone,
		backTo: SetupStepType.add_phone_number,
	},
	[SetupStepType.family_personalization]: {
		title: 'Family Personalization',
		skipTo: SetupStepType.add_members,
		component: FamilyPersonalizationGoals,
		props: { type: 'family' },
	},
	[SetupStepType.individual_personalization]: {
		title: 'Individual Personalization',
		skipTo: SetupStepType.add_members,
		component: FamilyPersonalizationGoals,
		props: { type: 'individual' },
	},
	[SetupStepType.add_members]: {
		title: 'Add Members',
		skipTo: SetupStepType.complete,
		component: AddMembers,
	},
	[SetupStepType.complete]: {
		title: 'Complete',
		component: SetupComplete,
		onLast: async () => {
			window.location.href = '/';
		},
	},
};

const PROFILE_STEPS_ORDER = [
	SetupStepType.account,
	SetupStepType.verify_email,
	SetupStepType.activation,
	SetupStepType.profile,
	SetupStepType.setup_family,
	SetupStepType.add_phone_number,
	SetupStepType.verify_phone_number,
	SetupStepType.timezone,
	SetupStepType.family_personalization,
	SetupStepType.individual_personalization,
	SetupStepType.add_members,
	SetupStepType.complete,
];

function getComponentFlow(currentUser?: ICurrentUser): SetupStepType[] {
	const removedSteps: SetupStepType[] = [];
	if (currentUser) {
		if (currentUser.isVerifiedEmail) {
			removedSteps.push(SetupStepType.account, SetupStepType.verify_email);
		}
		if (!currentUser.isOnboarding) {
			removedSteps.push(
				SetupStepType.account,
				SetupStepType.activation,
				SetupStepType.profile,
				SetupStepType.setup_family,
				SetupStepType.add_phone_number,
				SetupStepType.verify_phone_number,
				SetupStepType.timezone,
				SetupStepType.family_personalization,
				SetupStepType.individual_personalization,
				SetupStepType.add_members,
			);
		}
	}
	return PROFILE_STEPS_ORDER.filter((step) => !removedSteps.includes(step));
}

export type SetupContext = {
	progress: number;

	onboardingToken: string;
	setOnboardingToken: (token: string) => void;
	clearOnboardingToken: () => void;

	payload: any;
	setPayload: (payload: any) => void;

	prevStep: (payload?: unknown) => void;
	nextStep: (payload?: unknown) => void;

	skipStep: ((payload?: unknown) => void) | undefined;
	setSkipStep: (action: ((payload?: unknown) => void) | undefined) => void;

	backStep: ((payload?: unknown) => void) | undefined;
	setBackStep: (action: ((payload?: unknown) => void) | undefined) => void;

	activeSetupStep: SetupStepType | undefined;
	setActiveSetupStep: (step: SetupStepType) => void;
	resetActiveSetupStep: () => void;
	completeSetup: () => void;
};

export function useSetupContext() {
	return useOutletContext<SetupContext>();
}

export default function SetupLayout() {
	const currentUser = useCurrentUser((store) => store.user);
	const [onboardingToken, setTokenState] = useState(
		window.localStorage.getItem('ot') || '',
	);

	const [activeSetupStep, setActiveSetupStep] = useState<SetupStepType>();
	const [progress, setProgress] = useState<number>(0);
	const [payload, setPayload] = useState<any>();

	const componentFlow = useMemo(
		() => getComponentFlow(currentUser),
		[currentUser, currentUser?.isVerifiedEmail, currentUser?.isOnboarding],
	);

	function setOnboardingToken(token: string) {
		window.localStorage.setItem('ot', token);
		setTokenState(token);
	}
	function clearOnboardingToken() {
		window.localStorage.removeItem('ot');
		setTokenState('');
	}
	async function resetActiveSetupStep() {
		console.debug('Resetting componenet state');
		setActiveSetupStep(componentFlow[0]);
	}
	async function completeSetup() {
		setActiveSetupStep(componentFlow[componentFlow.length - 1]);
	}

	async function handlePrevStep(payload?: unknown) {
		setPayload(payload);

		const currStep = PROFILE_STEPS[activeSetupStep!];
		const currIndex = componentFlow.indexOf(activeSetupStep!);
		if (currIndex - 1 >= 0) {
			console.debug('unsetting step to', componentFlow[currIndex + 1]);
			setActiveSetupStep(componentFlow[currIndex - 1]);
		}

		if (currIndex === 0 && currStep.onFirst) {
			currStep.onFirst();
		}
	}
	async function handleNextStep(payload?: unknown) {
		setPayload(payload);

		const currStep = PROFILE_STEPS[activeSetupStep!];
		const currIndex = componentFlow.indexOf(activeSetupStep!);
		if (currIndex + 1 < componentFlow.length) {
			console.debug('setting step to', componentFlow[currIndex + 1]);
			setActiveSetupStep(componentFlow[currIndex + 1]);
		}

		if (currIndex + 1 === componentFlow.length && currStep.onLast) {
			currStep.onLast();
		}
	}

	const [skipStep, setSkipStep] = useState<undefined | (() => void)>();
	const [backStep, setBackStep] = useState<undefined | (() => void)>();

	useEffect(() => {
		if (activeSetupStep) {
			setProgress(
				Math.round(
					((componentFlow.indexOf(activeSetupStep) + 1) /
						componentFlow.length) *
						100,
				),
			);
		}
	}, [activeSetupStep]);

	const context: SetupContext = {
		progress,

		onboardingToken,
		setOnboardingToken,
		clearOnboardingToken,

		nextStep: handleNextStep,
		prevStep: handlePrevStep,

		skipStep,
		setSkipStep,

		backStep,
		setBackStep,

		payload,
		setPayload,

		activeSetupStep,
		setActiveSetupStep,
		resetActiveSetupStep,
		completeSetup,
	};

	return <Outlet context={context} />;
}
