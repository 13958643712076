import { AvatarSrc } from 'utils/types';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import greyForwardArrow from 'assets/Icons/Arrows/grey-forward-arrow.svg';

interface IMemberRowProps {
	firstName?: string;
	avatar: string;
	onClick?: () => void;
	avatarSrc?: AvatarSrc;
}

const MemberRow = ({
	firstName,
	avatar,
	avatarSrc = AvatarSrc.REMOTE,
	onClick = undefined,
}: IMemberRowProps) => (
	<div
		onClick={onClick}
		className="flex flex-row w-full h-80 items-center justify-between">
		<div className="flex flex-row items-center justify-start">
			<MemberAvatar
				avatarFilename={avatar}
				avatarSrc={avatarSrc}
				className="aspect-square h-12 w-12 mr-4"
			/>
			<p>{firstName}</p>
		</div>
		{onClick && (
			<img
				className="lg:visible invisible"
				src={greyForwardArrow}
				alt="Forward Arrow"
			/>
		)}
	</div>
);

export default MemberRow;
