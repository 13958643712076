import { useState, useEffect } from 'react';
import { IFamilyMember } from 'utils/types';

function useDefaultIndependenceLevel(routineUser?: IFamilyMember) {
	const [selectedLevel, setSelectedLevel] = useState<number>(1);

	useEffect(() => {
		if (!routineUser) return;

		const defaultIndependenceLevel =
			routineUser.independenceLevelId ||
			(routineUser.isResponsibleAdult ? 1 : 2);

		setSelectedLevel(defaultIndependenceLevel);
	}, [routineUser]);

	return [selectedLevel, setSelectedLevel] as const;
}

export default useDefaultIndependenceLevel;
