import { SetStateAction, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import analytics from 'utils/segmentClient';
import axiosClient from 'utils/axiosClient';
import {
	useFamilyMemberList,
	useFetchFamilyMembers,
} from 'contexts/FamilyContext';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import DragAndDropProfiles from 'components/DragAndDrop/DragAndDropProfiles';
import { IProfile, getAnyoneAndMemberProfiles } from 'utils/customOrderHelpers';

const UNSET_ORDER_VALUE = 100;

const sortProfiles = (a: IProfile, b: IProfile) => {
	const orderA =
		a.custom_order ?? UNSET_ORDER_VALUE + a.first_name.charCodeAt(0);
	const orderB =
		b.custom_order ?? UNSET_ORDER_VALUE + b.first_name.charCodeAt(0);

	return orderA - orderB;
};

interface IReorderProfilesProps {
	showDrawer: boolean;
	closeDrawer: () => void;
}

export default function ReorderProfiles({
	showDrawer,
	closeDrawer,
}: IReorderProfilesProps) {
	const members = useFamilyMemberList();
	const fetchMembers = useFetchFamilyMembers();

	const [profiles, setProfiles] = useState<IProfile[]>([]);
	const [orderChanged, setOrderChanged] = useState(false); // New state to track changes

	async function reorderProfilesApi() {
		await axiosClient
			.put(
				'/web/family/reorder_profiles',
				profiles.map((item, index) => ({
					custom_order: index,
					user_id: item.user_id,
				})),
			)
			.then((response) => {
				if (response.status === 200) {
					closeDrawer();
					fetchMembers();
					analytics.track('reordered_profiles', {
						first_profile_is_kid: profiles[0].is_kid,
						first_profile_is_adult: profiles[0].is_adult,
						first_profile_is_anyone: profiles[0].is_anyone,
						second_profile_is_kid: profiles[1].is_kid,
						second_profile_is_adult: profiles[1].is_adult,
						second_profile_is_anyone: profiles[1].is_anyone,
					});
				}
			})
			.catch((error) => console.log(error));
	}

	useEffect(() => {
		setProfiles(getAnyoneAndMemberProfiles(members).sort(sortProfiles));
		setOrderChanged(false);
	}, [members]);

	const updateProfilesOrder = (newProfiles: SetStateAction<IProfile[]>) => {
		setProfiles(newProfiles);
		setOrderChanged(true);
	};

	const attemptExit = () => {
		if (orderChanged) {
			reorderProfilesApi().then(closeDrawer);
		} else {
			closeDrawer();
		}
	};

	return (
		<SlideUpDrawer open={showDrawer} onClose={closeDrawer} maxHeight={'95%'}>
			<DndProvider backend={HTML5Backend}>
				<div className="flex flex-col py-6 px-4">
					<div className="flex w-full items-center justify-between text-rebrand-teal text-sm">
						<button onClick={closeDrawer}>Cancel</button>
						<button onClick={attemptExit} className="font-bold">
							Done
						</button>
					</div>
					<p className="text-rebrand-teal-text text-sm pt-6">
						Customize your Hearth family profile order. On the Hearth Companion
						App, your profile will always appear first.
					</p>
					<DragAndDropProfiles
						profiles={profiles}
						setProfiles={updateProfilesOrder}
					/>
				</div>
			</DndProvider>
		</SlideUpDrawer>
	);
}
