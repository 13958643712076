import { IAPIRecurrenceDetails } from 'utils/types';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import Frequency from 'components/CompanionApp/Recurrence/Frequency';
import { Button } from '@mui/material';

interface RecurrenceFormProps {
	initialRecurrenceDetails: IAPIRecurrenceDetails;
	handleSave?: (arg0: IAPIRecurrenceDetails) => void;
	start?: dayjs.Dayjs;
	onClose?: () => void;
}

const RecurrenceForm: React.FC<RecurrenceFormProps> = ({
	handleSave,
	initialRecurrenceDetails,
	start = dayjs(),
	onClose,
}) => {
	const [recurrenceDetails, setRecurrenceDetails] =
		useState<IAPIRecurrenceDetails>(initialRecurrenceDetails);

	function handleChange(name: string, value: string | number | undefined) {
		setRecurrenceDetails((state) => ({
			...state,
			[name]: value,
		}));
	}

	function onCancel() {
		setRecurrenceDetails(initialRecurrenceDetails);
		onClose && onClose();
	}

	function onSave() {
		handleSave && handleSave(recurrenceDetails);
	}

	function handleTimeChange(e: React.ChangeEvent<HTMLInputElement>) {
		const time = e.target.value;
		if (time) {
			// Check if the time string is not empty
			const [hours, minutes] = time.split(':').map(Number);
			const date = recurrenceDetails.dt_start
				? dayjs(recurrenceDetails.dt_start)
				: start;
			const newDate = date.hour(hours).minute(minutes);
			handleChange(
				'dt_start',
				newDate.isValid() ? newDate.format() : start.format(),
			);
		} else {
			// Handle the case where the time input is cleared
			handleChange('dt_start', start.hour(9).minute(0).format());
		}
	}

	return (
		<>
			<Frequency
				handleChange={handleChange}
				freq={recurrenceDetails.freq}
				interval={recurrenceDetails.interval}
				byDay={recurrenceDetails.by_day}
				byMonthDay={recurrenceDetails.by_month_day}
				start={start}
				showFreqDropdown={false}
			/>
			<div className="mt-4 flex flex-row justify-start items-center">
				<p>at</p>
				<input
					type="time"
					value={dayjs(recurrenceDetails.dt_start).format('HH:mm')}
					onChange={handleTimeChange}
					className="ml-3 px-2 py-3 flex bg-grey-teal rounded-md"
				/>
			</div>
			<div className="flex justify-between mt-4 h-8">
				<button
					onClick={onCancel}
					className="font-semibold text-xs text-rebrand-teal">
					Cancel
				</button>
				<Button variant="outlined" onClick={onSave}>
					Save
				</Button>
			</div>
		</>
	);
};

export default RecurrenceForm;
