import FormLayout from 'components/Forms/FormLayout';
import PageTitle from 'components/Reusable/PageTitle';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MemberCalendar from 'screens/onboarding/Oauth/MemberCalendar';
import useCalendarList from 'stores/calendarList';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import shallow from 'zustand/shallow';

interface ISelectedCalendar {
	id: number;
	includeEventDetails: boolean;
	checked: boolean;
	name: string;
	userId?: number;
	color: string;
	calendarId: string;
}

interface IPropTypes {
	isOnboarding?: boolean;
}

interface IAPICalendar {
	id: number;
	include_event_details: boolean;
	is_used_in_hearth: boolean;
	name: string;
	user_id?: number;
	color: string;
	calendar_id: string;
}

export default function ImportCalendars({ isOnboarding }: IPropTypes) {
	const navigate = useNavigate();
	const location = useLocation();

	const { isAuthenticated, user } = useCurrentUser((store) => ({
		isAuthenticated: store.isAuthenticated,
		user: store.user,
	}));
	const { googleCalendars, fetchGoogleCalendars } = useCalendarList(
		(store) => ({
			googleCalendars: store.calendars,
			fetchGoogleCalendars: store.fetchCalendars,
		}),
		shallow,
	);

	const [allSettings, setAllSettings] = useState<ISelectedCalendar[]>([]);

	const sortCalendars = (a: ISelectedCalendar, b: ISelectedCalendar) => {
		if (!a.name.includes('@') && b.name.includes('@')) {
			return 1;
		} else if (a.name.includes('@') && !b.name.includes('@')) {
			return -1;
		} else {
			return 1;
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			fetchGoogleCalendars();
		}
	}, [isAuthenticated]);

	async function getStoredCalendars() {
		try {
			const response = await axiosClient.get<IAPICalendar[]>(`/web/calendar`, {
				params: {
					for_family: true,
				},
			});
			return response.data;
		} catch {
			return [];
		}
	}

	useEffect(() => {
		async function inner() {
			const storedCalendars: IAPICalendar[] = await getStoredCalendars();
			setAllSettings(
				googleCalendars.map(({ calendar_id, name }) => {
					const storedCalendar: IAPICalendar | undefined = storedCalendars.find(
						(calendar: IAPICalendar) =>
							calendar.name === name && calendar.user_id === user?.id,
					);
					return {
						checked: !!storedCalendar,
						includeEventDetails: !!storedCalendar?.include_event_details,
						calendarId: calendar_id,
						name: name,
						id: !!storedCalendar ? storedCalendar.id : 0,
						color: !!storedCalendar ? storedCalendar.color : '',
					};
				}),
			);
		}
		if (isAuthenticated) {
			inner();
		}
	}, [googleCalendars]);
	function updateState(
		id: string,
		checked: boolean,
		includeEventDetails: boolean,
	) {
		const newArr: ISelectedCalendar[] = [...allSettings];
		const index = allSettings.findIndex(
			(calendar) => calendar.calendarId === id,
		);
		newArr[index] = { ...newArr[index], checked, includeEventDetails };
		setAllSettings(newArr);
	}

	async function toggleChecked(calendar: ISelectedCalendar) {
		const newValue = !calendar.checked;
		updateState(calendar.calendarId, newValue, newValue);
	}

	async function toggleShowEventDetails(calendar: ISelectedCalendar) {
		if (calendar.checked) {
			const newValue = !calendar.includeEventDetails;
			updateState(calendar.calendarId, calendar.checked, newValue);
		}
	}

	async function handleSubmit() {
		const param = allSettings
			.filter(({ checked }) => checked)
			.map(({ calendarId, includeEventDetails }) => ({
				calendar_id: calendarId,
				include_event_details: includeEventDetails,
			}));

		await axiosClient.post(`/web/external_calendar/google`, param);
		if (isOnboarding) {
			navigate('/onboard/complete');
		} else {
			navigate('/manage-calendar');
		}
	}

	const buttonLabel = location.pathname.startsWith('/onboarding')
		? 'Continue'
		: 'Done';

	return (
		<FormLayout label={buttonLabel} handleClick={handleSubmit}>
			<div className="mx-6">
				<PageTitle
					title="Select calendars to import"
					subtitle="Only the calendars you select will show up on your Hearth."
				/>

				<div className="mt-1 w-full">
					{allSettings.sort(sortCalendars).map((c, i) => (
						<div className="pb-1" key={`imported-calendar-id-${c.id}-${i}`}>
							<MemberCalendar
								calendar={c}
								settings={allSettings[i]}
								onCheckChange={() => toggleChecked(c)}
								onShareChange={() => toggleShowEventDetails(c)}
								showShareEventDetails={isOnboarding}
								userId={user?.id}
								userColor={user?.color}
							/>
						</div>
					))}
				</div>
			</div>
		</FormLayout>
	);
}
