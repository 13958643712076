import BlackCloseButton from 'assets/Icons/Closing/black-closing-x.svg';
import React from 'react';

interface IPropTypes {
	handleToggleModal: () => void;
}

export default function HearthHelperModal({ handleToggleModal }: IPropTypes) {
	return (
		<>
			<div className="flex justify-between mb-4">
				<h6 className="mt-6 font-medium">What is Hearth Helper?</h6>
				<button onClick={handleToggleModal}>
					<img src={BlackCloseButton} alt="back button" />
				</button>
			</div>
			<div>
				<p className="text-normal pb-4 text-sm">
					Text Hearth Helper with a picture of a paper schedule, calendar or
					email and it will be added to your calendar within 24 hours.
				</p>
				<p className="text-normal pb-4 text-sm">
					You’ll also be able to receive text notifications (you can always
					change these settings later).
				</p>
			</div>
		</>
	);
}
