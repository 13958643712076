import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Button, TextField } from '@mui/material';
import PasswordInput from 'components/Forms/PasswordInput';
import GoogleButton from 'components/Buttons/GoogleButton';

interface IProps {
	googleAuthLink: string;
	handleLoginCallback: (email: string, password: string) => void;
	isDeviceLogin: boolean;
}

interface IFormState {
	email: string;
	password: string;
}

interface IErrorState {
	email: boolean;
	password: boolean;
}

const EMAIL_NOT_FOUND = "We couldn't find an account with this email.";
const INCORRECT_PASSWORD = 'Incorrect password';

export default function SignIn({
	googleAuthLink,
	handleLoginCallback,
	isDeviceLogin,
}: IProps) {
	const navigate = useNavigate();
	const [formState, setFormState] = useState<IFormState>({
		email: '',
		password: '',
	});

	const [errors, setErrors] = useState<IErrorState>({
		email: false,
		password: false,
	});

	async function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState((state) => ({
			...state,
			[event.target.name]: event.target.value,
		}));

		setErrors((state) => ({
			...state,
			[event.target.name]: false,
		}));
	}

	async function handleLogin(email: string, password: string) {
		await handleLoginCallback(email, password);
	}

	function submitForm() {
		let errorsPresent = false;

		if (formState.email === '') {
			setErrors((state) => ({
				...state,
				email: true,
			}));
			errorsPresent = true;
		}

		if (formState.password.length < 8) {
			setErrors((state) => ({
				...state,
				password: true,
			}));
			errorsPresent = true;
		}

		if (errorsPresent) return;

		handleLogin(formState.email, formState.password).catch((e: AxiosError) => {
			const errorStatus = e?.response?.status;

			//TODO: This will also return 404 if the email is associated with an
			// account that is authenticated through google. Need to update that case
			// so it doesn't display the same 'couldn't find account' error
			if (errorStatus === 422 || errorStatus === 404) {
				setErrors({ email: true, password: false });
			} else if (errorStatus === 401) {
				setErrors({ email: false, password: true });
			} else {
				navigate('/error');
			}
		});
	}

	return (
		<div className="h-full flex flex-col justify-center px-6 max-w-lg mx-auto">
			<div className="mb-6">
				{isDeviceLogin ? (
					<p className="text-lg lg:text-lg py-4 font-semibold text-center">
						Login to connect <br />
						your Display
					</p>
				) : (
					<h4 className="font-semibold text-center">Login to Hearth</h4>
				)}
			</div>
			<GoogleButton googleAuthLink={googleAuthLink} />
			<p className="text-xxs text-center my-4">or</p>
			<div className="w-full mb-6 space-y-4">
				<TextField
					label="Email"
					type="email"
					name="email"
					value={formState.email}
					onChange={handleChange}
					fullWidth
					error={errors.email}
					helperText={errors.email ? EMAIL_NOT_FOUND : ''}
					variant="outlined"
				/>
				<PasswordInput
					value={formState.password}
					onChange={handleChange}
					helperText={errors.password ? INCORRECT_PASSWORD : ''}
					error={errors.password}
				/>
			</div>
			<Button variant="contained" onClick={submitForm}>
				Login
			</Button>
			<button
				onClick={() => navigate('/reset-password')}
				className="mt-4 text-rebrand-teal text-xs font-semibold">
				Forgot your password?
			</button>

			<p className="text-center w-full text-xxs mb-6 mt-12">
				Your privacy is important to us. See our
				<a
					href="https://hearthdisplay.com/pages/privacy-policy"
					className="text-rebrand-teal ml-1 underline">
					Privacy Policy
				</a>
			</p>
		</div>
	);
}
