import Checkbox from 'components/CheckBoxes/CheckBox';

interface IPropTypes {
	handleCheck: () => void;
	isChecked: boolean;
	shouldShowError: boolean;
}

export default function PrivacyPolicy({ handleCheck, isChecked }: IPropTypes) {
	return (
		<div className="my-6 flex flex-row">
			<Checkbox
				borderColor={'#008492'}
				isChecked={isChecked}
				onClick={handleCheck}
			/>
			<p className="ml-4 text-xxs flex-grow">
				If any child is under the age of 13, I agree to the collection and use
				of my child’s information as set forth in the{' '}
				<a
					href="https://hearthdisplay.com/pages/privacy-policy"
					className="text-xxs underline"
					target="_blank">
					Privacy Policy.
				</a>
			</p>
		</div>
	);
}
