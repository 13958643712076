import MemberAvatar from './MemberAvatar';
import MemberName from './MemberName';

interface IPropTypes {
	size: number;
	avatar_file_name: string;
	first_name?: string;
	className?: string;
}

export default function ViewAssignee({
	size,
	avatar_file_name,
	first_name,
	className = 'w-12 h-12',
}: IPropTypes) {
	const containerStyle = 'flex flex-col';
	const avatarStyle = 'relative';
	const nameStyle = 'flex justify-center';

	return (
		<div className={containerStyle}>
			<div
				className={avatarStyle}
				style={{
					height: size,
					width: size,
				}}>
				<MemberAvatar avatarFilename={avatar_file_name} className={className} />
			</div>

			<div
				className={nameStyle}
				style={{
					width: size,
				}}>
				{first_name && <MemberName first_name={first_name} />}
			</div>
		</div>
	);
}
