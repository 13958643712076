import { useNavigate } from 'react-router-dom';
import { apiHost, apiRoot } from 'utils/variables';
import axiosClient from 'utils/axiosClient';
import SignIn from 'components/SignIn/SignIn';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';

export default function DeviceLogin() {
	const navigate = useNavigate();

	const authLink = `${apiHost}${apiRoot}/hearth/session/authenticate_with_google?ot=${window.localStorage.getItem(
		'ot',
	)}`;

	async function handlePasswordLogin(email: string, password: string) {
		const response = await axiosClient.post(
			'/hearth/session/login_with_email',
			{
				email: email,
				password: password,
				onboarding_token: window.localStorage.getItem('ot'),
			},
		);

		if (response.status === 200) {
			navigate('/device-login/complete');
		}
	}

	return (
		<WithHeaderLayout menuButtonVisible={false}>
			<SignIn
				googleAuthLink={authLink}
				handleLoginCallback={handlePasswordLogin}
				isDeviceLogin={true}
			/>
		</WithHeaderLayout>
	);
}
