import { IFamilyMember, IRoutine } from 'utils/types';
import { FC } from 'react';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { DividingLine } from 'components/Reusable/Separators';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import RepeatsOn from 'components/CompanionApp/Recurrence/RepeatsOn';
import dayjs from 'dayjs';
import { ReactComponent as ProgressCheck } from 'assets/rebrand/check-circle-white.svg';
import {
	StepProgressBar,
	WeekProgressBar,
} from 'components/Routines/RoutineProgressBars';
import StreakTag from 'components/CompanionApp/Recurrence/StreakTag';
import { Button } from '@mui/material';

interface IRoutineDetailDrawerProps {
	open: boolean;
	onClose: () => void;
	onEdit: () => void;
	onEllipsisClick: () => void;
	routine?: IRoutine;
	member?: IFamilyMember;
}

const RoutineDetailDrawer: FC<IRoutineDetailDrawerProps> = ({
	open,
	onClose,
	onEdit,
	routine,
	member,
}) => {
	if (!routine) return <SlideUpDrawer open={open} onClose={onClose} />;

	const { name, completedSteps, pastProgress, recurrenceDetails, steps } =
		routine ?? {};

	const completionStreak = recurrenceDetails?.completionStreak;

	const currentDay = dayjs().format('dd').toUpperCase();
	const isCurrentDayInByDay = (byDay: string) =>
		byDay.split(',').includes(currentDay);
	const today = dayjs().format('dd').toUpperCase();
	const stepsToday = steps.filter((step) =>
		step.recurrenceDetails?.byDay.includes(today),
	);

	return (
		<SlideUpDrawer open={open} onClose={onClose}>
			<div className="p-4">
				<div className="flex flex-row justify-between items-center">
					<div className="flex flex-row text-md">
						<h6 className="font-semibold">{name}</h6>
					</div>
				</div>
				{recurrenceDetails && (
					<RepeatsOn
						freq={'weekly'}
						interval={recurrenceDetails.interval}
						dtStart={dayjs(recurrenceDetails.dtStart)}
						byDay={recurrenceDetails.byDay}
						byMonthDay={''}
						count={recurrenceDetails.count}
						until={recurrenceDetails.until}
						appendTime
					/>
				)}
				<StepProgressBar
					completedSteps={completedSteps}
					totalSteps={stepsToday.length}
				/>
				{steps && (
					<div className="text-grey-dark mt-4 max-h-[250px] overflow-y-auto pr-4">
						<p className="mb-2 text-grey-dark">Steps</p>
						{steps.map(
							({ name, isComplete, recurrenceDetails }, index) =>
								recurrenceDetails?.byDay &&
								isCurrentDayInByDay(recurrenceDetails?.byDay) && (
									<div
										className="flex flex-row my-2 items-center space-x-2"
										key={index}>
										{isComplete ? (
											<ProgressCheck
												height={20}
												width={20}
												className="fill-current text-rebrand-yellow flex-shrink-0"
											/>
										) : (
											<div className="w-5 h-5 bg-grey rounded-full flex-shrink-0" />
										)}
										<p>{name}</p>
									</div>
								),
						)}
					</div>
				)}
				<WeekProgressBar pastProgress={pastProgress} />
				<StreakTag completionStreak={completionStreak} className="mt-2" />
			</div>
			<DividingLine />
			<div className="flex flex-row justify-between m-4">
				<div className="flex flow-row items-center space-x-2">
					<MemberAvatar
						avatarFilename={member?.avatarFileName}
						className="w-7 h-7"
					/>
					<p>{member?.firstName}</p>
				</div>
				<Button
					onClick={onEdit}
					variant="outlined"
					sx={{
						height: 32,
					}}>
					Edit
				</Button>
			</div>
		</SlideUpDrawer>
	);
};

export default RoutineDetailDrawer;
