import React from 'react';
import { TextField } from '@mui/material';
import { IMember } from 'utils/types';

interface INameInput {
	name: string;
	index: number;
	handleChange: (index: number, member: Partial<IMember>) => void;
	shouldShowError: boolean;
	textBelow?: string;
}

export default function NameInput({
	name,
	index,
	handleChange,
	shouldShowError,
	textBelow,
}: INameInput) {
	return (
		<TextField
			name="name"
			label="Name*"
			placeholder="First name"
			fullWidth
			value={name}
			onChange={(event) => handleChange(index, { name: event.target.value })}
			error={shouldShowError && !!textBelow}
			helperText={shouldShowError ? textBelow : ''}
			onFocus={() => handleChange(index, { shouldShowError: false })}
			variant="outlined"
		/>
	);
}
