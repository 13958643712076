import { useRef, useState } from 'react';
import dayjs from 'dayjs';

import DateTimePicker from 'components/Forms/DateTimePicker';
import DateTimeDisplay from 'components/Forms/DateTimeDisplay';
import NumberInput from 'components/CompanionApp/Recurrence/NumberInput';
import { TealRadioButton } from 'components/CompanionApp/Recurrence/Radio';
import { formatEventTime } from 'utils/dateTimeHelper';
import ReusableModal from '../../Modals/ReusableModal';

interface IPropTypes {
	handleChange: (arg0: string, arg1: string | number | undefined) => void;
	start: dayjs.Dayjs;
	until: dayjs.Dayjs;
	count: number;
	never: boolean;
	on: boolean;
	after: boolean;
}

interface ITime {
	hours: number;
	minutes: number;
	amPm: string;
}

export default function Ends({
	handleChange,
	start,
	until,
	count,
	never,
	on,
	after,
}: IPropTypes) {
	const countInput = useRef<any>();
	const [untilDatePickerVisible, setUntilDatePickerVisible] =
		useState<boolean>(false);

	function handleEndsNever() {
		handleChange('count', undefined);
		handleChange('until', undefined);
		countInput.current.blur();
	}

	function handleEndsOn() {
		handleChange('count', undefined);
		handleChange('until', formatEventTime(until));
		countInput.current.blur();
	}

	function handleEndsAfter() {
		handleChange('count', count);
		handleChange('until', undefined);
		countInput.current.focus();
	}

	function untilHandler(time: ITime, selectDate: dayjs.Dayjs) {
		handleChange('until', formatEventTime(selectDate));
		setUntilDatePickerVisible(false);
	}

	return (
		<>
			<ReusableModal
				open={untilDatePickerVisible}
				handleToggleModal={() => setUntilDatePickerVisible(false)}>
				<DateTimePicker
					onSubmit={(time: ITime, selectedDate: dayjs.Dayjs) =>
						untilHandler(time, selectedDate)
					}
					onCancel={() => setUntilDatePickerVisible(false)}
					pickingStart={false}
					pickingEnd={true}
					startDate={start}
					endDate={until}
					timePickerVisible={false}
					timeErrorState={false}
				/>
			</ReusableModal>

			<div className="mt-4 flex items-start">
				<p className="text-md font-semibold mr-2 w-12">Ends</p>

				<div className="space-y-2">
					<div>
						<TealRadioButton
							onChange={handleEndsNever}
							checked={never}
							inputProps={{ 'aria-label': 'One Time' }}
						/>
						<span>never</span>
					</div>

					<div>
						<TealRadioButton
							onChange={handleEndsOn}
							checked={on}
							inputProps={{ 'aria-label': 'Recurs on Date' }}
						/>
						<span className="mr-3">on</span>
						<DateTimeDisplay
							handleClick={() => {
								setUntilDatePickerVisible(!untilDatePickerVisible);
								handleEndsOn();
							}}
							isAllDayEvent={true}
							dateTime={until}
						/>
					</div>

					<div>
						<TealRadioButton
							onChange={handleEndsAfter}
							checked={after}
							inputProps={{ 'aria-label': 'Ends After Count' }}
						/>
						<span className="mr-3">after</span>
						<NumberInput
							name="count"
							value={count}
							handleInputChange={(input: string) => {
								if (input.length > 0) {
									handleChange('count', parseInt(input));
								}
							}}
							inputRef={countInput}
						/>
						<span className="ml-3">times</span>
					</div>
				</div>
			</div>
		</>
	);
}
