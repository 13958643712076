import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type AnimatedContainerProps = {
	children: React.ReactNode;
	animationTime?: number;
	className?: string;
};

const AnimatedContainer: React.FC<AnimatedContainerProps> = ({
	children,
	animationTime = 50,
	className = '',
}) => {
	const [animationClass, setAnimationClass] = useState('opacity-0');
	const location = useLocation();

	useEffect(() => {
		setAnimationClass('opacity-0');
		setTimeout(() => {
			setAnimationClass('opacity-100');
		}, animationTime);
	}, [location]);

	return (
		<div
			className={`transition-opacity duration-300 ease-in-out ${animationClass} ${className}`}>
			{children}
		</div>
	);
};

export default AnimatedContainer;
