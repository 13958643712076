import { ReactComponent as Logo } from 'assets/rebrand/new-logo.svg';
import { FC } from 'react';

interface ILogoProps {
	className?: string;
}

const HearthLogo: FC<ILogoProps> = ({ className }) => (
	<Logo className={`${className} fill-current text-rebrand-teal-text`} />
);

export default HearthLogo;
