import React from 'react';
import DayCell from './DayCell';
import dayjs from 'dayjs';
import { IAPIEvent, IAPIExternalCalendar } from 'utils/types';

interface IProps {
	activeDate: dayjs.Dayjs;
	events?: Record<string, IAPIEvent[]>;
	calendars?: IAPIExternalCalendar[];
}

const WeekdaysHeader = () => {
	const daysInWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

	return (
		<div className="flex justify-between w-full h-6">
			{daysInWeek.map((day, i) => (
				<div className="flex justify-center w-6" key={day + i}>
					<p className="text-rebrand-grey-medium font-semibold text-xs">
						{day}
					</p>
				</div>
			))}
		</div>
	);
};

const DayGrid: React.FC<IProps> = ({ activeDate, events, calendars }) => {
	const buildDayCellGrid = (weekStartDay: number, firstDayOfWeek: number) =>
		Array.from({ length: 7 }, (__, dayIndex) => {
			// Calculates the first day of this particular calendar window (first Sunday of the first week of the month)
			const day = activeDate
				.startOf('month')
				.add(weekStartDay + dayIndex - firstDayOfWeek, 'day');
			const formattedDate = day.format('YYYY-MM-DD');

			// Gather all events for the given day
			const eventsForDay = events ? events[formattedDate] : [];

			return (
				<DayCell
					key={formattedDate}
					thisDate={day}
					thisMonth={day.month()}
					activeDate={activeDate}
					events={eventsForDay}
					calendars={calendars}
				/>
			);
		});

	const weeks = () => {
		const daysInMonth = activeDate.daysInMonth();
		const firstDayOfWeek = activeDate.startOf('month').day();
		const totalWeeks = Math.ceil((daysInMonth + firstDayOfWeek) / 7);

		return Array.from({ length: totalWeeks }, (_, weekIndex) => {
			const weekStartDay = weekIndex * 7;
			const days = buildDayCellGrid(weekStartDay, firstDayOfWeek);

			return (
				<div
					className="flex justify-between w-full h-full"
					key={`week-${weekIndex}`}>
					{days}
				</div>
			);
		});
	};

	return (
		<div className="w-full h-full mb-2 flex flex-col items-center">
			<WeekdaysHeader />
			{weeks()}
		</div>
	);
};

export default DayGrid;
