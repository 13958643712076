import React, { useEffect, useState } from 'react';
import StepwiseLayout from 'components/Layouts/StepwiseLayout';
import { useNavigate } from 'react-router-dom';
import BackButton from 'components/Buttons/BackButton';
import {
	actionTypes,
	useRoutinesDispatch,
	useRoutinesState,
} from 'contexts/RoutinesContext';
import { IRoutineTemplate } from 'utils/types';
import { ReactComponent as CheckCircle } from 'assets/rebrand/check-circle-white.svg';
import { iconPath } from 'utils/variables';
import {
	CUSTOM_ROUTINE_TEMPLATE,
	DEFAULT_RECURRENCE_DETAILS,
	ROUTINE_TEMPLATES,
	SAMPLE_ROUTINE_STEPS,
} from 'utils/routineHelpers';
import { AxiosResponse } from 'axios';
import axiosClient from 'utils/axiosClient';

export default function RoutineRecommendations() {
	const navigate = useNavigate();
	const state = useRoutinesState();
	const dispatch = useRoutinesDispatch();
	const { routineTemplate, routineUsers } = state;
	const [routineTemplates, setRoutineTemplates] =
		useState<IRoutineTemplate[]>(ROUTINE_TEMPLATES);

	useEffect(() => {
		if (routineUsers.length > 0) {
			const userId = routineUsers[0].id;

			axiosClient
				.get(`/web/routines/templates/${userId}`)
				.then((response: AxiosResponse<IRoutineTemplate[]>) => {
					setRoutineTemplates([...response.data, CUSTOM_ROUTINE_TEMPLATE]);
				});
		}
	}, [routineUsers]);

	const handleTemplateClick = (template: IRoutineTemplate) => {
		template.recurrenceDetails = DEFAULT_RECURRENCE_DETAILS;

		dispatch({
			type: actionTypes.SET_ROUTINE_TEMPLATE,
			payload: template,
		});

		if (template.name === 'Custom Routine') return;

		const steps = (template.steps ?? SAMPLE_ROUTINE_STEPS).map((step) => ({
			...step,
			recurrenceDetails: DEFAULT_RECURRENCE_DETAILS,
		}));

		dispatch({
			type: actionTypes.SET_ROUTINE_STEPS,
			payload: steps,
		});
	};

	const onCancel = () => {
		dispatch({ type: actionTypes.RESET_ROUTINE_CONTEXT });
		navigate('/routines');
	};

	return (
		<StepwiseLayout
			progress={60}
			enableFooter={!!routineTemplate}
			onFooterClick={() => navigate('/routines/builder/create')}
			onCancelClick={onCancel}>
			<div className="mx-6">
				<BackButton />
				<h6 className="mt-4 mb-2 text-lg font-semibold">
					Recommended routines
				</h6>
				<p>
					Don’t see what you’re looking for? Build a custom routine instead.
				</p>
			</div>
			<hr className="mt-6" />
			{routineTemplates.map((template, index) => (
				<div key={`${template.name}-${index}`}>
					<TemplateRow
						template={template}
						selectedTemplateName={routineTemplate?.name}
						handleClick={() => handleTemplateClick(template)}
					/>
					<hr />
				</div>
			))}
		</StepwiseLayout>
	);
}

type TemplateRowProps = {
	template: IRoutineTemplate;
	handleClick: () => void;
	selectedTemplateName: string | undefined;
};

const TemplateRow: React.FC<TemplateRowProps> = ({
	template: { image, name },
	handleClick,
	selectedTemplateName,
}) => (
	<div
		className="px-6 py-4 flex flex-row items-center cursor-pointer relative"
		onClick={handleClick}>
		<div className="w-16 h-16 bg-primary-light rounded flex items-center justify-center">
			<img src={`${iconPath}/${image}.svg`} alt={name} />
		</div>
		<div className="ml-4">
			<p className="mb-1">{name}</p>
			<p className="text-grey-dark">Daily</p>
		</div>
		{selectedTemplateName === name && (
			<CheckCircle className="absolute top-4 right-4 bg-rebrand-teal rounded-full h-5 w-5" />
		)}
	</div>
);
