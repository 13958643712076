import React, { useState } from 'react';
import { AvatarSrc, IProfilePicture } from 'utils/types';
import { ReactComponent as UploadIcon } from 'assets/Icons/Upload/upload.svg';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import AvatarMenu from 'components/Forms/Upload/AvatarMenu';
import AvatarDrawer from 'components/Forms/Upload/AvatarDrawer';
import axiosClient from 'utils/axiosClient';
import { Modal } from '@mui/material';

interface IUploadAvatarProps {
	onUploadClick: () => void;
	onAvatarChange: (avatar: string, avatarSrc?: AvatarSrc) => void;
	profilePicture?: IProfilePicture;
	userId?: number | string;
	firstName?: string;
	editing?: boolean;
	allowRemove?: boolean;
	editButton?: boolean;
}

const UploadAvatar = ({
	onUploadClick,
	onAvatarChange,
	profilePicture,
	userId,
	firstName,
	allowRemove = true,
	editButton = false,
}: IUploadAvatarProps) => {
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const [showAvatarMenu, setShowAvatarMenu] = useState<boolean>(false);
	const toggleAvatarMenu = () => setShowAvatarMenu(!showAvatarMenu);

	const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
	const toggleDeleteWarning = () => setShowDeleteWarning(!showDeleteWarning);

	const [showPostDelete, setShowPostDelete] = useState<boolean>(false);
	const togglePostDelete = () => {
		setShowPostDelete(!showPostDelete);
	};

	const handleChooseAvatar = () => {
		toggleAvatarMenu();
		toggleMenu();
	};

	const handleUploadPhoto = () => {
		onUploadClick();
		toggleMenu();
	};

	const handleRemovePhoto = () => {
		if (userId) {
			axiosClient
				.delete(`/web/upload/avatar/${userId}`)
				.then(() => {
					onAvatarChange('');
					toggleDeleteWarning();
					toggleMenu();
					togglePostDelete();
				})
				.catch(() => {
					console.log('Error removing avatar');
				});

			onAvatarChange('');
		}
	};

	return (
		<div className="flex flex-col h-full w-full items-center">
			<div
				className="relative h-full w-32 group cursor-pointer flex items-center justify-center hover:opacity-80"
				onClick={() => setShowMenu(true)}>
				<MemberAvatar
					avatarFilename={profilePicture?.filename}
					avatarSrc={profilePicture?.avatarSrc}
					className="h-full w-full"
				/>
				<UploadIcon className="z-20 w-6 h-6 fill-current text-white opacity-0 transition-opacity group-hover:opacity-100 absolute" />
			</div>

			{editButton && (
				<button
					className="mt-1 text-rebrand-teal font-bold text-xs z-10"
					onClick={toggleMenu}>
					Edit Photo
				</button>
			)}

			{firstName && <p className="mt-3 text-lg font-semibold">{firstName}</p>}

			{showMenu && (
				<AvatarMenu
					toggleMenu={toggleMenu}
					handleChooseAvatar={handleChooseAvatar}
					handleUploadPhoto={handleUploadPhoto}
					handleRemovePhoto={allowRemove ? toggleDeleteWarning : undefined}
				/>
			)}

			<DeleteWarning
				open={showDeleteWarning}
				onClose={() => {
					toggleDeleteWarning();
					toggleMenu();
				}}
				onDelete={handleRemovePhoto}
			/>

			<PostDeleteModal
				open={showPostDelete}
				onClose={togglePostDelete}
				onPick={toggleAvatarMenu}
			/>

			<AvatarDrawer
				showAvatarMenu={showAvatarMenu}
				toggleAvatarMenu={toggleAvatarMenu}
				onAvatarChange={(avatar: string) => {
					onAvatarChange(avatar, AvatarSrc.LOCAL);
				}}
			/>
		</div>
	);
};

export default UploadAvatar;

interface IDeleteWarningProps {
	open: boolean;
	onClose: () => void;
	onDelete: () => void;
}

const DeleteWarning = ({ open, onClose, onDelete }: IDeleteWarningProps) => (
	<Modal open={open} onClose={onClose}>
		<div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-11/12 md:w-2/3 max-w-xl shadow-lg p-6 rounded-lg">
			<h2 className="text-lg font-bold">Delete Profile Picture</h2>
			<p>
				Are you sure you want to delete this profile picture? This action can't
				be undone, but you can always upload the picture again.
			</p>
			<div className="flex flex-row justify-center space-x-4 mt-4">
				<button
					className="bg-error-dark text-white px-4 py-2 rounded-full w-100"
					onClick={onDelete}>
					Delete
				</button>
				<button
					className="bg-grey-green text-white px-4 py-2 rounded-full w-100"
					onClick={onClose}>
					Cancel
				</button>
			</div>
		</div>
	</Modal>
);

interface IPostDeleteModalProps {
	open: boolean;
	onClose: () => void;
	onPick: () => void;
}

const PostDeleteModal = ({ open, onClose, onPick }: IPostDeleteModalProps) => (
	<Modal open={open} onClose={onClose}>
		<div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-11/12 md:w-2/3 max-w-xl shadow-lg p-6 rounded-lg flex flex-col items-center">
			<h2 className="text-lg font-bold">Delete Profile Picture</h2>
			<p>Ok deleted! Let's pick a new Avatar!</p>
			<div className="flex flex-row justify-center space-x-4 mt-4">
				<button
					className="bg-primary text-white px-4 py-2 rounded-full w-100"
					onClick={() => {
						onPick();
						onClose();
					}}>
					Let's Go!
				</button>
				<button
					className="bg-grey-green text-white px-4 py-2 rounded-full w-100"
					onClick={onClose}>
					Cancel
				</button>
			</div>
		</div>
	</Modal>
);
