import { IAPIRecurrenceDetails } from 'utils/types';
import dayjs from 'dayjs';
import { RRule, Weekday, Frequency } from 'rrule';
import weekday from 'dayjs/plugin/weekday';
dayjs.extend(weekday);
var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

export const DEFAULT_RECURRENCE_DETAILS: IAPIRecurrenceDetails = {
	freq: 'weekly',
	dt_start: dayjs().format('YYYY-MM-DD') + 'T00:00:00',
	interval: 1,
	by_day: dayjs().format('dd').toUpperCase(),
};

export const formatRecurrenceDetails = (
	recurrenceDetails?: IAPIRecurrenceDetails,
	appendTime: boolean = false,
	shortForm: boolean = false,
): string => {
	if (recurrenceDetails) {
		const freq = recurrenceDetails.freq;
		const interval = recurrenceDetails.interval;
		const dtStart = recurrenceDetails.dt_start;
		const byDay = recurrenceDetails.by_day;
		const byMonthDay = recurrenceDetails.by_month_day;
		const count = recurrenceDetails.count;
		const until = recurrenceDetails.until;

		let freqString = freq.charAt(0).toUpperCase() + freq.slice(1);
		if (freq === 'yearly') {
			freqString = freqString.replace('Yearly', 'Annually');
		}
		if (!!interval && interval > 2 && freq !== 'daily') {
			freqString = freq.slice(0, -2) + 's';
		} else if (!!interval && interval > 2 && freq === 'daily') {
			freqString = 'days';
		} else if (!!interval && interval === 2 && freq !== 'daily') {
			freqString = freq.slice(0, -2);
		} else if (!!interval && interval === 2 && freq === 'daily') {
			freqString = 'day';
		} else if (byDay === 'SU,MO,TU,WE,TH,FR,SA') {
			freqString = '';
		} else if (byDay === 'SU,SA') {
			freqString = '';
		} else if (byDay === 'MO,TU,WE,TH,FR') {
			freqString = '';
		}

		let intervalString = '';
		if (!!interval && interval > 2) {
			intervalString = `Every ${interval} `;
		} else if (!!interval && interval === 2) {
			intervalString = `Every other `;
		}

		let repeatsOn = '';
		if (freq === 'weekly' && byDay) {
			const daysOfWeekArray = byDay.split(',');
			const daysOfWeek = daysOfWeekArray.map((day) => {
				switch (day) {
					case 'SU':
						return 'Sundays';
					case 'MO':
						return 'Mondays';
					case 'TU':
						return 'Tuesdays';
					case 'WE':
						return 'Wednesdays';
					case 'TH':
						return 'Thursdays';
					case 'FR':
						return 'Fridays';
					case 'SA':
						return 'Saturdays';
					default:
						return '';
				}
			});
			const daysOfWeekShort = daysOfWeekArray.map((day) => {
				switch (day) {
					case 'SU':
						return 'Sun';
					case 'MO':
						return 'Mon';
					case 'TU':
						return 'Tue';
					case 'WE':
						return 'Wed';
					case 'TH':
						return 'Thu';
					case 'FR':
						return 'Fri';
					case 'SA':
						return 'Sat';
					default:
						return '';
				}
			});
			if (byDay === 'SU,SA') {
				if (!!interval && interval > 1) {
					repeatsOn = ' on weekends';
				} else {
					repeatsOn = 'Repeats on weekends';
				}
			} else if (byDay === 'MO,TU,WE,TH,FR') {
				if (!!interval && interval > 1) {
					repeatsOn = ' on weekdays';
				} else {
					repeatsOn = 'Repeats on weekdays';
				}
			} else if (byDay === 'SU,MO,TU,WE,TH,FR,SA') {
				if (!!interval && interval > 1) {
					repeatsOn = ', daily';
				} else {
					repeatsOn = 'Daily';
				}
			} else if (daysOfWeekArray.length < 3 && !shortForm) {
				repeatsOn = ` on ${daysOfWeek.join(', ')}`;
			} else if (daysOfWeekArray.length < 4 && !shortForm) {
				repeatsOn = ` on ${daysOfWeekShort.join(', ')}`;
			} else {
				repeatsOn = ` on ${daysOfWeekArray
					.map((day) => day.substring(0, 1))
					.join(', ')}`;
			}
		} else if (freq === 'yearly') {
			repeatsOn = ` on ${dayjs(dtStart).format('MMM D')}`;
		} else if (freq === 'monthly' && !!byDay) {
			const weekDay = byDay
				.slice(-2)
				.replace('SU', 'Sunday')
				.replace('MO', 'Monday')
				.replace('TU', 'Tuesday')
				.replace('WE', 'Wednesday')
				.replace('TH', 'Thursday')
				.replace('FR', 'Friday')
				.replace('SA', 'Saturday');
			const weekNumber = byDay
				.slice(0, -2)
				.replace('-1', 'last')
				.replace('1', '1st')
				.replace('2', '2nd')
				.replace('3', '3rd')
				.replace('4', '4th');
			repeatsOn = ` on the ${weekNumber} ${weekDay}`;
		} else if (freq === 'monthly' && !!byMonthDay) {
			let monthDate = dayjs().format('YYYY-MM') + `${byMonthDay}`;
			repeatsOn = ` on the ${dayjs(monthDate).format('Do')}`;
		}

		let endsOn = '';
		if (!!until) {
			endsOn = ` until ${dayjs(until).format('MMMM D, YYYY')}`;
		} else if (!!count) {
			endsOn = `, ${count} time${count > 1 ? 's' : ''}`;
		}

		let recurrenceText = `${intervalString}${freqString}${repeatsOn}${endsOn}`;

		if (appendTime) {
			recurrenceText += ` at ${dayjs(dtStart).format('h:mm A')}`;
		}
		return recurrenceText;
	}
	return 'Never';
};

const translateToRRuleFreq = (freq: string): Frequency => {
	const frequencies: { [key: string]: Frequency } = {
		yearly: RRule.YEARLY,
		monthly: RRule.MONTHLY,
		weekly: RRule.WEEKLY,
		daily: RRule.DAILY,
	};

	return frequencies[freq];
};

function translateToRRuleByWeekDay(byDay: string): Weekday[] {
	const weekdays: { [key: string]: Weekday } = {
		MO: RRule.MO,
		TU: RRule.TU,
		WE: RRule.WE,
		TH: RRule.TH,
		FR: RRule.FR,
		SA: RRule.SA,
		SU: RRule.SU,
	};
	const byweekday: Weekday[] = [];
	byDay.split(',').forEach((day) => byweekday.push(weekdays[day]));

	return byweekday;
}

export const calculateInstanceDueAt = (
	recurrenceDetails: IAPIRecurrenceDetails,
	referenceDate: dayjs.Dayjs,
): dayjs.Dayjs => {
	const freq = translateToRRuleFreq(recurrenceDetails.freq);
	const interval = recurrenceDetails.interval;
	const dtstart = dayjs(recurrenceDetails.dt_start).toDate();

	if (recurrenceDetails.by_day) {
		if (freq === RRule.WEEKLY) {
			const byweekday = translateToRRuleByWeekDay(recurrenceDetails.by_day);

			const rule = new RRule({
				freq: freq,
				interval: interval,
				byweekday: byweekday,
				dtstart: dtstart,
			});

			return dayjs(rule.after(referenceDate.toDate(), true));
		}

		// last week day of month
		if (freq === RRule.MONTHLY && recurrenceDetails.by_day.includes('-1')) {
			const dayOfWeek = referenceDate.weekday();
			const lastWeekOfMonth = referenceDate.endOf('month').startOf('week');
			return lastWeekOfMonth.add(dayOfWeek, 'day');
		}

		// other week days of month
		return referenceDate;
	}

	if (recurrenceDetails.by_month_day) {
		const rule = new RRule({
			freq: freq,
			interval: interval,
			bymonthday: parseInt(recurrenceDetails.by_month_day),
			dtstart: dtstart,
		});

		return dayjs(rule.after(referenceDate.toDate(), true));
	}

	const rule = new RRule({
		freq: freq,
		interval: interval,
		dtstart: dtstart,
	});

	return dayjs(rule.after(referenceDate.toDate(), true));
};

export const handleDueAtChange = (
	handleChange: (arg0: string, arg1: any) => void,
	dueAt: dayjs.Dayjs,
) => {
	handleChange('dt_start', dueAt.format('YYYY-MM-DD') + 'T00:00:00');
	handleChange('by_day', dueAt.format('dd').toUpperCase());
};

export const handleDtStartChange = (
	handleChange: (arg0: string, arg1: any) => void,
	dtStart: dayjs.Dayjs,
	byMonthDay?: string,
) => {
	if (dtStart.date() < 29 && byMonthDay) {
		handleChange('by_day', undefined);
		handleChange('by_month_day', dtStart.format('D'));
	} else {
		handleChange('by_day', getByDayForMonthlyFreq(dtStart));
		handleChange('by_month_day', undefined);
	}
};

export const getByDayForMonthlyFreq = (dtStart: dayjs.Dayjs): string => {
	const dayOfMonth = dtStart.date();
	const dayOfWeek = dtStart.format('dd').toUpperCase();

	if (dayOfMonth / 7 > 4) {
		return `-1${dayOfWeek}`;
	}

	if (dayOfMonth / 7 > 3 && dayOfMonth / 7 <= 4) {
		return `4${dayOfWeek}`;
	}

	if (dayOfMonth / 7 > 2 && dayOfMonth / 7 <= 3) {
		return `3${dayOfWeek}`;
	}

	if (dayOfMonth / 7 > 1 && dayOfMonth / 7 <= 2) {
		return `2${dayOfWeek}`;
	}

	return `1${dayOfWeek}`;
};
