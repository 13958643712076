import { useContext, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
	IAPIEvent,
	IAPIFamilyMember,
	ITimezoneObject,
	IAPIRecurrenceRule,
	IAPIRecurrenceDetails,
} from 'utils/types';
import axiosClient from 'utils/axiosClient';
import useCurrentUser from 'stores/currentUser';
import { useNavigate } from 'react-router-dom';
import analytics from 'utils/segmentClient';
import { formatEventTime } from 'utils/dateTimeHelper';
import { fetchMembers } from 'utils/eventHelper';
import EventDialog from './EventDialog';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { UpdateNotificationContext } from 'contexts/NotificationContext';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IPropTypes {
	event: IAPIEvent;
	recurrenceRule: IAPIRecurrenceRule | undefined;
	handleCancel: () => void;
}

export default function EditEvent({
	event,
	recurrenceRule,
	handleCancel,
}: IPropTypes) {
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);
	const [disabledSaveButton, setDisableSaveButton] = useState<boolean>(false);

	const user = useCurrentUser((store) => store.user);
	const [members, setMembers] = useState<IAPIFamilyMember[]>([]);
	const [currentUserTimeZone, setCurrentUserTimeZone] =
		useState<ITimezoneObject>();

	// Handle Attendees
	const originalAttendeeUserIds = event.attendee_list.map(
		(attendee) => attendee.user_id,
	);

	const numOfAttendingAdults = members
		.filter(
			(member) =>
				originalAttendeeUserIds.includes(member.id) &&
				member.is_responsible_adult,
		)
		.map((member) => member.id);
	const numOfAttendingKids = members
		.filter(
			(member) =>
				originalAttendeeUserIds.includes(member.id) &&
				!member.is_responsible_adult,
		)
		.map((member) => member.id);

	function handleResponse(
		statusCode: number,
		trackMessage: string,
		isRecurring: boolean,
	) {
		if (statusCode === 200 || statusCode === 201) {
			analytics.track(`${trackMessage}`, {
				from: 'web app',
				userId: `user-${user?.id}`,
				isRecurring: isRecurring,
			});
			analytics.track('Event attendees', {
				adults: numOfAttendingAdults.length,
				kids: numOfAttendingKids.length,
				userId: `user-${user?.id}`,
			});
			fetchMembers(setMembers);
			navigate('/calendar');
		} else {
			handleError();
		}
	}

	function handleError() {
		updateNotification({
			message: 'Event could not be updated',
			showNotification: true,
		});
		setDisableSaveButton(false);
	}

	const getScope = (
		shouldEditSingleEvent?: boolean,
		shouldEditExceptionEvent?: boolean,
		shouldEditInstanceOfRecurringEvent?: boolean,
	) => {
		if (shouldEditInstanceOfRecurringEvent) {
			return 'this_event';
		} else if (shouldEditSingleEvent || shouldEditExceptionEvent) {
			return;
		} else {
			return 'all_events';
		}
	};
	const getTrackMessage = (
		shouldEditSingleEvent?: boolean,
		shouldEditExceptionEvent?: boolean,
		shouldEditInstanceOfRecurringEvent?: boolean,
	) => {
		if (shouldEditInstanceOfRecurringEvent) {
			return 'Exception created';
		} else if (shouldEditSingleEvent) {
			return 'Event edited';
		} else if (shouldEditExceptionEvent) {
			return 'Exception edited';
		} else {
			return 'Event edited';
		}
	};

	async function handleEditEvent(
		eventForm: any,
		startDateTime: Dayjs,
		endDateTime: Dayjs,
		isAllDayEvent: boolean,
		selectedAttendees: number[],
		selectedColor: string,
		recurrenceDetails?: IAPIRecurrenceDetails | undefined,
		isExcludedEvent?: boolean,
	) {
		setDisableSaveButton(true);

		// for editing single event
		const shouldEditSingleEvent =
			!recurrenceRule || (!isExcludedEvent && !recurrenceDetails);
		// for editing single event that was created by editing an instance of recurring event
		const shouldEditExceptionEvent =
			isExcludedEvent && !!event.original_start_at;
		// for editing single instance of recurring event that has not been edited before
		const shouldEditInstanceOfRecurringEvent =
			isExcludedEvent && !event.original_start_at;
		// else for editing all instances/exceptions of recurring event

		const scope = getScope(
			shouldEditSingleEvent,
			shouldEditExceptionEvent,
			shouldEditInstanceOfRecurringEvent,
		);
		const trackMessage = getTrackMessage(
			shouldEditSingleEvent,
			shouldEditExceptionEvent,
			shouldEditInstanceOfRecurringEvent,
		);

		const data = {
			subject: eventForm.title,
			description: eventForm.notes,
			is_all_day_event: isAllDayEvent,
			start_at: formatEventTime(startDateTime, isAllDayEvent),
			start_timezone: currentUserTimeZone?.timezone,
			end_at: formatEventTime(endDateTime, isAllDayEvent, true),
			end_timezone: currentUserTimeZone?.timezone,
			recurrence_rule_id: recurrenceRule?.id,
			original_start_at: event.original_start_at ?? undefined,
			recurrence_details: !!recurrenceDetails
				? {
						freq: recurrenceDetails.freq,
						interval: recurrenceDetails.interval,
						dt_start: formatEventTime(
							dayjs(recurrenceDetails.dt_start),
							isAllDayEvent,
						),
						by_day: recurrenceDetails.by_day,
						by_month_day: recurrenceDetails.by_month_day,
						count: recurrenceDetails.count,
						until: recurrenceDetails.until,
				  }
				: undefined,
			attendee_list: members
				.filter((member) => selectedAttendees.includes(member.id))
				.map((m) => ({ user_id: m.id })),
			color: selectedColor,
		};

		const params = {
			scope: scope,
			start_at: shouldEditInstanceOfRecurringEvent ? event.start_at : undefined,
		};

		await axiosClient
			.put(`/web/event/${event.id}`, data, { params })
			.then((response) => {
				handleResponse(response.status, trackMessage, !!recurrenceDetails);
			})
			.catch(() => {
				handleError();
			});
	}

	useEffect(() => {
		fetchMembers(setMembers);
	}, []);

	return (
		<EventDialog
			// defaultStart={dayjs(event.start_at).tz(currentUserTimeZone.timezone)}
			// defaultEnd={!event.is_all_day_event ? dayjs(event.end_at).tz(currentUserTimeZone.timezone) : dayjs(event.end_at).subtract(1, "day").tz(currentUserTimeZone.timezone)}

			defaultStart={dayjs(event.start_at)}
			defaultEnd={
				!event.is_all_day_event
					? dayjs(event.end_at)
					: dayjs(event.end_at).subtract(1, 'day')
			}
			handleSubmit={handleEditEvent}
			disableSaveButton={disabledSaveButton}
			currentUserTimeZone={currentUserTimeZone}
			setCurrentUserTimeZone={setCurrentUserTimeZone}
			members={members}
			event={event}
			eventAttendeeIds={originalAttendeeUserIds}
			defaultRecurrenceDetails={
				!!recurrenceRule
					? {
							freq: recurrenceRule.freq,
							interval: recurrenceRule.interval,
							dt_start: dayjs(recurrenceRule.dt_start).toString(),
							by_day: recurrenceRule.by_day,
							by_month_day: recurrenceRule.by_month_day,
							count: recurrenceRule.count,
							until: recurrenceRule.until,
					  }
					: {
							freq: 'weekly',
							interval: 1,
							dt_start: dayjs(event.start_at).toString(),
							by_day: dayjs(event.start_at).format('dd').toUpperCase(),
							by_month_day: undefined,
							count: undefined,
							until: undefined,
					  }
			}
			handleCancel={handleCancel}
		/>
	);
}
