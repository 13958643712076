import NavigationArrow from 'components/Buttons/NavigationArrow';
import dayjs from 'dayjs';
import { ReactComponent as TodayIcon } from 'assets/rebrand/today.svg';

interface IProps {
	handleNavigateForward: () => void;
	handleNavigateBackward: () => void;
	handleNavigateToday: () => void;
	activeDate: dayjs.Dayjs;
	dateTimePicker?: boolean;
}

export default function MonthViewNavigation({
	handleNavigateForward,
	handleNavigateBackward,
	handleNavigateToday,
	activeDate,
	dateTimePicker,
}: IProps) {
	return (
		<div className="w-full justify-center">
			<div className="flex flex-row justify-between w-full items-center my-4">
				{/* TODAY BUTTON */}
				{!dateTimePicker && (
					<button onClick={handleNavigateToday}>
						<TodayIcon />
					</button>
				)}

				{/* CALENDAR MONTH AND YEAR */}
				<p
					className={
						dateTimePicker
							? 'text-left text-lg'
							: 'text-center text-xs font-medium flex-grow'
					}>
					{activeDate.format('MMMM YYYY')}
				</p>

				{/* NAVIGATE TO PREVIOUS OR NEXT MONTH */}
				<div className="justify-end">
					<div className="w-12 flex place-content-between">
						<NavigationArrow
							onClick={handleNavigateBackward}
							arrowDirection="back"
						/>
						<NavigationArrow
							onClick={handleNavigateForward}
							arrowDirection="forward"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
