import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useLocation, useNavigate } from 'react-router-dom';

import animationData from 'components/lotties/colorful-confetti.json';
import axiosClient from 'utils/axiosClient';
import useCurrentUser from 'stores/currentUser';
import { removeLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import PageTitle from 'components/Reusable/PageTitle';
import { Button } from '@mui/material';
import { ReactComponent as Confetti } from 'assets/rebrand/confetti.svg';

interface IPropTypes {
	isSignIn: boolean;
}

export default function OnboardingComplete({ isSignIn }: IPropTypes) {
	const location = useLocation();
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	const navigate = useNavigate();
	const signUserOut = useCurrentUser((store) => store.signUserOut);

	const goToLoginPage = async () => {
		await signUserOut();
		navigate('/login');
	};

	//Automatically navigates user to companion app "/login" after 15 seconds
	useEffect(() => {
		setTimeout(() => {
			goToLoginPage();
		}, 15000);
	}, []);

	useEffect(() => {
		removeLastOnboardingURLCookie();

		async function inner() {
			await axiosClient.post('/onboarding/complete', {});
		}

		inner();
	}, []);

	const subtitle = location.pathname.startsWith('/onboard')
		? 'Yay! Your Hearth is now ready to use. You’ll be redirected to the Companion App in just a moment.'
		: 'Yay! You’ve now been added to your family’s Hearth. You’ll be redirected to the Companion App in just a moment.';

	return (
		<div className="h-screen flex justify-center">
			<div className="absolute h-screen top-0 left-0 right-0 z-0">
				<Lottie
					options={defaultOptions}
					height={'100%'}
					width={'100%'}
					isClickToPauseDisabled={true}
				/>
			</div>
			<div className="flex flex-col w-full max-w-md mx-6 h-screen items-center justify-center">
				<Confetti className="mb-8 w-24" />
				{isSignIn ? (
					<h3 className="mb-8">Signed in!</h3>
				) : (
					<PageTitle
						title="Way to Go!"
						subtitle={subtitle}
						className="items-center text-center mb-8"
					/>
				)}
				<Button variant="contained" onClick={goToLoginPage}>
					Open Companion App
				</Button>
			</div>
		</div>
	);
}
