export function convertKeys(
	obj: any,
	caseConverter: (str: string) => string = toCamelCase,
): any {
	if (Array.isArray(obj)) {
		return obj.map((item) => convertKeys(item, caseConverter));
	} else if (typeof obj === 'object' && obj !== null) {
		return Object.fromEntries(
			Object.entries(obj).map(([key, value]) => [
				caseConverter(key),
				convertKeys(value, caseConverter),
			]),
		);
	} else {
		return obj;
	}
}

export function toCamelCase(str: string): string {
	return str.replace(/_[a-z]/g, (match) => match[1].toUpperCase());
}

export function toSnakeCase(str: string): string {
	return str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}
