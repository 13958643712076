import React from 'react';
import StepwiseLayout from 'components/Layouts/StepwiseLayout';
import { useNavigate } from 'react-router-dom';
import BackButton from 'components/Buttons/BackButton';
import {
	actionTypes,
	routineTypes,
	useRoutinesDispatch,
	useRoutinesState,
} from 'contexts/RoutinesContext';
import MemberSelection from 'components/Reusable/MemberSelection';
import { newMemberSelection } from 'utils/routineHelpers';
import IndependenceTooltip from 'components/Routines/IndependenceLevels/IndependenceTooltip';
import { DropdownSelect } from 'components/Reusable/DropdownSelect';
import { useIndependenceLevels } from 'hooks/useIndependenceLevels';
import { saveUserIndependenceLevel } from 'api/independenceLevels';
import useDefaultIndependenceLevel from 'hooks/useDefaultIndependenceLevel';

export default function RoutineUsers() {
	const navigate = useNavigate();
	const state = useRoutinesState();
	const dispatch = useRoutinesDispatch();
	const { routineUsers, memberRoutines } = state;
	const { levelOptions } = useIndependenceLevels();
	const defaultUser = routineUsers[0];
	const [selectedLevel, setSelectedLevel] =
		useDefaultIndependenceLevel(defaultUser);

	const navigateToNextStep = () => {
		if (state.routineType === routineTypes.TEMPLATE) {
			navigate('/routines/builder/recommended');
		} else {
			dispatch({
				type: actionTypes.SET_ROUTINE_STEPS,
				payload: [],
			});
			navigate('/routines/builder/create');
		}
	};

	const onCancel = () => {
		dispatch({ type: actionTypes.RESET_ROUTINE_CONTEXT });
		navigate('/routines');
	};

	const saveIndependenceLevel = () => {
		saveUserIndependenceLevel(defaultUser.id, selectedLevel).then(() => {
			dispatch({
				type: actionTypes.UPDATE_INDEPENDENCE_LEVEL,
				payload: { userId: defaultUser.id, level: selectedLevel },
			});
			navigateToNextStep();
		});
	};

	return (
		<StepwiseLayout
			progress={40}
			enableFooter={routineUsers.length > 0}
			onFooterClick={saveIndependenceLevel}
			onCancelClick={onCancel}>
			<div className="mx-6">
				<BackButton />
				<h4 className="my-4 font-medium">Who is this routine for?</h4>
				<MemberSelection
					members={memberRoutines}
					selectedMembers={routineUsers}
					onMemberSelectionChange={(member) =>
						newMemberSelection(member, routineUsers, dispatch)
					}
				/>
			</div>
			<hr className="my-6" />
			<div className="mx-6">
				<h6 className="mt-6 font-medium">In general, this person...</h6>
				<div className="mt-3">
					<DropdownSelect
						options={levelOptions}
						selectedValue={selectedLevel}
						onChange={(value: number | string) =>
							setSelectedLevel(Number(value))
						}
					/>
				</div>
				<IndependenceTooltip />
			</div>
		</StepwiseLayout>
	);
}
