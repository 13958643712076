import { Button } from '@mui/material';
import { ReactComponent as CheckCircle } from 'assets/rebrand/check-circle-white.svg';

interface IPropTypes {
	title: string;
	onClick: (title: string) => void;
	selected?: boolean;
}

export default function PersonalizationTags({
	title,
	onClick,
	selected,
}: IPropTypes) {
	function toggleStyling() {
		onClick(title);
	}

	return (
		<div className="relative">
			<Button
				onClick={toggleStyling}
				variant="contained"
				disableElevation
				sx={{
					'&:hover': {
						backgroundColor: '#D9EDEF',
					},
					backgroundColor: selected ? '#D9EDEF' : '#FFF',
					borderRadius: '8px',
					border: '1px solid #008492',
					paddingVertical: '4px',
					paddingHorizontal: '12px',
				}}>
				<p className="text-xxs text-rebrand-teal font-normal">{title}</p>
			</Button>
			{selected && (
				<CheckCircle className="absolute -top-1 -right-1 w-4 h-4 fill-current text-rebrand-teal" />
			)}
		</div>
	);
}
