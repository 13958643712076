import { useState, useContext } from 'react';
import { IAPITask, NotificationType } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import analytics from 'utils/segmentClient';
import useCurrentUser from 'stores/currentUser';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import NewConfirmationLayout from 'components/Modals/Confirmations/NewConfirmationLayout';

interface IPropTypes {
	completedTasks: IAPITask[];
	fetchTasks: () => void;
}
export default function ClearAllButton({
	completedTasks,
	fetchTasks,
}: IPropTypes) {
	const currentUser = useCurrentUser((store) => store.user);
	const updateNotification = useContext(UpdateNotificationContext);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

	async function handleClearAll() {
		setShowConfirmation(false);
		await axiosClient
			.post(`/web/task/delete_completed`, {
				completed_ids: completedTasks.map((task) => task.id),
			})
			.then(() => {
				analytics.track('Cleared all completed to-dos', {
					from: 'web app',
					userId: `user-${currentUser?.id}`,
				});
				fetchTasks();
				updateNotification({
					message: 'Cleared all completed to-dos!',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
			})
			.catch(() => {
				updateNotification({
					message: 'Clear all failed.',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			});
	}

	return (
		<>
			<NewConfirmationLayout
				open={showConfirmation}
				title="Clear All To-dos"
				description="This will not delete future recurring instances of to-dos."
				confirmButtonLabel="Clear All"
				confirmButtonColor="text-hearth-rust"
				handleCancel={() => setShowConfirmation(false)}
				handleConfirm={handleClearAll}
			/>
			{/* CLEAR ALL BUTTON */}
			<button
				className="font-bold text-rebrand-teal text-sm"
				onClick={() => setShowConfirmation(true)}>
				Clear All
			</button>
		</>
	);
}
