import { ONBOARDING_SCREENS } from '../screens/onboarding/onboardingFlow';

export const FamilyPersonalizationTags = [
	'Be more present and focused when together',
	'Track my family’s daily routines consistently',
	'Have our information all in one place',
	'Visualize my family’s schedules and to-dos',
	'Encourage my kids to be independent',
];

export const IndividualPersonalizationTags = [
	'Weekly Planning',
	'Visualizing Schedules',
	'Daily Planning',
	'Caregiving',
	'Habit Tracking',
	'Household Management',
	'Routine Management',
	'Education',
	'Home Schooling',
	'Tracking Kid’s Progress',
	'Tracking extracurricular activities',
	'Schedule Coordination',
	'Menu Planning',
];

export const personalizationOptions: IPersonalizationOptions = {
	family: {
		tags: FamilyPersonalizationTags,
		webRoute: '/onboard/family-personalization',
		nextPage: '/onboard/individual-personalization',
		apiRoute: '/onboarding/family/personalization',
		title: 'What family goals are you trying to achieve when using Hearth?',
		progress: ONBOARDING_SCREENS.familyPersonalization.progress,
	},
	individual: {
		tags: IndividualPersonalizationTags,
		webRoute: '/onboard/individual-personalization',
		nextPage: '/onboard/members',
		apiRoute: '/onboarding/family/personalization',
		title: 'How are you planning to use your Hearth?',
		progress: ONBOARDING_SCREENS.individualPersonalization.progress,
	},
};

export interface IPersonalizationType {
	tags: string[];
	webRoute: string;
	nextPage: string;
	apiRoute: string;
	title: string;
	progress: number;
}

export interface IPersonalizationOptions {
	family: IPersonalizationType;
	individual: IPersonalizationType;
}
