import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
	IAPIRecurrenceDetails,
	IFamilyMember,
	IRecurrenceDetails,
	IRoutine,
	IRoutineStep,
	IRoutineTemplate,
	NotificationType,
} from 'utils/types';
import {
	actionTypes,
	useRoutinesDispatch,
	useRoutinesState,
} from 'contexts/RoutinesContext';
import RoutineStepDrawer, {
	IRoutineStepForm,
} from 'components/Routines/RoutineStepDrawer';
import StepwiseLayout from 'components/Layouts/StepwiseLayout';
import MemberSelection from 'components/Reusable/MemberSelection';
import {
	DEFAULT_RECURRENCE_DETAILS,
	newMemberSelection,
} from 'utils/routineHelpers';
import RoutineSteps from 'components/Routines/RoutineSteps';
import Toggle from 'components/Buttons/Toggle';
import dayjs from 'dayjs';
import FadeWrapper from 'components/Reusable/FadeWrapper';
import { convertKeys, toCamelCase, toSnakeCase } from 'utils/responseMapper';
import RoutineHeader from 'components/Routines/RoutineHeader';
import RepeatsOn from 'components/CompanionApp/Recurrence/RepeatsOn';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { useNavigate } from 'react-router-dom';
import IndependenceDrawer from 'components/Routines/IndependenceLevels/IndependenceDrawer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReusableModal from '../Modals/ReusableModal';
import RecurrenceForm from '../CompanionApp/Recurrence/RecurrenceForm';
import { Button } from '@mui/material';

interface IRoutineCreateProps {
	onSubmit: (apiParams: IRoutineApiParams) => void;
	routine?: IRoutineTemplate | IRoutine | undefined;
	editing?: boolean;
}

export interface IRoutineForm {
	name: string;
	image: string;
	recurrenceDetails?: IRecurrenceDetails;
}

export interface IRoutineApiParams {
	routineFormData: IRoutineForm;
	routineCoverImage?: string;
	routineSteps: IRoutineStep[];
	routineUsers: IFamilyMember[];
	templateName?: string;
}

const setRecurrenceDefaults = (
	routine: IRoutine | IRoutineTemplate | undefined,
): IRecurrenceDetails => {
	if (routine && routine.recurrenceDetails) {
		return routine.recurrenceDetails;
	}

	return DEFAULT_RECURRENCE_DETAILS;
};

function setRoutineDefaults(
	routine: IRoutineTemplate | IRoutine | undefined,
	routineTemplate: IRoutineTemplate | null,
): { name: string; image: string } {
	if (routine) {
		return { name: routine.name, image: routine.image };
	}

	if (routineTemplate) {
		return {
			name:
				routineTemplate.name === 'Custom Routine' ? '' : routineTemplate.name,
			image: routineTemplate.image ?? 'customCover',
		};
	}

	return { name: '', image: 'customCover' };
}

const RoutineForm: React.FC<IRoutineCreateProps> = ({ routine, onSubmit }) => {
	const navigate = useNavigate();
	const state = useRoutinesState();
	const dispatch = useRoutinesDispatch();
	const updateNotification = useContext(UpdateNotificationContext);
	const { routineUsers, memberRoutines, routineSteps, routineTemplate } = state;

	const [editingStepIndex, setEditingStepIndex] = useState<number | null>(null);
	const [stepDrawer, setStepDrawer] = useState(false);
	const toggleStepDrawer = () => setStepDrawer(!stepDrawer);

	// Handle Step creation and reset step form
	const createStep = (recurrence?: IRecurrenceDetails) => {
		reset();
		setValue('recurrenceDetails', recurrence || DEFAULT_RECURRENCE_DETAILS);
		setValue('order', routineSteps.length);
		toggleStepDrawer();
	};

	// Handle Repeat logic
	const repeatAtStart = routine ? Boolean(routine?.recurrenceDetails) : true;
	const [shouldRepeat, setShouldRepeat] = useState(repeatAtStart);
	const toggleShouldRepeat = () => {
		setShouldRepeat(!shouldRepeat);
		setValueRoutine(
			'recurrenceDetails',
			shouldRepeat ? undefined : DEFAULT_RECURRENCE_DETAILS,
		);
	};

	// Handle Recurrence changes
	const [recurrenceModal, setRecurrenceModal] = useState(false);
	const toggleRecurrenceModal = () => {
		setRecurrenceModal(!recurrenceModal);
	};

	// Set default values
	const initialRecurrenceDetails = setRecurrenceDefaults(routine);
	let { name, image } = setRoutineDefaults(routine, routineTemplate);

	// Setup routine form
	const {
		register: registerRoutine,
		getValues: getValuesRoutine,
		setValue: setValueRoutine,
		watch: watchRoutine,
		handleSubmit: handleSubmitRoutine,
		formState: { errors: routineErrors },
	} = useForm<IRoutineForm>({
		defaultValues: { name, image, recurrenceDetails: initialRecurrenceDetails },
	});

	// Setup routine step form
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
	} = useForm<IRoutineStepForm>();

	const setStepRecurrenceDetails = (recurrenceDetails?: IRecurrenceDetails) =>
		recurrenceDetails ??
		watchRoutine('recurrenceDetails') ??
		DEFAULT_RECURRENCE_DETAILS;

	const onStepClick = (stepIndex: number) => {
		if (routineSteps[stepIndex].id) {
			setValue('id', routineSteps[stepIndex].id);
		}

		setValue('order', routineSteps[stepIndex].order);
		setValue('image', routineSteps[stepIndex].image);
		setValue('name', routineSteps[stepIndex].name);
		setValue(
			'recurrenceDetails',
			setStepRecurrenceDetails(routineSteps[stepIndex].recurrenceDetails),
		);

		setEditingStepIndex(stepIndex);
		toggleStepDrawer();
	};

	const addOrEditStep = (data: IRoutineStepForm) => {
		const newStep = {
			id: data.id ? data.id : undefined,
			name: data.name,
			image: data.image,
			order: data.order === undefined ? routineSteps.length : data.order,
			recurrenceDetails: data.recurrenceDetails,
		};

		if (editingStepIndex !== null) {
			// Update the step at the editingStepIndex
			const updatedSteps = [...routineSteps];
			updatedSteps[editingStepIndex] = newStep;

			dispatch({
				type: actionTypes.SET_ROUTINE_STEPS,
				payload: updatedSteps,
			});

			// Reset the editingStepIndex
			setEditingStepIndex(null);
		} else {
			// Add a new step
			dispatch({
				type: actionTypes.SET_ROUTINE_STEPS,
				payload: [...routineSteps, newStep],
			});
		}

		toggleStepDrawer();
	};

	const onSubmitRoutine = async () => {
		const routineFormData = getValuesRoutine();

		// Validate routine form before submission
		if (routineFormData.name === '') {
			updateNotification({
				type: NotificationType.ERROR,
				message: 'Please enter a title.',
				showNotification: true,
			});

			return;
		}

		if (routineSteps.length === 0) {
			updateNotification({
				type: NotificationType.ERROR,
				message: 'Please add at least one step.',
				showNotification: true,
			});

			return;
		}

		onSubmit({
			routineFormData,
			routineSteps: routineSteps,
			routineUsers: routineUsers.map(({ routines, ...rest }) => rest),
			routineCoverImage: state.routine?.image,
			templateName: routineTemplate?.name,
		});
	};

	const onSubmitRecurrenceDetails = (
		recurrenceDetails: IAPIRecurrenceDetails,
	) => {
		// Convert the details to camel case and save them in a variable
		const updatedRecurrenceDetails = convertKeys(
			recurrenceDetails,
			toCamelCase,
		);

		// Fetch current recurrence details of the routine before updating them
		const existingRoutineRecurrenceDetails =
			getValuesRoutine('recurrenceDetails');

		// Set the new recurrence details for the routine
		setValueRoutine('recurrenceDetails', updatedRecurrenceDetails);

		// Update the steps' recurrence details
		const updatedRoutineSteps = routineSteps.map((step) => {
			// Check if the step's recurrence details are the same as the routine's existing recurrence details
			if (step.recurrenceDetails === existingRoutineRecurrenceDetails) {
				// If so, assign the updated recurrence details to the step
				return {
					...step,
					recurrenceDetails: updatedRecurrenceDetails,
				};
			}

			// If not, adjust the byDay of the step's recurrence details
			return {
				...step,
				recurrenceDetails: {
					...DEFAULT_RECURRENCE_DETAILS,
					...step.recurrenceDetails,
					byDay: adjustByDay(
						updatedRecurrenceDetails.byDay,
						step.recurrenceDetails?.byDay,
					),
				},
			};
		});

		// Dispatch the updated steps
		dispatch({
			type: actionTypes.SET_ROUTINE_STEPS,
			payload: updatedRoutineSteps,
		});

		// Close the recurrence modal
		toggleRecurrenceModal();
	};

	const adjustByDay = (
		routineByDay: string,
		stepByDay: string = '',
	): string => {
		// Get the days from the strings
		const routineDays = routineByDay.split(',');
		const stepDays = stepByDay.split(',');

		// Find the intersection of the two arrays, keeping the order of routineDays
		const updatedStepDays = routineDays.filter((day) => stepDays.includes(day));

		if (updatedStepDays.length === 0) {
			// If there are no common days, return the routine's days
			return routineByDay;
		}

		return updatedStepDays.join(',');
	};

	const recurrenceDetails = watchRoutine('recurrenceDetails');

	const onCancel = () => {
		dispatch({ type: actionTypes.RESET_ROUTINE_CONTEXT });
		navigate('/routines');
	};

	const shouldOpenDrawer = routineUsers?.[0]?.independenceLevelId === null;
	return (
		<StepwiseLayout
			progress={80}
			enableFooter={true}
			onFooterClick={handleSubmitRoutine(onSubmitRoutine)}
			onCancelClick={onCancel}
			footerText="Save">
			<RoutineHeader
				image={watchRoutine('image')}
				register={registerRoutine}
				setValue={setValueRoutine}
				errors={routineErrors}
			/>

			<hr className="mt-4" />

			<div className="mx-6">
				<p className="mt-4 text-md font-semibold">Assigned to</p>
				<MemberSelection
					members={memberRoutines}
					selectedMembers={routineUsers}
					onMemberSelectionChange={(member) =>
						newMemberSelection(member, routineUsers, dispatch)
					}
				/>
			</div>

			<hr className="mt-4" />

			<div className="mx-6">
				<DndProvider backend={HTML5Backend}>
					<RoutineSteps
						routineByDay={watchRoutine('recurrenceDetails')?.byDay}
						routineSteps={routineSteps}
						onStepClick={onStepClick}
					/>
				</DndProvider>

				<div className="mt-6">
					<Button
						className="h-8"
						variant="outlined"
						onClick={() => createStep(routine?.recurrenceDetails)}>
						Add Step
					</Button>
				</div>
			</div>

			<hr className="mt-4 mb-4" />

			<div className="mx-6">
				<div className="flex flex-row justify-between items-center">
					<p className="text-md font-semibold">Repeats</p>
					<Toggle
						isToggledOn={shouldRepeat}
						handleToggleSwitch={toggleShouldRepeat}
					/>
				</div>
				<FadeWrapper isVisible={shouldRepeat}>
					<div onClick={toggleRecurrenceModal}>
						<RepeatsOn
							freq={recurrenceDetails?.freq ?? 'DAILY'}
							interval={recurrenceDetails?.interval}
							dtStart={dayjs(recurrenceDetails?.dtStart)}
							byDay={recurrenceDetails?.byDay}
							byMonthDay={''}
							count={recurrenceDetails?.count}
							until={recurrenceDetails?.until}
							handleClick={() => {}}
							appendTime
						/>
					</div>
				</FadeWrapper>
			</div>

			<hr className="mt-4 mb-5" />

			<RoutineStepDrawer
				open={stepDrawer}
				onClose={toggleStepDrawer}
				register={register}
				errors={errors}
				onSubmit={handleSubmit(addOrEditStep)}
				setValue={setValue}
				watch={watch}
				routineRecurrenceDetails={getValuesRoutine('recurrenceDetails')}
			/>

			<IndependenceDrawer
				routineUsers={routineUsers}
				shouldShowOpenOnMount={shouldOpenDrawer}
			/>

			<ReusableModal
				open={recurrenceModal}
				handleToggleModal={toggleRecurrenceModal}
				title="Repeats">
				<RecurrenceForm
					initialRecurrenceDetails={convertKeys(
						getValuesRoutine('recurrenceDetails'),
						toSnakeCase,
					)}
					handleSave={onSubmitRecurrenceDetails}
					onClose={toggleRecurrenceModal}
				/>
			</ReusableModal>
		</StepwiseLayout>
	);
};

export default RoutineForm;
