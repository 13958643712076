import RadioButton from 'components/CompanionApp/Recurrence/Radio';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import analytics from 'utils/segmentClient';
import { IAPIEvent } from 'utils/types';
import ConfirmationModalLayout from 'components/Modals/ConfirmationModalLayout';

interface IPropTypes {
	open: boolean;
	action: 'Delete' | 'Edit';
	isExcludedEvent: boolean;
	handleSelect: (arg0: boolean) => void;
	handleCancel: () => void;
	handleConfirmEdit?: () => void;
	event?: IAPIEvent;
}

export default function ExceptionConfirmation({
	open,
	action,
	isExcludedEvent,
	handleSelect,
	handleCancel,
	handleConfirmEdit,
	event,
}: IPropTypes) {
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);
	const user = useCurrentUser((store) => store.user);

	// Delete original recurring event and any exceptions
	function handleDeleteAll() {
		axiosClient
			.delete(`/web/event/${event?.recurrence_rule_id}/all`)
			.then((res) => {
				if (res.status === 200) {
					analytics.track('Event deleted', {
						from: 'web app',
						userId: `user-${user?.id}`,
						isRecurring: true,
					});
					navigate('/calendar');
				}
			})
			.catch(() => {
				updateNotification({
					message: 'All events could not be deleted',
					showNotification: true,
				});
			});
	}

	// Delete instance of recurring event by creating an exception
	function handleDeleteInstance() {
		axiosClient
			.post(`/web/event/exception`, {
				start_at: event?.start_at,
				recurrence_rule_id: event?.recurrence_rule_id,
			})
			.then((res) => {
				if (res.status === 201) {
					analytics.track('Exception created', {
						from: 'web app',
						userId: `user-${user?.id}`,
					});
					navigate('/calendar');
				}
			})
			.catch(() => {
				updateNotification({
					message: 'This event could not be deleted',
					showNotification: true,
				});
			});
	}

	// If instance of recurring event is edited, an exception was already created
	// Delete single event and exception of previously edited instance of recurring event
	function handleDeleteException() {
		axiosClient
			.delete(`/web/event/${event?.id}`)
			.then((res) => {
				if (res.status === 200) {
					analytics.track('Exception deleted', {
						from: 'web app',
						userId: `user-${user?.id}`,
					});
					navigate('/calendar');
				}
			})
			.catch(() => {
				updateNotification({
					message: 'This event could not be deleted',
					showNotification: true,
				});
			});
	}

	function handleConfirmDelete() {
		const shouldDeleteAll = !isExcludedEvent;
		const shouldDeleteInstanceOfRecurringEvent =
			isExcludedEvent && !event?.original_start_at;
		const shouldDeleteSingleExceptionEvent =
			isExcludedEvent && !!event?.original_start_at;

		if (shouldDeleteAll) {
			handleDeleteAll();
		} else if (shouldDeleteInstanceOfRecurringEvent) {
			handleDeleteInstance();
		} else if (shouldDeleteSingleExceptionEvent) {
			handleDeleteException();
		}
	}

	return (
		<ConfirmationModalLayout
			title={`${action} Recurring Event`}
			open={open}
			handleCancel={handleCancel}
			handleConfirm={
				handleConfirmEdit ? handleConfirmEdit : handleConfirmDelete
			}>
			<RadioButton
				isSelected={isExcludedEvent}
				handleSelect={() => handleSelect(true)}
				label="This event"
				className="py-2"
			/>
			<RadioButton
				isSelected={!isExcludedEvent}
				handleSelect={() => handleSelect(false)}
				label="All events"
				className="py-2"
			/>
		</ConfirmationModalLayout>
	);
}
