import {
	IAPIFamilyMember,
	IAPIRoutine,
	IFamilyMember,
	IRecurrenceDetails,
	IRoutine,
	IRoutineTemplate,
	IRoutineStep,
} from 'utils/types';
import React from 'react';
import { actionTypes } from 'contexts/RoutinesContext';
import { convertKeys } from 'utils/responseMapper';
import dayjs from 'dayjs';
import { generateFilename } from 'utils/avatars';
import axiosClient from 'utils/axiosClient';

export const SAMPLE_MORNING_ROUTINE: IRoutineStep[] = [
	{
		order: 1,
		name: 'Wake up',
		image: 'sun',
	},
	{
		order: 2,
		name: 'Brush teeth +/ hair',
		image: 'toothbrush',
	},
	{
		order: 3,
		name: 'Get dressed',
		image: 'shirt',
	},
	{
		order: 4,
		name: 'Make bed',
		image: 'bed',
	},
];

export const SAMPLE_AFTERNOON_ROUTINE: IRoutineStep[] = [
	{
		order: 1,
		name: 'Snack',
		image: '🍪',
	},
	{
		order: 2,
		name: 'Homework',
		image: '📚',
	},
	{
		order: 3,
		name: 'Sports',
		image: '⚽',
	},
	{
		order: 4,
		name: 'Chore',
		image: '🧹',
	},
];

export const SAMPLE_EVENING_ROUTINE: IRoutineStep[] = [
	{
		order: 1,
		name: 'Bath / Shower',
		image: '🛀',
	},
	{
		order: 2,
		name: 'Brush teeth',
		image: '🪥',
	},
	{
		order: 3,
		name: 'PJs',
		image: '👖',
	},
	{
		order: 4,
		name: 'Read',
		image: '📖',
	},
	{
		order: 5,
		name: 'Lights out',
		image: '🌙',
	},
];

export const SAMPLE_ROUTINE_STEPS: IRoutineStep[] = [
	{
		order: 1,
		name: 'Wake up',
		image: '☀️',
	},
	{
		order: 2,
		name: 'Make bed',
		image: '🛏️',
	},
	{
		order: 3,
		name: 'Brush teeth',
		image: '🪥',
	},
	{
		order: 4,
		name: 'Brush hair',
		image: '💇',
	},
	{
		order: 5,
		name: 'Get dressed',
		image: '👕',
	},
	{
		order: 6,
		name: 'Eat breakfast',
		image: '🍳',
	},
];

export const CUSTOM_ROUTINE_TEMPLATE: IRoutineTemplate = {
	name: 'Custom Routine',
	image: 'customCover',
};

export const ROUTINE_TEMPLATES: IRoutineTemplate[] = [
	{
		name: 'Morning Routine',
		image: 'dayCover',
		steps: SAMPLE_MORNING_ROUTINE,
	},
	{
		name: 'Afternoon Routine',
		image: 'afternoonCover',
		steps: SAMPLE_AFTERNOON_ROUTINE,
	},
	{
		name: 'Evening Routine',
		image: 'nightCover',
		steps: SAMPLE_EVENING_ROUTINE,
	},
	CUSTOM_ROUTINE_TEMPLATE,
];

export const DEFAULT_RECURRENCE_DETAILS: IRecurrenceDetails = {
	freq: 'weekly',
	dtStart: dayjs().format('YYYY-MM-DD') + 'T09:00:00',
	interval: 1,
	byDay: 'SU,MO,TU,WE,TH,FR,SA',
};

export const ROUTINE_ICONS = [
	'plus',
	'afternoon',
	'bed',
	'breakfast',
	'ellipsis',
	'evening',
	'hairbrush',
	'shirt',
	'star',
	'sun',
	'sunrise',
	'toothbrush',
	'customCover',
	'dayCover',
	'afternoonCover',
	'nightCover',
	'magic-moment-stars',
];

export const newMemberSelection = (
	currentMember: IFamilyMember,
	routineUsers: IFamilyMember[],
	dispatch: React.Dispatch<any>,
) => {
	dispatch({
		type: actionTypes.SET_ROUTINE_USERS,
		payload: [currentMember],
	});
};

export const mapApiRoutineToRoutine = (apiRoutine: IAPIRoutine): IRoutine => {
	const camelCasedRoutine = convertKeys(apiRoutine);

	return camelCasedRoutine as IRoutine;
};

export const mapApiFamilyMemberToFamilyMember = (
	apiFamilyMembers: IAPIFamilyMember[],
): IFamilyMember[] => {
	const camelCasedFamilyMembers = convertKeys(apiFamilyMembers);

	return camelCasedFamilyMembers as IFamilyMember[];
};

export async function updateRoutineCoverImage(
	filename: string,
	routineId?: number,
) {
	if (!filename) {
		throw new Error('Error uploading picture, image is undefined');
	}

	const formData = new FormData();
	const response = await fetch(filename);
	const blob = await response.blob();
	const newFilename = filename || generateFilename();
	const file = new File([blob], newFilename, { type: 'image/png' });
	formData.append('cover_image', file);

	const baseUrl = '/web/upload/routine_cover';

	return axiosClient.put(baseUrl, formData, {
		params: routineId ? { routine_id: routineId } : {},
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

export const resetRoutineSteps = async (
	routineId: number,
	routineSteps: IRoutineStep[],
) => {
	return await axiosClient.put(`/web/routines/${routineId}/reset_steps`, {
		ids: routineSteps.map((step) => step.id),
	});
};
