import { useState } from 'react';
import dayjs from 'dayjs';

import removeIcon from 'assets/Icons/Todos/Schedule/removeIcon.svg';
import RecurrencePopUp from 'components/CompanionApp/Todos/Recurrence/RecurrencePopUp';
import TextBox from 'components/Forms/TextBox';
import { IAPIRecurrenceDetails } from 'utils/types';
import { formatRecurrenceDetails } from 'utils/recurrenceHelpers';
import { ReactComponent as RecurringIcon } from 'assets/rebrand/recurring.svg';
import ReusableModal from 'components/Modals/ReusableModal';

interface IPropTypes {
	setRecurrenceDetails: (arg0?: IAPIRecurrenceDetails) => void;
	setDueAt: (arg0?: dayjs.Dayjs) => void;
	recurrenceDetails?: IAPIRecurrenceDetails;
	dueAt?: dayjs.Dayjs;
	disabled?: boolean;
}

export default function SelectRecurrence({
	setRecurrenceDetails,
	setDueAt,
	recurrenceDetails,
	dueAt = dayjs(),
	disabled = false,
}: IPropTypes) {
	const [showRecurrencePopUp, setShowRecurrencePopUp] =
		useState<boolean>(false);
	const displayedText = formatRecurrenceDetails(recurrenceDetails, false, true);
	const showRemoveButton = recurrenceDetails && !disabled; // cannot remove if no date selected or if disabled

	return (
		<>
			<ReusableModal
				open={showRecurrencePopUp}
				handleToggleModal={() => setShowRecurrencePopUp(false)}>
				<RecurrencePopUp
					closeRecurrencePopUp={() => setShowRecurrencePopUp(false)}
					setRecurrenceDetails={setRecurrenceDetails}
					setDueAt={setDueAt}
					recurrenceDetails={recurrenceDetails}
					dueAt={dueAt}
				/>
			</ReusableModal>

			<div className="flex items-center w-full mt-2">
				<RecurringIcon className="mr-2 fill-current" />
				<button onClick={() => setShowRecurrencePopUp(true)}>
					<TextBox text={displayedText} />
				</button>
				{showRemoveButton && (
					<button className="ml-3" onClick={() => setRecurrenceDetails()}>
						<img src={removeIcon} alt={'remove'} />
					</button>
				)}
			</div>
		</>
	);
}
