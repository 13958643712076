import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import ScreenSaverSection from 'screens/CompanionApp/DisplaySettings/ScreenSaverSection';
import PageTitle from 'components/Reusable/PageTitle';
import useFeatures from 'stores/feature';
import { FeatureGateLock } from 'components/CompanionApp/Membership/FeatureGateLock';

export default function DisplaySettings() {
	const isAvailable = useFeatures(
		(store) => store.features.privacyMode.isAvailable,
	);

	return (
		<WithHeaderLayout>
			<div className="mx-6 mt-10">
				<PageTitle
					title="Display Settings"
					subtitle="Privacy Mode"
					isAvailable={isAvailable}
				/>
			</div>
			<div className="flex flex-col items-center w-full">
				<hr className="w-full mt-2" />
				<div className="mt-4 mx-6">
					{isAvailable ? (
						<ScreenSaverSection />
					) : (
						<FeatureGateLock
							body={
								'Upgrade your Family Membership to set a custom wallpaper for your Display.'
							}
						/>
					)}
				</div>
			</div>
		</WithHeaderLayout>
	);
}
