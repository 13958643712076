import React, {
	ChangeEvent,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import shallow from 'zustand/shallow';
import { useLocation, useNavigate } from 'react-router-dom';

import Input from 'components/Forms/Input';
import axiosClient from 'utils/axiosClient';
import useCurrentUser from 'stores/currentUser';
import { nameInputError } from 'utils/errorCheck';
import { AvatarSrc, IProfilePicture } from 'utils/types';
import { getRandomAvatar } from 'utils/avatars';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import { ONBOARDING_SCREENS } from './onboardingFlow';
import PageTitle from 'components/Reusable/PageTitle';
import { Button, TextField } from '@mui/material';

interface IFormState {
	firstName: string;
	lastName: string;
	profilePicture: IProfilePicture;
}

export default function ProfileInfo() {
	const navigate = useNavigate();
	const location = useLocation();
	const updateNotification = useContext(UpdateNotificationContext);

	const { user, isCurrentUserFetched } = useCurrentUser(
		(store) => ({
			user: store.user,
			isCurrentUserFetched: store.isCurrentUserFetched,
		}),
		shallow,
	);

	const [formState, setFormState] = useState<IFormState>({
		firstName: '',
		lastName: '',
		profilePicture: {},
	});
	const [shouldShowError, setShouldShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isCurrentUserFetched) {
			setFormState({
				firstName: !!user?.firstName ? user.firstName : '',
				lastName: !!user?.lastName ? user.lastName : '',
				profilePicture: {
					filename: user?.filename ?? getRandomAvatar(),
					avatarSrc: AvatarSrc.REMOTE,
				},
			});
		}
	}, [isCurrentUserFetched]);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState((state) => ({
			...state,
			[event.target.name]: event.target.value,
		}));
	}

	async function handleSubmit() {
		console.log('submitting');
		if (
			nameInputError(formState.firstName) ||
			nameInputError(
				formState.lastName,
				location.pathname.startsWith('/join-family'),
			)
		) {
			setShouldShowError(true);
		} else {
			let payloadData = {
				id: user?.id,
				first_name: formState.firstName,
				last_name: formState.lastName,
			};

			// Add avatar icon selection to form state
			if (
				formState.profilePicture.avatarSrc === AvatarSrc.LOCAL &&
				formState.profilePicture.filename
			) {
				payloadData = {
					...payloadData,
					...{ avatar_file_name: formState.profilePicture.filename },
				};
			}

			await axiosClient
				.put('/onboarding/user/my', payloadData)
				.then(() => {
					console.log('should redirect');
					if (location.pathname.startsWith('/onboard')) {
						navigate('/onboard/create-family');
					} else {
						navigate('/join-family/phone-number');
					}
				})
				.catch(() => {
					updateNotification({
						message: 'Error adding profile',
						showNotification: true,
					});
				});
		}
	}

	return (
		<OnboardingStepsLayout progress={ONBOARDING_SCREENS.addProfile.progress}>
			<PageTitle title="Let's start with your profile" />
			<form onSubmit={handleSubmit} className="mt-6 space-y-6">
				<div className="w-full flex justify-center">
					<MemberAvatar
						avatarFilename={formState.profilePicture.filename}
						className="h-128 w-128"
					/>
				</div>
				<TextField
					name="firstName"
					label="Name"
					placeholder="Required"
					value={formState.firstName}
					onChange={handleChange}
					fullWidth
					error={!!nameInputError(formState.firstName) && shouldShowError}
					helperText={
						shouldShowError ? nameInputError(formState.firstName) : undefined
					}
					variant="outlined"
				/>
				<TextField
					name="lastName"
					label="Last Name"
					placeholder={
						location.pathname.startsWith('/join-family')
							? 'Optional'
							: 'Required'
					}
					value={formState.lastName}
					onChange={handleChange}
					fullWidth
					error={
						!!nameInputError(
							formState.lastName,
							location.pathname.startsWith('/join-family'),
						) && shouldShowError
					}
					helperText={
						shouldShowError
							? nameInputError(
									formState.lastName,
									location.pathname.startsWith('/join-family'),
							  )
							: undefined
					}
					variant="outlined"
				/>
				<Button onClick={handleSubmit} variant="contained" fullWidth>
					Continue
				</Button>
			</form>
		</OnboardingStepsLayout>
	);
}
