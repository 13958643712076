import { useNavigate } from 'react-router-dom';

export default function NewEvent() {
	const navigate = useNavigate();

	return (
		<button
			onClick={() => navigate('/new-event')}
			className="flex justify-center w-full mb-3 mt-1">
			<p className="font-bold text-rebrand-teal">+ New Event</p>
		</button>
	);
}
