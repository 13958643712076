import { useState } from 'react';
import filterIcon from 'assets/Icons/Todos/filterIcon.svg';
import { IAPIFamilyMember } from 'utils/types';
import { SelectAvatarLayout } from 'components/CompanionApp/Avatars/SelectAvatarLayout';
import AnyoneAvatar from 'components/CompanionApp/Todos/Assignees/AnyoneAvatar';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import Checkbox from 'components/CheckBoxes/CheckBox';
import { FILTER_OPTIONS, getProfileType } from 'utils/todoHelpers';
import analytics from 'utils/segmentClient';
import useCurrentUser from 'stores/currentUser';
import {
	useFetchTasks,
	useFilters,
	useSaveFilters,
} from 'contexts/TodosContext';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';

interface IPropTypes {
	members: IAPIFamilyMember[];
}

export default function FilterTodos({ members }: IPropTypes) {
	const currentUser = useCurrentUser((store) => store.user);
	const fetchTasks = useFetchTasks();
	const savedFilters = useFilters();
	const setSavedFilters = useSaveFilters();
	const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
	const [showOptions, setShowOptions] = useState<boolean>(false);

	function removeFilter(option: string) {
		setSelectedFilters(
			selectedFilters.filter((selectedFilter) => selectedFilter !== option),
		);
	}

	function addFilter(option: string) {
		setSelectedFilters([...selectedFilters, option]);
	}

	function handleSelectOption(option: string) {
		// selected filter options not saved until save button pressed
		if (selectedFilters.includes(option)) {
			removeFilter(option);
		} else {
			addFilter(option);
		}
	}

	function handleOpen() {
		// initially, only select saved filter options (incase unsaved selected filter options are different)
		setSelectedFilters(savedFilters);
		setShowOptions(true);
	}

	function handleClose() {
		setShowOptions(false);
	}

	function handleReset() {
		setSelectedFilters([]);
	}

	function handleSave() {
		// save all selected filter options
		setSavedFilters(selectedFilters);
		setShowOptions(false);
		fetchTasks();
		analytics.track('todo_filtered', {
			from: 'web app',
			user_id: `user-${currentUser?.id}`,
			profile_type: getProfileType(members, currentUser?.id),
			filter_today: savedFilters.includes('Today'),
			filter_tomorrow: savedFilters.includes('Tomorrow'),
			filter_next_7_days: savedFilters.includes('Next 7 Days'),
			filter_next_30_days: savedFilters.includes('Next 30 Days'),
			filter_no_date: savedFilters.includes('No Date'),
			filter_overdue: savedFilters.includes('Overdue'),
			filter_recurring: savedFilters.includes('Recurring'),
			filter_done: savedFilters.includes('Done'),
		});
	}

	return (
		<>
			{/* FILTER BUTTON - change color if any saved filters */}
			<button
				onClick={handleOpen}
				style={{
					backgroundColor: savedFilters.length > 0 ? '#D1ECEB' : '#EFF5F6',
				}}
				className="mb-1 py-1 px-3 ml-2 rounded-full w-max flex items-center">
				<img src={filterIcon} alt="filter" />
				<p className="text-sm text-rebrand-gray-dark">Filter</p>
			</button>
			{/* FILTER OPTIONS POP UP */}
			<SlideUpDrawer open={showOptions} onClose={handleClose}>
				<div className="p-6">
					<div className="flex items-center justify-between mb-4">
						<h6 className="text-rebrand-teal-text font-semibold">Filters</h6>
						{/* UNSELECT ALL SELECTED FILTERS */}
						<button
							onClick={handleReset}
							className="text-rebrand-teal font-semibold">
							Reset
						</button>
					</div>
					{/* FILTER BY ASSIGNEE - anyone or members */}
					<div className="flex space-x-4 pb-2 mb-3 overflow-x-auto">
						<button onClick={() => handleSelectOption('Anyone')}>
							<SelectAvatarLayout
								firstName="Anyone"
								isSelected={selectedFilters.includes('Anyone')}>
								<AnyoneAvatar />
							</SelectAvatarLayout>
						</button>
						{members.map((member) => (
							<button
								key={`filter-member-${member.id}`}
								onClick={() => handleSelectOption(`${member.id}`)}>
								<SelectAvatarLayout
									firstName={member.first_name}
									isSelected={selectedFilters.includes(`${member.id}`)}>
									<MemberAvatar
										avatarFilename={member.avatar_file_name}
										className="w-12 h-12"
									/>
								</SelectAvatarLayout>
							</button>
						))}
					</div>
					{/* FILTER BY OPTIONS */}
					{FILTER_OPTIONS.map((option) => (
						<div
							key={`filter-option-${option}`}
							className="flex w-full items-center justify-between mb-5">
							<p className="text-sm text-blackGreen">{option}</p>
							<Checkbox
								isChecked={selectedFilters.includes(option)}
								onClick={() => handleSelectOption(option)}
							/>
						</div>
					))}
					{/* SAVE ALL SELECTED FILTERS */}
					<button
						className="text-xs text-white font-bold bg-rebrand-teal w-full rounded-full py-3 mt-1"
						onClick={handleSave}>
						Save
					</button>
				</div>
			</SlideUpDrawer>
		</>
	);
}
