import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import PageTitle from 'components/Reusable/PageTitle';
import { Button } from '@mui/material';
import PasswordInput from 'components/Forms/PasswordInput';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';

export default function CreateNewPassword() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [confirmPasswordError, setConfirmPasswordError] =
		useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [resetToken, setResetToken] = useState<string>();

	useEffect(() => {
		const resetToken = searchParams.get('rt');
		if (resetToken) {
			window.localStorage.setItem('rt', resetToken);
			setResetToken(resetToken);
		}

		return () => {
			window.localStorage.removeItem('rt');
		};
	}, []);

	const validatePassword = () => {
		if (password.length < 8 || password.length > 40) {
			setPasswordError(true);
			return 'Password needs to be between 8-40 characters.';
		}

		if (confirmPassword.length < 8 || confirmPassword.length > 40) {
			setConfirmPasswordError(true);
			return 'Password needs to be between 8-40 characters.';
		}

		if (password !== confirmPassword) {
			setPasswordError(true);
			setConfirmPasswordError(true);
			return 'Passwords do not match.';
		}

		return null;
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const error = validatePassword();
		setError(error);

		if (!error) {
			const response = await axiosClient.post('/user/reset_password', {
				reset_code: resetToken,
				new_password: password,
			});
			if (response.status === 200) {
				navigate('/reset-password/success');
			}
		}
	};

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordError(false);
	};

	const handleConfirmPasswordChange = (
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setConfirmPassword(event.target.value);
		setConfirmPasswordError(false);
	};

	return (
		<WithHeaderLayout menuButtonVisible={false} className="px-6 pb-8">
			<div className="mt-12 w-full mb-6 max-w-sm space-y-6">
				<PageTitle
					title="Create new password"
					subtitle="Enter a new password for your account."
					className=""
				/>

				<form onSubmit={handleSubmit}>
					<div className="space-y-4">
						<PasswordInput
							label="Password"
							value={password}
							onChange={handlePasswordChange}
							error={passwordError}
						/>
						<PasswordInput
							label="Confirm Password"
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
							error={confirmPasswordError || passwordError}
						/>
					</div>
					<p
						className={`mt-1 text-center text-xxs ${
							error ? 'text-rebrand-error' : 'text-rebrand-grey-dark'
						}`}>
						{error}
					</p>
					<div className="mt-7">
						<Button variant="contained" type="submit" fullWidth>
							Update Password
						</Button>
					</div>
				</form>
			</div>
		</WithHeaderLayout>
	);
}
