import React, { useCallback, useState } from 'react';

import IconWrapper from 'components/Reusable/IconWrapper';
import { actionTypes, useRoutinesDispatch } from 'contexts/RoutinesContext';
import { IRoutineStep } from 'utils/types';
import { ReactComponent as TrashIcon } from 'assets/Icons/trash.svg';
import TooltipMenu from 'components/Reusable/TooltipMenu';
import RepeatsOn from 'components/CompanionApp/Recurrence/RepeatsOn';
import dayjs from 'dayjs';
import DragAndDropItem from 'components/DragAndDrop/DragAndDropItem';

interface RoutineStepsProps {
	onStepClick: (stepIndex: number) => void;
	routineSteps: IRoutineStep[];
	routineByDay?: string;
}

const RoutineSteps: React.FC<RoutineStepsProps> = ({
	onStepClick,
	routineSteps,
	routineByDay,
}) => {
	const [removingStepIndex, setRemovingStepIndex] = useState<number | null>(
		null,
	);
	const dispatch = useRoutinesDispatch();

	const moveRoutineStep = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragStep = routineSteps[dragIndex];
			const newSteps = [...routineSteps];
			newSteps.splice(dragIndex, 1);
			newSteps.splice(hoverIndex, 0, dragStep);

			// Update the order property of each step
			newSteps.forEach((step, index) => {
				step.order = index;
			});

			// Dispatch the new order of routineSteps to the context
			dispatch({
				type: actionTypes.SET_ROUTINE_STEPS,
				payload: newSteps,
			});
		},
		[routineSteps, dispatch],
	);

	const deleteStep = (index: number) => {
		setRemovingStepIndex(index);

		setTimeout(() => {
			const newSteps = [...routineSteps];
			newSteps.splice(index, 1);

			newSteps.forEach((step, idx) => {
				step.order = idx;
			});

			dispatch({
				type: actionTypes.SET_ROUTINE_STEPS,
				payload: newSteps,
			});

			setRemovingStepIndex(null);
		}, 300);
	};

	const sortedRoutineSteps = routineSteps.sort((a, b) => a.order - b.order);

	return (
		<>
			{sortedRoutineSteps.map(
				({ name, image, id, recurrenceDetails }, index) => (
					<DragAndDropItem
						key={id}
						index={index}
						handleDragAndDrop={moveRoutineStep}
						isRemoving={index === removingStepIndex}
						style="p-2 my-3">
						<div className="flex-col w-full" onClick={() => onStepClick(index)}>
							<div className="w-full flex justify-between items-center z-10">
								<div className="flex items-center">
									<IconWrapper
										imageValue={image === 'plus' ? 'magic-moment-stars' : image}
									/>
									<p className="ml-3">{name}</p>
								</div>
								<TooltipMenu buttonPadding="py-5 px-2">
									<button
										className="flex px-4 py-2 text-grey-darkGreen items-center rounded-b-xl"
										onClick={(e) => {
											e.stopPropagation();
											deleteStep(index);
										}}>
										<TrashIcon className="fill-current mr-3" />
										Delete
									</button>
								</TooltipMenu>
							</div>
							{recurrenceDetails &&
								recurrenceDetails?.byDay !== routineByDay && (
									<div className="mt-1 w-full items-start">
										<RepeatsOn
											freq="weekly"
											interval={recurrenceDetails.interval}
											dtStart={dayjs(recurrenceDetails.dtStart)}
											byDay={recurrenceDetails.byDay}
											byMonthDay=""
											count={recurrenceDetails.count}
											until={recurrenceDetails.until}
										/>
									</div>
								)}
						</div>
					</DragAndDropItem>
				),
			)}
		</>
	);
};

export default RoutineSteps;
