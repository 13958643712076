import { FC } from 'react';

interface ILineProps {
	className?: string;
}

const Line: FC<ILineProps> = ({ className }) => (
	<hr className={`w-full ${className}`} />
);

export default Line;
