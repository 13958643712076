import React from 'react';

interface IHeaderProgressBarProps {
	width: number; // Progress width as a percentage
}

const HeaderProgressBar: React.FC<IHeaderProgressBarProps> = ({ width }) => {
	return (
		<div className="bg-rebrand-teal-grey h-1 overflow-hidden relative">
			<div
				className="bg-rebrand-teal h-full absolute"
				style={{
					width: `${width}%`,
				}}
			/>
		</div>
	);
};

export default HeaderProgressBar;
