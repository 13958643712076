import { useContext, useState, useEffect } from 'react';
import axiosClient from 'utils/axiosClient';
import { ITimezoneObject } from 'utils/types';
import TimezoneSelect from 'components/Forms/TimezoneSelect';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { Button } from '@mui/material';
import PageTitle from 'components/Reusable/PageTitle';
import SetupContainer from 'components/Layouts/SetupContainer';
import { useSetupContext } from '../SetupLayout';

export default function AddTimeZone() {
	const { nextStep } = useSetupContext();
	const [timezone, setTimezone] = useState<ITimezoneObject>();
	const updateNotification = useContext(UpdateNotificationContext);

	async function handleSubmit() {
		if (!timezone) {
			updateNotification({
				message: 'Please select a timezone',
				showNotification: true,
			});

			return;
		}

		axiosClient
			.put('/onboarding/device/timezone', {
				timezone: timezone.timezone,
			})
			.then((response) => {
				if (response.status === 200) {
					nextStep();
				} else {
					updateNotification({
						message: 'Something went wrong. Please try again.',
						showNotification: true,
					});
				}
			})
			.catch((error) => {
				updateNotification({
					message: 'Something went wrong. Please try again.',
					showNotification: true,
				});
				console.debug(error);
			});
	}

	return (
		<SetupContainer>
			<PageTitle
				title="Confirm your timezone"
				subtitle="Hearth Display will show your calendar and reminders in this timezone."
			/>
			<form className="relative bg-white space-y-7 mt-6">
				<TimezoneSelect
					selectedTimezone={timezone}
					onChange={(timezone: ITimezoneObject) => setTimezone(timezone)}
				/>
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Confirm
				</Button>
			</form>
		</SetupContainer>
	);
}
