import { Checkbox } from '@mui/material';

interface IButton {
	onClick: () => void;
	borderColor?: string;
	isChecked?: boolean;
	size?: number;
}

export default function HearthCheckbox({
	onClick,
	isChecked,
	borderColor = '#003648',
	size = 24,
}: IButton) {
	return (
		<Checkbox
			checked={isChecked}
			onChange={() => onClick()}
			name="custom-checkbox"
			inputProps={{ 'aria-label': 'Checkbox' }}
			sx={{
				margin: 0,
				padding: 0,
				'& .MuiSvgIcon-root': {
					color: borderColor,
				},
				height: size,
				width: size,
			}}
		/>
	);
}
