import Checkbox from 'components/CheckBoxes/CheckBox';
import { getCalendarColor } from 'utils/colorHelpers';
import Toggle from 'components/Buttons/Toggle';

interface IPropTypes {
	onCheckChange: () => void;
	onShareChange: () => void;
	settings: any;
	calendar: any;
	showShareEventDetails?: boolean;
	userId?: number;
	userColor?: string;
}

export default function MemberCalendar({
	calendar,
	onCheckChange,
	onShareChange,
	settings,
	showShareEventDetails = true,
	userId,
	userColor,
}: IPropTypes) {
	const checkedColor = getCalendarColor(calendar.color, userColor, userId);

	return (
		<div className="pt-2 w-full">
			{/* Toggle show or hide events in calendar */}
			<div className="flex items-center space-x-4">
				<Checkbox
					key={calendar.calendar_id}
					borderColor={checkedColor}
					isChecked={settings.checked}
					onClick={onCheckChange}
					size={32}
				/>
				<span>{calendar.name}</span>
			</div>

			{showShareEventDetails && calendar.calendar_id !== null && (
				<div className="flex w-full mt-3 justify-between items-center">
					{/* Toggle show event titles or "Busy" */}
					<p className="text-grey-dark">Share event details</p>
					<Toggle
						isToggledOn={settings.includeEventDetails}
						handleToggleSwitch={onShareChange}
					/>
				</div>
			)}
		</div>
	);
}
