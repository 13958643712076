import { IAPIEvent, IAPIExternalCalendar, IAPIFamilyMember } from 'utils/types';
import Attendees from 'components/CompanionApp/Event/Attendees';
import { buildEventStyles } from 'utils/eventHelper';
import useSelectedDate from 'stores/selectedDate';
import { ReactComponent as RecurringIcon } from 'assets/rebrand/recurring.svg';

interface IProps {
	event: IAPIEvent;
	calendars: IAPIExternalCalendar[];
	members: IAPIFamilyMember[];
	onShowEventDetails: () => void;
}

export default function EventCell({
	event,
	calendars,
	members,
	onShowEventDetails,
}: IProps) {
	const parentContainerStyle = `flex w-full flex-row h-8 mb-1 items-center`;
	const textBoxStyle =
		'h-6 overflow-hidden whitespace-nowrap text-grey-extraDark text-left';

	const selectedDate = useSelectedDate((store) => store.selectedDate);
	const {
		displayTime,
		opacity,
		attendeeResponseStatus,
		backgroundColor,
		textDecoration,
		profileAvatar,
	} = buildEventStyles(calendars, event, members, selectedDate);

	return (
		<button onClick={onShowEventDetails} className={parentContainerStyle}>
			<div
				style={{ opacity: opacity }}
				className="flex flex-row items-center justify-between w-full">
				<div className="flex flex-row justify-start items-center space-x-4">
					{/* Event Color */}
					<div
						style={{
							backgroundColor:
								attendeeResponseStatus !== 'accepted'
									? 'white'
									: backgroundColor,
							borderColor: backgroundColor,
							borderWidth: 2,
						}}
						className="w-4 h-4 rounded-full"
					/>

					{/* Event Time */}
					<p
						style={{
							textDecoration: textDecoration,
							maxWidth: 'calc(100vw / 3.8)',
							minWidth: 100,
						}}
						className={textBoxStyle}>
						{displayTime}
					</p>

					{/* Event Title */}
					<p
						style={{
							fontWeight: attendeeResponseStatus !== 'accepted' ? 400 : 700,
							maxWidth: 'calc(100vw / 2.5)',
						}}
						className={`${textBoxStyle} flex-grow`}>
						{event.subject}
					</p>
				</div>

				{/* Attendees */}
				<div className="flex flex-row justify-center items-center space-x-1">
					{!!event.recurrence_rule_id && (
						<RecurringIcon className="w-6 h-6 fill-current text-rebrand-gray-dark" />
					)}
					{event.attendee_list.length > 0 && (
						<Attendees
							event={event}
							members={members}
							profileAvatar={profileAvatar}
						/>
					)}
				</div>
			</div>
		</button>
	);
}
