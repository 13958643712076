import React from 'react';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';

import { DividingLine } from 'components/Reusable/Separators';

interface IAction {
	icon: React.ReactNode;
	label: string;
	color?: string;
	onClick: () => void;
}

interface IEditDrawerProps {
	open: boolean;
	onClose: () => void;
	actions: IAction[];
}

const EditDrawer: React.FC<IEditDrawerProps> = ({ open, onClose, actions }) => (
	<SlideUpDrawer open={open} onClose={onClose}>
		<div className="my-2">
			{actions.map((action, index) => (
				<div key={`${action}-${index}`} className="flex flex-col">
					<button
						className={`mx-6 flex flex-row items-center py-3 space-x-3 ${
							action.color ?? 'text-grey-darkGreen'
						}`}
						onClick={action.onClick}>
						{action.icon}
						<p>{action.label}</p>
					</button>
					{index < actions.length - 1 && <DividingLine />}
				</div>
			))}
		</div>
	</SlideUpDrawer>
);

export default EditDrawer;
