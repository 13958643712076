import React from 'react';
import axiosClient from 'utils/axiosClient';

export const nameInputError = (name: string, optional?: boolean) => {
	const regex = /[^a-zA-Z\s]/;

	if (name === '' && !optional) return 'Required';

	if (name.length < 2 && name.length > 0) return 'Minimum 2 characters';

	if (name.length >= 50) return 'Maximum 50 characters';

	if (regex.test(name)) return 'No special characters allowed';
};

export const validateEmailString = (email: string | undefined) => {
	if (!email) return false;

	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const verifyUniqueEmail = async (
	email: string,
	type: 'onboarding' | 'web',
) => {
	return await axiosClient
		.post(`/${type}/family/our/invite/validate`, {
			contact_type: 'email',
			value: email,
		})
		.then((response) => {
			return { ok: true, error: null };
		})
		.catch((error) => {
			const errorContent =
				error.response?.status < 500
					? error.response.data.message
					: 'Server error';
			return { ok: false, error: errorContent };
		});
};

export const emailInputError = (
	email: string | undefined,
	isAdult: boolean,
	isError?: boolean,
) => {
	const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
	if (email === '' && isAdult) {
		return 'Required';
	}

	if (email && !regex.test(email)) {
		return 'Invalid email';
	}

	if (isError) {
		return 'Unable to use email';
	}
};

export const formatPhoneNumber = (
	event: React.ChangeEvent<HTMLInputElement>,
) => {
	// removes all non-digit characters
	const digits = event.target.value.replace(/\D/g, '');
	// removes extra 1 incase included from autofill
	const input = digits.slice(digits.startsWith('11') ? 2 : 1);

	// parts of phone number
	const countryCode = '+1 ' + input;
	const areaCode = '(' + input.slice(0, 3) + ')';
	const telephonePrefix = ' ' + input.slice(3, 6);
	const lineNumber = '-' + input.slice(6);

	if (input.length > 6) {
		return (
			countryCode.slice(0, 3) +
			areaCode.slice(0, 9) +
			telephonePrefix +
			lineNumber
		);
	}
	if (input.length > 3) {
		return countryCode.slice(0, 3) + areaCode + telephonePrefix;
	}
	return countryCode;
};
