import React from 'react';

interface ITextBoxProps {
	text: string;
}

const TextBox: React.FC<ITextBoxProps> = ({ text }) => (
	<p className="px-4 py-3 bg-grey-teal rounded-md text-sm text-blackGreen text-left whitespace-nowrap">
		{text}
	</p>
);

export default TextBox;
