import dayjs, { Dayjs } from 'dayjs';
import { ITime, ITimezoneObject, ITimezones } from 'utils/types';
import axiosClient from 'utils/axiosClient';

export function handleAmPmConversion(time: ITime): number {
	if (time.amPm === 'PM' && time.hours != 12) {
		return time.hours + 12;
	}

	if (time.amPm === 'AM' && time.hours === 12) {
		return 0;
	}

	return time.hours;
}

export function calculateEventLength(
	startDateTime: dayjs.Dayjs,
	endDateTime: dayjs.Dayjs,
): number {
	return endDateTime.diff(startDateTime);
}

export function handleTimeError(
	startDateTime: dayjs.Dayjs,
	endDateTime: dayjs.Dayjs,
): boolean {
	return endDateTime.isBefore(startDateTime);
}

export const timezones: ITimezones = {
	'America/New_York': {
		name: 'Eastern Time - New York',
		shortName: 'Eastern Time - New York',
		timezone: 'America/New_York',
	},
	'America/Chicago': {
		name: 'Central Time - Chicago',
		shortName: 'Central Time - Chicago',
		timezone: 'America/Chicago',
	},
	'America/Denver': {
		name: 'Mountain Time - Denver',
		shortName: 'Mountain Time - Denver',
		timezone: 'America/Denver',
	},
	'America/Los_Angeles': {
		name: 'Pacific Time - Los Angeles',
		shortName: 'Pacific Time - Los Angeles',
		timezone: 'America/Los_Angeles',
	},
	'America/Anchorage': {
		name: 'Alaska Time - Anchorage',
		shortName: 'Alaska Time - Anchorage',
		timezone: 'America/Anchorage',
	},
	'Pacific/Honolulu': {
		name: 'Hawaii-Aleutian Standard Time - Honolulu',
		shortName: 'Hawaii-Aleutian Standard Time',
		timezone: 'Pacific/Honolulu',
	},
};

export const combineAvailableTimezones = (
	mobileDeviceTimezone?: ITimezoneObject,
	hearthDeviceTimezone?: ITimezoneObject,
): ITimezoneObject[] => {
	const customTimezones = [mobileDeviceTimezone, hearthDeviceTimezone].filter(
		(timezone) => timezone?.shortName !== undefined,
	) as ITimezoneObject[];

	const defaultTimezones = Object.values(timezones);

	const timezoneMap = new Map();

	for (const timezone of [...customTimezones, ...defaultTimezones]) {
		timezoneMap.set(timezone.shortName, timezone);
	}

	return Array.from(timezoneMap.values());
};

export function formatEventTime(
	dateTime: dayjs.Dayjs,
	isAllDayEvent: boolean = false,
	isEndTime: boolean = false,
): string {
	const dayjsFormat = 'YYYY-MM-DDTHH:mm:ssZ';

	if (isAllDayEvent) {
		return dateTime
			.hour(0)
			.minute(0)
			.second(0)
			.add(isEndTime ? 1 : 0, 'day')
			.format(dayjsFormat);
	}

	return dateTime.second(0).format(dayjsFormat);
}

export const validateSimpleDay = (day: string, month: string): string => {
	const daysInMonth = dayjs(month).daysInMonth();

	if (!/^\d+$/.test(day)) {
		return 'Invalid day';
	}

	if (parseInt(day) > daysInMonth) {
		return 'Invalid day';
	}

	if (day === '') {
		return 'Day is required';
	}

	return '';
};

export const validateSimpleMonth = (month: string): string => {
	if (!/^\d+$/.test(month)) {
		return 'Invalid month';
	}

	if (parseInt(month) > 12) {
		return 'Invalid month';
	}

	if (month === '') {
		return 'Month is required';
	}

	return '';
};

export const validateSimpleYear = (year: string): string => {
	if (!/^\d{4}$/.test(year)) {
		return 'Invalid year';
	}

	if (dayjs(year).isAfter(dayjs())) {
		return 'Must be a past year';
	}

	if (year === '') {
		return 'Year is required';
	}

	return '';
};

export const addLocalTimezone = (date: Dayjs): string => dayjs(date).format();

export function getHearthDeviceTimezone(
	setHearthDeviceTimezone: (arg0: ITimezoneObject) => void,
) {
	axiosClient.get('/web/family/hearth_device_timezone').then((res) => {
		if (!timezones[res.data]) {
			setHearthDeviceTimezone({
				name: getTimezoneName(res.data),
				shortName: getTimezoneShortName(res.data),
				timezone: res.data,
			});
		}
	});
}

export function getMobileDeviceTimezone(
	setMobileDeviceTimezone: (arg0: ITimezoneObject) => void,
) {
	const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	if (currentTimezone && !timezones[currentTimezone]) {
		setMobileDeviceTimezone({
			name: getTimezoneName(currentTimezone),
			shortName: getTimezoneShortName(currentTimezone),
			timezone: currentTimezone,
		});
	} else {
		setMobileDeviceTimezone({
			name: timezones[currentTimezone].name,
			shortName: timezones[currentTimezone].shortName,
			timezone: timezones[currentTimezone].timezone,
		});
	}
}

export const getTimezoneName = (timezone: string): string => {
	const shortName = getTimezoneShortName(timezone);
	const city = extractTimezoneCity(timezone);

	return `${shortName} - ${city}`;
};

export const getTimezoneShortName = (timezone: string): string => {
	const date = new Date();
	const options: any = {
		timeZone: timezone,
		timeZoneName: 'longGeneric', // does not include Daylight or Standard - ex. Eastern Time vs Eastern Standard Time
	};
	const formatter = new Intl.DateTimeFormat('en-US', options);
	const parts = formatter.formatToParts(date);
	const timeZoneName = parts.find((part) => part.type === 'timeZoneName');

	return timeZoneName?.value ?? '';
};

export const extractTimezoneCity = (timezone: string): string => {
	// ex. America/Toronto => Toronto
	const index = timezone.indexOf('/');
	if (index !== -1) {
		return timezone.substring(index + 1);
	}
	return timezone;
};
