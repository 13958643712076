import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TextContainer from 'components/onboarding/TextContainer';
import CodeInput from 'components/Forms/CodeInput';

import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import Link from 'components/Links/Link';
import OnboardingProgressBar from 'components/ProgressBars/OnboardingProgressBar';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import OnboardingStepsLayout from '../../../components/Layouts/OnboardingStepsLayout';
import { ONBOARDING_SCREENS } from '../onboardingFlow';
import PageTitle from '../../../components/Reusable/PageTitle';
import { Button } from '@mui/material';

enum VerifyEmailErrorStates {
	REQUIRED = 'Required',
	INCORRECT_CODE = 'Incorrect code',
}

export default function VerifyEmailAddress() {
	const isAuthenticated = useCurrentUser((store) => store.isAuthenticated);
	const [email, setEmail] = useState<string>();
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [otp, setOtp] = useState(new Array(6).fill(''));
	const [verifyEmailErrorState, setVerifyEmailErrorState] =
		useState<VerifyEmailErrorStates>();

	useEffect(() => {
		async function inner() {
			const response = await axiosClient.get(`/onboarding/user/my/email/${id}`);
			if (response.status === 200) {
				setEmail(response.data.value);
			}
		}

		if (isAuthenticated) {
			inner();
		}
	}, [isAuthenticated, id]);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	async function handleSubmit() {
		if (otp.join('').length < 6) {
			setVerifyEmailErrorState(VerifyEmailErrorStates.REQUIRED);
			return;
		}

		try {
			const response = await axiosClient.post('/onboarding/user/verify_email', {
				email: email,
				code: otp.join(''),
			});
			if (response.status === 200 && response.data.status === 'verified') {
				navigate(
					`${
						location.pathname.startsWith('/onboard')
							? '/onboard/activation'
							: '/join-family/profile'
					}`,
					{ replace: true },
				);
			}
		} catch {
			setVerifyEmailErrorState(VerifyEmailErrorStates.INCORRECT_CODE);
		}
	}

	async function handleResend() {
		const response = await axiosClient.post(
			'/onboarding/user/resend_email_code',
			{
				email: email,
			},
		);
		if (response.status === 200) {
			alert('Code resent');
		}
	}

	return (
		<OnboardingStepsLayout progress={ONBOARDING_SCREENS.verifyEmail.progress}>
			<PageTitle title="Please verify your email" />
			<p className="mt-2">We've sent a verification code to</p>
			<p className="font-semibold">{email}</p>

			<form onSubmit={handleSubmit}>
				<CodeInput
					code={otp}
					setCode={setOtp}
					errorText={verifyEmailErrorState}
				/>
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Continue
				</Button>
			</form>

			<div className="text-xxs text-center mt-4">
				Didn't receive the email?&nbsp;
				<button
					onClick={handleResend}
					className="font-medium text-rebrand-teal underline">
					Resend email
				</button>
			</div>
		</OnboardingStepsLayout>
	);
}
