import DatePickerCell from './DatePickerCell';
import dayjs from 'dayjs';
import { useState } from 'react';

interface IProps {
	pickingStart: boolean;
	pickingEnd: boolean;
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	activeDate: dayjs.Dayjs;
	onSelect: (date: dayjs.Dayjs) => void;
}

export default function DatePickerGrid({
	pickingStart,
	pickingEnd,
	startDate,
	endDate,
	activeDate,
	onSelect,
}: IProps) {
	const [currentSelect, setCurrentSelect] = useState<boolean>(true);
	const [startDateTime, setStartDateTime] = useState<dayjs.Dayjs>(startDate);
	const [endDateTime, setEndDateTime] = useState<dayjs.Dayjs>(endDate);

	const daysInMonth = activeDate.daysInMonth();
	const firstDayOfWeek = activeDate.date(1).day();
	const firstDayOfMonth = activeDate.date(1);
	const daysInWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

	function handleSelect(selectedDate: dayjs.Dayjs) {
		if (pickingStart && !pickingEnd) {
			// Pick start date, set end date to start date incase initial end date is before selected start date
			setStartDateTime(selectedDate);
			setEndDateTime(selectedDate);
			onSelect(selectedDate);
		} else if (pickingEnd && !pickingStart) {
			// Pick end date
			setEndDateTime(selectedDate);
			onSelect(selectedDate);
		} else if (pickingStart && pickingEnd) {
			// Pick start date and end date from same date picker
			setCurrentSelect(!currentSelect);
			if (currentSelect) {
				setStartDateTime(selectedDate);
				setEndDateTime(selectedDate);
				onSelect(selectedDate);
			} else {
				setEndDateTime(selectedDate);
				onSelect(selectedDate);
			}
		}
	}

	let weeks = [];
	let week = [];

	for (
		let gridDay = 0;
		gridDay < Math.ceil((daysInMonth + firstDayOfWeek) / 7) * 7;
		gridDay++
	) {
		if (gridDay % 7 === 0) {
			week = [];
		}
		if (gridDay < firstDayOfWeek) {
			week.push(
				<DatePickerCell
					key={`day-in-prev-month-${gridDay}`}
					thisDate={firstDayOfMonth.subtract(firstDayOfWeek - gridDay, 'day')}
					thisMonth={firstDayOfMonth
						.subtract(firstDayOfWeek - gridDay, 'day')
						.month()}
					activeDate={activeDate}
					startDate={startDateTime}
					endDate={endDateTime}
					onSelect={handleSelect}
				/>,
			);
		} else if (gridDay > daysInMonth) {
			week.push(
				<DatePickerCell
					key={`day-in-month-${gridDay}`}
					thisDate={firstDayOfMonth.add(gridDay - firstDayOfWeek, 'day')}
					thisMonth={firstDayOfMonth
						.add(gridDay - firstDayOfWeek, 'day')
						.month()}
					activeDate={activeDate}
					startDate={startDateTime}
					endDate={endDateTime}
					onSelect={handleSelect}
				/>,
			);
		} else {
			week.push(
				<DatePickerCell
					key={`day-in-next-month-${gridDay}`}
					thisDate={activeDate.date(gridDay - firstDayOfWeek + 1)}
					thisMonth={activeDate.date(gridDay - firstDayOfWeek + 1).month()}
					activeDate={activeDate}
					startDate={startDateTime}
					endDate={endDateTime}
					onSelect={handleSelect}
				/>,
			);
		}

		{
			/* INDIVIDUAL WEEK IN MONTH */
		}
		if (gridDay > 0 && gridDay % 6 === 0) {
			weeks.push(
				<div
					style={{ flex: 1 }}
					className="w-full justify-center flex"
					key={`week-${Math.floor(gridDay / 7)}`}>
					<div className="w-full justify-between flex">{week}</div>
				</div>,
			);
		}
	}

	return (
		<div className="w-full h-full">
			{/* WEEK DAYS (S, M, T, ETC.) */}
			<div className="flex flex-col h-full items-center">
				<div className="flex justify-between w-full">
					{daysInWeek.map((day, i) => (
						<div className="flex justify-center w-6" key={'Day' + i}>
							<p
								style={{ color: '#C0C2C2', fontWeight: 600, fontSize: 14 }}
								key={`days-in-dropdown-${i}`}>
								{day}
							</p>
						</div>
					))}
				</div>
				{/* ALL WEEKS IN MONTH */}
				{weeks}
			</div>
		</div>
	);
}
