import React, { FC } from 'react';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { SVG_AVATARS } from 'utils/avatars';

interface IAvatarDrawerProps {
	showAvatarMenu: boolean;
	toggleAvatarMenu: () => void;
	onAvatarChange: (avatar: string) => void;
}

const AvatarDrawer: FC<IAvatarDrawerProps> = ({
	showAvatarMenu,
	toggleAvatarMenu,
	onAvatarChange,
}) => (
	<SlideUpDrawer open={showAvatarMenu} onClose={toggleAvatarMenu}>
		<div className="m-3 grid grid-cols-6 gap-2 rounded-t-2xl">
			{SVG_AVATARS.map((name, index) => (
				<div
					key={index}
					className="flex items-center justify-center"
					onClick={() => {
						onAvatarChange(name);
						toggleAvatarMenu();
					}}>
					<div className="rounded-full border-4 border-opacity-0 hover:border-opacity-100 border-rebrand-teal-bright transition duration-300">
						<img src={`/assets/avatars/${name}`} alt={`Avatar ${index}`} />
					</div>
				</div>
			))}
		</div>
	</SlideUpDrawer>
);

export default AvatarDrawer;
