interface IPropTypes {
	onClick: () => void;
	label: string;
	isPrimary?: boolean;
}
export default function TextButton({
	onClick,
	label,
	isPrimary = false,
}: IPropTypes) {
	const buttonStyle = isPrimary
		? 'ml-8 text-primary text-sm font-bold'
		: 'text-tertiary text-sm font-bold';
	return (
		<button className={buttonStyle} onClick={onClick}>
			{label}
		</button>
	);
}
