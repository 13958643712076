import axiosClient from 'utils/axiosClient';

interface IPropTypes {
	primaryText: string;
	handleToggleModal: () => void;
	handleDelete: () => void;
	// fetchTasks: () => void;
	// taskId: number;
}

export default function DeleteConfirmation({
	primaryText,
	handleToggleModal,
	handleDelete,
}: // fetchTasks,
// taskId,
IPropTypes) {
	// async function handleDelete() {
	//     await axiosClient.delete(`/web/task/${taskId}`);
	//     fetchTasks();
	// }

	return (
		<div className="flex flex-col items-start w-full h-full px-6 z-30">
			<p className="text-blackGreen text-sm my-2">{primaryText}</p>

			<button className="my-2" onClick={handleDelete}>
				<p className="text-blackGreen text-sm">Delete</p>
			</button>
			<button className="my-2" onClick={handleToggleModal}>
				<p className="text-blackGreen text-sm">Cancel</p>
			</button>
		</div>
	);
}
