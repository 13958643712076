import CalendarDetailsPopupLayout from './CalendarDetailsPopupLayout';
import { getCalendarColor } from 'utils/colorHelpers';
import {
	ICurrentUser,
	ICalendar,
	ICalendarUpdateParams,
	IProfileUser,
	ICalendarUpdateType,
} from 'utils/types';
import google from 'assets/Icons/Calendar/googleLogo.png';

interface IProps {
	handleCloseModal: () => void;
	calendar: ICalendar;
	updateCalendar: (params: ICalendarUpdateParams) => Promise<void>;
	user?: ICurrentUser;
	assignedUser?: IProfileUser;
	profiles: Map<number | null, IProfileUser>;
}

export default function GoogleCalendarDetailsPopup({
	handleCloseModal,
	calendar,
	updateCalendar,
	user,
	assignedUser,
	profiles,
}: IProps) {
	const currentColor = getCalendarColor(
		calendar.color,
		assignedUser?.color,
		user?.id,
	);

	const providerIcon = (
		<img src={google} className="h-3.5 w-3.5 mr-2" alt="google logo" />
	);

	const handleColorChange = (newColor: string) =>
		updateCalendar({
			type: ICalendarUpdateType.color,
			id: calendar.id,
			name: calendar.name,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: calendar.includeEventDetails,
			color: newColor,
			assignedUserId: calendar.assignedUserId,
		});

	const handleToggleSwitch = () =>
		updateCalendar({
			type: ICalendarUpdateType.toggle,
			id: calendar.id,
			name: calendar.name,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: !calendar.includeEventDetails,
			color: calendar.color,
			assignedUserId: calendar.assignedUserId,
		});

	const handleAssignUser = (profile: IProfileUser) => {
		updateCalendar({
			type: ICalendarUpdateType.assign,
			id: calendar.id,
			name: calendar.name,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: calendar.includeEventDetails,
			color: null,
			assignedUserId: profile.user_id,
		}).then(handleCloseModal);
	};

	return (
		<CalendarDetailsPopupLayout
			handleCloseModal={handleCloseModal}
			calendarName={calendar.name}
			currentColor={currentColor}
			assignedUser={assignedUser}
			profiles={profiles}
			handleUserAssign={handleAssignUser}
			handleColorChange={handleColorChange}
			handleToggleSwitch={handleToggleSwitch}
			isToggledOn={calendar.includeEventDetails}
			switchTitle="Share event details"
			providerIcon={providerIcon}>
			<p className="text-xxs text-normal text-rebrand-grey-dark mt-4">
				{user?.id === calendar.userId ? (
					<>To unsync this calendar, resync your Google calendars.</>
				) : (
					<>This calendar can only be removed by the owner.</>
				)}
			</p>
		</CalendarDetailsPopupLayout>
	);
}
