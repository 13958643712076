import { useNavigate } from 'react-router-dom';
import { AvatarSrc, IAPIFamilyMember, IAPIFamilyInvite } from 'utils/types';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import invitePending from 'assets/Images/invite-pending.svg';
import MemberRow from 'components/Reusable/MemberRow';
import ReorderProfiles from 'screens/CompanionApp/ManageFamily/ReorderProfiles';
import {
	useFamilyInviteList,
	useFamilyMemberList,
	useFamilyName,
} from 'contexts/FamilyContext';
import { useState } from 'react';
import { ReactComponent as ReorderSVG } from 'assets/Icons/reorder.svg';
import FamilyName from './FamilyName';

export default function ManageFamily() {
	const navigate = useNavigate();
	const familyName = useFamilyName();
	const familyMembers = useFamilyMemberList();
	const familyInvites = useFamilyInviteList();

	const [showDrawer, setShowDrawer] = useState(false);

	function addFamilyMember() {
		navigate('/add-family-member');
	}

	function toggleEditFamilyMember(member: IAPIFamilyMember) {
		navigate(`/edit-family-member/${member.id}`, { state: member });
	}

	function toggleEditFamilyInvite(invite: IAPIFamilyInvite) {
		navigate(`/edit-invited-member/${invite.id}`, { state: invite });
	}

	return (
		<WithHeaderLayout menuButtonVisible={true} className="p-6">
			<div className="flex justify-between items-start">
				{familyName && <FamilyName />}
				{familyMembers.length > 1 && (
					<button onClick={() => setShowDrawer(true)}>
						<ReorderSVG />
					</button>
				)}
			</div>

			{familyMembers.map((member) => (
				<MemberRow
					key={`family-member-${member.id}`}
					onClick={() => toggleEditFamilyMember(member)}
					firstName={member.first_name}
					avatar={member.avatar_file_name}
				/>
			))}

			{familyInvites.map((invite) => (
				<MemberRow
					key={`family-invite-${invite.id}`}
					onClick={() => toggleEditFamilyInvite(invite)}
					firstName={invite.first_name}
					avatar={invitePending}
					avatarSrc={AvatarSrc.NONE}
				/>
			))}

			<MemberRow
				onClick={addFamilyMember}
				firstName="Add Profile"
				avatar="anyone_128.svg"
			/>

			<ReorderProfiles
				showDrawer={showDrawer}
				closeDrawer={() => setShowDrawer(false)}
			/>
		</WithHeaderLayout>
	);
}
