import { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';

import useCalendarList from '../../../stores/calendarList';
import MemberCalendar from './MemberCalendar';
import axiosClient from 'utils/axiosClient';
import useCurrentUser from '../../../stores/currentUser';
import { IAPIExternalCalendar } from 'utils/types';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import { Button } from '@mui/material';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import PageTitle from 'components/Reusable/PageTitle';

interface ISelectedCalendar {
	calendarId: string;
	includeEventDetails: boolean;
	checked: boolean;
	name: string;
}

interface IPropTypes {
	isOnboarding?: boolean;
}

interface IAPICalendar {
	name: string;
	include_event_details: boolean;
	user_id?: number;
}

export default function SelectCalendars({ isOnboarding = true }: IPropTypes) {
	const navigate = useNavigate();
	const { isAuthenticated, isCurrentUserFetched, user } = useCurrentUser(
		(store) => ({
			isAuthenticated: store.isAuthenticated,
			isCurrentUserFetched: store.isCurrentUserFetched,
			user: store.user,
		}),
	);
	const { googleCalendars, fetchGoogleCalendars } = useCalendarList(
		(store) => ({
			googleCalendars: store.calendars,
			fetchGoogleCalendars: store.fetchCalendars,
		}),
		shallow,
	);
	const [allSettings, setAllSettings] = useState<ISelectedCalendar[]>([]);

	const sortCalendars = (a: IAPIExternalCalendar, b: IAPIExternalCalendar) => {
		if (!a.name.includes('@') && b.name.includes('@')) {
			return 1;
		} else if (a.name.includes('@') && !b.name.includes('@')) {
			return -1;
		} else {
			return 0;
		}
	};

	useEffect(() => {
		if (isCurrentUserFetched && !isAuthenticated) {
			if (isOnboarding) {
				navigate('/error');
			} else {
				navigate('/login');
			}
		}
	}, [isCurrentUserFetched, isAuthenticated]);

	useEffect(() => {
		if (isCurrentUserFetched && isAuthenticated) {
			fetchGoogleCalendars();
		}
	}, [isCurrentUserFetched, isAuthenticated]);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	async function getStoredCalendars() {
		try {
			const response = await axiosClient.get<IAPICalendar[]>(`/web/calendar`, {
				params: {
					for_family: true,
				},
			});
			return response.data;
		} catch {
			return [];
		}
	}

	useEffect(() => {
		async function inner() {
			const storedCalendars: IAPICalendar[] = await getStoredCalendars();
			setAllSettings(
				googleCalendars.sort(sortCalendars).map(({ calendar_id, name }) => {
					const storedCalendar = storedCalendars.find(
						(calendar: IAPICalendar) =>
							calendar.name === name && calendar.user_id === user?.id,
					);
					return {
						checked: !!storedCalendar,
						includeEventDetails: !!storedCalendar?.include_event_details,
						calendarId: calendar_id,
						name: name,
					};
				}),
			);
		}

		if (isAuthenticated) {
			inner();
		}
	}, [googleCalendars]);

	function updateState(
		id: string,
		checked: boolean,
		includeEventDetails: boolean,
	) {
		const newArr: ISelectedCalendar[] = [...allSettings];
		const index = allSettings.findIndex(
			(calendar) => calendar.calendarId === id,
		);
		newArr[index] = { ...newArr[index], checked, includeEventDetails };
		setAllSettings(newArr);
	}

	async function toggleChecked(calendar: ISelectedCalendar) {
		const newValue = !calendar.checked;
		updateState(calendar.calendarId, newValue, newValue);
	}

	async function toggleShowEventDetails(calendar: ISelectedCalendar) {
		if (calendar.checked) {
			const newValue = !calendar.includeEventDetails;
			updateState(calendar.calendarId, calendar.checked, newValue);
		}
	}

	async function handleSubmit() {
		const param = allSettings
			.filter(({ checked }) => checked)
			.map(({ calendarId, includeEventDetails }) => ({
				calendar_id: calendarId,
				include_event_details: includeEventDetails,
			}));

		await axiosClient.post(`/web/external_calendar/google`, param);
		if (isOnboarding) {
			navigate('/onboard/complete');
		} else {
			navigate('/settings/manage-calendars');
		}
	}

	const buttonLabel = isOnboarding ? 'Continue' : 'Done';
	const description =
		'Only selected calendars will be synced. You can update your selection at any time.' +
		(isOnboarding ? ' (You can change these in your settings later.)' : '');

	return (
		<OnboardingStepsLayout progress={100}>
			<PageTitle title="Select calendars to import" subtitle={description} />
			<div className="my-6 space-y-6 max-w-sm">
				{allSettings.map((c, i) => {
					return (
						<div key={c.calendarId}>
							<MemberCalendar
								calendar={c}
								settings={allSettings[i]}
								onCheckChange={() => toggleChecked(c)}
								onShareChange={() => toggleShowEventDetails(c)}
								showShareEventDetails={isOnboarding}
							/>
						</div>
					);
				})}
			</div>

			<Button variant="contained" fullWidth onClick={handleSubmit}>
				{buttonLabel}
			</Button>
		</OnboardingStepsLayout>
	);
}
