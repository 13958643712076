import Todo from 'components/CompanionApp/Todos/Todo';
import dayjs from 'dayjs';
import { IAPIFamilyMember, IAPITask } from 'utils/types';
import ClearAllButton from 'components/CompanionApp/Todos/ClearAllButton';
import FormHeading from 'components/Forms/FormHeading';

interface IPropTypes {
	tasks: IAPITask[];
	members: IAPIFamilyMember[];
	fetchTasks: () => void;
	handleNotification: (arg0: string) => void;
}

export default function CompletedTodos({
	tasks,
	members,
	fetchTasks,
	handleNotification,
}: IPropTypes) {
	// all completed tasks
	const completedTasks = tasks.filter(
		(task) => !!task.completed_at && task.is_filtered,
	);
	const sortCompleted = (a: IAPITask, b: IAPITask) => {
		if (dayjs(a.completed_at).isAfter(b.completed_at)) {
			return -1;
		} else {
			return 1;
		}
	};

	return (
		<div className="items-center flex flex-col w-full">
			<hr className="mb-4 w-full border-t-0.5 border-rebrand-grey-medium" />

			{completedTasks.length > 0 && (
				<FormHeading label={`Done (${completedTasks.length})`}>
					<ClearAllButton
						completedTasks={completedTasks}
						fetchTasks={fetchTasks}
					/>
				</FormHeading>
			)}
			{completedTasks.sort(sortCompleted).map((task) => (
				<Todo
					key={`completed-todo-${task.id}`}
					task={task}
					members={members}
					fetchTasks={fetchTasks}
					handleNotification={handleNotification}
				/>
			))}
		</div>
	);
}
