import { Button } from '@mui/material';
import { ReactNode } from 'react';

interface IPropTypes {
	children?: ReactNode;
	label: string;
	handleClick: any; // @todo: Replace 'any' with a more specific type
	isDisabled?: boolean;
	className?: string;
}

export default function FormLayout({
	children,
	label,
	handleClick,
	isDisabled = false,
	className = '',
}: IPropTypes) {
	return (
		<div className={`h-full w-full flex flex-col justify-between ${className}`}>
			<div className="w-full flex flex-col items-center justify-start pt-10">
				{children}
			</div>
			<div className="flex justify-center items-center mt-4 mx-6 pb-12">
				<Button
					onClick={handleClick}
					disabled={isDisabled}
					variant="contained"
					fullWidth>
					{label}
				</Button>
			</div>
		</div>
	);
}
