import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import { nameInputError } from 'utils/errorCheck';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import { Button, TextField } from '@mui/material';
import PageTitle from 'components/Reusable/PageTitle';
import { ONBOARDING_SCREENS } from './onboardingFlow';

export default function CreateFamily() {
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);

	const [name, setName] = useState<string>('');
	const [shouldShowError, setShouldShowError] = useState<boolean>(false);
	const [isContinueButtonPressed, setIsContinueButtonPressed] =
		useState<boolean>(false);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	async function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setShouldShowError(false);
		setName(event.target.value);
	}

	async function handleSubmit() {
		if (nameInputError(name)) {
			setShouldShowError(true);
			return;
		} else {
			setIsContinueButtonPressed(true);
			await axiosClient
				.post('/onboarding/family', {
					name: name,
				})
				.then((response) => {
					if (response.status === 201) {
						navigate('/onboard/phone-number');
					}
				})
				.catch((e) => {
					if (e.response.status !== 409) {
						setIsContinueButtonPressed(false);
					}
					updateNotification({
						message:
							e.response.status === 409
								? 'Family for user already created'
								: 'Error creating family',
						showNotification: true,
					});
				});
		}
	}

	return (
		<OnboardingStepsLayout progress={ONBOARDING_SCREENS.addFamily.progress}>
			<PageTitle
				title="What's your family's name?"
				subtitle="This is the unique name of your Hearth. "
			/>
			<p>(You can change this later.)</p>

			<form className="mt-6 space-y-6">
				<TextField
					name="name"
					label="Family Name"
					placeholder="Enter family name"
					value={name}
					onChange={handleChange}
					fullWidth
					error={!!nameInputError(name) && shouldShowError}
					helperText={shouldShowError ? nameInputError(name) : undefined}
					variant="outlined"
				/>

				<Button
					disabled={isContinueButtonPressed}
					variant="contained"
					onClick={handleSubmit}
					fullWidth>
					Continue
				</Button>
			</form>
		</OnboardingStepsLayout>
	);
}
