import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';

import useCurrentUser from 'stores/currentUser';
import { IAPIFamilyMember, NotificationType } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import UncompletedTodos from 'components/CompanionApp/Todos/UncompletedTodos';
import CompletedTodos from 'components/CompanionApp/Todos/CompletedTodos';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import PageTitle from 'components/Reusable/PageTitle';
import { EmptyState } from 'components/Reusable/EmptyState';
import { useFetchTasks, useTaskList } from 'contexts/TodosContext';
import { scheduledTodosFeatureFlag } from 'utils/variables';
import SortTodos from 'components/CompanionApp/Todos/SortTodos';
import FilterTodos from 'components/CompanionApp/Todos/FilterTodos';
import AnimatedContainer from 'components/Reusable/AnimateContainer';
import { UpdateNotificationContext } from 'contexts/NotificationContext';

export default function Todos() {
	const navigate = useNavigate();
	const fetchTasks = useFetchTasks();
	const tasks = useTaskList();
	const filteredTasks = tasks.filter((task) => task.is_filtered);
	const [members, setMembers] = useState<IAPIFamilyMember[]>([]);
	const [loading, setLoading] = useState(true);

	const updateNotification = useContext(UpdateNotificationContext);
	const [notificationMessage, setNotificationMessage] = // TODO: Unused, intentioally?
		useState<string>('To-do added!');

	const { isAuthenticated } = useCurrentUser(
		(store) => ({
			isAuthenticated: store.isAuthenticated,
		}),
		shallow,
	);

	function fetchMembers() {
		axiosClient
			.get('/web/family/our/member')
			.then((res) => {
				setMembers(res.data);
				setLoading(false);
			})
			.catch(() => {
				updateNotification({
					message: 'Fetching Assignees, reload to try again',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			});
	}

	function handleNotification(message: string) {
		// TODO: This is not being used update with notification context instead?
		// If we use notification context, we don't need to do this prop drilling,
		// instead use updateNotification directly inside the component sending the notification
		setNotificationMessage(message);
	}

	useEffect(() => {
		if (isAuthenticated) {
			fetchMembers();
			fetchTasks();
		}
	}, [isAuthenticated]);

	return (
		<WithHeaderLayout>
			<AnimatedContainer>
				<div className="w-full px-6 pt-10">
					<PageTitle
						title="To-dos"
						ctaButton={
							<button
								onClick={() => navigate(`/new-todo`)}
								className="text-sm font-bold text-rebrand-teal">
								+ New To-do
							</button>
						}
					/>
				</div>
				{scheduledTodosFeatureFlag && (
					<div className="flex px-6 mt-4 w-full justify-start">
						<SortTodos members={members} />
						<FilterTodos members={members} />
					</div>
				)}
				{!loading && (
					<>
						{tasks.length === 0 ? (
							<EmptyState message="You haven’t added any to-dos yet! Add your first one to start managing your family’s chores on-the-go." />
						) : (
							<>
								{filteredTasks.length === 0 ? (
									<EmptyState message="No to-dos found." />
								) : (
									<div className="w-full">
										<UncompletedTodos
											tasks={tasks}
											members={members}
											fetchTasks={fetchTasks}
											handleNotification={handleNotification}
										/>

										<CompletedTodos
											tasks={tasks}
											members={members}
											fetchTasks={fetchTasks}
											handleNotification={handleNotification}
										/>
									</div>
								)}
							</>
						)}
					</>
				)}
			</AnimatedContainer>
		</WithHeaderLayout>
	);
}
