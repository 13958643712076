import { useEffect, useState } from 'react';
import AgendaView from 'components/CompanionApp/Calendar/AgendaView';
import MonthView from 'components/CompanionApp/Calendar/MonthView';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import useSelectedDate from 'stores/selectedDate';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import { IAPIEvent, IAPIExternalCalendar, IAPIFamilyMember } from 'utils/types';
import dayjs from 'dayjs';

export default function Calendar() {
	const isAuthenticated = useCurrentUser((store) => store.isAuthenticated);
	const selectedDate = useSelectedDate((store) => store.selectedDate);

	const [monthEvents, setMonthEvents] = useState<Record<string, IAPIEvent[]>>();
	const [members, setMembers] = useState<IAPIFamilyMember[]>([]);
	const [calendars, setCalendars] = useState<IAPIExternalCalendar[]>([]);

	function getCalendarViewRange(selectedDate: dayjs.Dayjs) {
		const startOfMonth = selectedDate.startOf('month');
		const endOfMonth = selectedDate.endOf('month');

		// Find the first day of the week (Sunday) before the start of the month
		const startOfCalendarView = startOfMonth.day(0);

		// Find the last day of the week (Saturday) after the end of the month
		const endOfCalendarView = endOfMonth.day(6);

		return {
			start: startOfCalendarView,
			end: endOfCalendarView,
		};
	}

	function refreshEvents(date: dayjs.Dayjs) {
		const { start, end } = getCalendarViewRange(date);

		axiosClient
			.get('/web/event/v0/family/group_by_day', {
				params: {
					tf_start: start.toISOString(),
					tf_end: end.toISOString(),
				},
			})
			.then((response) => {
				setMonthEvents(response.data);
			})
			.catch((error) => {
				console.error('Error fetching events:', error);
			});
	}

	function fetchCalendars() {
		axiosClient
			.get('/web/calendar', {
				params: {
					for_family: true,
				},
			})
			.then((res) => {
				setCalendars(res.data);
			});
	}

	function fetchMembers() {
		axiosClient.get('/web/family/our/member').then((res) => {
			setMembers(res.data);
		});
	}

	useEffect(() => {
		if (isAuthenticated) {
			fetchCalendars();
			fetchMembers();
			refreshEvents(selectedDate);
		}
	}, [isAuthenticated]);

	return (
		<WithHeaderLayout className="">
			<div className="w-full h-full flex flex-col">
				{/* Month Calendar View */}
				<div className="min-h-[280px]">
					<MonthView
						pickingStart={false}
						pickingEnd={false}
						startDate={selectedDate}
						endDate={selectedDate}
						onSelect={() => null}
						events={monthEvents}
						calendars={calendars}
						refreshEvents={refreshEvents}
					/>
				</div>

				{/* Agenda Calendar View */}
				<div className="flex-1 overflow-y-auto">
					<AgendaView
						events={monthEvents}
						calendars={calendars}
						members={members}
					/>
				</div>
			</div>
		</WithHeaderLayout>
	);
}
