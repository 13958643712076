import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import EventCell from '../Calendar/EventCell';
import AllDayEventCell from '../Calendar/AllDayEventCell';
import { IAPIEvent, IAPIExternalCalendar, IAPIFamilyMember } from 'utils/types';
import useSelectedDate from 'stores/selectedDate';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface IPropTypes {
	events?: Record<string, IAPIEvent[]>;
	calendars: IAPIExternalCalendar[];
	members: IAPIFamilyMember[];
}

export default function AgendaView({ events, calendars, members }: IPropTypes) {
	const selectedDate = useSelectedDate((store) => store.selectedDate);
	const navigate = useNavigate();

	function handleShowEventDetails(event: IAPIEvent) {
		navigate(`/event/${event.id}`, { state: event });
	}

	// Format the selected date to match the keys in the events object
	const formattedSelectedDate = selectedDate.format('YYYY-MM-DD');

	// Retrieve events for the selected date
	const dailyEvents = events && events[formattedSelectedDate];

	if (!events || !dailyEvents || dailyEvents.length === 0) {
		return (
			<div className="flex flex-col w-full h-full p-6">
				<h6 className="font-medium">{selectedDate.format('dddd, MMMM D')}</h6>
				<p className="mt-4">No events today!</p>
			</div>
		);
	}

	const sortedEvents = [...dailyEvents].sort((a, b) =>
		a.is_all_day_event && !b.is_all_day_event ? -1 : 1,
	);

	return (
		<div className="w-full flex flex-col px-6 pt-5">
			<h6 className="font-medium mb-4">
				{selectedDate.format('dddd, MMMM D')}
			</h6>
			{sortedEvents.map((event) => {
				if (event.is_all_day_event) {
					return (
						<AllDayEventCell
							key={event.id}
							event={event}
							calendars={calendars}
							members={members}
							onShowEventDetails={() => handleShowEventDetails(event)}
						/>
					);
				} else {
					return (
						<EventCell
							key={event.id}
							event={event}
							calendars={calendars}
							members={members}
							onShowEventDetails={() => handleShowEventDetails(event)}
						/>
					);
				}
			})}
		</div>
	);
}
