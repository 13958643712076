import { ReactComponent as RemoveSvg } from '../../../assets/Icons/remove.svg';

interface IPropTypes {
	index: number;
	handleRemoveMember: (arg0: number) => void;
}

export default function RemoveMember({
	index,
	handleRemoveMember,
}: IPropTypes) {
	return (
		<div className="absolute bottom-0 right-0 flex justify-end w-full">
			<button
				onClick={() => {
					handleRemoveMember(index);
				}}
				className="w-70 flex items-center cursor-pointer text-grey-extraDark">
				<span className="text-xxs">Remove</span>
				<RemoveSvg className="w-5 h-5 ml-1 fill-current" />
			</button>
		</div>
	);
}
