import create from 'zustand';

import { IAPIExternalCalendar } from 'utils/types';
import axiosClient from 'utils/axiosClient';

interface IStore {
	calendars: IAPIExternalCalendar[];
	isFetching: boolean;
	isGoogleAuthenticated: boolean;
	lastFetchedAt?: Date;
	fetchCalendars: () => void;
}

const useCalendarList = create<IStore>((set, get) => ({
	calendars: [],
	isFetching: false,
	isGoogleAuthenticated: false,
	isUsedInHearth: false,
	includeEventDetails: false,

	fetchCalendars: async () => {
		if (!!get().isFetching) {
			return;
		}
		set((state) => ({
			...state,
			isFetching: true,
		}));

		try {
			const response = await axiosClient.get('/web/external_calendar/google');
			if (response.status === 200) {
				set((state) => ({
					...state,
					calendars: response.data.map((x: IAPIExternalCalendar) => ({
						id: x.id,
						calendar_id: x.calendar_id,
						description: x.description,
						name: x.name,
						timezone: x.timezone,
						color: x.color,
					})),
					isUsedInHearth: false,
					includeEventDetails: false,
					isFetching: false,
					lastFetchedAt: new Date(),
					isGoogleAuthenticated: true,
				}));
			}
		} catch (error) {
			// @todo need better error handling here
			console.log('Error fetching calendars', error);
			set((state) => ({
				...state,
				isFetching: false,
			}));
		}
	},
}));

export default useCalendarList;
