import { ReactNode, useContext, useEffect, useState } from 'react';
import ColorPicker from 'components/Modals/ColorPicker';
import ReusableModal from 'components/Modals/ReusableModal';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';
import analytics from 'utils/segmentClient';
import { ReactComponent as StarSVG } from 'assets/Icons/Membership/featureGateStar.svg';
import { UpdateFeatureGateContext } from 'contexts/FeatureGateContext';
import { FeatureGateType } from 'components/CompanionApp/Membership/FeatureGateModal';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';

interface IProps {
	handleColorChange?: (arg0: string) => void;
	color: string;
	name?: string;
	showOutline?: boolean;
	isAvailable?: boolean;
	isNewLayout?: boolean;
}

export default function CalendarNameAndColor({
	handleColorChange,
	color,
	name = 'Hearth Calendar',
	showOutline = true,
	isAvailable = true,
	isNewLayout = false,
}: IProps) {
	const updateFeatureGate = useContext(UpdateFeatureGateContext);

	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
	const [selectedColor, setSelectedColor] = useState<string>(color);

	function handleSave() {
		if (handleColorChange) {
			handleColorChange(selectedColor);
			analytics.track('calendar_color_changed', {
				from_web: true,
			});
		}
		setShowColorPicker(false);
	}

	function handleSelectSave(newSelectColor: string) {
		setSelectedColor(newSelectColor);
		if (handleColorChange) {
			handleColorChange(newSelectColor);
			analytics.track('calendar_color_changed', {
				from_web: true,
			});
		}
		setShowColorPicker(false);
	}

	function colorPickerOnClick() {
		if (handleColorChange && isAvailable) {
			setShowColorPicker(true);
		}
		if (!isAvailable) {
			updateFeatureGate({
				type: FeatureGateType.DEFAULT,
				showFeatureGate: true,
			});
		}
	}

	const layout = !isNewLayout ? (
		<TextField
			value={name}
			onClick={colorPickerOnClick}
			InputProps={{
				readOnly: true,
				startAdornment: (
					<InputAdornment position="start">
						<div
							className="h-6 w-6 rounded-sm"
							style={{ backgroundColor: color }}
						/>
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						{!isAvailable && <StarSVG />}
					</InputAdornment>
				),
				inputProps: {
					style: { cursor: 'pointer' },
				},
			}}
			variant="outlined"
			fullWidth={showOutline}
			sx={{
				'& .MuiInputBase-root': {
					cursor: 'pointer',
					paddingLeft: showOutline ? 2 : 0,
				},
				'& .MuiOutlinedInput-notchedOutline': {
					border: showOutline ? 1 : 'none',
					borderColor: '#A9B3B3',
				},
			}}
		/>
	) : (
		<div
			className="w-full my-1 flex justify-between"
			onClick={colorPickerOnClick}>
			<div>Color</div>
			<div className="h-6 w-6 rounded-sm" style={{ backgroundColor: color }} />
		</div>
	);

	const colorPicker = !isNewLayout ? (
		<ReusableModal
			open={showColorPicker}
			handleToggleModal={() => setShowColorPicker(false)}>
			<ColorPicker
				handleCancel={() => setShowColorPicker(false)}
				handleSave={handleSave}
				handleSelect={(newSelectedColor: string) =>
					setSelectedColor(newSelectedColor)
				}
				selectedColor={selectedColor}
			/>
		</ReusableModal>
	) : (
		<SlideUpDrawer
			open={showColorPicker}
			onClose={() => setShowColorPicker(false)}>
			<ColorPicker
				handleCancel={() => setShowColorPicker(false)}
				handleSave={handleSave}
				handleSelect={(newSelectedColor: string) =>
					handleSelectSave(newSelectedColor)
				}
				selectedColor={selectedColor}
				isNewLayout
			/>
		</SlideUpDrawer>
	);

	return (
		<>
			{layout}
			{colorPicker}
		</>
	);
}
