import React, { ChangeEvent, useContext, useState } from 'react';

import axiosClient from 'utils/axiosClient';
import { nameInputError } from 'utils/errorCheck';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { Button, TextField } from '@mui/material';
import PageTitle from 'components/Reusable/PageTitle';
import SetupContainer from 'components/Layouts/SetupContainer';
import { useSetupContext } from '../SetupLayout';
import { NotificationType } from 'utils/types';

export default function SetupFamily() {
	const { nextStep } = useSetupContext();
	const updateNotification = useContext(UpdateNotificationContext);

	const [name, setName] = useState<string>('');
	const [shouldShowError, setShouldShowError] = useState<boolean>(false);
	const [isContinueButtonPressed, setIsContinueButtonPressed] =
		useState<boolean>(false);

	async function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setShouldShowError(false);
		setName(event.target.value);
	}

	async function handleSubmit() {
		if (nameInputError(name)) {
			setShouldShowError(true);
			return;
		} else {
			setIsContinueButtonPressed(true);
			await axiosClient
				.put('/web/family/name', {
					name: name,
				})
				.then((response) => {
					if (response.status === 200) {
						nextStep();
					}
				})
				.catch(() => {
					updateNotification({
						message: 'Family setup failed.',
						showNotification: true,
						type: NotificationType.ERROR,
					});
				});
		}
	}

	return (
		<SetupContainer>
			<PageTitle
				title="What's your family's name?"
				subtitle="This is the unique name of your Hearth. "
			/>
			<p>(You can change this later.)</p>

			<form className="mt-6 space-y-6">
				<TextField
					name="name"
					label="Family Name"
					placeholder="Enter family name"
					value={name}
					onChange={handleChange}
					fullWidth
					error={!!nameInputError(name) && shouldShowError}
					helperText={shouldShowError ? nameInputError(name) : undefined}
					variant="outlined"
				/>

				<Button
					disabled={isContinueButtonPressed}
					variant="contained"
					onClick={handleSubmit}
					fullWidth>
					Continue
				</Button>
			</form>
		</SetupContainer>
	);
}
