import React, { ReactNode } from 'react';
import CancelHeader from 'components/Headers/CancelHeader';
import FooterButton from 'components/Buttons/FooterButton';
import AnimatedContainer from 'components/Reusable/AnimateContainer';

interface StepwiseLayoutProps {
	children: ReactNode;
	progress: number;
	enableFooter?: boolean;
	onFooterClick?: () => void;
	onCancelClick?: () => void;
	footerText?: string;
}

const StepwiseLayout: React.FC<StepwiseLayoutProps> = ({
	children,
	progress,
	enableFooter = false,
	onFooterClick = () => {},
	onCancelClick = () => {},
	footerText,
}) => (
	<div className="flex flex-col h-full w-full">
		<CancelHeader width={progress} handleClick={onCancelClick} />
		<div className="pt-8 flex-grow">
			<AnimatedContainer>{children}</AnimatedContainer>
		</div>
		<div className="py-12 px-8">
			<FooterButton
				disabled={!enableFooter}
				handleClick={onFooterClick}
				text={footerText}
			/>
		</div>
	</div>
);

export default StepwiseLayout;
