import { ReactNode } from 'react';
import MemberAvatar from '../Todos/Assignees/MemberAvatar';
import { ReactComponent as Ellipsis } from 'assets/Icons/Routines/ellipsis.svg';
import React from 'react';

interface IProps {
	calendarName: string;
	color: string;
	hasEllipsis?: boolean;
	onClick: () => void;
	onEllipsisClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function CalendarHeader({
	children,
	avatarFilename,
}: {
	children: ReactNode;
	avatarFilename?: string;
}) {
	if (!avatarFilename) {
		return (
			<h6 className="mt-8 mb-2 mx-6 leading-5 text-xs font-medium">
				{children}
			</h6>
		);
	} else {
		return (
			<div className="mt-8 mb-2 mx-6 flex items-center">
				<MemberAvatar
					avatarFilename={avatarFilename}
					className="w-8 h-8 border-white border-1 bg-white"
				/>
				<h6 className="ml-2 leading-5 text-xs font-medium">{children}</h6>
			</div>
		);
	}
}

function UnmemoizedCalendarEntry({
	calendarName,
	color,
	onClick,
	onEllipsisClick,
	hasEllipsis = false,
}: IProps) {
	return (
		<div
			className="w-full flex flex-row items-start px-4 py-4 border rounded-lg border-rebrand-grey soft-shadow"
			onClick={onClick}>
			<div
				className="min-w-6 h-6 w-6 rounded-md"
				style={{ backgroundColor: color }}
			/>
			<p className="ml-2 text-ellipsis whitespace-nowrap overflow-hidden">
				{calendarName}
			</p>
			{hasEllipsis && (
				<button
					className="pl-3 py-3 ml-auto"
					onClick={(event) => {
						event.stopPropagation();
						onEllipsisClick(event);
					}}>
					<Ellipsis className="w-4 fill-current text-rebrand-teal-text" />
				</button>
			)}
		</div>
	);
}

const CalendarEntry = React.memo(UnmemoizedCalendarEntry);
export default CalendarEntry;
