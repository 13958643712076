import { useContext, useState } from 'react';
import axiosClient from 'utils/axiosClient';
import { useFamilyName, useFetchFamilyName } from 'contexts/FamilyContext';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { NotificationType } from 'utils/types';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import PageTitle from 'components/Reusable/PageTitle';
import { Button, TextField } from '@mui/material';

export default function FamilyName() {
	const updateNotification = useContext(UpdateNotificationContext);

	const familyName = useFamilyName();
	const fetchFamilyName = useFetchFamilyName();

	const [newFamilyName, setNewFamilyName] = useState<string>(familyName);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	async function editFamilyNameApi() {
		await axiosClient
			.put('/web/family/name', {
				name: newFamilyName,
			})
			.then((response) => {
				if (response.status === 200) {
					setIsEditing(false);
					fetchFamilyName();
					updateNotification({
						message: 'Family name updated!',
						showNotification: true,
						type: NotificationType.SUCCESS,
					});
				}
			})
			.catch(() => {
				updateNotification({
					message: 'Update family name failed.',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			});
	}

	return (
		<>
			<SlideUpDrawer open={isEditing}>
				<div className="flex h-full w-full justify-center items-center fixed top-0 left-0">
					<div className="bg-white px-8 pt-6 rounded-lg max-w-325">
						<TextField
							variant="outlined"
							value={newFamilyName}
							label="Family Name"
							onChange={(e) => setNewFamilyName(e.target.value)}
						/>
						<div className="flex w-full justify-between items-center my-2">
							<Button
								sx={{ paddingLeft: 0, paddingRight: 0 }}
								variant="text"
								onClick={() => setIsEditing(false)}>
								Cancel
							</Button>
							<Button
								variant="outlined"
								onClick={editFamilyNameApi}
								className="h-8">
								Save
							</Button>
						</div>
					</div>
				</div>
			</SlideUpDrawer>

			<button className="text-left" onClick={() => setIsEditing(true)}>
				<PageTitle title={familyName} />
			</button>
		</>
	);
}
