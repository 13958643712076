import React, { ReactNode } from 'react';
import AnimatedContainer from 'components/Reusable/AnimateContainer';
import HeaderProgressBar from 'components/ProgressBars/HeaderProgressBar';
import BackButton from 'components/Buttons/BackButton';
import { useNavigate } from 'react-router-dom';

interface IOnboardingStepsLayoutProps {
	children: ReactNode;
	progress: number;
	skipRoute?: string;
}

const OnboardingStepsLayout: React.FC<IOnboardingStepsLayoutProps> = ({
	children,
	progress,
	skipRoute,
}) => {
	const navigate = useNavigate();

	return (
		<div className="flex flex-col h-full w-full items-center">
			<div className="w-full">
				<div className="mt-12 mb-6 flex flex-row justify-between">
					<BackButton />
					{skipRoute && (
						<button onClick={() => navigate(skipRoute)}>
							<p className="flex text-xs font-semibold mr-6">Skip</p>
						</button>
					)}
				</div>
				<HeaderProgressBar width={progress ?? 0} />
			</div>
			<div className="flex-grow flex justify-center mt-12 mx-6 max-w-md">
				<AnimatedContainer>{children}</AnimatedContainer>
			</div>
		</div>
	);
};
export default OnboardingStepsLayout;
