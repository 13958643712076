import { ReactComponent as BackArrow } from 'assets/rebrand/back-arrow.svg';

interface ISetupBackButtonProps {
	onClick?: () => void;
	className?: string;
}

export default function SetupBackButton({
	className = '',
	onClick = () => {},
}: ISetupBackButtonProps) {
	return (
		<button onClick={onClick} className={`flex items-center ${className}`}>
			<BackArrow className="fill-current h-6 w-6" />
			<span>Back</span>
		</button>
	);
}
