import React, { ChangeEvent, useContext, useState } from 'react';

import axiosClient from 'utils/axiosClient';
import { formatPhoneNumber } from 'utils/errorCheck';
import HearthHelperModal from 'components/Modals/HearthHelperModal';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import PageTitle from 'components/Reusable/PageTitle';
import ReusableModal from 'components/Modals/ReusableModal';
import { Button, TextField } from '@mui/material';
import SetupContainer from 'components/Layouts/SetupContainer';
import { useSetupContext } from '../SetupLayout';

interface IFormState {
	phoneNumber: string;
	rawPhoneNumber: string;
}

export default function AddPhoneNumber() {
	const { nextStep } = useSetupContext();
	const updateNotification = useContext(UpdateNotificationContext);

	const [showHelperModal, setShowHelperModal] = useState(false);
	const [formState, setFormState] = useState<IFormState>({
		phoneNumber: '+1',
		rawPhoneNumber: '1',
	});
	const [errors, setErrors] = useState<string | undefined>(undefined);

	function validatePhoneNumber(phoneNumber: string): boolean {
		if (phoneNumber.length < 3) {
			setErrors('Enter a phone number or skip this step');
			return true;
		}

		if (phoneNumber.length < 17) {
			setErrors('Please enter a valid phone number');
			return true;
		}

		const phoneNumberRegex = /^\+1\(\d{3}\)\d{3}-\d{4}$/;
		if (phoneNumberRegex.test(phoneNumber)) {
			setErrors('Please enter a valid phone number');
			return true;
		}

		return false;
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const formattedPhoneNumber = formatPhoneNumber(event);

		setErrors(undefined);

		setFormState({
			phoneNumber: formattedPhoneNumber,
			rawPhoneNumber: formattedPhoneNumber.replace(/[-() ]/g, ''),
		});
	}

	const toggleHelperModalHandler = () => setShowHelperModal(!showHelperModal);

	async function handleSubmit() {
		if (validatePhoneNumber(formState.phoneNumber)) return;

		try {
			const { status, data } = await axiosClient.post(
				'/onboarding/user/my/phone_number',
				{
					value: formState.rawPhoneNumber,
				},
			);

			if (status !== 201) {
				throw new Error('Problem adding Phone Number');
			}

			nextStep({ contactId: data.id, isVerified: data.is_verified });
		} catch (e) {
			updateNotification({
				message: 'Problem adding Phone Number',
				showNotification: true,
			});
		}
	}

	return (
		<SetupContainer>
			{showHelperModal && (
				<button
					onClick={toggleHelperModalHandler}
					className="w-screen h-screen bg-grey-darkGreen opacity-30 left-0 top-0 absolute z-40"
				/>
			)}
			<PageTitle
				title="Add your phone number to use Hearth Helper"
				subtitle="We’ll text you a one time password to keep your account secure."
			/>
			<form onSubmit={handleSubmit} className="mt-6">
				<TextField
					label="Phone Number"
					type="tel"
					name="phoneNumber"
					value={formState.phoneNumber}
					onChange={handleChange}
					fullWidth
					error={Boolean(errors)}
					helperText={errors}
					inputProps={{ maxLength: 17 }}
					variant="outlined"
				/>
				<p className="text-xxs mt-1 mb-6 ml-4 text-rebrand-grey-dark">
					Standard message and data rates may apply.
				</p>
				<Button onClick={handleSubmit} fullWidth variant="contained">
					Continue
				</Button>
			</form>
			<div className="w-full flex justify-center">
				<button
					className="mt-4 text-rebrand-teal underline text-medium text-xxs text-center"
					onClick={toggleHelperModalHandler}>
					What is Hearth Helper?
				</button>
			</div>
			<ReusableModal
				open={showHelperModal}
				handleToggleModal={toggleHelperModalHandler}>
				<HearthHelperModal handleToggleModal={toggleHelperModalHandler} />
			</ReusableModal>
		</SetupContainer>
	);
}
