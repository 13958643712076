import dayjs from 'dayjs';
import { checkIsPastDue } from 'utils/todoHelpers';
import { ReactComponent as CalendarIcon } from 'assets/rebrand/calendar.svg';

interface IPropTypes {
	dueAt: dayjs.Dayjs;
}

export const DueDateTag = ({ dueAt }: IPropTypes) => {
	const dueDate = dueAt.format('MMM D, YYYY');
	const isPastDue = checkIsPastDue(dueAt);

	return (
		<div className="py-2 items-center flex">
			<CalendarIcon
				className={`fill-current mr-1 ${
					isPastDue ? 'text-rebrand-red' : 'text-rebrand-teal-text'
				}`}
			/>
			<p className={isPastDue ? 'text-rebrand-red' : 'text-rebrand-teal-text'}>
				{dueDate}
			</p>
		</div>
	);
};
