import { useState } from 'react';
import dayjs from 'dayjs';
import NumberInput from 'components/CompanionApp/Recurrence/NumberInput';
import TextBox from 'components/Forms/TextBox';
import RadioButton from './Radio';
import { Button } from '@mui/material';

var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface IPropTypes {
	handleChange: (arg0: string, arg1: string | number | undefined) => void;
	freq: string;
	interval: number;
	byDay: string | undefined;
	byMonthDay: string | undefined;
	start: dayjs.Dayjs;
	showFreqDropdown?: boolean;
}

interface IWeekDayButton {
	handleSelect: (arg0: boolean) => void;
	day: string;
	byDay: string;
}

interface IWeeklyFrequency {
	handleChange: (arg0: string, arg1: string | number | undefined) => void;
	byDay: string;
	classname?: string;
}

interface IMonthlyFrequency {
	handleChange: (arg0: string, arg1: string | number | undefined) => void;
	start: dayjs.Dayjs;
	byDay: string | undefined;
	byMonthDay: string | undefined;
}

interface IFreqDropdown {
	onSelect: (arg0: string) => void;
	freq: string;
	disabled?: boolean;
}

const WeekDayButton = ({ handleSelect, day, byDay }: IWeekDayButton) => (
	<button
		onClick={() => {
			handleSelect(!byDay.includes(day));
		}}
		style={{
			height: 32,
			width: 32,
			borderRadius: 16,
			minWidth: 0,
			padding: 0,
			color: '#003546',
			backgroundColor: byDay.includes(day) ? '#9EEBEA' : '#EFF5F6',
		}}>
		{day[0]}
	</button>
);

export const WeeklyFrequency = ({
	handleChange,
	byDay,
	classname = 'mt-3',
}: IWeeklyFrequency) => {
	const weekDays: string[] = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

	function handleDayChange(day: string) {
		let selectedDayList = byDay.split(',');

		if (selectedDayList.includes(day)) {
			handleChange(
				'by_day',
				selectedDayList.filter((selectedDay) => selectedDay !== day).join(','),
			);
		} else {
			handleChange(
				'by_day',
				[...selectedDayList, day]
					.filter((day) => day !== '')
					.sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b))
					.join(','),
			);
		}
	}

	return (
		<div className={`flex justify-between w-full ${classname}`}>
			{weekDays.map((day) => (
				<WeekDayButton
					key={`repeats-on-${day}`}
					byDay={byDay}
					day={day}
					handleSelect={() => handleDayChange(day)}
				/>
			))}
		</div>
	);
};

const MonthlyFrequency = ({
	handleChange,
	start,
	byDay,
	byMonthDay,
}: IMonthlyFrequency) => {
	let dayOfMonth = start.date();
	let dayOfWeek = start.format('dd').toUpperCase();

	let weekNumberString = '1st';
	let weekNumber = '1';
	if (dayOfMonth / 7 > 1 && dayOfMonth / 7 <= 2) {
		weekNumberString = '2nd';
		weekNumber = '2';
	} else if (dayOfMonth / 7 > 2 && dayOfMonth / 7 <= 3) {
		weekNumberString = '3rd';
		weekNumber = '3';
	} else if (dayOfMonth / 7 > 3 && dayOfMonth / 7 <= 4) {
		weekNumberString = '4th';
		weekNumber = '4';
	} else if (dayOfMonth / 7 > 4) {
		weekNumberString = 'last';
		weekNumber = '-1';
	}

	return (
		<div className="mt-3 flex items-start">
			<p className="mr-2 w-12">on the</p>
			<div className="flex flex-col">
				{/* DATE ONLY (hidden if date is greater than 28 to avoid bugs on inconsistent month days 29, 30, 31) */}
				{dayOfMonth < 29 && (
					<RadioButton
						handleSelect={() => {
							handleChange('by_month_day', `${dayOfMonth}`);
							handleChange('by_day', undefined);
						}}
						isSelected={!!byMonthDay}
						label={`${start.format('Do')}`}
						className="mb-2"
					/>
				)}
				{/* FIRST-FOURTH WEEK (hidden if date is within 5th week otherwise this will be a duplicate of last week) */}
				{dayOfMonth / 7 <= 4 && (
					<RadioButton
						handleSelect={() => {
							handleChange('by_day', `${weekNumber}${dayOfWeek}`);
							handleChange('by_month_day', undefined);
						}}
						isSelected={
							!!byDay && !byDay.includes('-1') && byDay.includes(`${dayOfWeek}`)
						}
						label={`${weekNumberString} ${start.format('dddd')}`}
						className="mb-2"
					/>
				)}
				{/* LAST WEEK - (hidden if date is not within 4th or 5th week, used to avoid bugs for months that do not have a 5th week) */}
				{dayOfMonth / 7 > 3 && (
					<RadioButton
						handleSelect={() => {
							handleChange('by_day', `-1${dayOfWeek}`);
							handleChange('by_month_day', undefined);
						}}
						isSelected={
							!!byDay && byDay.includes('-1') && byDay.includes(`${dayOfWeek}`)
						}
						label={`last ${start.format('dddd')}`}
					/>
				)}
			</div>
		</div>
	);
};

const FreqDropdown = ({ onSelect, freq, disabled = false }: IFreqDropdown) => {
	const [freqDropdown, setFreqDropdown] = useState<boolean>(false);
	const freqOptions = ['day', 'week', 'month', 'year'];
	return (
		<>
			{freqDropdown && (
				<button
					onClick={() => setFreqDropdown(false)}
					className="absolute top-0 left-0 h-full w-full"
				/>
			)}
			<div className="ml-3 relative z-20">
				<button onClick={() => setFreqDropdown(!freqDropdown)}>
					<TextBox
						text={freq
							.replace('daily', 'day')
							.replace('weekly', 'week')
							.replace('monthly', 'month')
							.replace('yearly', 'year')}
					/>
				</button>
				{freqDropdown && !disabled && (
					<div
						style={{
							width: 102,
							backgroundColor: 'white',
							top: 48,
							borderWidth: 2,
							borderColor: '#ECE9E9',
							borderRadius: 4,
						}}
						className="absolute flex flex-col py-2 px-4">
						{freqOptions.map((option) => (
							<button
								onClick={() => {
									onSelect(
										option
											.replace('day', 'daily')
											.replace('week', 'weekly')
											.replace('month', 'monthly')
											.replace('year', 'yearly'),
									);
									setFreqDropdown(false);
								}}
								className="my-2 w-full flex flex-col"
								key={option}>
								<p className="text-sm text-blackGreen text-left">{option}</p>
							</button>
						))}
					</div>
				)}
			</div>
		</>
	);
};

export default function Frequency({
	handleChange,
	freq,
	interval,
	byDay,
	byMonthDay,
	start,
	showFreqDropdown = true,
}: IPropTypes) {
	function handleSelectionChange(newFreq: string) {
		handleChange('freq', newFreq);
		if (newFreq === 'weekly') {
			handleChange('by_day', start.format('dd').toUpperCase());
			handleChange('by_month_day', undefined);
		} else if (newFreq === 'monthly' && start.date() < 29) {
			handleChange('by_day', undefined);
			handleChange('by_month_day', start.format('D'));
		} else if (newFreq === 'monthly' && start.date() >= 29) {
			handleChange('by_day', `-1${start.format('dd').toUpperCase()}`);
			handleChange('by_month_day', undefined);
		} else {
			handleChange('by_day', undefined);
			handleChange('by_month_day', undefined);
		}
	}

	return (
		<>
			<div
				className={`flex items-center mt-4 mb-1 ${
					!showFreqDropdown && 'hidden'
				}`}>
				<p className="mr-3 w-12">every</p>
				<NumberInput
					name="interval"
					value={interval}
					handleInputChange={(input: string) => {
						if (input.length > 0) {
							handleChange('interval', parseInt(input));
						}
					}}
				/>

				<FreqDropdown
					onSelect={(newFreq: string) => handleSelectionChange(newFreq)}
					freq={freq}
				/>
			</div>
			{freq === 'weekly' && (
				<WeeklyFrequency
					handleChange={handleChange}
					byDay={byDay ?? start.format('dd').toUpperCase()}
				/>
			)}
			{freq === 'monthly' && (
				<MonthlyFrequency
					handleChange={handleChange}
					start={start}
					byDay={byDay}
					byMonthDay={byMonthDay}
				/>
			)}
		</>
	);
}
