import { useCallback, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from '@mui/material';

interface IEditorProps {
	image: File | string;
	handleClose: () => void;
	cancelUpload?: () => void;
	setImage?: (file: string) => void;
	editorSettings: EditorSettings;
}

export interface EditorSettings {
	circle?: boolean;
	scaleToCanvas?: boolean;
	aspectRatio?: number;
	maxEditorWidth?: number;
}

const AVATAR_DOWNSCALED_SIZE = 255; // TODO: This should be passed in as a prop, refactor when we allow passing scaledSize for avatar images

const MAXIMUM_EDITOR_WIDTH = 325;
const MAXIMUM_EDITOR_HEIGHT = 500;

const ImageEditor = ({
	image,
	handleClose,
	setImage,
	cancelUpload,
	editorSettings = {},
}: IEditorProps) => {
	const {
		circle = true,
		scaleToCanvas = true,
		aspectRatio = 1,
	} = editorSettings;

	const editor = useRef<AvatarEditor>(null);

	const [zoom, setZoom] = useState<number>(1);

	const getOptimalDimensions = useCallback(() => {
		// Checking if it's using the default props which means it's a profile pic upload
		if (scaleToCanvas && aspectRatio === 1) {
			// TODO: refactor to pass scaledSize for avatar images instead of keeping logic here
			// this is just a temporary fix to maintain current functionality
			return {
				width: AVATAR_DOWNSCALED_SIZE,
				height: AVATAR_DOWNSCALED_SIZE,
			};
		}

		const widthPadding = 50;
		const heightPadding = 300;

		const editorWidth = Math.min(
			MAXIMUM_EDITOR_WIDTH,
			window.innerWidth - widthPadding,
		);
		const editorHeight = Math.min(
			MAXIMUM_EDITOR_HEIGHT,
			window.innerHeight - heightPadding,
		);

		let isLandscape = aspectRatio > 1;

		if (isLandscape) {
			return {
				width: editorWidth,
				height: editorWidth / aspectRatio,
			};
		} else {
			return {
				width: editorHeight * aspectRatio,
				height: editorHeight,
			};
		}
	}, [aspectRatio, scaleToCanvas]);

	const [editorSize, setEditorSize] = useState(getOptimalDimensions());

	useEffect(() => {
		const handleResize = () => {
			setEditorSize(getOptimalDimensions());
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [getOptimalDimensions]);

	const handleZoom = (_: any, value: any) => {
		setZoom(value);
	};

	const handleCancel = () => {
		handleClose();
		cancelUpload && cancelUpload();
	};

	const handleCircularCrop = (
		canvasScaled: HTMLCanvasElement,
	): HTMLCanvasElement => {
		// Create a new canvas with rounded corners
		const roundedCanvas: HTMLCanvasElement = document.createElement('canvas');
		const context: CanvasRenderingContext2D | null =
			roundedCanvas.getContext('2d');

		if (context) {
			roundedCanvas.width = canvasScaled.width;
			roundedCanvas.height = canvasScaled.height;

			const radiusInPixels: number =
				((editorSize.width / 2) * canvasScaled.width) / editorSize.width; // convert the radius percentage to pixels

			context.beginPath();
			context.moveTo(radiusInPixels, 0);
			context.lineTo(canvasScaled.width - radiusInPixels, 0);
			context.arc(
				canvasScaled.width - radiusInPixels,
				radiusInPixels,
				radiusInPixels,
				(3 * Math.PI) / 2,
				0,
			);
			context.lineTo(canvasScaled.width, canvasScaled.height - radiusInPixels);
			context.arc(
				canvasScaled.width - radiusInPixels,
				canvasScaled.height - radiusInPixels,
				radiusInPixels,
				0,
				Math.PI / 2,
			);
			context.lineTo(radiusInPixels, canvasScaled.height);
			context.arc(
				radiusInPixels,
				canvasScaled.height - radiusInPixels,
				radiusInPixels,
				Math.PI / 2,
				Math.PI,
			);
			context.lineTo(0, radiusInPixels);
			context.arc(
				radiusInPixels,
				radiusInPixels,
				radiusInPixels,
				Math.PI,
				(3 * Math.PI) / 2,
			);
			context.closePath();

			context.clip();
			context.drawImage(canvasScaled, 0, 0);

			// Get the data URL from the new canvas
			return roundedCanvas;
		}
		// Fallback to the original canvas if something went wrong
		return canvasScaled;
	};

	const handleSave = (): void => {
		if (editor.current) {
			let canvas: HTMLCanvasElement = scaleToCanvas
				? editor.current.getImageScaledToCanvas()
				: editor.current.getImage();

			if (circle) canvas = handleCircularCrop(canvas);
			const dataUrl = canvas.toDataURL();

			setImage && setImage(dataUrl ?? canvas.toDataURL());
			handleClose();
		}
	};

	return (
		<div className="flex flex-col w-full justify-center items-center">
			<AvatarEditor
				ref={editor}
				image={image}
				scale={zoom}
				borderRadius={circle ? editorSize.height / 2 : 0}
				width={editorSize.width}
				height={editorSize.height}
				rotate={0}
				color={[246, 249, 249, 1]}
			/>
			<div className="w-full px-1 mt-5 mb-2">
				<Slider
					aria-label="raceSlider"
					value={zoom}
					min={1}
					max={10}
					step={0.1}
					onChange={handleZoom}
					sx={{ color: '#008492', height: 8 }}
				/>
			</div>
			<div className="mb-3 w-full flex justify-between">
				<button
					className="text-xs font-semibold text-rebrand-teal"
					onClick={handleCancel}>
					Cancel
				</button>
				<button
					className="text-xs font-semibold text-rebrand-teal border border-rebrand-teal rounded-full py-2 px-5"
					onClick={handleSave}>
					Save
				</button>
			</div>
		</div>
	);
};

export default ImageEditor;
