import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import React, { useContext, useEffect, useState } from 'react';
import { IFamilyMember, IRoutine, NotificationType } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/Reusable/PageTitle';
import MemberRow from 'components/Reusable/MemberRow';
import {
	actionTypes,
	useRoutinesDispatch,
	useRoutinesState,
} from 'contexts/RoutinesContext';
import {
	useDeleteRoutineApi,
	useFetchMembersWithRoutines,
} from 'hooks/useFetchMembersWithRoutines';
import ReusableModal from 'components/Modals/ReusableModal';
import EditDrawer from 'components/Reusable/EditDrawer';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { EventName, trackEvent } from 'utils/segmentClient';
import { ReactComponent as EditIcon } from 'assets/Icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/Icons/trash.svg';
import { ReactComponent as CopyIcon } from 'assets/Icons/copy.svg';
import { ReactComponent as ResetIcon } from 'assets/Icons/reset.svg';
import { resetRoutineSteps } from 'utils/routineHelpers';
import useCurrentUser from 'stores/currentUser';
import RoutineDetailDrawer from 'components/Routines/RoutineDetailDrawer';
import RoutineDetailCard from 'components/Routines/RoutineDetailCard';
import AnimatedContainer from 'components/Reusable/AnimateContainer';

export default function ListRoutines() {
	const navigate = useNavigate();
	const currentUser = useCurrentUser((store) => store.user);
	const state = useRoutinesState();
	const dispatch = useRoutinesDispatch();
	const updateNotification = useContext(UpdateNotificationContext);

	const { memberRoutines } = state;

	const [detailDrawer, setDetailDrawer] = useState(false);
	const toggleDetailDrawer = () => setDetailDrawer(!detailDrawer);

	const [editDrawer, setEditDrawer] = useState(false);
	const toggleEditDrawer = () => setEditDrawer(!editDrawer);

	const [selectedRoutine, setSelectedRoutine] = useState<IRoutine>();
	const [selectedMember, setSelectedMember] = useState<IFamilyMember>();

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

	const [showResetModal, setShowResetModal] = useState(false);
	const toggleResetModal = () => setShowResetModal(!showResetModal);

	const [loading, setLoading] = useState(true);

	const fetchMemberRoutines = useFetchMembersWithRoutines();

	useEffect(() => {
		if (loading) {
			fetchMemberRoutines().catch(() => {
				updateNotification({
					message: 'Oops! Something went wrong. Please try again.',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			});
		}
	}, [loading]);

	useEffect(() => {
		if (memberRoutines?.length > 0) {
			setLoading(false);
		}
	}, [memberRoutines]);

	const initialRoutineClick = (member: IFamilyMember) => {
		trackEvent(EventName.FirstRoutineCreated, {
			routine_users: member,
		});

		dispatch({
			type: actionTypes.SET_ROUTINE_USERS,
			payload: [member],
		});

		navigate('builder/template');
	};

	const actionClick = (
		routine: IRoutine,
		member: IFamilyMember,
		action: () => void,
	) => {
		setSelectedRoutine(routine);
		setSelectedMember(member);
		action();
	};

	const resetRoutineClick = () => {
		if (selectedRoutine?.id) {
			resetRoutineSteps(selectedRoutine.id, selectedRoutine.steps)
				.then(() => {
					trackEvent(EventName.RoutineReset, {
						routineId: selectedRoutine.id,
						currentUser: currentUser,
					});
					setLoading(true);
					setEditDrawer(false);
					setShowResetModal(false);
				})
				.catch(() => {
					toggleResetModal();
					updateNotification({
						message:
							'Oops! Something went wrong resetting the routine. Please try again.',
						showNotification: true,
						type: NotificationType.ERROR,
					});
				});
		}
	};

	const deleteRoutine = useDeleteRoutineApi();

	const deleteRoutineClick = async () => {
		if (selectedRoutine?.id) {
			await deleteRoutine(selectedRoutine.id);
		}

		await fetchMemberRoutines();
		toggleDeleteModal();

		if (detailDrawer) toggleDetailDrawer();
		if (editDrawer) toggleEditDrawer();
	};

	const navigateToEditRoutine = () => {
		if (!selectedRoutine) return;

		navigate('edit', {
			state: {
				routine: selectedRoutine,
			},
		});
	};

	const copyRoutine = () => {
		if (!selectedRoutine) return;

		dispatch({
			type: actionTypes.SET_ROUTINE_TEMPLATE,
			payload: {
				...selectedRoutine,
				name: `${selectedRoutine.name} (Copy)`,
			},
		});

		if (selectedRoutine?.users) {
			dispatch({
				type: actionTypes.SET_ROUTINE_USERS,
				payload: selectedRoutine?.users,
			});
		}

		if (selectedRoutine?.steps) {
			dispatch({
				type: actionTypes.SET_ROUTINE_STEPS,
				payload: selectedRoutine.steps,
			});
		}

		trackEvent(EventName.RoutineCopied, {
			source_routine: selectedRoutine.id,
		});

		navigate('builder/create');
	};

	const routineDrawerActions = [
		{
			icon: <ResetIcon className="fill-current" />,
			label: 'Reset Today',
			onClick: toggleResetModal,
		},
		{
			icon: <EditIcon />,
			label: 'Edit',
			onClick: navigateToEditRoutine,
		},
		{
			icon: <CopyIcon className="fill-current" />,
			label: 'Copy',
			onClick: copyRoutine,
		},
		{
			icon: <TrashIcon className="fill-current" />,
			label: 'Delete',
			onClick: toggleDeleteModal,
		},
	];

	return (
		<WithHeaderLayout>
			<AnimatedContainer>
				<div className="mt-10 mx-6">
					<PageTitle
						title="Routines"
						ctaButton={
							<button
								onClick={() => navigate('builder/template')}
								className="text-sm font-bold text-rebrand-teal">
								+ New Routine
							</button>
						}
					/>
					{!loading && (
						<>
							{memberRoutines.map((member) => (
								<div
									key={`member-${member.id}`}
									className="flex flex-col w-full">
									<MemberRow
										firstName={member.firstName}
										avatar={member.avatarFileName}
									/>
									<div>
										{member.routines && member.routines?.length > 0 ? (
											<>
												<div className="mb-6 space-y-4">
													{member.routines.map((routine: IRoutine) => (
														<RoutineDetailCard
															key={routine.id}
															routine={routine}
															onCardClick={() =>
																actionClick(routine, member, toggleDetailDrawer)
															}
															onEllipsisClick={() =>
																actionClick(routine, member, toggleEditDrawer)
															}
														/>
													))}
												</div>
											</>
										) : (
											<div className="p-4 flex flex-col justify-center bg-rebrand-grey-light rounded tracking-normal items-center w-full text-rebrand-grey-dark">
												<p className="text-center">
													{member.firstName} doesn't have any routines yet!
												</p>
												<button
													className="underline"
													onClick={() => initialRoutineClick(member)}>
													Create Routine
												</button>
											</div>
										)}
									</div>
								</div>
							))}
						</>
					)}
				</div>
			</AnimatedContainer>
			<RoutineDetailDrawer
				open={detailDrawer}
				onClose={toggleDetailDrawer}
				onEdit={navigateToEditRoutine}
				onEllipsisClick={toggleEditDrawer}
				routine={selectedRoutine}
				member={selectedMember}
			/>
			<EditDrawer
				open={editDrawer}
				onClose={toggleEditDrawer}
				actions={routineDrawerActions}
			/>
			<ReusableModal
				handleToggleModal={toggleDeleteModal}
				open={showDeleteModal}
				title="Delete Routine">
				<p className="tracking-normal">
					Are you sure you want to delete this routine?
				</p>
				<div className="flex flex-row justify-between my-4">
					<button
						className="text-rebrand-grey-medium font-semibold"
						onClick={toggleDeleteModal}>
						Cancel
					</button>
					<button
						className="text-rebrand-error font-semibold"
						onClick={deleteRoutineClick}>
						Delete
					</button>
				</div>
			</ReusableModal>
			<ReusableModal
				handleToggleModal={toggleResetModal}
				open={showResetModal}
				title="Reset Routine">
				<p className="tracking-normal">
					Are you sure you want to reset this routine?
				</p>
				<div className="flex flex-row justify-between my-4">
					<button
						className="text-rebrand-grey-medium font-semibold"
						onClick={toggleResetModal}>
						Cancel
					</button>
					<button
						className="text-rebrand-teal-dark font-semibold"
						onClick={resetRoutineClick}>
						Reset
					</button>
				</div>
			</ReusableModal>
		</WithHeaderLayout>
	);
}
