import React, { useState } from 'react';
import { ReactComponent as Ellipsis } from 'assets/Icons/Routines/ellipsis.svg';

interface TooltipProps {
	children: React.ReactNode;
	buttonPadding?: string;
}

const TooltipMenu: React.FC<TooltipProps> = ({ children, buttonPadding }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleTooltip = (e: React.MouseEvent) => {
		e.stopPropagation();
		setIsOpen(!isOpen);
	};

	return (
		<div className={`relative `}>
			<button className={buttonPadding} onClick={toggleTooltip}>
				<Ellipsis className="w-4 cursor-pointer fill-current" />
			</button>
			{isOpen && (
				<>
					<div
						className="fixed top-0 left-0 w-full h-full z-0"
						onClick={toggleTooltip}
						style={{ backgroundColor: 'transparent' }}
					/>
					<div className="z-10 absolute top-7 right-0 mt-2 py-2 bg-white border border-secondary rounded-lg shadow-md">
						{children}
					</div>
				</>
			)}
		</div>
	);
};

export default TooltipMenu;
