import { ReactComponent as PlusIcon } from 'assets/rebrand/plus-sign.svg';
import { FC } from 'react';

interface AnyoneAvatarProps {
	className?: string;
}

const AnyoneAvatar: FC<AnyoneAvatarProps> = ({
	className = 'w-12 h-12 bg-rebrand-grey-light rounded-full flex items-center justify-center',
}) => (
	<div className={className}>
		<PlusIcon className="w-6 h-6 fill-current text-rebrand-grey-medium" />
	</div>
);

export default AnyoneAvatar;
