import { ReactNode } from 'react';
import { ReactComponent as EditIcon } from 'assets/Icons/edit.svg';
import Toggle from 'components/Buttons/Toggle';
import AssignMemberCalendar from './AssignMemberCalendar';
import { IProfileUser } from 'utils/types';
import CalendarNameAndColor from 'components/CompanionApp/Event/CalendarNameAndColor';

interface IProps {
	handleCloseModal: () => void;
	calendarName: string;
	currentColor: string;
	assignedUser?: IProfileUser;
	profiles: Map<number | null, IProfileUser>;

	handleColorChange?: (arg0: string) => void;
	handleUserAssign?: (profile: IProfileUser) => void;
	handleEditOnClick?: () => void;
	handleToggleSwitch?: () => void;
	isToggledOn?: boolean;
	switchTitle?: string;
	providerIcon?: ReactNode;
	children?: ReactNode;
}

export default function CalendarDetailsPopupLayout({
	calendarName,
	currentColor,
	assignedUser,
	profiles,
	handleUserAssign,
	handleColorChange,
	handleEditOnClick,
	handleToggleSwitch,
	isToggledOn = false,
	switchTitle,
	providerIcon,
	children,
}: IProps) {
	return (
		<div className="w-full px-4">
			<div className="w-full flex flex-row mb-3 items-center">
				{providerIcon && <span className="w-4 h-4 mr-2">{providerIcon}</span>}
				<p className="font-semibold text-md">{calendarName}</p>
				{!!handleEditOnClick && (
					<button onClick={handleEditOnClick} className="flex ml-auto">
						<EditIcon className="h-4 w-4" />
					</button>
				)}
			</div>
			<CalendarNameAndColor
				handleColorChange={handleColorChange}
				color={currentColor}
				name="Color"
				showOutline={false}
				isNewLayout
			/>

			{!!handleToggleSwitch && (
				<div className="flex flex-row w-full mt-4 justify-between">
					<p className="text-sm text-rebrand-black">{switchTitle}</p>
					<Toggle
						isToggledOn={isToggledOn}
						handleToggleSwitch={handleToggleSwitch}
					/>
				</div>
			)}
			{assignedUser && !!handleUserAssign && (
				<AssignMemberCalendar
					handleUserAssign={handleUserAssign}
					assignedUser={assignedUser}
					profiles={profiles}
				/>
			)}
			{children}
		</div>
	);
}
