import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ConfirmationModalLayout from 'components/Modals/ConfirmationModalLayout';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { NotificationType } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import ReusableModal from 'components/Modals/ReusableModal';
import { AxiosResponse } from 'axios';
import useCurrentUser from 'stores/currentUser';
import analytics from 'utils/segmentClient';
import { primaryColor } from 'styles/colors';
import {
	ACCEPTED_FORMATS,
	MAX_FILE_SIZE,
	bytesToMB,
	getUploadProgressPercentage,
	isAboveMaxFileSize,
	isValidFormat,
} from 'utils/fileUploadHelpers';

export default function DisplaySettings() {
	const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
		useState(false);
	const fileUploadInputRef = useRef<HTMLInputElement>(null);
	const [isUploading, setIsUploading] = useState(false);
	const [isFetchingScreensaver, setIsFetchingScreensaver] = useState(true);
	const [isDeleting, setIsDeleting] = useState(false);

	const [imageUploadProgress, setImageUploadProgress] = useState<
		undefined | number
	>(undefined);
	const [selectedScreensaverUrl, setSelectedScreensaverUrl] =
		useState(undefined);
	const [isUserScreenSaver, setIsUserScreenSaver] = useState(false);

	const updateNotification = useContext(UpdateNotificationContext);
	const user = useCurrentUser((store) => store.user);

	const fetchSelectedScreensaver = useCallback(async () => {
		setIsFetchingScreensaver(true);
		axiosClient
			.get('web/family/screen_saver/selected')
			.then((res) => {
				const { image_url, category } = res.data;
				setSelectedScreensaverUrl(image_url);
				setIsUserScreenSaver(category === 'user');
			})
			.catch(() => {
				updateNotification({
					message: 'fetching screensaver',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			})
			.finally(() => {
				setIsFetchingScreensaver(false);
			});
	}, [updateNotification, setIsFetchingScreensaver]);
	useEffect(() => {
		fetchSelectedScreensaver();
	}, [fetchSelectedScreensaver]);

	const replaceScreensaverFlow = async (file: File) => {
		setIsUploading(true);
		try {
			const presignedUrlResponse: AxiosResponse<{
				presigned_url: string;
				origin_url: string;
			}> = await axiosClient.get('/web/upload/screen_saver_presigned_url', {
				params: {
					file_name: file.name,
				},
			});
			const { presigned_url, origin_url } = presignedUrlResponse.data;

			await axiosClient.put(presigned_url, file, {
				headers: {
					'x-amz-acl': 'public-read',
					'Content-Type': file.type,
				},
				withCredentials: false, // This call goes to amazon, don't send credentials
				onUploadProgress: (progressEvent) => {
					setImageUploadProgress(getUploadProgressPercentage(progressEvent));
				},
				timeout: 30000, // To allow for slower connections to have enough time to upload the file
			});

			await axiosClient.post(
				'/web/upload/screen_saver_presigned_url/confirm',
				null,
				{
					params: {
						origin_url,
					},
				},
			);
			updateNotification({
				message: 'Image Uploaded',
				showNotification: true,
				type: NotificationType.SUCCESS,
			});
			analytics.track('screensaver_replaced', {
				userId: user?.id,
				fileType: file.type,
			});
			setImageUploadProgress(undefined); // Reset progress bar
			await fetchSelectedScreensaver();
		} catch (e: any) {
			analytics.track('screensaver_upload_error', {
				errorType: 'network_error',
				userId: user?.id,
				fileType: file.type,
				errorMessage: e.response?.data?.message || e?.message,
			});
			updateNotification({
				message: 'uploading image',
				showNotification: true,
				type: NotificationType.ERROR,
			});
		} finally {
			setIsUploading(false);
		}
	};

	const onClickReplaceScreensaver = () => {
		fileUploadInputRef.current?.click();
	};
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if (!file) return;
		if (!isValidFormat(file)) {
			updateNotification({
				message: `invalid format. \nplease use any of the following: ${ACCEPTED_FORMATS.join(
					', ',
				)}`,
				showNotification: true,
				type: NotificationType.ERROR,
			});
			analytics.track('screensaver_upload_error', {
				errorType: 'invalid_format',
				userId: user?.id,
				fileType: file.type,
			});
		} else if (isAboveMaxFileSize(file)) {
			updateNotification({
				message: `File size is too large. \nplease use a file under ${bytesToMB(
					MAX_FILE_SIZE,
				)} MB`,
				showNotification: true,
				type: NotificationType.ERROR,
			});
			analytics.track('screensaver_upload_error', {
				errorType: 'file_too_large',
				userId: user?.id,
				fileType: file.type,
				fileSizeMb: bytesToMB(file.size),
			});
		} else {
			replaceScreensaverFlow(file);
		}
	};

	const onClickRemoveScreensaver = () => {
		setConfirmDeleteModalVisible(true);
	};
	const onDeleteModalConfirm = () => {
		setIsDeleting(true);
		setConfirmDeleteModalVisible(false);
		axiosClient
			.delete('/web/family/screen_saver/selected')
			.then(() => {
				analytics.track('screensaver_removed', {
					userId: user?.id,
				});
				fetchSelectedScreensaver();
			})
			.catch(() => {
				updateNotification({
					message: 'deleting, please try again',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			})
			.finally(() => {
				setIsDeleting(false);
			});
	};

	const onDeleteModalCancel = () => {
		setConfirmDeleteModalVisible(false);
	};

	return (
		<>
			<p className="mb-2 font-normal">
				Set a custom wallpaper to use when activating this mode from your Hearth
				Display.
			</p>
			<div
				id="selectedScreensaver"
				className="rounded-md"
				style={{
					alignItems: 'center',
					marginTop: 16,
				}}>
				{isFetchingScreensaver || isDeleting ? (
					<div className="flex items-center justify-center h-full">
						<CircularProgress
							color={'inherit'} // TODO: Update when we set a global theme provider
							style={{ color: primaryColor }}
						/>
					</div>
				) : (
					<img
						src={selectedScreensaverUrl}
						alt="screensaver_image"
						style={{
							objectFit: 'cover',
							aspectRatio: '9 / 16',
							width: '60%',
							opacity: isFetchingScreensaver ? 0.5 : 1,
							borderRadius: 5,
							margin: 'auto',
						}}
					/>
				)}
			</div>
			<div className="m-auto flex justify-between w-3/5 py-2 font-semibold text-xs">
				<button
					className="text-rebrand-teal"
					onClick={onClickReplaceScreensaver}>
					Replace
				</button>
				{isUserScreenSaver && (
					<button
						className="text-rebrand-error"
						onClick={onClickRemoveScreensaver}>
						Remove
					</button>
				)}
			</div>
			<input
				ref={fileUploadInputRef}
				id="file-upload"
				type="file"
				accept={ACCEPTED_FORMATS.map((format) => `.${format}`).join(',')}
				hidden
				onChange={handleFileChange}
			/>

			<ConfirmationModalLayout
				open={confirmDeleteModalVisible}
				title="Remove Image"
				text="Are you sure you want to remove this image?"
				secondaryButtonLabel="Cancel"
				primaryButtonLabel="Remove"
				handleCancel={onDeleteModalCancel}
				handleConfirm={onDeleteModalConfirm}
			/>

			<ReusableModal open={isUploading} title="Uploading...">
				<div className="w-full mb-3">
					<LinearProgress
						value={imageUploadProgress}
						variant={imageUploadProgress ? 'determinate' : 'indeterminate'}
						color={'inherit'} // TODO: Update when we set a global theme provider
						style={{ color: primaryColor }}
					/>
				</div>
			</ReusableModal>
		</>
	);
}
