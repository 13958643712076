import { FC } from 'react';
import IconProgressBar from 'components/ProgressBars/IconProgressBar';
import { ReactComponent as ProgressCheck } from 'assets/rebrand/check-circle-white.svg';
import { LinearProgress, linearProgressClasses } from '@mui/material';

interface IRoutineProgressBarProps {
	completedSteps: number;
	totalSteps: number;
}

export const StepProgressBar: FC<IRoutineProgressBarProps> = ({
	completedSteps,
	totalSteps,
}) => {
	let progressBarValue = (completedSteps / totalSteps) * 100;
	// Handle the case where one or both values are not valid numbers
	if (isNaN(completedSteps) || isNaN(totalSteps) || totalSteps === 0) {
		progressBarValue = 0;
	}
	return (
		<div className="mt-4">
			<p className="mb-2 text-grey-dark">
				Today ({completedSteps} of {totalSteps})
			</p>
			<LinearProgress
				variant="determinate"
				value={progressBarValue}
				sx={{
					[`&.${linearProgressClasses.colorPrimary}`]: {
						backgroundColor: '#FFF3E1',
					},
					[`& .${linearProgressClasses.bar}`]: {
						backgroundColor: '#FEBA00',
					},
				}}
			/>
		</div>
	);
};

interface IWeekProgressProps {
	pastProgress: {
		[key: number | string]: boolean;
	};
}

export const WeekProgressBar: FC<IWeekProgressProps> = ({ pastProgress }) => (
	<div className="mt-4">
		<p className="mb-2 text-grey-dark">This Week</p>
		<IconProgressBar
			pastProgress={pastProgress ?? []}
			icon={<ProgressCheck className="text-rebrand-yellow" />}
			backgroundClassName="bg-rebrand-yellow-light"
		/>
	</div>
);
