import useCurrentUser from 'stores/currentUser';
import {
	PROFILE_STEPS,
	SetupStatus,
	SetupStepType,
	useSetupContext,
} from './SetupLayout';
import { createElement, useEffect } from 'react';
import axiosClient from 'utils/axiosClient';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

export default function SetupOnboarding() {
	const {
		activeSetupStep,
		setActiveSetupStep,
		resetActiveSetupStep,

		setSkipStep,
		setBackStep,
	} = useSetupContext();
	const { currentUser, isAuthenticated } = useCurrentUser((store) => ({
		currentUser: store.user,
		isAuthenticated: store.isAuthenticated,
	}));
	const [searchParams] = useSearchParams();
	const abortController = new AbortController();

	function handleSkipStep(skipTo?: SetupStepType) {
		return () => {
			if (skipTo) {
				setActiveSetupStep(skipTo);
			}
		};
	}

	useEffect(() => {
		if (!isAuthenticated) {
			console.debug('User is not authenticated');
			resetActiveSetupStep();
			return;
		}

		if (isAuthenticated && currentUser?.isOnboarding) {
			console.debug('User is onboarding');
			resetActiveSetupStep();
			return;
		}

		axiosClient
			.get<SetupStatus>('/setup/web/profile', {
				params: { ot: searchParams.get('ot') },
				signal: abortController.signal,
			})
			.then((response) => {
				if (!response.data.state || !(response.data.state in SetupStepType)) {
					resetActiveSetupStep();
				} else {
					setActiveSetupStep(response.data.state);
				}
			})
			.catch((error) => {
				if (error.response?.status === 401) {
					resetActiveSetupStep();
				}
			});

		return () => {
			abortController.abort();
		};
	}, []);

	useEffect(() => {
		if (activeSetupStep) {
			console.debug('Active step set to', activeSetupStep);
			const setupStep = PROFILE_STEPS[activeSetupStep];

			if (isAuthenticated && !setupStep.noSave) {
				axiosClient
					.post<SetupStatus>(
						'/setup/web/profile',
						{
							state: activeSetupStep,
						},
						{
							signal: abortController.signal,
						},
					)
					.catch((error) => {
						if (axios.isCancel(error)) {
							console.debug(error);
						}
					});
			}

			if (setupStep.skipTo) {
				setSkipStep(() => handleSkipStep(setupStep.skipTo));
			} else {
				setSkipStep(() => undefined);
			}

			if (setupStep.backTo) {
				setBackStep(() => handleSkipStep(setupStep.backTo));
			} else {
				setBackStep(() => undefined);
			}
		}

		return () => {
			abortController.abort();
		};
	}, [activeSetupStep]);

	const step = PROFILE_STEPS[activeSetupStep!];
	if (!step) {
		return (
			<div className="absolute flex flex-col items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
				Loading...
			</div>
		);
	}

	return createElement(step.component, step.props);
}
