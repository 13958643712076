import { ReactNode } from 'react';
import { Backdrop, Modal } from '@mui/material';

interface IPropTypes {
	onClose?: () => void;
	open: boolean;
	children: ReactNode;
	unwrap?: boolean;
}

export default function ReusableModalLayout({
	onClose,
	open,
	children,
	unwrap = false,
}: IPropTypes) {
	const pane = !unwrap ? (
		<div className="flex flex-col bg-white z-10 px-6 py-4 rounded-lg w-full max-w-325">
			{children}
		</div>
	) : (
		<>{children}</>
	);

	return (
		<Modal
			className="flex flex-col justify-center items-center"
			open={open}
			onClose={onClose}
			BackdropComponent={Backdrop}
			BackdropProps={{ timeout: 500 }}>
			{pane}
		</Modal>
	);
}
