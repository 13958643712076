import React, { ReactNode } from 'react';

interface IFadeWrapperProps {
	isVisible: boolean;
	children: ReactNode;
}

const FadeWrapper: React.FC<IFadeWrapperProps> = ({ isVisible, children }) => (
	<div
		className={`transition-opacity duration-300 ease-in-out overflow-hidden ${
			isVisible ? 'opacity-100 h-auto' : 'opacity-0 h-0'
		}`}>
		{children}
	</div>
);

export default FadeWrapper;
