import React, { createContext, useState } from 'react';

interface IPopup {
	index: number | null;
	message?: string;
	type: 'email' | 'birthday' | '';
	showNotification: boolean;
}

interface IPopupContext {
	popUp: IPopup;
	updateInputPopup: (notification: IPopup) => void;
}

const defaultValue: IPopupContext = {
	popUp: {
		index: null,
		message: 'Default Value',
		type: '',
		showNotification: false,
	},
	updateInputPopup: (popUp: IPopup) => {},
};

export const InputPopupContext = createContext<IPopupContext>(defaultValue);

// Create a provider component that wraps your app and provides the error context value
export function InputPopupProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const [popUp, setNotification] = useState(defaultValue.popUp);

	const updateInputPopup = (newPopup: IPopup) => {
		if (popUp.type === newPopup.type && popUp.index != newPopup.index) {
			setNotification({ ...popUp, ...newPopup, ['showNotification']: true });
		}
		if (popUp.type != newPopup.type) {
			setNotification({ ...popUp, ...newPopup, ['showNotification']: true });
		} else {
			setNotification({
				...newPopup,
				['showNotification']: !popUp.showNotification,
			});
		}
	};

	return (
		<InputPopupContext.Provider value={{ popUp, updateInputPopup }}>
			{children}
		</InputPopupContext.Provider>
	);
}
