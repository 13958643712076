import React, { FC, ReactNode } from 'react';
import { ReactComponent as CheckCircle } from 'assets/rebrand/check-circle-white.svg';

interface ICardProps {
	children: ReactNode;
	onClick?: () => void;
	className?: string;
}

const Card: FC<ICardProps> = ({
	children,
	onClick = () => {},
	className = 'p-4 border-0.5 border-rebrand-grey-light soft-shadow',
}) => (
	<div className={`rounded-lg ${className}`} onClick={onClick}>
		{children}
	</div>
);

interface ISelectionCardProps extends ICardProps {
	isSelected: boolean;
}

export const SelectionCard: FC<ISelectionCardProps> = ({
	isSelected,
	...props
}) => (
	<Card
		{...props}
		className={`cursor-pointer relative transition-all duration-500 ease-in-out ${
			props.className
		} ${isSelected ? 'bg-rebrand-teal-grey border-rebrand-teal' : ''}`}>
		<CheckCircle
			className={`absolute top-0 right-0 transform translate-x-1 -translate-y-1 h-4 w-4 bg-rebrand-teal rounded-full transition-opacity duration-500 ease-in-out ${
				isSelected ? 'opacity-100' : 'opacity-0'
			}`}
		/>

		{props.children}
	</Card>
);

interface ICardHeaderProps {
	headerText: string;
	headerButton?: ReactNode;
	textAlignment?: 'left' | 'center';
}

export const CardHeader: FC<ICardHeaderProps> = ({
	headerText,
	headerButton,
	textAlignment = 'center',
}) => {
	return (
		<div
			className={`flex flex-row items-center justify-${
				headerButton ? 'between' : 'center'
			}`}>
			<h6
				className={`text-rebrand-teal-text font-medium text-${textAlignment}`}>
				{headerText}
			</h6>
			{headerButton}
		</div>
	);
};

export default Card;
