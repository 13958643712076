export const ONBOARDING_SCREENS = {
	initiate: {
		step: 1,
		progress: null,
		title: 'Set up your family',
		route: 'onboard/authenticate',
	},
	addEmail: {
		step: 2,
		progress: 7,
		title: 'Create an account',
		route: 'onboard/create-account',
	},
	verifyEmail: {
		step: 3,
		design: 2.2,
		progress: 14,
		title: 'Verify your email',
		route: 'onboard/verify-email-address/:id',
	},
	createAccount: {
		step: 4,
		design: 2.4,
		progress: 21,
		title: 'Account created',
	},
	activation: {
		step: 5,
		design: 2.5,
		progress: 28,
		title: 'We found a membership associated with this email',
	},
	addProfile: {
		step: 6,
		design: 2.8,
		progress: 35,
		title: 'Let’s start with your profile',
		route: 'onboard/create-account',
	},
	addPhone: {
		step: 7,
		design: 2.9,
		progress: 42,
		title: 'Add your phone number to use Hearth Helper',
		route: 'onboard/phone-number',
	},
	verifyPhone: {
		step: 8,
		design: '2.10',
		progress: 49,
		title: 'Enter verification code',
		route: 'onboard/verify-phone-number/:id',
	},
	addFamily: {
		step: 9,
		design: 2.11,
		progress: 56,
		title: 'What’s your family’s name?',
		route: 'onboard/create-family',
		nextRoute: 'onboard/check-timezone',
	},
	addTimezone: {
		step: 10,
		design: '',
		progress: 63,
		title: 'Confirm your timezone',
		route: 'onboard/check-timezone',
		nextRoute: 'onboard/family-personalization',
	},
	familyPersonalization: {
		step: 11,
		design: 2.14,
		progress: 70,
		title: 'How are you planning to use your Hearth?',
		route: 'onboard/individual-personalization',
		nextRoute: 'onboard/members',
	},
	individualPersonalization: {
		step: 12,
		design: 2.15,
		progress: 77,
		title: 'What family goals are you trying to achieve when using Hearth?',
		route: 'onboard/family-personalization',
		nextRoute: 'onboard/individual-personalization',
	},
	members: {
		step: 13,
		design: 2.12,
		progress: 84,
		title: 'Add family members',
		route: 'onboard/members',
	},
	connectGoogleCalender: {
		// disabled as of 1/16/24
		step: null,
		design: 2.13,
		title: 'Select Google calendars to import',
		route: 'manage-calendars/connect-google-calendar',
	},
	complete: {
		step: 14,
		design: 2.16,
		progress: null,
		title: 'Way to go!',
		route: 'onboard/complete',
	},
};
