import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { FC } from 'react';

interface IConfirmationLayoutProps {
	open: boolean;
	title: string;
	description: string;
	confirmButtonLabel?: string;
	confirmButtonColor?: string;
	handleCancel: () => void;
	handleConfirm: () => void;
}

const NewConfirmationLayout: FC<IConfirmationLayoutProps> = ({
	open,
	title,
	description,
	confirmButtonLabel = 'Confirm',
	confirmButtonColor = 'text-rebrand-teal',
	handleCancel,
	handleConfirm,
}) => (
	<SlideUpDrawer open={open}>
		<div className="flex h-full w-full justify-center items-center fixed top-0 left-0">
			<div className="bg-white px-6 py-4 rounded-lg max-w-325">
				<p className="text-md font-semibold">{title}</p>
				<p className="text-sm py-2">{description}</p>
				<div className="w-full flex justify-between items-center">
					<button
						className="text-rebrand-grey-medium text-sm font-bold"
						onClick={handleCancel}>
						Cancel
					</button>
					<button
						className={`${confirmButtonColor} text-sm font-bold`}
						onClick={handleConfirm}>
						{confirmButtonLabel}
					</button>
				</div>
			</div>
		</div>
	</SlideUpDrawer>
);

export default NewConfirmationLayout;
