import TextContainer from 'components/onboarding/TextContainer';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCalendarList from 'stores/calendarList';
import useCurrentUser from 'stores/currentUser';

export default function ManageCalendarsLanding() {
	const navigate = useNavigate();
	const { isFetching, isGoogleAuthenticated, fetchCalendars } = useCalendarList(
		(store) => ({
			isFetching: store.isFetching,
			isGoogleAuthenticated: store.isGoogleAuthenticated,
			fetchCalendars: store.fetchCalendars,
		}),
	);
	const fetchCurrentUser = useCurrentUser((store) => store.fetchCurrentUser);
	const [isSetupComplete, setIsSetupComplete] = useState<boolean>(false);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		async function inner() {
			await fetchCurrentUser();
			await fetchCalendars();
			setIsSetupComplete(true);
		}

		inner();
	}, []);

	useEffect(() => {
		if (isSetupComplete && !isFetching) {
			if (searchParams.has('error-app-exists')) {
				navigate('/manage-calendars/authenticate-error');
			} else if (isGoogleAuthenticated) {
				navigate('/manage-calendars/select-calendars');
			} else {
				navigate('/manage-calendars/connect-google-calendar');
			}
		}
	}, [isSetupComplete]);

	return (
		<>
			<TextContainer textTitle="Wait a second please..." />
		</>
	);
}
