import { FC } from 'react';
import { ReactComponent as LockSVG } from 'assets/Icons/Membership/featureGateLock.svg';

interface IPropTypes {
	body: string;
}

export const FeatureGateLock: FC<IPropTypes> = ({ body }) => {
	return (
		<div className="bg-rebrand-gray-light flex flex-col w-full items-center py-6 px-4 ">
			<div className="flex items-center bg-rebrand-gray-dark py-2 px-4 rounded-full">
				<LockSVG />
				<p className="text-sm text-white font-semibold pl-2">Premium Feature</p>
			</div>

			<p className="text-center text-rebrand-gray-dark mt-4 mx-12">{body}</p>
		</div>
	);
};
