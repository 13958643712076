import { ChangeEvent, useState, useContext, ReactNode } from 'react';
import PageTitle from 'components/Reusable/PageTitle';
import { useNavigate, Link } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import { AxiosError } from 'axios';
import FormLayout from 'components/Forms/FormLayout';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { EventName, trackEvent } from 'utils/segmentClient';
import { CalendarProviderTypes } from 'utils/types';
import { TextField } from '@mui/material';

enum ICalLinkErrorStates {
	INVALID = 'Invalid link',
	REQUIRED = 'Required',
	SUBSCRIPTION_EXISTS = 'Calendar has already been added to your account',
}

enum NameErrorStates {
	REQUIRED = 'Required',
}

interface IFormState {
	link: string;
	name: string;
}

interface IAddCalendar {
	provider: CalendarProviderTypes;
	title: string;
	instruction: string;
	help?: ReactNode;
}

function AddCalendar({ provider, title, instruction, help }: IAddCalendar) {
	const navigate = useNavigate();
	const [formState, setFormState] = useState<IFormState>({
		link: '',
		name: '',
	});
	const [linkErrorState, setLinkErrorState] = useState<ICalLinkErrorStates>();
	const [nameErrorState, setNameErrorState] = useState<NameErrorStates>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const updateNotification = useContext(UpdateNotificationContext);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState((state) => ({
			...state,
			[event.target.name]: event.target.value,
		}));
		if (event.target.name === 'link') {
			setLinkErrorState(undefined);
		} else {
			setNameErrorState(undefined);
		}
	}

	function isLinkPresent() {
		const isEmptyString = formState.link === '';
		setLinkErrorState(isEmptyString ? ICalLinkErrorStates.REQUIRED : undefined);
		return !isEmptyString;
	}

	function isNamePresent() {
		const isEmptyString = formState.name === '';
		setNameErrorState(isEmptyString ? NameErrorStates.REQUIRED : undefined);
		return !isEmptyString;
	}

	function handleSubmit() {
		if (isLinkPresent() && isNamePresent()) {
			setIsSubmitting(true);
			axiosClient
				.post('/web/external_calendar/ical', {
					provider,
					ics_url: formState.link,
					calendar_name: formState.name,
				})
				.then(() => {
					trackEvent(EventName.ConnectedCalendar, {
						provider,
					});
					navigate('/manage-calendar');
				})
				.catch((e) => {
					const errorStatus = (e as AxiosError)?.response?.status;
					if (errorStatus === 400) {
						setLinkErrorState(ICalLinkErrorStates.SUBSCRIPTION_EXISTS);
					} else if (errorStatus === 422) {
						setLinkErrorState(ICalLinkErrorStates.INVALID);
					} else {
						updateNotification({
							message: 'Unable to save calendar',
							showNotification: true,
						});
					}
					setIsSubmitting(false);
				});
		}
	}

	return (
		<div className="mx-5 h-full">
			<FormLayout
				label={'Add Calendar'}
				handleClick={handleSubmit}
				isDisabled={!!linkErrorState || !!nameErrorState || isSubmitting}>
				<div className="mx-6 w-full">
					<Link to="/manage-calendar">
						<p className="text-xs text-rebrand-teal font-bold">Cancel</p>
					</Link>
					<div className="pt-6 pb-1">
						<PageTitle title={title} subtitle={instruction} />
					</div>
					<div className="space-y-5">
						<TextField
							className="w-full mb-2"
							name="link"
							label="Link"
							placeholder="Enter link"
							value={formState.link}
							onChange={handleChange}
							onBlur={isLinkPresent}
							error={Boolean(linkErrorState)}
							helperText={linkErrorState}
						/>
						<TextField
							className="w-full"
							name="name"
							label="Calendar Name"
							placeholder="Enter name"
							value={formState.name}
							onChange={handleChange}
							onBlur={isNamePresent}
							error={Boolean(nameErrorState)}
							helperText={nameErrorState}
						/>
					</div>

					{help && (
						<p className="pt-3 text-xxs text-left">
							Not sure where to find this link?&nbsp;
							{help}
						</p>
					)}
				</div>
			</FormLayout>
		</div>
	);
}

export function AddAppleCalendar() {
	return (
		<AddCalendar
			title="Add Apple Calendar"
			instruction="Copy the public share link for your Apple Calendar and paste here."
			provider={CalendarProviderTypes.ICAL}
			help={
				<a
					href="https://hearthdisplay.kustomer.help/en_us/apple-calendar-url-HkvyWYJOn"
					target="_blank"
					rel="noreferrer"
					className="text-xxs text-rebrand-teal underline">
					Apple Calendar FAQs
				</a>
			}
		/>
	);
}

export function AddOutlookCalendar() {
	return (
		<AddCalendar
			title="Add Outlook Calendar"
			instruction="Copy the public share link for your Outlook Calendar and paste here."
			provider={CalendarProviderTypes.OUTLOOK}
			help={
				<a
					href="https://hearthdisplay.kustomer.help/en_us/outlook-calendar-url-HJYlTO5j2"
					target="_blank"
					rel="noreferrer"
					className="text-xxs text-rebrand-teal underline">
					Outlook FAQs
				</a>
			}
		/>
	);
}

export function AddIcsCalendar() {
	return (
		<AddCalendar
			title="Add Calendar"
			instruction="Copy the public share link for your calendar and paste here."
			provider={CalendarProviderTypes.ICS}
		/>
	);
}
