import dayjs from 'dayjs';

import { IAPIEvent, IAPIExternalCalendar, IAPIFamilyMember } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import { getProfileColor, getEventBackgroundColor } from 'utils/colorHelpers';

export function fetchMembers(
	setMembers: (
		value:
			| ((prevState: IAPIFamilyMember[]) => IAPIFamilyMember[])
			| IAPIFamilyMember[],
	) => void,
) {
	axiosClient.get('/web/family/our/member').then((res) => {
		setMembers(res.data);
	});
}

const getOpacity = (event: IAPIEvent): number => {
	const dateOfAllDayEventInPast =
		event.is_all_day_event && dayjs().isAfter(dayjs(event.end_date));
	const dateTimeOfFixedTimeEventInPast =
		!event.is_all_day_event && dayjs().isAfter(dayjs(event.end_at));

	if (dateOfAllDayEventInPast || dateTimeOfFixedTimeEventInPast) {
		return 0.5;
	}
	return 1;
};

const getBorderWidth = (
	event: IAPIEvent,
	attendeeResponseStatus: string,
): number => {
	if (
		!event.is_all_day_event &&
		(attendeeResponseStatus !== 'accepted' || event.is_hearth_event)
	) {
		return 2;
	}
	if (event.is_all_day_event && attendeeResponseStatus !== 'accepted') {
		return 1;
	}
	return 0;
};

const getTextDecoration = (attendeeResponseStatus: string): string => {
	if (attendeeResponseStatus === 'declined') {
		return 'line-through';
	}
	return 'none';
};

export const getPartialTimeFormatted = (
	startDateTime: dayjs.Dayjs,
	endDateTime: dayjs.Dayjs,
	selectedDate?: dayjs.Dayjs,
): string => {
	// first and last day of overnight event
	const isFirstDay = selectedDate?.isSame(startDateTime.startOf('day'));
	const isLastDay = selectedDate?.isSame(endDateTime.startOf('day'));

	const startFormat = startDateTime.minute() === 0 ? 'ha' : 'h:mma';
	const endFormat = endDateTime.minute() === 0 ? 'ha' : 'h:mma';
	const showOrHideAMPM = endDateTime.format('a') === 'am' ? '' : 'am';

	if (isFirstDay) {
		// ends at midnight since event carries over to next day - always show am/pm
		return `${startDateTime.format(startFormat)}–12am`;
	}
	if (isLastDay) {
		// starts at midnight since event carries over from previous day
		return `12${showOrHideAMPM}–${endDateTime.format(endFormat)}`;
	}
	// isMiddleDay
	return '12am-12am';
};

export const getDisplayTime = (
	event: IAPIEvent,
	selectedDate?: dayjs.Dayjs,
): string => {
	const startAt = dayjs(event.start_at);
	const endAt = dayjs(event.end_at);

	const startFormat = startAt.minute() === 0 ? 'h' : 'h:mm';
	const endFormat = endAt.minute() === 0 ? 'ha' : 'h:mma';
	const showOrHideAMPM = startAt.format('a') === endAt.format('a') ? '' : 'a';
	const isOvernight = startAt.startOf('day').isBefore(endAt.startOf('day'));

	const displayPartialTime = getPartialTimeFormatted(
		startAt,
		endAt,
		selectedDate?.startOf('day'),
	);
	const displayFullTime = `${startAt.format(
		startFormat + showOrHideAMPM,
	)}–${endAt.format(endFormat)}`;

	if (isOvernight) {
		return displayPartialTime;
	}
	return displayFullTime;
};

const getEventProfileAvatar = (
	members: IAPIFamilyMember[],
	calendar?: IAPIExternalCalendar,
): string | null => {
	if (calendar?.assigned_user_id) {
		const assignedUser = members.find(
			(member) => member.id === calendar.assigned_user_id,
		);
		return assignedUser?.avatar_file_name || null;
	} else {
		return null;
	}
};

export interface EventStyles {
	displayTime: string;
	opacity: number;
	attendeeResponseStatus: string;
	backgroundColor: string;
	borderWidth: number;
	textDecoration: string;
	profileAvatar: string | null;
}

export const buildEventStyles = (
	calendars: IAPIExternalCalendar[],
	event: IAPIEvent,
	members: IAPIFamilyMember[],
	selectedDate?: dayjs.Dayjs,
): EventStyles => {
	const calendar = calendars.find(
		(calendar) => calendar.id === event.calendar_id,
	);
	const attendee = event.attendee_list.find(
		(attendee) => attendee.user_id === calendar?.user_id || !calendar?.user_id,
	);
	const attendeeResponseStatus = !!attendee?.response_status
		? attendee.response_status
		: 'accepted';
	const profileColor = getProfileColor(members, calendar, attendee?.user_id);

	const displayTime = getDisplayTime(event, selectedDate?.startOf('day'));
	const opacity = getOpacity(event);
	const backgroundColor = getEventBackgroundColor(
		event,
		profileColor,
		calendar?.color,
		attendee?.user_id,
	);
	const profileAvatar = getEventProfileAvatar(members, calendar);

	const borderWidth = getBorderWidth(event, attendeeResponseStatus);
	const textDecoration = getTextDecoration(attendeeResponseStatus);

	return {
		displayTime,
		opacity,
		attendeeResponseStatus,
		backgroundColor,
		borderWidth,
		textDecoration,
		profileAvatar,
	};
};
