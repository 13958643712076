import { ReactNode } from 'react';
import Scrim from './Scrim';

interface IPropTypes {
	children: ReactNode;
	popup: boolean;
	handleClosePopup: () => void;
}

export default function BottomPopupLayout({
	children,
	popup,
	handleClosePopup,
}: IPropTypes) {
	const popupBackground = popup
		? 'h-full w-full flex fixed top-0 bottom-0 right-0 z-30 lg:w-3/4'
		: 'h-full invisible w-full flex fixed top-0 right-0 bottom-0 z-30 lg:w-3/4';
	const popupStyle =
		'bg-white items-center flex flex-col w-full absolute bottom-0 z-30 py-6';

	return (
		<div className={popupBackground}>
			<Scrim handleToggleModal={handleClosePopup} />

			<div
				style={{
					borderTopRightRadius: 8,
					borderTopLeftRadius: 8,
					transition: 'all 0.3s ease-in-out',
					transform: popup ? 'translateY(0%)' : 'translateY(100%)',
				}}
				className={popupStyle}>
				{children}
			</div>
		</div>
	);
}
