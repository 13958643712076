import { Link as RouterLink } from 'react-router-dom';

interface ILink {
	linkTo?: string;
	label: string;
	underline?: Boolean;
	onClick?: () => void;
	openInNewTab?: Boolean;
}

export default function Link({
	linkTo,
	label,
	underline = true,
	onClick,
	openInNewTab = true,
}: ILink) {
	let className = `text-rebrand-teal font-family:body`;
	if (underline) className = `${className} underline`;

	if (!!linkTo) {
		return linkTo.startsWith('http') ? (
			<a
				href={linkTo}
				className={className}
				target={openInNewTab ? '_blank' : '_self'}
				rel={openInNewTab ? 'noopener noreferrer' : undefined}>
				{label}
			</a>
		) : (
			<RouterLink to={linkTo} className={className}>
				{label}
			</RouterLink>
		);
	} else {
		return (
			<span onClick={onClick} className={className + ' cursor-pointer'}>
				{label}
			</span>
		);
	}
}
