import Info from 'assets/Icons/info.png';
import InfoBackground from 'assets/Icons/infoBackground.png';
import { FC } from 'react';

interface IPropTypes {
	popup?: boolean;
	info?: string;
	handleClick?: () => void;
}

interface IInfoToolTipProps {
	info: string | undefined;
}

export const InfoToolTip: FC<IInfoToolTipProps> = (props: {
	info: string | undefined;
}) => (
	<div style={{ right: -37 }} className="absolute z-20 bottom-0 flex">
		<div style={{ width: 256, height: 110 }} className="relative flex">
			<img
				className="w-full h-full"
				src={InfoBackground}
				alt="info background"
			/>

			<p className="absolute bottom-2 right-0 w-full h-full flex justify-center items-center px-8 text-left text-xxs text-rebrand-teal-text">
				{props.info}
			</p>
		</div>
	</div>
);

export default function InfoPopup({ popup, info, handleClick }: IPropTypes) {
	const popupButton = `absolute top-0 h-full justify-center justify-end flex w-5 right-4`;
	return (
		<button onClick={handleClick} className={popupButton}>
			<div className="relative self-center flex w-full">
				<img className="w-5 h-5" src={Info} alt="info" />
				{popup && <InfoToolTip info={info} />}
			</div>
		</button>
	);
}
