import React from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	styled,
} from '@mui/material';
import DropDownArrow from 'assets/Icons/Arrows/drop-down-arrow.svg';

const StyledSelect = styled(Select)(() => ({
	borderRadius: 4,
	'& .MuiSelect-icon': {
		backgroundImage: `url(${DropDownArrow})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		paddingRight: 24,
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: '#008492',
	},
	'&:hover .MuiOutlinedInput-notchedOutline': {
		borderColor: '#008492',
	},
}));

export interface DropdownSelectOption {
	value: string | number;
	label: string | number;
}

export interface DropdownSelectNumberOption {
	value: number;
	label: string;
}

interface DropdownSelectProps {
	options: DropdownSelectOption[];
	selectedValue: string | number;
	onChange: (value: string) => void;
	label?: string;
}

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
	options,
	selectedValue,
	onChange,
	label,
}) => {
	const isValidValue = (
		value: string | number,
		options: DropdownSelectOption[],
	) => options.some((option) => option.value === value);

	return (
		<FormControl fullWidth>
			<InputLabel>{label}</InputLabel>
			<StyledSelect
				value={isValidValue(selectedValue, options) ? selectedValue : ''}
				onChange={(e) => onChange(e.target.value as string)}
				label={label}>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.value}
						sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}>
						{option.label}
					</MenuItem>
				))}
			</StyledSelect>
		</FormControl>
	);
};
