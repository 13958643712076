interface IPropTypes {
	message: string;
	buttonLabel?: string;
	handleClick?: () => void;
}

export const EmptyState = ({
	message,
	buttonLabel,
	handleClick,
}: IPropTypes) => (
	<div className="flex w-full justify-center px-5 py-4">
		<div className="p-4 flex flex-col justify-center bg-rebrand-grey-light rounded tracking-normal items-center w-full text-rebrand-grey-dark">
			<p className="text-center">{message}</p>
			{handleClick && buttonLabel && (
				<button className="underline" onClick={handleClick}>
					{buttonLabel}
				</button>
			)}
		</div>
	</div>
);
