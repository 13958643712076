import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import axiosClient from 'utils/axiosClient';
import { nameInputError } from 'utils/errorCheck';
import { AvatarSrc, IProfilePicture } from 'utils/types';
import { getRandomAvatar } from 'utils/avatars';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import PageTitle from 'components/Reusable/PageTitle';
import { Button, TextField } from '@mui/material';
import SetupContainer from 'components/Layouts/SetupContainer';
import useCurrentUser from 'stores/currentUser';
import { useSetupContext } from '../SetupLayout';

interface IFormState {
	firstName: string;
	lastName: string;
	profilePicture: IProfilePicture;
}

export default function ProfileInfo() {
	const user = useCurrentUser((store) => store.user!);
	const { nextStep } = useSetupContext();
	const updateNotification = useContext(UpdateNotificationContext);

	const [formState, setFormState] = useState<IFormState>({
		firstName: '',
		lastName: '',
		profilePicture: {},
	});
	const [shouldShowError, setShouldShowError] = useState<boolean>(false);

	useEffect(() => {
		setFormState({
			firstName: !!user.firstName ? user.firstName : '',
			lastName: !!user.lastName ? user.lastName : '',
			profilePicture: {
				filename: user.filename ?? getRandomAvatar(),
				avatarSrc: AvatarSrc.REMOTE,
			},
		});
	}, []);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState((state) => ({
			...state,
			[event.target.name]: event.target.value,
		}));
	}

	async function handleSubmit() {
		if (nameInputError(formState.firstName)) {
			setShouldShowError(true);
		} else {
			let payloadData = {
				id: user.id,
				first_name: formState.firstName,
				last_name: formState.lastName,
			};

			if (
				formState.profilePicture.avatarSrc === AvatarSrc.LOCAL &&
				formState.profilePicture.filename
			) {
				payloadData = {
					...payloadData,
					...{ avatar_file_name: formState.profilePicture.filename },
				};
			}

			await axiosClient
				.put('/onboarding/user/my', payloadData)
				.then(() => {
					nextStep();
				})
				.catch(() => {
					updateNotification({
						message: 'Error adding profile',
						showNotification: true,
					});
				});
		}
	}

	return (
		<SetupContainer>
			<PageTitle title="Let's start with your profile" />
			<form onSubmit={handleSubmit} className="mt-6 space-y-6">
				<div className="w-full flex justify-center">
					<MemberAvatar
						avatarFilename={formState.profilePicture.filename}
						className="h-128 w-128"
					/>
				</div>
				<TextField
					name="firstName"
					label="Name"
					placeholder="Required"
					value={formState.firstName}
					onChange={handleChange}
					fullWidth
					error={!!nameInputError(formState.firstName) && shouldShowError}
					helperText={
						shouldShowError ? nameInputError(formState.firstName) : undefined
					}
					variant="outlined"
				/>
				<TextField
					name="lastName"
					label="Last Name"
					placeholder="Required"
					value={formState.lastName}
					onChange={handleChange}
					fullWidth
					error={!!nameInputError(formState.lastName) && shouldShowError}
					helperText={
						shouldShowError ? nameInputError(formState.lastName) : undefined
					}
					variant="outlined"
				/>
				<Button onClick={handleSubmit} variant="contained" fullWidth>
					Continue
				</Button>
			</form>
		</SetupContainer>
	);
}
