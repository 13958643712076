import React, { ForwardedRef, RefObject, useState } from 'react';
import InputWrapper from 'components/Forms/InputWrapper';
import { ReactComponent as UploadIcon } from 'assets/Icons/Upload/upload.svg';
import ImageUploadModal from 'components/Forms/Upload/ImageUploadModal';
import { EditorSettings } from 'components/Images/ImageEditor';

interface UploadInputProps {
	onFileChange: (file: File | undefined) => void;
	onDataUrlChange: (dataUrl: string | undefined) => void;
	rawImage?: File;
	displayInput?: boolean;
	circle?: boolean;
	editorSettings?: EditorSettings;
}

const UploadInput = React.forwardRef(
	(
		{
			onFileChange,
			onDataUrlChange,
			rawImage,
			displayInput = false,
			editorSettings,
		}: UploadInputProps,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		const [error, setError] = useState<string>('');
		const [previousFile, setPreviousFile] = useState<File | undefined>();
		const [editorModal, setEditorModal] = useState(false);

		const handleCloseEditor = () => {
			if (error) {
				onFileChange(previousFile);
				setError('');
			}
			setEditorModal(false);
		};

		const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];

			if (!file && rawImage) {
				return;
			}

			if (!file && !rawImage) {
				onFileChange(undefined);
				setError('Please select a file');
				return;
			}

			if (file && file.type.startsWith('image/')) {
				if (rawImage) setPreviousFile(rawImage);
				onFileChange(file);
			} else {
				setError('Invalid file type');
			}

			setEditorModal(true);
		};

		return (
			<>
				<input
					ref={ref}
					id="file-upload"
					type="file"
					className="hidden"
					onChange={handleFileChange}
					onAbort={() => setError('Upload aborted')}
				/>
				{displayInput && (
					<InputWrapper label="Profile Picture">
						<div
							className="flex flex-row w-full justify-between items-center cursor-pointer"
							onClick={() =>
								(ref as RefObject<HTMLInputElement>).current?.click()
							}>
							<span className={error ? 'text-error' : ''}>
								{rawImage?.name || error || 'Select file...'}
							</span>
							<UploadIcon className="w-6 h-6 fill-current text-grey-medium" />
						</div>
					</InputWrapper>
				)}
				<ImageUploadModal
					error={error}
					open={editorModal}
					onClose={handleCloseEditor}
					rawImage={rawImage}
					setImage={(dataUrl: string) => {
						onDataUrlChange(dataUrl);
						handleCloseEditor();
					}}
					cancelUpload={() => {
						if (previousFile) onFileChange(previousFile);
						handleCloseEditor();
					}}
					editorSettings={editorSettings}
				/>
			</>
		);
	},
);

export default UploadInput;
