import { createContext, useContext, useState, ReactNode } from 'react';
import { IAPITask } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import { scheduledTodosFeatureFlag } from 'utils/variables';

interface TodosContextType {
	tasks: IAPITask[];
	filters: string[];
	sortBy: string;
	fetchTasks: any;
	saveFilters: any;
	saveSortBy: any;
}

interface TodosProviderType {
	children: ReactNode;
}

export const TodosContext = createContext<TodosContextType | null>(null);

export const useTaskList = (): IAPITask[] => {
	const todosContext = useContext(TodosContext);

	if (!todosContext) {
		throw new Error('useTaskList has to be used within <TodoContext.Provider>');
	}

	return todosContext.tasks;
};

export const useFilters = (): string[] => {
	const todosContext = useContext(TodosContext);

	if (!todosContext) {
		throw new Error('useFilters has to be used within <TodoContext.Provider>');
	}

	return todosContext.filters;
};

export const useSortBy = (): string => {
	const todosContext = useContext(TodosContext);

	if (!todosContext) {
		throw new Error('useSortBy has to be used within <TodoContext.Provider>');
	}

	return todosContext.sortBy;
};

export const useFetchTasks = (): any => {
	const todosContext = useContext(TodosContext);

	if (!!todosContext) {
		return todosContext.fetchTasks;
	} else {
		throw new Error(
			'useFetchTasks has to be used within <TodoContext.Provider>',
		);
	}
};

export const useSaveFilters = (): any => {
	const todosContext = useContext(TodosContext);

	if (!todosContext) {
		throw new Error(
			'useSaveFilters has to be used within <TodoContext.Provider>',
		);
	}

	return todosContext.saveFilters;
};

export const useSaveSortBy = (): any => {
	const todosContext = useContext(TodosContext);

	if (!todosContext) {
		throw new Error(
			'useSaveSortBy has to be used within <TodoContext.Provider>',
		);
	}

	return todosContext.saveSortBy;
};

export const TodosProvider = ({ children }: TodosProviderType) => {
	const [tasks, setTasks] = useState<IAPITask[]>([]);
	const [filters, setFilters] = useState<string[]>([]);
	const [sortBy, setSortBy] = useState<string>(
		scheduledTodosFeatureFlag ? 'Date' : '',
	);

	const fetchTasks = () => {
		const savedFilterBy = localStorage.getItem('filter_todos');
		const savedSortBy = localStorage.getItem('sort_todos');
		if (savedFilterBy) {
			setFilters(savedFilterBy.split(','));
		}
		if (savedSortBy) {
			setSortBy(savedSortBy);
		}
		axiosClient
			.get('/web/task', {
				params: {
					filter_by: savedFilterBy,
					sort_by: savedSortBy,
				},
			})
			.then((res) => {
				setTasks(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const saveFilters = (selectedFilters: string[]) => {
		localStorage.setItem('filter_todos', `${selectedFilters}`);
		setFilters(selectedFilters);
	};

	const saveSortBy = (selectedSortBy: string) => {
		localStorage.setItem('sort_todos', selectedSortBy);
		setSortBy(selectedSortBy);
	};

	return (
		<TodosContext.Provider
			value={{ tasks, filters, sortBy, fetchTasks, saveFilters, saveSortBy }}>
			{children}
		</TodosContext.Provider>
	);
};
