interface IPropTypes {
	first_name: string;
}

export default function MemberName({ first_name }: IPropTypes) {
	return (
		<p className="text-center mt-1 overflow-hidden whitespace-nowrap">
			{first_name.endsWith(' ') ? first_name.slice(0, -1) : first_name}
		</p>
	);
}
