import dayjs from 'dayjs';
import { IAPITask, IAPIFamilyMember } from 'utils/types';

// Filter
export const FILTER_OPTIONS = [
	'Today',
	'Tomorrow',
	'Next 7 Days',
	'Next 30 Days',
	'No Date',
	'Overdue',
	'Recurring',
	'Done',
];

// Sort
export const SORT_OPTIONS = ['Date', 'Newest', 'Priority'];

// Schedule
export const checkIsPastDue = (dueAt: dayjs.Dayjs): boolean =>
	dueAt.isBefore(dayjs());

export const isTaskScheduled = (task: IAPITask): boolean =>
	!!task.due_at && !task.completed_at;

export const isValidStreak = (streak: number = 0): boolean => streak > 1;

// Tracking
export const getProfileType = (
	members: IAPIFamilyMember[],
	userId?: number,
): string => {
	if (userId) {
		const isResponsibleAdult = members
			.filter((member) => userId === member.id)
			.map((member) => member.is_responsible_adult);
		return isResponsibleAdult ? 'adult' : 'kid';
	}
	return 'shared';
};
