import dayjs from 'dayjs';
import create from 'zustand';

interface ISelectedDateStore {
	selectedDate: dayjs.Dayjs;

	selectDate: (date: dayjs.Dayjs) => void;
}

const useSelectedDate = create<ISelectedDateStore>((set, get) => ({
	selectedDate: dayjs(),

	selectDate: (date) => {
		set((state) => ({
			...state,
			selectedDate: date,
		}));
	},
}));

export default useSelectedDate;
