import { useState } from 'react';
import dayjs from 'dayjs';

import { IAPIFamilyMember, IAPIRecurrenceDetails, IAPITask } from 'utils/types';
import FormLayout from 'components/Forms/FormLayout';
import FormHeading, { FormRow } from 'components/Forms/FormHeading';
import Toggle from 'components/Buttons/Toggle';
import Notes from 'components/Forms/Notes';
import TitleInput from 'components/Forms/TitleInput';
import axiosClient from 'utils/axiosClient';
import SelectDueDate from 'components/CompanionApp/Todos/Schedule/SelectDueDate';
import SelectRecurrence from 'components/CompanionApp/Todos/Recurrence/SelectRecurrence';
import NewConfirmationLayout from 'components/Modals/Confirmations/NewConfirmationLayout';
import AssigneeGroup from 'components/CompanionApp/Todos/Assignees/AssigneeGroup';
import Line from 'components/Reusable/Line';

interface IPropTypes {
	task: IAPITask;
	members: IAPIFamilyMember[];
	handleToggleEdit: () => void;
	handleNotification: (arg0: string) => void;
	fetchTasks: () => void;
}

export interface IFormState {
	title: string;
	notes: string;
}

export default function EditTodo({
	task,
	members,
	handleToggleEdit,
	handleNotification,
	fetchTasks,
}: IPropTypes) {
	const [isPriority, setIsPriority] = useState<boolean>(task.is_priority);
	const [dueAt, setDueAt] = useState<dayjs.Dayjs | undefined>(
		task.due_at ? dayjs(task.due_at) : undefined,
	);
	const [formState, setFormState] = useState<IFormState>({
		title: `${task.subject}`,
		notes: `${task.description}`,
	});
	const [formTitleError, setFormTitleError] = useState<boolean>(false);
	const [assigneeID, setAssigneeID] = useState<number | undefined>(
		task.user_id,
	);

	const [recurrenceDetails, setRecurrenceDetails] = useState<
		IAPIRecurrenceDetails | undefined
	>(task.recurrence_details);

	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

	function handleInputChange(e: {
		currentTarget: { name: string; value: string };
	}) {
		setFormState({
			...formState,
			[e.currentTarget.name]: e.currentTarget.value,
		});
		setFormTitleError(false);
	}

	async function editTodo() {
		const response = await axiosClient.put(`/web/task/edit/${task.id}`, {
			subject: formState.title,
			description: formState.notes,
			is_priority: isPriority,
			due_at: dueAt,
			user_id: assigneeID,
			recurrence_rule_id: task.recurrence_rule_id,
			recurrence_details: recurrenceDetails,
		});
		if (response.status === 200) {
			fetchTasks();
		}
	}
	async function handleEditTodo() {
		if (!formTitleError) {
			try {
				editTodo();
			} catch (error) {}
			handleToggleEdit();
			handleNotification('To-do edited!');
		}
	}

	function handleSave() {
		const shouldConfirmBeforeSaving = !!task.recurrence_rule_id;

		if (formState.title === '') {
			setFormTitleError(true);
		} else {
			if (shouldConfirmBeforeSaving) {
				setShowConfirmation(true);
			} else {
				handleEditTodo();
			}
		}
	}

	return (
		<div className="h-screen w-screen">
			<FormLayout
				className="absolute left-0 top-0 bg-white z-10"
				label="Save"
				handleClick={handleSave}>
				<div className="w-full px-6">
					<button
						className="text-rebrand-teal font-semibold"
						onClick={handleToggleEdit}>
						Cancel
					</button>
				</div>

				<TitleInput
					handleInputChange={handleInputChange}
					value={formState.title}
					error={formTitleError}
				/>

				<Line />

				<FormHeading label="Priority">
					<Toggle
						isToggledOn={isPriority}
						handleToggleSwitch={() => setIsPriority(!isPriority)}
					/>
				</FormHeading>

				<Line />

				<>
					<FormHeading label="Assigned to" />
					<AssigneeGroup
						members={members}
						selection={[assigneeID]}
						setSelection={setAssigneeID}
						isSingleSelection={true}
					/>
				</>

				<Line />

				<FormRow>
					<SelectDueDate
						setDueAt={(date?: dayjs.Dayjs) => setDueAt(date)}
						dueAt={dueAt}
						disabled={!!recurrenceDetails}
					/>
					{!!task.recurrence_rule_id && (
						<SelectRecurrence
							setRecurrenceDetails={(
								newRecurrenceDetails?: IAPIRecurrenceDetails,
							) => setRecurrenceDetails(newRecurrenceDetails)}
							recurrenceDetails={recurrenceDetails}
							setDueAt={(newDueAt?: dayjs.Dayjs) => setDueAt(newDueAt)}
							dueAt={dueAt}
							disabled={true} // disabled until recurrence rule can be removed when editing
						/>
					)}
				</FormRow>

				<Line />

				<FormRow label="Notes">
					<Notes value={formState.notes} onInputChange={handleInputChange} />
				</FormRow>
			</FormLayout>
			<NewConfirmationLayout
				open={showConfirmation}
				title="Edit Recurring To-do"
				description="This will edit this and all future instances of this to-do."
				handleCancel={() => setShowConfirmation(false)}
				handleConfirm={handleEditTodo}
			/>
		</div>
	);
}
