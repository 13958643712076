import { Modal } from '@mui/material';
import ImageEditor, { EditorSettings } from 'components/Images/ImageEditor';
import React from 'react';

interface ImageUploadModalProps {
	open: boolean;
	onClose: () => void;
	rawImage: File | undefined;
	error: string;
	setImage: (dataUrl: string) => void;
	cancelUpload: () => void;
	editorSettings?: EditorSettings;
}

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({
	open,
	onClose,
	rawImage,
	error,
	setImage,
	cancelUpload,
	editorSettings = {},
}) => (
	<Modal
		open={open}
		onClose={onClose}
		sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}>
		<div className="bg-white w-full mx-6 px-6 py-4 shadow-xl sm:p-6 rounded-lg overflow-scroll">
			<h2 className="text-lg font-semibold mb-3">Adjust Photo</h2>
			{rawImage && !error && (
				<ImageEditor
					handleClose={onClose}
					image={rawImage}
					setImage={setImage}
					cancelUpload={cancelUpload}
					editorSettings={editorSettings}
				/>
			)}
			{error && (
				<div className="flex flex-col w-full justify-center items-center mt-4">
					<p className="text-rebrand-error font-bold rounded-full mb-4">
						{error}
					</p>
					<button
						className="text-medium-grey text-white px-4 py-2 rounded-lg"
						onClick={onClose}>
						Close
					</button>
				</div>
			)}
		</div>
	</Modal>
);

export default ImageUploadModal;
