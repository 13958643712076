import { FC, ReactNode } from 'react';

interface IFormRowProps {
	label?: string;
	error?: boolean | null;
	className?: string;
	children?: ReactNode;
	headerChildren?: ReactNode;
}

export const FormRow: FC<IFormRowProps> = ({
	label,
	error,
	className,
	children,
	headerChildren,
}) => (
	<>
		{label ? (
			<FormHeading label={label} error={error} className={className}>
				{headerChildren}
			</FormHeading>
		) : (
			<div className="mt-4" />
		)}
		<div className="px-6 pb-4 w-full">{children}</div>
	</>
);

interface IFormHeadingProps {
	children?: ReactNode;
	label?: string;
	error?: boolean | null;
	className?: string;
}

const FormHeading: FC<IFormHeadingProps> = ({
	children,
	label,
	error,
	className = 'px-6 py-4',
}) => (
	<div className={`w-full flex justify-between items-center ${className}`}>
		{(label || error) && (
			<div className="flex flex-row items-baseline justify-start">
				{label && <h6 className="font-semibold">{label}</h6>}
				{error && <p className="text-xxs text-rebrand-error ml-2">Required</p>}
			</div>
		)}
		{children}
	</div>
);

export default FormHeading;
