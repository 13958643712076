import { ReactComponent as ForwardArrow } from 'assets/Icons/Arrows/forward-arrow.svg';
import { ReactComponent as BackwardArrow } from 'assets/Icons/Arrows/back-arrow.svg';

interface IPropTypes {
	arrowDirection: 'back' | 'forward';
	onClick?: () => void;
}

export default function NavigationArrow({
	arrowDirection,
	onClick,
}: IPropTypes) {
	return (
		<button
			onClick={onClick}
			className="flex content-center h-full fill-current text-rebrand-teal-text">
			{arrowDirection === 'forward' ? <ForwardArrow /> : <BackwardArrow />}
		</button>
	);
}
