import { useContext, useState } from 'react';
import dayjs from 'dayjs';

import axiosClient from 'utils/axiosClient';
import Checkbox from 'components/CheckBoxes/CheckBox';
import { IAPIFamilyMember, IAPITask } from 'utils/types';
import ViewTodo from 'components/CompanionApp/Todos/ViewTodo';
import EditTodo from 'components/CompanionApp/Todos/EditTodo';
import TodoIcons from 'components/CompanionApp/Todos/TodoIcons';
import { DueDateLabel } from 'components/CompanionApp/Todos/Schedule/DueDateLabel';
import { isTaskScheduled } from 'utils/todoHelpers';
import { scheduledTodosFeatureFlag } from 'utils/variables';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { UpdateNotificationContext } from 'contexts/NotificationContext';

interface IPropTypes {
	task: IAPITask;
	members: IAPIFamilyMember[];
	fetchTasks: () => void;
	handleNotification: (arg0: string) => void;
}

export default function Todo({
	task,
	members,
	fetchTasks,
	handleNotification,
}: IPropTypes) {
	const updateNotification = useContext(UpdateNotificationContext);

	const [isChecked, setIsChecked] = useState<boolean>(!!task.completed_at);
	const [view, setView] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);

	const shouldShowDueDate = scheduledTodosFeatureFlag && isTaskScheduled(task);

	const toggleView = () => setView(!view);

	function handleToggleEdit() {
		setEdit(!edit);
		if (!edit) {
			handleClosePopup();
		}
	}

	function handleClosePopup() {
		setView(false);
	}

	async function handleUndo() {
		await axiosClient
			.post(`/web/task/undo/${task.id}`)
			.then(() => fetchTasks())
			.catch(() => {
				setIsChecked(!!task.completed_at);
				updateNotification({
					message: 'This to-do cannot be undone',
					showNotification: true,
				});
			});
	}

	async function handleComplete() {
		await axiosClient
			.post(`/web/task/complete/${task.id}`)
			.then(() => fetchTasks())
			.catch(() => {
				setIsChecked(!!task.completed_at);
				updateNotification({
					message:
						'This to-do cannot be completed, please contact our support team',
					showNotification: true,
				});
			});
	}

	function handleCheck() {
		setIsChecked(!isChecked);
		if (!!task.completed_at) {
			handleUndo();
		} else {
			handleComplete();
		}
	}

	return (
		<div className="items-center flex flex-col w-full px-6 mb-4">
			<SlideUpDrawer open={view} onClose={toggleView}>
				<ViewTodo
					task={task}
					members={members}
					handleToggleEdit={handleToggleEdit}
				/>
			</SlideUpDrawer>
			{/*@todo we should refactor this to use the router*/}
			{edit && (
				<EditTodo
					task={task}
					members={members}
					handleToggleEdit={handleToggleEdit}
					handleNotification={handleNotification}
					fetchTasks={fetchTasks}
				/>
			)}

			<div className="w-full">
				<div className="flex w-full items-start">
					<Checkbox isChecked={isChecked} onClick={handleCheck} />

					<button
						className="flex flex-col w-full ml-2 overflow-clip"
						onClick={toggleView}>
						<div className="flex items-center overflow-hidden relative w-full pr-6">
							<p
								className={`text-sm text-left truncate ${
									isChecked && 'text-rebrand-grey-dark'
								}`}>
								{task.subject}
							</p>
							<TodoIcons task={task} members={members} />
						</div>

						{shouldShowDueDate && <DueDateLabel dueAt={dayjs(task.due_at)} />}
					</button>
				</div>
			</div>
		</div>
	);
}
