import { ReactNode } from 'react';

interface IPropTypes {
	children: ReactNode;
}

export default function WithoutHeaderLayout({ children }: IPropTypes) {
	return (
		<div className="fixed flex flex-col h-full w-full lg:w-3/4">
			<div className="bg-white overflow-scroll items-center flex flex-col w-full h-full lg:py-24">
				{children}
			</div>
		</div>
	);
}
