import TextareaAutosize from 'react-textarea-autosize';

interface IPropTypes {
	onInputChange?: (e: {
		currentTarget: { name: string; value: string };
	}) => void;
	value: string | undefined;
	disabled?: boolean;
	className?: string;
}

export default function Notes({
	onInputChange,
	value,
	disabled,
	className,
}: IPropTypes) {
	return (
		<TextareaAutosize
			name="notes"
			placeholder="Tap to enter notes"
			onChange={onInputChange}
			defaultValue={value}
			className={`mb-2 w-full text-sm text-primary-extraDark border-0 focus:outline-none bg-white resize-none ${className}`}
			minRows={1}
			maxRows={10}
			readOnly={disabled}
		/>
	);
}
