import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ReactComponent as GoogleLogo } from 'assets/rebrand/google-logo.svg';

const LogoButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	justifyContent: 'center',
	position: 'relative',
	paddingLeft: theme.spacing(4),

	// Absolute positioning of the logo
	'& .MuiButton-startIcon': {
		position: 'absolute',
		left: theme.spacing(2),
		marginRight: '0px',
	},
}));

interface IGoogleButtonProps {
	googleAuthLink: string;
}

const GoogleButton: FC<IGoogleButtonProps> = ({ googleAuthLink }) => (
	<LogoButton
		variant="outlined"
		color="primary"
		startIcon={<GoogleLogo />}
		href={googleAuthLink}
		fullWidth>
		Continue with Google
	</LogoButton>
);

export default GoogleButton;
