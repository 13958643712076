import React from 'react';
import AddAssignee from 'components/CompanionApp/Todos/Assignees/AddAssignee';
import { IFamilyMember } from 'utils/types';

interface IMemberSelectionProps {
	members: IFamilyMember[];
	selectedMembers: IFamilyMember[];
	onMemberSelectionChange: (member: IFamilyMember) => void;
}

const MemberSelection: React.FC<IMemberSelectionProps> = ({
	members,
	selectedMembers,
	onMemberSelectionChange,
}) => (
	<div className="w-full flex overflow-x-auto space-x-4">
		{members.map((member) => (
			<AddAssignee
				key={`avatar-${member.id}-in-add-todo`}
				onSelectionChange={() => onMemberSelectionChange(member)}
				isSelected={
					selectedMembers
						? selectedMembers.some((u) => u.id === member.id)
						: false
				}
				avatar_file_name={member.avatarFileName}
				first_name={member.firstName}
			/>
		))}
	</div>
);

export default MemberSelection;
