import TextBox from 'components/Forms/TextBox';
import dayjs from 'dayjs';

interface IPropTypes {
	handleClick: () => void;
	isAllDayEvent: boolean;
	dateTime: dayjs.Dayjs | undefined;
	disabled?: boolean;
}

const getDisplayedText = (
	isAllDayEvent: boolean,
	dateTime: dayjs.Dayjs | undefined,
): string => {
	if (isAllDayEvent && !!dateTime) {
		return dateTime.format('MMM D, YYYY');
	}
	if (!isAllDayEvent && !!dateTime) {
		return `${dateTime.format('MMM D, YYYY')} at ${dateTime.format('h:mma')}`;
	}
	return 'Add date';
};

export default function DateTimeDisplay({
	handleClick,
	isAllDayEvent,
	dateTime,
	disabled,
}: IPropTypes) {
	const displayedText = getDisplayedText(isAllDayEvent, dateTime);

	return (
		<button onClick={handleClick} disabled={disabled}>
			<TextBox text={displayedText} />
		</button>
	);
}
