import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRedirectScreenBasedOnAuth } from 'hooks/useRedirectScreenBasedOnAuth';
import TextContainer from 'components/onboarding/TextContainer';
import useCurrentUser from 'stores/currentUser';
import analytics from 'utils/segmentClient';

export default function Landing() {
	const navigate = useNavigate();
	const { user, deviceSerialId, isAuthenticated, isCurrentUserFetched } =
		useCurrentUser((store) => ({
			user: store.user,
			deviceSerialId: store.deviceSerialId,
			isAuthenticated: store.isAuthenticated,
			isCurrentUserFetched: store.isCurrentUserFetched,
		}));
	const redirectScreen = useRedirectScreenBasedOnAuth(isAuthenticated);

	const [windowSize, setWindowSize] = useState(getWindowSize());

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	function handleWindowResize() {
		setWindowSize(getWindowSize());
	}

	useEffect(() => {
		if (isCurrentUserFetched) {
			handleWindowResize();
			if (isAuthenticated) {
				redirectScreen();
				analytics.identify(`user-${user?.id}`, {
					serialId: deviceSerialId,
					email: user?.email,
				});
				analytics.track('Device', {
					type:
						windowSize.innerWidth < 500 ||
						windowSize.innerHeight < 500 ||
						windowSize.innerHeight > windowSize.innerWidth
							? 'mobile'
							: 'desktop',
					userId: `user-${user?.id}`,
				});
			} else {
				navigate('/login');
			}
		}
	}, [isCurrentUserFetched, isAuthenticated, redirectScreen]);

	return (
		<>
			<TextContainer textTitle="Wait a second please..." />
		</>
	);
}
