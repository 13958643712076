import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

interface IDay {
	thisDate: dayjs.Dayjs;
	thisMonth: number;
	activeDate: dayjs.Dayjs;
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	onSelect: (date: dayjs.Dayjs) => void;
}

export default function DatePickerCell({
	thisDate,
	thisMonth,
	activeDate,
	startDate,
	endDate,
	onSelect,
}: IDay) {
	const calendarDay = thisDate.format('YYYY-MM-DD');

	const first = startDate.format('YYYY-MM-DD');
	const last = endDate.format('YYYY-MM-DD');

	const currentMonth = activeDate.format('M');
	const calendarMonth = (thisMonth + 1).toString();

	let textColor = '#003648';
	if (last === calendarDay) {
		textColor = 'white';
	} else if (calendarMonth !== currentMonth) {
		textColor = '#EFF5F6';
	}

	// background color of circle around selected dates
	let circleColor = 'transparent';
	if (first === calendarDay && first !== last) {
		circleColor = '#EFF5F6';
	} else if (last === calendarDay) {
		circleColor = '#008492';
	}

	// background fill for date range
	let rangeColor = 'transparent';
	let rangeWidth = 'calc(100vw / 7)';
	let align = 'center';
	if (first === calendarDay && first !== last) {
		rangeColor = '#F6F9F9';
		rangeWidth = 'calc(100vw / 7)';
		align = 'flex-start';
	} else if (last === calendarDay && first !== last) {
		rangeColor = '#F6F9F9';
		rangeWidth = 'calc((100vw / 7) / 2)';
		align = 'flex-end';
	} else if (dayjs(calendarDay).isBetween(first, last)) {
		rangeColor = '#F6F9F9';
	}

	return (
		<div className="flex items-end h-full justify-center">
			<button
				onClick={() => onSelect(thisDate)}
				style={{
					borderRadius: 50,
					backgroundColor: circleColor,
					width: 24,
					height: 24,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: textColor,
					fontSize: 14,
				}}>
				{thisDate.date()}
			</button>
		</div>
	);
}
