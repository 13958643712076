import { useNavigate } from 'react-router-dom';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import analytics from 'utils/segmentClient';
import ConfirmationModalLayout from 'components/Modals/ConfirmationModalLayout';

interface IPropTypes {
	open: boolean;
	handleToggleModal: () => void;
	eventId: number;
}

export default function DeleteConfirmation({
	open,
	handleToggleModal,
	eventId,
}: IPropTypes) {
	const navigate = useNavigate();
	const user = useCurrentUser((store) => store.user);

	function deleteSingleEventHandler() {
		axiosClient.delete(`/web/event/${eventId}`).then((res) => {
			if (res.status === 200) {
				analytics.track('Event deleted', {
					from: 'web app',
					userId: `user-${user?.id}`,
				});
				handleToggleModal();
				navigate('/calendar');
			}
		});
	}

	return (
		<ConfirmationModalLayout
			open={open}
			title="Delete Event"
			handleCancel={handleToggleModal}
			handleConfirm={deleteSingleEventHandler}
			text="Are you sure you want to delete this event?"
		/>
	);
}
