import { FC, ReactNode } from 'react';
import { Drawer, DrawerProps } from '@mui/material';

interface ISlideUpDrawerProps extends DrawerProps {
	height?: string;
	maxHeight?: string;
	children?: ReactNode;
}

const SlideUpDrawer: FC<ISlideUpDrawerProps> = ({
	open,
	onClose,
	height = undefined,
	maxHeight = '80%',
	children,
}) => (
	<Drawer
		anchor="bottom"
		open={open}
		onClose={onClose}
		sx={{
			'& .MuiDrawer-paper': {
				height: height,
				maxHeight: maxHeight,
				borderTopLeftRadius: '8px',
				borderTopRightRadius: '8px',
			},
		}}>
		{children}
	</Drawer>
);

export default SlideUpDrawer;
