import { useEffect, useState } from 'react';

import CodeInput from 'components/Forms/CodeInput';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import PageTitle from 'components/Reusable/PageTitle';
import { Button } from '@mui/material';
import SetupContainer from 'components/Layouts/SetupContainer';
import { useSetupContext } from '../SetupLayout';

interface IPhoneNumber {
	value: string;
}

interface IPayload {
	contactId: string;
	isVerified: boolean;
}

enum VerifyPhoneErrorStates {
	REQUIRED = 'Required',
	INCORRECT_CODE = 'Incorrect code',
}

export default function VerifyPhoneNumber() {
	const { isAuthenticated } = useCurrentUser((store) => ({
		isAuthenticated: store.isAuthenticated,
	}));
	const { nextStep, payload } = useSetupContext();
	const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>();

	const [otp, setOtp] = useState(new Array(6).fill(''));
	const [verifyPhoneErrorState, setVerifyPhoneErrorState] =
		useState<VerifyPhoneErrorStates>();

	useEffect(() => {
		async function inner() {
			const response = await axiosClient.get(
				`/onboarding/user/my/phone_number/${payload.contactId}`,
			);
			if (response.status === 200) {
				setPhoneNumber({
					value: response.data.value,
				});
			}
		}

		if (isAuthenticated && payload?.contactId) {
			inner();
		}
	}, [isAuthenticated, payload?.contactId]);

	useEffect(() => {
		if (payload?.isVerified) {
			nextStep();
		}
	}, [payload?.isVerified]);

	async function handleSubmit() {
		if (otp.join('').length < 6) {
			setVerifyPhoneErrorState(VerifyPhoneErrorStates.REQUIRED);
			return;
		}

		try {
			const response = await axiosClient.post(
				`/onboarding/user/my/phone_number/${payload?.contactId}/verify`,
				{
					code: otp.join(''),
				},
			);
			if (response.status === 200) {
				nextStep();
			}
		} catch {
			setVerifyPhoneErrorState(VerifyPhoneErrorStates.INCORRECT_CODE);
		}
	}

	// Sends a new verification code to phone number incase first one was lost
	async function handleResend() {
		await axiosClient.post('/onboarding/user/my/phone_number', {
			value: phoneNumber?.value,
		});
		alert('Code resent');
	}

	return (
		<SetupContainer>
			<PageTitle
				title="Enter verification code"
				subtitle={`We sent a 6-digit code to ${phoneNumber?.value}.`}
			/>

			<form onSubmit={handleSubmit} className="mt-6">
				<CodeInput
					code={otp}
					setCode={setOtp}
					errorText={verifyPhoneErrorState}
				/>
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Continue
				</Button>
			</form>

			<div className="text-xxs text-center mt-4">
				Didn't receive it?&nbsp;
				<button
					onClick={handleResend}
					className="font-medium text-rebrand-teal">
					Resend Code
				</button>
			</div>
		</SetupContainer>
	);
}
