import { useNavigate, useLocation } from 'react-router-dom';

export function useRedirectScreenBasedOnAuth(isAuthenticated: boolean) {
	const navigate = useNavigate();
	const location = useLocation();
	const defaultScreen = '/calendar';
	const loginScreen = '/login';

	const storeLatestScreen = () => {
		localStorage.setItem('latestScreen', location.pathname.toString());
	};

	const restoreLatestScreen = () => {
		return localStorage.getItem('latestScreen');
	};

	const resetLatestScreen = () => {
		localStorage.setItem('latestScreen', '');
	};

	const redirectToLatestScreen = () => {
		const latestScreen = restoreLatestScreen();
		if (latestScreen) {
			navigate(latestScreen);
			resetLatestScreen();
		} else {
			navigate(defaultScreen);
		}
	};

	const redirectToLoginScreen = () => {
		storeLatestScreen();
		navigate(loginScreen);
	};

	return isAuthenticated ? redirectToLatestScreen : redirectToLoginScreen;
}
