import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CodeInput from 'components/Forms/CodeInput';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import PageTitle from 'components/Reusable/PageTitle';
import { Button } from '@mui/material';
import { ONBOARDING_SCREENS } from './onboardingFlow';

interface IPhoneNumber {
	value: string;
}

enum VerifyPhoneErrorStates {
	REQUIRED = 'Required',
	INCORRECT_CODE = 'Incorrect code',
}

export default function VerifyPhoneNumber() {
	const isAuthenticated = useCurrentUser((store) => store.isAuthenticated);
	const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>();
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [otp, setOtp] = useState(new Array(6).fill(''));
	const [verifyPhoneErrorState, setVerifyPhoneErrorState] =
		useState<VerifyPhoneErrorStates>();

	useEffect(() => {
		async function inner() {
			const response = await axiosClient.get(
				`/onboarding/user/my/phone_number/${id}`,
			);
			if (response.status === 200) {
				setPhoneNumber({
					value: response.data.value,
				});
			}
		}

		if (isAuthenticated) {
			inner();
		}
	}, [isAuthenticated, id]);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	async function handleSubmit() {
		if (otp.join('').length < 6) {
			setVerifyPhoneErrorState(VerifyPhoneErrorStates.REQUIRED);
			return;
		}

		try {
			const response = await axiosClient.post(
				`/onboarding/user/my/phone_number/${id}/verify`,
				{
					code: otp.join(''),
				},
			);
			if (response.status === 200) {
				navigate(
					`${
						location.pathname.startsWith('/onboard')
							? '/onboard/check-timezone'
							: '/join-family/complete'
					}`,
					{ replace: true },
				);
			}
		} catch {
			setVerifyPhoneErrorState(VerifyPhoneErrorStates.INCORRECT_CODE);
		}
	}

	// Sends a new verification code to phone number incase first one was lost
	async function handleResend() {
		await axiosClient.post('/onboarding/user/my/phone_number', {
			value: phoneNumber?.value,
		});
		alert('Code resent');
	}

	return (
		<OnboardingStepsLayout progress={ONBOARDING_SCREENS.verifyPhone.progress}>
			<PageTitle
				title="Enter verification code"
				subtitle={`We sent a 6-digit code to ${phoneNumber?.value}.`}
			/>

			<form onSubmit={handleSubmit} className="mt-6">
				<CodeInput
					code={otp}
					setCode={setOtp}
					errorText={verifyPhoneErrorState}
				/>
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Continue
				</Button>
			</form>

			<div className="text-xxs text-center mt-4">
				Didn't receive it?&nbsp;
				<button
					onClick={handleResend}
					className="font-medium text-rebrand-teal">
					Resend Code
				</button>
			</div>
		</OnboardingStepsLayout>
	);
}
