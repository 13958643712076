import animationData from '../../components/lotties/circular-loading-bar.json';
import TextContainer from 'components/onboarding/TextContainer';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useCurrentUser from 'stores/currentUser';

export default function Landing() {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const {
		isAuthenticated,
		isCurrentUserFetched,
		fetchCurrentUser,
		signUserOut,
	} = useCurrentUser((store) => ({
		isAuthenticated: store.isAuthenticated,
		isCurrentUserFetched: store.isCurrentUserFetched,
		fetchCurrentUser: store.fetchCurrentUser,
		signUserOut: store.signUserOut,
	}));

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	useEffect(() => {
		fetchCurrentUser();
	}, []);

	useEffect(() => {
		async function inner() {
			if (isCurrentUserFetched) {
				// We save the code that we received in URL parameters to local storage to read later in next request
				if (location.pathname.startsWith('/onboard')) {
					if (searchParams.has('ot')) {
						const onboardingToken = searchParams.get('ot');
						if (!!onboardingToken) {
							window.localStorage.setItem('ot', onboardingToken);
							await signUserOut();
							navigate('/onboard/authenticate');
						}
					} else if (isAuthenticated) {
						navigate('/onboard/activation');
					} else if (searchParams.has('error-app-exists')) {
						navigate('/onboard/authenticate-error');
					}
				} else if (location.pathname.startsWith('/join-family')) {
					if (searchParams.has('it')) {
						const inviteToken = searchParams.get('it');
						if (!!inviteToken) {
							window.localStorage.setItem('it', inviteToken);
							await signUserOut();
							navigate('/join-family/authenticate');
						}
					} else if (isAuthenticated) {
						navigate('/join-family/profile');
					} else if (searchParams.has('error-app-exists')) {
						navigate('/join-family/authenticate-error');
					}
				} else if (location.pathname.startsWith('/re-auth')) {
					if (isAuthenticated) {
						navigate('/manage-calendar');
					} else if (searchParams.has('error-app-exists')) {
						navigate('/re-auth/authenticate-error');
					} else {
						navigate('/re-auth/authenticate');
					}
				}
			}
		}
		inner();
	}, [isCurrentUserFetched, isAuthenticated]);

	if (!searchParams.has('ot') && !isAuthenticated) {
		return (
			<>
				<TextContainer
					textTitle="Oops we have a problem"
					textBody="It seems you have not started the onboarding from a Hearth device"
				/>
			</>
		);
	}

	return (
		<div className=" absolute flex flex-col items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
			<div className="w-40">
				<Lottie
					options={defaultOptions}
					height={'100%'}
					width={'100%'}
					isClickToPauseDisabled={true}
				/>
			</div>

			<p className="text-sm font-normal">Loading...</p>
		</div>
	);
}
