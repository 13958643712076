import { Link } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-common-types';
import google from 'assets/Icons/Calendar/googleLogo.png';

interface IButton {
	// Design related parameters
	isFilled?: boolean;
	isBorder?: boolean;
	// TODO: Discuss action item color schemes
	colorScheme?: 'primary' | 'secondary' | 'error' | 'neutral' | 'rebrand-teal';
	isUnderlinedText?: boolean;
	isDisabled?: boolean;
	type?: 'button' | 'submit';
	linkTo?: string;
	linkState?: any;
	label: string;
	onClick?: () => void;
	icon?: IconName;
	image?: boolean;
	position?: 'inline-block' | 'absolute';
}

const getGoogleIcon = () => {
	return (
		<img
			src={google}
			alt="google icon"
			style={{ height: 18, width: 18, marginTop: 3, marginBottom: 3 }}
			className="absolute left-4"
		/>
	);
};

export default function Button({
	isFilled = true,
	isBorder = false,
	colorScheme = 'rebrand-teal',
	isUnderlinedText = false,
	isDisabled = false,
	type = 'button',
	linkTo,
	label,
	onClick,
	image = false,
}: IButton) {
	let className = `block left-0 bottom-36 z-1 w-full py-3 text-center rounded-full font-semibold text-sm`;
	if (isFilled) {
		className = `${className} bg-${colorScheme} my-4 text-white`;
	} else {
		className = `${className} text-${colorScheme}`;
	}
	if (isBorder) {
		className = `${className} border border-${colorScheme}`;
	}
	let buttonText = isUnderlinedText ? 'underline' : 'no-underline';

	if (!!linkTo) {
		const inner = (
			<div className="relative items-center">
				{image ? getGoogleIcon() : null}
				{label}
			</div>
		);

		return linkTo.startsWith('http') ? (
			<a href={linkTo} className={className}>
				{inner}
			</a>
		) : (
			<Link to={linkTo} className={className}>
				{inner}
			</Link>
		);
	}
	return (
		<button
			style={{
				backgroundColor: isFilled ? '#008492' : 'white',
			}}
			type={type}
			className={className}
			onClick={onClick}
			disabled={isDisabled}>
			<div className="relative items-center">
				{image ? getGoogleIcon() : null}
				{label}
			</div>
		</button>
	);
}
