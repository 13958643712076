import React from 'react';
import { AvatarSrc } from 'utils/types';
import { determineFileSrc } from 'utils/avatars';
import DefaultAvatar from 'assets/Images/default-avatar.png';
import Checkmark from 'components/CompanionApp/Todos/Assignees/Checkmark';

interface MemberAvatarProps {
	avatarFilename?: string;
	className?: string;
	avatarSrc?: AvatarSrc;
	showCheckmark?: boolean;
}

const MemberAvatar: React.FC<MemberAvatarProps> = ({
	avatarFilename,
	className = 'w-12 h-12',
	avatarSrc = AvatarSrc.REMOTE,
	showCheckmark = false,
}) => {
	const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
		event.currentTarget.src = DefaultAvatar;
	};

	return (
		<div className={`relative rounded-full p-[1px] ${className}`}>
			<img
				className="w-full h-full z-10 rounded-full"
				src={determineFileSrc(avatarSrc, avatarFilename)}
				onError={handleImageError}
				alt="Avatar"
			/>
			{showCheckmark && (
				<div className="absolute bottom-0 right-0 z-10">
					<Checkmark />
				</div>
			)}
		</div>
	);
};

export default MemberAvatar;
