import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { IRoutine, NotificationType } from 'utils/types';
import { actionTypes, useRoutinesDispatch } from 'contexts/RoutinesContext';
import RoutineForm, {
	IRoutineApiParams,
} from 'components/Routines/RoutineForm';
import axiosClient from 'utils/axiosClient';
import { convertKeys, toSnakeCase } from 'utils/responseMapper';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { generateRoutinePayload } from 'utils/routineTracking';
import useCurrentUser from 'stores/currentUser';
import { EventName, trackEvent } from 'utils/segmentClient';
import { addLocalTimezone } from 'utils/dateTimeHelper';
import dayjs from 'dayjs';

interface ILocationState {
	routine: IRoutine;
}

const EditRoutine: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const routineToEdit: IRoutine = (location.state as ILocationState)?.routine;

	if (!routineToEdit) navigate('/routines');

	const updateNotification = useContext(UpdateNotificationContext);
	const dispatch = useRoutinesDispatch();
	const { user } = useCurrentUser((store) => ({
		user: store.user,
	}));

	useEffect(() => {
		dispatch({
			type: actionTypes.SET_ROUTINE_USERS,
			payload: routineToEdit.users,
		});

		dispatch({
			type: actionTypes.SET_ROUTINE_STEPS,
			payload: routineToEdit.steps,
		});
	}, []);

	const editRoutineApi = ({
		routineFormData,
		routineSteps = [],
		routineUsers = [],
	}: IRoutineApiParams) => {
		const { name, recurrenceDetails, image } = routineFormData;

		const userIds = routineUsers.map((user) => user.id);

		if (recurrenceDetails) {
			recurrenceDetails.dtStart = addLocalTimezone(
				dayjs(recurrenceDetails.dtStart),
			);
		}

		trackEvent(
			EventName.RoutineEdited,
			generateRoutinePayload(user, routineUsers, routineFormData, routineSteps),
		);

		axiosClient
			.put(`/web/routines/${routineToEdit.id}`, {
				name: name,
				image: image,
				user_ids: userIds,
				steps: convertKeys(routineSteps, toSnakeCase),
				recurrence_details: convertKeys(recurrenceDetails, toSnakeCase),
			})
			.then(() => {
				dispatch({ type: actionTypes.RESET_ROUTINE_CONTEXT });
				updateNotification({
					message: 'Routine updated!',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
				navigate('/routines');
			})
			.catch(() => {
				updateNotification({
					message: 'Problem updating the routine. Please try again.',
					type: NotificationType.ERROR,
					showNotification: true,
				});
			});
	};

	return <RoutineForm routine={routineToEdit} onSubmit={editRoutineApi} />;
};

export default EditRoutine;
