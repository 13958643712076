import {
	ICurrentUser,
	ICalendar,
	CalendarProviderTypes,
	ICalendarUpdateParams,
	IProfileUser,
} from 'utils/types';
import HearthCalendarDetailsPopup from './HearthCalendarDetailsPopup';
import GoogleCalendarDetailsPopup from './GoogleCalendarDetailsPopup';
import GenericCalendarDetailsPopup from './GenericCalendarDetailsPopup';

interface IProps {
	handleCloseModal: () => void;
	calendar?: 0 | ICalendar | null | undefined;
	updateCalendar: (params: ICalendarUpdateParams) => Promise<void>;
	user?: ICurrentUser;
	assignedUser?: IProfileUser;
	profiles: Map<number | null, IProfileUser>;
	unsubscribeCalendar: (arg0: number) => Promise<void>;
}

export default function CalendarDetailsPopup({
	handleCloseModal,
	calendar,
	updateCalendar,
	user,
	assignedUser,
	profiles,
	unsubscribeCalendar,
}: IProps) {
	if (calendar) {
		switch (calendar.provider) {
			case CalendarProviderTypes.HEARTH:
				return (
					<HearthCalendarDetailsPopup
						handleCloseModal={handleCloseModal}
						calendar={calendar}
						profiles={profiles}
						updateCalendar={updateCalendar}
					/>
				);
			case CalendarProviderTypes.GOOGLE:
				return (
					<GoogleCalendarDetailsPopup
						handleCloseModal={handleCloseModal}
						calendar={calendar}
						updateCalendar={updateCalendar}
						profiles={profiles}
						assignedUser={assignedUser}
						user={user}
					/>
				);
			case CalendarProviderTypes.OUTLOOK:
			case CalendarProviderTypes.ICS:
			case CalendarProviderTypes.ICAL:
				return (
					<GenericCalendarDetailsPopup
						handleCloseModal={handleCloseModal}
						calendar={calendar}
						profiles={profiles}
						updateCalendar={updateCalendar}
						assignedUser={assignedUser}
						user={user}
						unsubscribeCalendar={unsubscribeCalendar}
						provider={calendar.provider}
					/>
				);
			default:
				return null;
		}
	} else {
		return null;
	}
}
