import { avatarUrl } from 'utils/variables';
import { AvatarSrc, IProfilePicture } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import DefaultAvatar from 'assets/Images/default-avatar.png';

type Avatar = string;

export const AVATARS: Avatar[] = [
	'blue-dino1_128.png',
	'blue-dino2_128.png',
	'blue-dino3_128.png',
	'coral-dino1_128.png',
	'coral-dino2_128.png',
	'coral-dino3_128.png',
	'green-dino1_128.png',
	'green-dino2_128.png',
	'green-dino3_128.png',
	'orange-dino1_128.png',
	'orange-dino2_128.png',
	'orange-dino3_128.png',
	'pink-dino1_128.png',
	'pink-dino2_128.png',
	'pink-dino3_128.png',
	'purple-dino1_128.png',
	'purple-dino2_128.png',
	'purple-dino3_128.png',
	'yellow-dino1_128.png',
	'yellow-dino2_128.png',
	'yellow-dino3_128.png',
];

export const SVG_AVATARS: Avatar[] = [
	'01-blue-1.svg',
	'02-green-1.svg',
	'03-purple-1.svg',
	'04-orange-1.svg',
	'05-pink-1.svg',
	'06-teal-1.svg',
	'07-blue-2.svg',
	'08-green-2.svg',
	'09-purple-2.svg',
	'10-orange-2.svg',
	'11-pink-2.svg',
	'12-teal-2.svg',
	'13-blue-3.svg',
	'14-green-3.svg',
	'15-purple-3.svg',
	'16-orange-3.svg',
	'17-pink-3.svg',
	'18-teal-3.svg',
	'19-blue-4.svg',
	'20-green-4.svg',
	'21-purple-4.svg',
	'22-orange-4.svg',
	'23-pink-4.svg',
	'24-teal-4.svg',
];

export function getRandomAvatar(usedAvatars: Avatar[] = []): Avatar {
	const availableAvatars = SVG_AVATARS.filter(
		(avatar) => !usedAvatars.includes(avatar),
	);

	return availableAvatars[Math.floor(Math.random() * availableAvatars.length)];
}

export const determineFileSrc = (
	avatarSrc: AvatarSrc,
	avatarFilename?: string,
) => {
	if (!avatarFilename || avatarFilename === '') return DefaultAvatar;

	switch (avatarSrc) {
		case AvatarSrc.NONE:
			return avatarFilename;
		case AvatarSrc.LOCAL:
			return `/assets/avatars/${avatarFilename}`;
		case AvatarSrc.REMOTE:
			return `${avatarUrl}/${avatarFilename}`;
		case AvatarSrc.DATA_URL:
			return avatarFilename;
	}
};

export async function updateProfilePicture(memberId: number, filename: string) {
	if (!filename) {
		throw new Error('Error uploading picture, image is undefined');
	}

	if (!memberId) {
		throw new Error('Error uploading picture, Member ID is undefined');
	}

	const formData = new FormData();
	const response = await fetch(filename);
	const blob = await response.blob();
	const newFilename = filename || generateFilename();
	const file = new File([blob], newFilename, { type: 'image/png' });
	formData.append('profile_picture', file);

	return axiosClient.put(`/web/upload/profile_picture/${memberId}`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

export function handleProfilePictureChange(
	dataUrl: string,
	updatePicture: (newProfilePicture: IProfilePicture) => void,
	user_id: number,
) {
	updatePicture({
		filename: dataUrl,
		avatarSrc: AvatarSrc.DATA_URL,
	});

	updateProfilePicture(user_id, dataUrl)
		.then(() => {
			console.log('Updated profile picture');
		})
		.catch(() => {
			console.log('Failed to update profile picture');
		});
}

export function generateFilename() {
	const randomString = Math.random().toString(36).substring(2, 10);
	return `${randomString}.png`;
}
