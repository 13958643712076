import {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
} from 'react';
import { IAPIFamilyInvite, IAPIFamilyMember } from 'utils/types';
import axiosClient from 'utils/axiosClient';

interface IFamilyContextProps {
	familyName: string;
	fetchFamilyName: any;
	familyMembers: IAPIFamilyMember[];
	fetchFamilyMembers: any;
	familyInvites: IAPIFamilyInvite[];
	fetchFamilyInvites: any;
}

interface IFamilyProviderProps {
	children: ReactNode;
}

const defaultValue: IFamilyContextProps = {
	familyName: '',
	fetchFamilyName: () => {},
	familyMembers: [],
	fetchFamilyMembers: () => {},
	familyInvites: [],
	fetchFamilyInvites: () => {},
};

export const FamilyContext = createContext<IFamilyContextProps>(defaultValue);

export const useFamilyName = (): string => {
	const familyContext = useContext(FamilyContext);

	if (!familyContext) {
		throw new Error(
			'useFamilyName has to be used within <FamilyContext.Provider>',
		);
	}

	return familyContext.familyName;
};

export const useFetchFamilyName = (): any => {
	const familyContext = useContext(FamilyContext);

	if (!!familyContext) {
		return familyContext.fetchFamilyName;
	} else {
		throw new Error(
			'useFetchFamilyName has to be used within <FamilyContext.Provider>',
		);
	}
};

export const useFamilyMemberList = (): IAPIFamilyMember[] => {
	const familyContext = useContext(FamilyContext);

	if (!familyContext) {
		throw new Error(
			'useFamilyMemberList has to be used within <FamilyContext.Provider>',
		);
	}

	return familyContext.familyMembers;
};

export const useFetchFamilyMembers = (): any => {
	const familyContext = useContext(FamilyContext);

	if (!!familyContext) {
		return familyContext.fetchFamilyMembers;
	} else {
		throw new Error(
			'useFetchFamilyMembers has to be used within <FamilyContext.Provider>',
		);
	}
};

export const useFamilyInviteList = (): IAPIFamilyInvite[] => {
	const familyContext = useContext(FamilyContext);

	if (!familyContext) {
		throw new Error(
			'useFamilyInviteList has to be used within <FamilyContext.Provider>',
		);
	}

	return familyContext.familyInvites;
};

export const useFetchFamilyInvites = (): any => {
	const familyContext = useContext(FamilyContext);

	if (!!familyContext) {
		return familyContext.fetchFamilyInvites;
	} else {
		throw new Error(
			'useFetchFamilyInvites has to be used within <FamilyMembersContext.Provider>',
		);
	}
};

export const FamilyProvider = ({ children }: IFamilyProviderProps) => {
	const [familyName, setFamilyName] = useState<string>('');
	const [familyMembers, setFamilyMembers] = useState<IAPIFamilyMember[]>([]);
	const [familyInvites, setFamilyInvites] = useState<IAPIFamilyInvite[]>([]);

	function fetchFamilyName() {
		axiosClient
			.get('web/family/our/name')
			.then((response) => {
				setFamilyName(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function fetchFamilyMembers() {
		axiosClient
			.get('/web/family/our/member')
			.then((response) => {
				setFamilyMembers(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	function fetchFamilyInvites() {
		axiosClient
			.get('web/family/our/invite')
			.then((response) => {
				setFamilyInvites(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		fetchFamilyName();
		fetchFamilyMembers();
		fetchFamilyInvites();
	}, []);

	return (
		<FamilyContext.Provider
			value={{
				familyName,
				fetchFamilyName,
				familyMembers,
				fetchFamilyMembers,
				familyInvites,
				fetchFamilyInvites,
			}}>
			{children}
		</FamilyContext.Provider>
	);
};
