import React, { FC } from 'react';
import ReusableModal from 'components/Modals/ReusableModal';
import TextButton from 'components/Buttons/TextButton';

interface IConfirmationModalProps {
	open: boolean;
	title: string;
	text?: string;
	primaryButtonLabel?: string;
	secondaryButtonLabel?: string;
	handleCancel: () => void;
	handleConfirm: () => void;
	children?: React.ReactNode;
}
const ConfirmationModalLayout: FC<IConfirmationModalProps> = ({
	open,
	title,
	text,
	primaryButtonLabel = 'Confirm',
	secondaryButtonLabel = 'Cancel',
	handleCancel,
	handleConfirm,
	children,
}) => (
	<ReusableModal open={open} title={title} handleToggleModal={handleCancel}>
		{text && <p className="text-sm">{text}</p>}
		{children}
		<div className="mt-3 mb-2">
			<TextButton onClick={handleCancel} label={secondaryButtonLabel} />
			<TextButton
				onClick={handleConfirm}
				label={primaryButtonLabel}
				isPrimary
			/>
		</div>
	</ReusableModal>
);

export default ConfirmationModalLayout;
