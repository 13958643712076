import shallow from 'zustand/shallow';
import dayjs from 'dayjs';
import useSelectedDate from 'stores/selectedDate';
import { IAPIEvent, IAPIExternalCalendar } from 'utils/types';
import { FC, useCallback, useMemo } from 'react';

interface IDay {
	thisDate: dayjs.Dayjs;
	thisMonth: number;
	activeDate: dayjs.Dayjs;
	events?: IAPIEvent[];
	calendars?: IAPIExternalCalendar[];
}

const MAX_DOTS = 3;

const COLOR_OPTIONS: { [key: string]: string } = {
	red: '#EC001E',
	coral: '#FF6A6F',
	peach: '#FF805A',
	orange: '#FF4C00',
	gold: '#FEBA00',
	lightGreen: '#CAED6A',
	green: '#00A03B',
	naGray: '#F4C0C0',
	blue: '#3B5AFA',
	lightBlue: '#60D2F4',
	lightPurple: '#C89DFD',
	naBlue: '#3B5AFA',
	purple: '#A501D8',
	pink: '#FFA5E3',
	magenta: '#FF32C0',
};

const determineEventColor = (
	event: IAPIEvent,
	calendars?: IAPIExternalCalendar[],
): string => {
	if (event.color) {
		return event.color;
	}

	if (event.calendar_id && calendars) {
		const calendar = calendars.find((cal) => cal.id === event.calendar_id);
		if (calendar && calendar.color) {
			return calendar.color;
		}
	}

	return '#008492';
};

const DayCell: FC<IDay> = ({
	thisDate,
	thisMonth,
	activeDate,
	events,
	calendars,
}) => {
	const { selectedDate, selectDate } = useSelectedDate(
		(store) => ({
			selectedDate: store.selectedDate,
			selectDate: store.selectDate,
		}),
		shallow,
	);

	// Memoized calculations for class names and styles
	const { textColor, circleColor, eventDots } = useMemo(() => {
		const currentDay = dayjs().format('YYYY-MM-DD');
		const calendarDay = thisDate.format('YYYY-MM-DD');
		const currentMonth = activeDate.format('M');
		const calendarMonth = (thisMonth + 1).toString();

		let textColor = 'text-rebrand-teal-text';
		if (selectedDate.format('YYYY-MM-DD') === calendarDay) {
			textColor = 'text-rebrand-white';
		} else if (calendarMonth > currentMonth || calendarMonth < currentMonth) {
			textColor = 'text-rebrand-grey-medium';
		}

		let circleColor = 'bg-white';
		if (
			currentDay === calendarDay &&
			currentDay !== selectedDate.format('YYYY-MM-DD')
		) {
			circleColor = 'bg-rebrand-teal-light';
		} else if (selectedDate.format('YYYY-MM-DD') === calendarDay) {
			circleColor = 'bg-rebrand-teal';
		}

		const eventDots = (events || []).slice(0, MAX_DOTS).map((event) => (
			<div
				key={event.id}
				className="w-1 h-1 rounded-full"
				style={{
					backgroundColor: determineEventColor(event, calendars),
				}}
			/>
		));

		return { textColor, circleColor, eventDots };
	}, [thisDate, thisMonth, activeDate, selectedDate, events, calendars]);

	// Memoized onClick handler
	const handleSelectDate = useCallback(() => {
		const currentDay = dayjs();
		const thisDatetime = thisDate
			.set('hour', currentDay.hour())
			.set('minute', currentDay.minute());
		selectDate(thisDatetime);
	}, [selectDate, thisDate]);

	return (
		<div className="relative flex flex-col items-center justify-center h-full">
			<div className="flex flex-col items-center">
				<button
					aria-label={`Select date ${thisDate.format('YYYY-MM-DD')}`}
					className={`flex justify-center items-center w-6 h-6 rounded-full text-xs p-1 ${textColor} ${circleColor}`} // Make the button size dynamic
					onClick={handleSelectDate}>
					{thisDate.date()}
				</button>
				<div className="mt-1">
					{events && events.length > 0 && (
						<div className="flex space-x-1">{eventDots}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DayCell;
