import { IAPIFamilyMember, IAPITask } from 'utils/types';
import { ReactComponent as PriorityStar } from 'assets/rebrand/star.svg';
import { ReactComponent as Notes } from 'assets/rebrand/hamburger-2.svg';
import { ReactComponent as Recurring } from 'assets/rebrand/recurring.svg';

import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import { FC } from 'react';

interface IPropTypes {
	task: IAPITask;
	members: IAPIFamilyMember[];
}

const TodoIcons: FC<IPropTypes> = ({ task, members }) => {
	const completedBy = members.find(
		(member) => member.id === task.completed_by || member.id === task.user_id,
	);

	return (
		<div className="items-center flex absolute bg-white right-0">
			{task.is_priority && (
				<PriorityStar className="h-5 w-5 ml-1 fill-current text-rebrand-gold" />
			)}
			{task.description && (
				<Notes className="h-5 w-5 ml-1 fill-current text-rebrand-teal-text" />
			)}
			{task.recurrence_rule_id && (
				<Recurring className="h-5 w-5 ml-1 fill-current text-rebrand-teal-text" />
			)}
			{completedBy && (
				<MemberAvatar
					avatarFilename={completedBy.avatar_file_name}
					className={'ml-1 w-6 h-6'}
				/>
			)}
		</div>
	);
};

export default TodoIcons;
