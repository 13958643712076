import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
} from '@mui/material';

import { ITimezoneObject } from 'utils/types';
import {
	getHearthDeviceTimezone,
	getMobileDeviceTimezone,
	combineAvailableTimezones,
} from 'utils/dateTimeHelper';
import analytics from 'utils/segmentClient';
import useCurrentUser from 'stores/currentUser';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ITimezoneSelectProps {
	selectedTimezone: ITimezoneObject | undefined;
	onChange: (timezone: ITimezoneObject) => void;
	showBorder?: boolean;
}

const TimezoneSelect: FC<ITimezoneSelectProps> = ({
	selectedTimezone,
	onChange,
	showBorder = true,
}) => {
	const location = useLocation();
	const user = useCurrentUser((store) => store.user);
	const [mobileDeviceTimezone, setMobileDeviceTimezone] =
		useState<ITimezoneObject>();
	const [hearthDeviceTimezone, setHearthDeviceTimezone] =
		useState<ITimezoneObject>();
	const availableTimezones = combineAvailableTimezones(
		mobileDeviceTimezone,
		hearthDeviceTimezone,
	);
	const isOnboarded = !location.pathname.startsWith('/onboard');

	useEffect(() => {
		// get timezone of user
		getMobileDeviceTimezone(setMobileDeviceTimezone);
		if (isOnboarded) {
			// get timezone of family
			getHearthDeviceTimezone(setHearthDeviceTimezone);
		}
	}, []);

	useEffect(() => {
		// set default selected timezone
		if (mobileDeviceTimezone) {
			onChange(mobileDeviceTimezone);
		}
	}, [mobileDeviceTimezone]);

	const handleChange = (event: SelectChangeEvent) => {
		const selectedTZ = availableTimezones.find(
			(tz) => tz.name === event.target.value,
		);

		if (selectedTZ) {
			onChange(selectedTZ);
		}

		if (isOnboarded) {
			analytics.track('timezone_change', {
				from: 'web app',
				user_id: `user-${user?.id}`,
				user_timezone: mobileDeviceTimezone?.shortName,
				family_timezone: hearthDeviceTimezone?.shortName,
				selected_timezone: selectedTZ?.shortName,
			});
		}
	};

	return (
		<FormControl variant="outlined" fullWidth>
			{showBorder && (
				<InputLabel id="timezone-select-label">Timezone</InputLabel>
			)}
			<Select
				labelId="timezone-select-label"
				variant={showBorder ? 'outlined' : 'standard'}
				id="timezone-select"
				value={selectedTimezone?.name || ''}
				onChange={handleChange}
				label="Timezone"
				displayEmpty>
				{availableTimezones.map((timezone) => (
					<MenuItem key={timezone.name} value={timezone.name}>
						{timezone.shortName}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default TimezoneSelect;
