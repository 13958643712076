import { primaryColor, COLORS } from 'styles/colors';
import { IAPIEvent, IAPIExternalCalendar, IAPIFamilyMember } from 'utils/types';

export const defaultProfileColor = (userId: number | undefined): string => {
	if (!!userId) {
		return COLORS.filter((color) => color !== primaryColor)[userId % 15];
	}
	return primaryColor;
};

export const getProfileColor = (
	members: IAPIFamilyMember[],
	calendar?: IAPIExternalCalendar,
	userId?: number,
): string => {
	const profileColor = members.find((member) => member.id === userId)?.color;
	if (calendar?.assigned_user_id) {
		const assignedUser = members.find(
			(member) => member.id === calendar.assigned_user_id,
		);
		return (
			assignedUser?.color ||
			profileColor ||
			defaultProfileColor(assignedUser?.id)
		);
	}
	if (!!profileColor) {
		return profileColor;
	}
	return defaultProfileColor(userId);
};

export const getCalendarColor = (
	calendarColor?: string | null,
	userColor?: string | null,
	userId?: number,
): string => {
	if (!!calendarColor) {
		return calendarColor;
	}
	if (!!userColor && !calendarColor) {
		return userColor;
	}
	return defaultProfileColor(userId);
};

const getHearthEventBackgroundColor = (
	attendeeCount: number,
	profileColor: string,
	eventColor?: string,
): string => {
	if (!!eventColor) {
		return eventColor;
	}
	if (attendeeCount === 1) {
		return profileColor;
	}
	return primaryColor;
};

const getExternalEventBackgroundColor = (
	profileColor: string,
	calendarColor?: string,
	attendeeUserId?: number,
): string => {
	return getCalendarColor(calendarColor, profileColor, attendeeUserId);
};

export const getEventBackgroundColor = (
	event: IAPIEvent,
	profileColor: string,
	calendarColor?: string,
	attendeeUserId?: number,
): string => {
	if (event.is_hearth_event) {
		return getHearthEventBackgroundColor(
			event.attendee_list.length,
			profileColor,
			event.color,
		);
	}
	return getExternalEventBackgroundColor(
		profileColor,
		calendarColor,
		attendeeUserId,
	);
};
