import DatePickerGrid from './DatePickerGrid';
import DayGrid from './DayGrid';
import NewEvent from 'components/Buttons/NewEvent';
import MonthViewNavigation from 'components/CompanionApp/Calendar/MonthViewNavigation';
import dayjs from 'dayjs';
import { useState } from 'react';
import useSelectedDate from 'stores/selectedDate';
import shallow from 'zustand/shallow';
import { IAPIEvent, IAPIExternalCalendar } from 'utils/types';

interface IPropTypes {
	dateTimePicker?: boolean;
	pickingStart: boolean;
	pickingEnd: boolean;
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	onSelect: (date: dayjs.Dayjs) => void;
	events?: Record<string, IAPIEvent[]>;
	calendars?: IAPIExternalCalendar[];
	refreshEvents?: (date: dayjs.Dayjs) => void;
}

export default function MonthView({
	dateTimePicker,
	pickingStart,
	pickingEnd,
	startDate,
	endDate,
	onSelect,
	events,
	calendars,
	refreshEvents,
}: IPropTypes) {
	const { selectedDate, selectDate } = useSelectedDate(
		(store) => ({
			selectedDate: store.selectedDate,
			selectDate: store.selectDate,
		}),
		shallow,
	);

	let isActiveDate = selectedDate;
	if (dateTimePicker && pickingStart) {
		isActiveDate = startDate;
	} else if (dateTimePicker && pickingEnd) {
		isActiveDate = endDate;
	}
	const [activeDate, setActiveDate] = useState<dayjs.Dayjs>(isActiveDate);

	function handleNavigateNextMonth() {
		const nextMonth = activeDate.add(1, 'month');
		setActiveDate(nextMonth);
		refreshEvents && refreshEvents(nextMonth);
	}

	function handleNavigatePrevMonth() {
		const prevMonth = activeDate.subtract(1, 'month');
		setActiveDate(prevMonth);
		refreshEvents && refreshEvents(prevMonth);
	}

	function handleNavigateToday() {
		setActiveDate(dayjs());
		selectDate(dayjs());
		refreshEvents && refreshEvents(dayjs());
	}

	return (
		<div
			className={`flex h-full w-full px-4 justify-center ${
				!dateTimePicker && 'soft-shadow'
			}`}>
			<div className="flex-col flex w-full">
				{/* Calendar Navigation */}
				<MonthViewNavigation
					dateTimePicker={dateTimePicker}
					activeDate={activeDate}
					handleNavigateForward={handleNavigateNextMonth}
					handleNavigateBackward={handleNavigatePrevMonth}
					handleNavigateToday={handleNavigateToday}
				/>
				{/* Month Calendar View */}
				{!dateTimePicker ? (
					<DayGrid
						activeDate={activeDate}
						events={events}
						calendars={calendars}
					/>
				) : (
					<DatePickerGrid
						pickingStart={pickingStart}
						pickingEnd={pickingEnd}
						startDate={startDate}
						endDate={endDate}
						activeDate={activeDate}
						onSelect={onSelect}
					/>
				)}

				{!dateTimePicker && <NewEvent />}
			</div>
		</div>
	);
}
