import axiosClient from 'utils/axiosClient';
import { ICurrentUser } from 'utils/types';
import create from 'zustand';

interface ICurrentUserStore {
	user?: ICurrentUser;

	// This field is not in the API.
	// When there is no user logged in (say not onboard or automatically logged out), this is false
	isAuthenticated: boolean;

	// This records if we have read from local storage at start of app
	isCurrentUserFetched: boolean;

	deviceSerialId?: string;

	fetchCurrentUser: () => Promise<ICurrentUser | undefined>;
	signUserOut: () => void;
}

const useCurrentUser = create<ICurrentUserStore>((set) => ({
	isAuthenticated: false,
	isCurrentUserFetched: false,
	isOnboarding: false,

	fetchCurrentUser: async () => {
		try {
			const response = await axiosClient.get('/web/user/my');
			if (response.status === 200) {
				const user = {
					id: response.data.user.id,
					firstName: response.data.user.first_name,
					lastName: response.data.user.last_name,
					email: response.data.user.email,
					filename: response.data.user.avatar_file_name,
					color: response.data.user.color,
					isOnboarding: response.data.user.is_onboarding,
					isVerifiedEmail: response.data.user.is_verified_email,
				};
				set((state) => ({
					...state,
					user: user,
					deviceSerialId: response.data.device_serial_id,
					isAuthenticated: true,
					isCurrentUserFetched: true,
				}));
				return user;
			} else {
				throw new Error('Unexpected API Status');
			}
		} catch {
			set((state) => ({
				...state,
				isAuthenticated: false,
				isCurrentUserFetched: true,
				isOnboarding: false,
			}));
			return undefined;
		}
	},

	signUserOut: async () => {
		try {
			await axiosClient.post(`/web/session/sign_out`);
		} catch {
			// This will fail if they are not signed in
			// No action is required
		}

		set(() => ({
			user: undefined,
			isAuthenticated: false,
			isCurrentUserFetched: true,
		}));
	},
}));

export default useCurrentUser;
