import { IAPIFamilyMember } from './types';

export interface IProfile {
	first_name: string;
	avatar_file_name: string;
	is_kid: boolean;
	is_adult: boolean;
	is_anyone: boolean;
	is_selected?: boolean;
	custom_order?: number;
	user_id?: number;
}

export const findMissingNumberInList = (
	list: (number | undefined)[],
): number | undefined => {
	// sequence of numbers
	const numberList = list
		.filter((n): n is number => typeof n === 'number')
		.sort((a, b) => a - b);

	if (numberList.length > 0) {
		// find the missing number in the sequence of numbers
		for (let i = 0; i < numberList.length; i++) {
			if (i !== numberList[i]) {
				// if there is no matching number for index `i` in the sequence of numbers, the missing number is `i`
				return i;
			}
		}
		// if there is no missing number in the sequence of numbers, the missing number is at the end
		return numberList.length;
	}
	// if there is no sequence of numbers, the missing number is undefined
	return;
};

export const getAnyoneAndMemberProfiles = (
	members: IAPIFamilyMember[],
	selectedMemberUserIds?: number[],
): IProfile[] => {
	const memberProfiles: IProfile[] = members.map((member) => ({
		first_name: member.first_name,
		avatar_file_name: member.avatar_file_name,
		is_kid: !member.is_responsible_adult,
		is_adult: member.is_responsible_adult,
		is_anyone: false,
		is_selected: selectedMemberUserIds?.includes(member.id),
		custom_order: member.custom_order,
		user_id: member.id,
	}));

	const customOrderList = memberProfiles.map((profile) => profile.custom_order);

	const missingNumberInList = findMissingNumberInList(customOrderList);

	const anyoneProfile: IProfile = {
		first_name: 'Anyone',
		avatar_file_name: 'anyone_128.svg',
		is_kid: false,
		is_adult: false,
		is_anyone: true,
		is_selected:
			selectedMemberUserIds?.filter((userId) => typeof userId === 'number')
				.length === 0,
		custom_order: missingNumberInList,
	};

	memberProfiles.splice(anyoneProfile.custom_order ?? 0, 0, anyoneProfile);
	return memberProfiles;
};
