import { segmentWriteKey } from './variables';
import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load({ writeKey: `${segmentWriteKey!}` });

export enum EventName {
	RoutineCreated = 'Routine Created',
	RoutineEdited = 'Routine Edited',
	RoutineDeleted = 'Routine Deleted',
	RoutineReset = 'routine_reset',
	FirstRoutineCreated = 'First Routine Created',
	RoutineCopied = 'Routine Copied',
	IndependenceLevelChanged = 'independence_level_changed',
	ConnectedCalendar = 'connected_calendar',
}

interface EventProperties {
	[key: string]: any;
}

export const trackEvent = (
	eventName: EventName,
	eventProperties?: EventProperties,
) => {
	const developerMode = process.env.NODE_ENV === 'development';

	analytics
		.track(eventName, eventProperties)
		.then(() => {
			if (developerMode) console.log('trackEvent', eventName, eventProperties);
		})
		.catch((err) => {
			if (developerMode) console.error('Error tracking event', err);
		});
};

export default analytics;
