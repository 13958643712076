import radioOff from 'assets/buttons/radioOFF.png';
import radioOn from 'assets/buttons/radioON.png';
import { Radio, styled } from '@mui/material';

interface IRadioButton {
	handleSelect: (arg0: string) => void;
	isSelected: boolean;
	label: string;
	className?: string;
}

export default function RadioButton({
	handleSelect,
	isSelected,
	label,
	className,
}: IRadioButton) {
	const containerStyle = `flex items-center ${className}`;
	return (
		<div className={containerStyle}>
			<button onClick={() => handleSelect(label)}>
				{isSelected ? (
					<img className="w-7 h-7" src={radioOn} alt="radio box on" />
				) : (
					<img className="w-7 h-7" src={radioOff} alt="radio box off" />
				)}
			</button>
			<p className="text-sm text-blackGreen ml-2">{label}</p>
		</div>
	);
}

export const TealRadioButton = styled(Radio)({
	'&.Mui-checked': {
		'& .MuiSvgIcon-root': {
			color: '#008492',
		},
	},
});
