import { iconPath } from 'utils/variables';
import { ROUTINE_ICONS } from 'utils/routineHelpers';
import React from 'react';

interface IconWrapperProps {
	imageValue?: string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ imageValue = 'plus' }) => {
	const isSvgIcon = (imageValue: string) =>
		ROUTINE_ICONS.some((icon) => icon === imageValue);

	return (
		<div className="flex flex-shrink-0 items-center justify-center w-12 h-12 p-3 border border-secondary rounded-full">
			{isSvgIcon(imageValue) ? (
				<img
					src={`${iconPath}/${imageValue}.svg`}
					alt="icon"
					className="w-8 h-8"
				/>
			) : (
				<span className="text-lg">{imageValue}</span>
			)}
		</div>
	);
};

export default IconWrapper;
