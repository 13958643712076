import dayjs from 'dayjs';
import { checkIsPastDue } from 'utils/todoHelpers';

interface IPropTypes {
	dueAt: dayjs.Dayjs;
}

export const DueDateLabel = ({ dueAt }: IPropTypes) => {
	const dueDate = dueAt.format('MMM D, YYYY');
	const isPastDue = checkIsPastDue(dueAt);

	return (
		<p
			className={`text-xxs ${
				isPastDue ? 'text-rebrand-red' : 'text-rebrand-teal-text'
			}`}>
			{dueDate}
		</p>
	);
};
