import { useContext, useState } from 'react';

import { useFetchTasks } from 'contexts/TodosContext';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import analytics from 'utils/segmentClient';
import { IAPITask, NotificationType } from 'utils/types';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import DeleteTodoConfirmation from 'components/Modals/Confirmations/DeleteTodoConfirmation';

interface IPropTypes {
	task: IAPITask;
}

export default function DeleteTodo({ task }: IPropTypes) {
	const currentUser = useCurrentUser((store) => store.user);
	const fetchTasks = useFetchTasks();
	const updateNotification = useContext(UpdateNotificationContext);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

	async function handleDelete() {
		await axiosClient
			.delete(`/web/task/${task.id}`)
			.then(() => {
				analytics.track('To-do deleted', {
					from: 'web app',
					userId: `user-${currentUser?.id}`,
				});
				fetchTasks();
				updateNotification({
					message: 'To-do deleted!',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
			})
			.catch(() => {
				updateNotification({
					message: 'Delete failed.',
					showNotification: true,
					type: NotificationType.ERROR,
				});
			});
	}

	return (
		<>
			<DeleteTodoConfirmation
				open={showConfirmation}
				isRecurring={!!task.recurrence_rule_id}
				isComplete={!!task.completed_at}
				handleCancel={() => setShowConfirmation(false)}
				handleDelete={handleDelete}
			/>
			<button
				onClick={() => setShowConfirmation(true)}
				className="text-sm text-rebrand-teal font-semibold">
				Delete
			</button>
		</>
	);
}
