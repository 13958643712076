import Switch from 'react-switch';

interface IPropTypes {
	isToggledOn: boolean;
	handleToggleSwitch: () => void;
}

export default function Toggle({
	isToggledOn,
	handleToggleSwitch,
}: IPropTypes) {
	return (
		<Switch
			onChange={handleToggleSwitch}
			checked={isToggledOn}
			handleDiameter={20}
			uncheckedIcon={false}
			checkedIcon={false}
			height={14}
			width={34}
			boxShadow={'0 3px 1px -1px rgba(0, 0, 0, 0.2)'}
			offColor={'#D9EDEF'}
			onColor={'#D9EDEF'}
			offHandleColor={'#FFFFFF'}
			onHandleColor={'#008492'}
			activeBoxShadow={'0 0 2px 3px #AEDFDC'}
		/>
	);
}
