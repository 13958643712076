import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import BackButton from 'components/Buttons/BackButton';
import EmailInput from 'components/onboarding/AddFamilyMembers/EmailInput';
import axiosClient from 'utils/axiosClient';
import invitePending from 'assets/Images/invite-pending.svg';
import DeleteConfirmation from 'components/Modals/DeleteConfirmation';
import BottomPopupLayout from 'components/Modals/BottomPopupLayout';
import { IAPIFamilyInvite, IMemberError, NotificationType } from 'utils/types';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { Button, TextField } from '@mui/material';
import { usePostMessageToCompanionApp } from 'hooks/companionAppHooks';

interface IFormState {
	first_name: string;
	email: string;
}

interface LocationState {
	state: IAPIFamilyInvite;
}

interface IPartialMember {
	email?: string;
	errors?: IMemberError;
	shouldShowError?: boolean;
	first_name?: string;
}

export default function EditInvitedMember() {
	const updateNotification = useContext(UpdateNotificationContext);
	const postMessageToCompanionApp = usePostMessageToCompanionApp();

	const navigate = useNavigate();
	const location = useLocation() as LocationState;
	const { ...invite } = location.state;

	function navigatePageBack() {
		navigate('/manage-family');
	}

	const [modalVisible, setModalVisible] = useState<boolean>(false);
	const [formState, setFormState] = useState<IFormState>({
		first_name: invite.first_name,
		email: invite.value,
	});

	function toggleModal() {
		setModalVisible(!modalVisible);
	}

	function handleChange(index: number, updatedValue: Partial<IPartialMember>) {
		setFormState({
			...formState,
			...updatedValue,
		});
	}

	async function deleteFamilyInvite() {
		await axiosClient
			.delete(`/web/family/our/invite/${invite.id}`)
			.then(() => {
				navigatePageBack();
				updateNotification({
					message: 'Successfully deleted profile',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
				postMessageToCompanionApp('family_members_updated');
			})
			.catch(() => {
				updateNotification({
					message: 'Failed to delete profile',
					showNotification: true,
				});
			});
	}

	async function resendFamilyInvite() {
		await axiosClient
			.put(`/web/family/our/invite/${invite.id}/resend`)
			.then(() => {
				updateNotification({
					message: 'Invite sent!',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
				postMessageToCompanionApp('family_members_updated');
			})
			.catch(() => {
				updateNotification({
					message: 'Failed to resend invite',
					showNotification: true,
				});
			});
	}

	return (
		<WithHeaderLayout menuButtonVisible>
			<BottomPopupLayout popup={modalVisible} handleClosePopup={toggleModal}>
				<DeleteConfirmation
					primaryText="Are you sure you want to delete this profile? You cannot undo this."
					handleDelete={deleteFamilyInvite}
					handleToggleModal={toggleModal}
				/>
			</BottomPopupLayout>
			<div className="flex flex-col justify-between mt-4">
				<div>
					<BackButton
						backRoute="/manage-family"
						className="lg:absolute lg:top-11"
					/>
					<img
						src={invitePending}
						className="w-128 h-128 aspect-square mx-auto"
						alt="Invite Pending"
					/>
					<div className="flex w-full mt-3">
						<p className="mx-auto text-lg font-semibold">{invite.first_name}</p>
					</div>

					<div className="m-6 space-y-4">
						<TextField
							fullWidth
							value={formState.first_name}
							onChange={(e) => handleChange(0, { first_name: e.target.value })}
							label="Name"
						/>
						<EmailInput
							isDisabled
							email={formState.email}
							label="Email"
							index={0}
							shouldShowError={false}
							infoPopUpVisible={false}
							handleChange={handleChange}
						/>
						<p className="flex flex-col text-xs font-normal text-center w-full">
							Not seeing the invite email? Make sure to check all your email
							folders before resending! Once you’ve accepted your invite, your
							information will appear here.
							<br />
							<button
								className="mt-[6px] underline text-xs text-rebrand-teal"
								onClick={resendFamilyInvite}>
								Resend Invite
							</button>
						</p>
					</div>
				</div>

				<div className="mx-6">
					<Button variant="outlined" onClick={toggleModal} fullWidth>
						Delete Profile
					</Button>
				</div>
			</div>
		</WithHeaderLayout>
	);
}
