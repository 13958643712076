import { useNavigate } from 'react-router-dom';
import { ReactComponent as Hamburger } from 'assets/rebrand/hamburger-menu.svg';
import HearthLogo from 'components/Logos/HearthLogo';

interface IPropTypes {
	menuButtonVisible?: boolean;
}

export default function Header({ menuButtonVisible = true }: IPropTypes) {
	const navigate = useNavigate();

	function handleToggleMenu() {
		navigate('/menu');
	}

	return (
		<div
			className={`flex-none h-16 flex items-center w-full border-b border-rebrand-grey-light ${
				menuButtonVisible ? 'justify-between' : 'justify-center'
			} px-18`}>
			<HearthLogo className="h-18" />
			{menuButtonVisible && (
				<button onClick={handleToggleMenu}>
					<Hamburger className="fill-current text-rebrand-teal-text" />
				</button>
			)}
		</div>
	);
}
