import { useState } from 'react';

interface IPropTypes {
	name: string;
	value: number;
	handleInputChange: (arg0: string) => void;
	inputRef?: any;
	disabled?: boolean;
}

export default function NumberInput({
	name,
	value,
	handleInputChange,
	inputRef,
	disabled = false,
}: IPropTypes) {
	const [numberString, setNumberString] = useState<string>(value.toString());

	function onInputChange(e: React.FormEvent<HTMLInputElement>) {
		handleInputChange(e.currentTarget.value);
		setNumberString(e.currentTarget.value);
		if (e.currentTarget.value.length === 2) {
			inputRef.current.blur();
		}
	}

	return (
		<input
			name={name}
			value={numberString}
			onChange={onInputChange}
			ref={inputRef}
			onFocus={() => {
				handleInputChange('');
				setNumberString('');
			}}
			onBlur={(e) => {
				e.target.value === ''
					? setNumberString(value.toString())
					: (e.target.value = numberString);
			}}
			inputMode="numeric"
			pattern="[0-9]{2}"
			maxLength={2}
			style={{
				fontSize: 16,
				fontWeight: 400,
				color: '#3D4646',
				borderWidth: 0,
				borderRadius: 5,
				backgroundColor: '#F6F9F9',
				maxWidth:
					numberString.length === 0 ? 42 : 32 + 10 * numberString.length,
			}}
			className="px-4 py-3 focus:outline-none focus:border-2"
			disabled={disabled}
		/>
	);
}
