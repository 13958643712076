import {
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	FormHelperText,
} from '@mui/material';
import { useState } from 'react';
import { IMember } from 'utils/types';

interface IPropTypes {
	index: number;
	type: string;
	handleChange: (arg0: number, arg1: Partial<IMember>) => void;
	shouldShowError: boolean;
}

export default function ProfileType({
	index,
	type,
	handleChange,
	shouldShowError,
}: IPropTypes) {
	const profileTypes = ['Adult', 'Kid'];
	const [selectedType, setSelectedType] = useState<string>(type || '');
	const errorState = shouldShowError && !profileTypes.includes(selectedType);

	const handleSelectChange = (event: any) => {
		const newType = event.target.value;
		setSelectedType(newType);

		if (newType) {
			handleChange(index, {
				isResponsibleAdult: newType === 'Adult',
				profileType: newType,
			});
		}
	};

	return (
		<FormControl variant="outlined" fullWidth error={errorState} sx={{ mt: 3 }}>
			<InputLabel id="profile-type-label">Profile Type*</InputLabel>
			<Select
				labelId="profile-type-label"
				id="profile-type-select"
				value={selectedType}
				onChange={handleSelectChange}
				label="ProfileType"
				displayEmpty
				required
				renderValue={(selected) => {
					if (selected !== '') {
						return selected;
					}
					return <span style={{ color: '#A9B3B3' }}>Profile Type*</span>;
				}}>
				{profileTypes.map((type) => (
					<MenuItem key={type} value={type}>
						{type}
					</MenuItem>
				))}
			</Select>
			{errorState && <FormHelperText>Required</FormHelperText>}
		</FormControl>
	);
}
