import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import { IAPIFamilyMember, IAPIRecurrenceDetails } from 'utils/types';
import useCurrentUser from 'stores/currentUser';
import FormLayout from 'components/Forms/FormLayout';
import Toggle from 'components/Buttons/Toggle';
import Notes from 'components/Forms/Notes';
import TitleInput from 'components/Forms/TitleInput';
import analytics from 'utils/segmentClient';
import SelectDueDate from 'components/CompanionApp/Todos/Schedule/SelectDueDate';
import SelectRecurrence from 'components/CompanionApp/Todos/Recurrence/SelectRecurrence';
import AssigneeGroup from 'components/CompanionApp/Todos/Assignees/AssigneeGroup';
import FormHeading, { FormRow } from 'components/Forms/FormHeading';
import Line from 'components/Reusable/Line';

interface IFormState {
	title: string;
	notes: string;
}

export default function AddTodo() {
	const navigate = useNavigate();

	const isAuthenticated = useCurrentUser((store) => store.isAuthenticated);

	const [isPriority, setIsPriority] = useState<boolean>(false);
	const [dueAt, setDueAt] = useState<dayjs.Dayjs>();

	const [members, setMembers] = useState<IAPIFamilyMember[]>([]);
	const [assigneeIDs, setAssigneeIDs] = useState<number[]>([]);

	const [formState, setFormState] = useState<IFormState>({
		title: '',
		notes: '',
	});

	const [formTitleError, setFormTitleError] = useState<boolean>(false);

	const [recurrenceDetails, setRecurrenceDetails] =
		useState<IAPIRecurrenceDetails>();

	function fetchMembers() {
		axiosClient.get('/web/family/our/member').then((res) => {
			setMembers(res.data);
		});
	}

	useEffect(() => {
		if (isAuthenticated) {
			fetchMembers();
		}
	}, [isAuthenticated]);

	function handleInputChange(e: {
		currentTarget: { name: string; value: string };
	}) {
		setFormState({
			...formState,
			[e.currentTarget.name]: e.currentTarget.value,
		});
		setFormTitleError(false);
	}

	function handleTracking(userId?: number) {
		const event = recurrenceDetails ? 'Recurring Todo Created' : 'Task Created';
		if (userId) {
			analytics.track(event, {
				from: 'web app',
				userId: `user-${userId}`,
			});
		} else {
			analytics.track(event, {
				from: 'web app',
			});
		}
	}
	async function createTodo(userId?: number) {
		// Refactoring this more in another PR to update tracking
		try {
			const response = await axiosClient.post('/web/task', {
				subject: formState.title,
				description: formState.notes,
				is_priority: isPriority,
				due_at: dueAt,
				assignee_list: userId ? [{ user_id: userId }] : [],
				recurrence_details: recurrenceDetails
					? Object.fromEntries(Object.entries(recurrenceDetails))
					: undefined,
			});
			if (response.status === 201) {
				handleTracking(userId);
				fetchMembers();
				navigate(`/todos`);
			}
		} catch (error) {
			// Handle any errors
		}
	}

	async function handleSave() {
		if (formState?.title === '') {
			setFormTitleError(true);
		} else {
			if (assigneeIDs.length > 0) {
				for (const assigneeID of assigneeIDs) {
					await createTodo(assigneeID);
				}
			} else {
				await createTodo();
			}
		}
	}

	return (
		<FormLayout label="Save" handleClick={handleSave}>
			<div className="w-full px-6">
				<button
					className="text-rebrand-teal font-semibold"
					onClick={() => navigate(-1)}>
					Cancel
				</button>
			</div>

			<TitleInput
				handleInputChange={handleInputChange}
				value={formState.title}
				error={formTitleError}
			/>

			<Line />

			<FormHeading label="Priority">
				<Toggle
					isToggledOn={isPriority}
					handleToggleSwitch={() => setIsPriority(!isPriority)}
				/>
			</FormHeading>

			<Line />

			<>
				<FormHeading label="Assigned to" />
				<AssigneeGroup
					members={members}
					selection={assigneeIDs}
					setSelection={setAssigneeIDs}
				/>
			</>

			<Line />

			<FormRow>
				<SelectDueDate
					setDueAt={(date?: dayjs.Dayjs) => setDueAt(date)}
					dueAt={dueAt}
					disabled={!!recurrenceDetails}
				/>
				<SelectRecurrence
					setRecurrenceDetails={(recurrenceDetails?: IAPIRecurrenceDetails) =>
						setRecurrenceDetails(recurrenceDetails)
					}
					recurrenceDetails={recurrenceDetails}
					setDueAt={(newDueAt?: dayjs.Dayjs) => setDueAt(newDueAt)}
					dueAt={dueAt}
				/>
			</FormRow>

			<Line />

			<FormRow label="Notes">
				<Notes value={formState.notes} onInputChange={handleInputChange} />
			</FormRow>
		</FormLayout>
	);
}
