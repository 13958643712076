import { useContext, useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import {
	IPersonalizationType,
	personalizationOptions,
} from 'utils/PersonalizationTags';
import PersonalizationTags from 'components/Buttons/PersonalizationTags';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import PageTitle from 'components/Reusable/PageTitle';
import { Button } from '@mui/material';
import SetupContainer from 'components/Layouts/SetupContainer';
import { useSetupContext } from '../SetupLayout';

interface IProps {
	type: 'family' | 'individual';
}

export default function FamilyPersonalizationGoals({ type }: IProps) {
	const { nextStep } = useSetupContext();
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const updateNotification = useContext(UpdateNotificationContext);

	function selected(tag: string) {
		if (selectedTags.includes(tag)) {
			// If the tag is already selected, remove it
			setSelectedTags(selectedTags.filter((t) => t !== tag));
		} else {
			// Otherwise, add the tag
			setSelectedTags([...selectedTags, tag]);
		}
	}

	const typeData: IPersonalizationType = personalizationOptions[type];

	async function onSubmit() {
		if (selectedTags.length === 0) {
			updateNotification({
				message: 'Please select at least one option',
				showNotification: true,
			});
			return;
		}

		axiosClient
			.post(typeData.apiRoute, {
				personalization_data: selectedTags,
				type: type,
			})
			.then((response) => {
				if (response.status === 200) {
					nextStep();
				} else {
					updateNotification({
						message: 'Failed to save selected tags',
						showNotification: true,
					});
					console.debug('Failed to save selected tags');
				}
			})
			.catch((error) => {
				updateNotification({
					message: 'Failed to save selected tags',
					showNotification: true,
				});
				console.debug('Failed to save selected tags');
			});
	}

	return (
		<SetupContainer>
			<PageTitle
				title={typeData.title}
				subtitle="Your answers will help us suggest useful content and features for your experience. (Select all that apply)"
			/>
			<div className="inline-flex flex-wrap mt-7 mb-8 gap-2">
				{typeData.tags.map((tag: string, index) => (
					<PersonalizationTags
						key={`personalization-tag-${tag}`}
						title={tag}
						selected={selectedTags.indexOf(tag) !== -1}
						onClick={(title) => selected(title)}
					/>
				))}
			</div>
			<div className="pb-8">
				<Button variant="contained" fullWidth onClick={onSubmit}>
					Continue
				</Button>
			</div>
		</SetupContainer>
	);
}
