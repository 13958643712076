import { BrowserRouter, Routes, Route } from 'react-router-dom';

import OnboardingLayout from 'components/onboarding/Layout';
import SetupLayout from 'components/setup/SetupLayout';
import Landing from 'screens/onboarding/Landing';
import AuthenticateWithGoogle from 'screens/onboarding/AuthenticateWithGoogle';
import AuthenticateWithGoogleError from 'screens/onboarding/AuthenticateWithGoogleError';
import CreateAccount from 'screens/onboarding/Oauth/CreateAccount';
import SubscriptionActivation from 'screens/onboarding/SubscriptionActivation';
import ProfileInfo from 'screens/onboarding/ProfileInfo';
import AddPhoneNumber from 'screens/onboarding/AddPhoneNumber';
import VerifyPhoneNumber from 'screens/onboarding/VerifyPhoneNumber';
import CreateFamily from 'screens/onboarding/CreateFamily';
import OnboardingComplete from 'screens/onboarding/OnboardingComplete';
import Error404 from 'screens/Errors/Error404';
import AddTimeZone from 'screens/onboarding/AddTimeZone';
import VerifyEmailAddress from 'screens/onboarding/Oauth/VerifyEmailAddress';
import ConnectGoogleCalendar from 'screens/onboarding/Oauth/ConnectGoogleCalendar';
import SelectCalendars from 'screens/onboarding/Oauth/SelectCalendars';
import ManageCalendarsLanding from 'screens/onboarding/Oauth/ManageCalendarsLanding';
import SettingsLanding from 'screens/onboarding/Settings/Landing';
import Calendar from 'screens/CompanionApp/Calendar';
import CompanionAppLayout from 'components/CompanionApp/CompanionAppLayout';
import Event from 'screens/CompanionApp/Event';
import ManageCalendars from 'screens/CompanionApp/ManageCalendars/ManageCalendars';
import Menu from 'screens/CompanionApp/Menu';
import ImportCalendars from 'screens/CompanionApp/ManageCalendars/ImportCalendars';
import WebLogin from 'screens/CompanionApp/WebLogin';
import AddEvent from 'components/CompanionApp/Event/AddEvent';
import Todos from 'screens/CompanionApp/Todos';
import AddTodo from 'components/CompanionApp/Todos/AddTodo';
import DeviceLogin from 'screens/onboarding/DeviceLogin';
import AddMembers from 'screens/onboarding/AddMembers';
import ResetPassword from 'screens/CompanionApp/PasswordManagement/ResetPassword';
import CreateNewPassword from 'screens/CompanionApp/PasswordManagement/CreateNewPassword';
import NewPasswordSuccess from 'screens/CompanionApp/PasswordManagement/Success';
import FamilyPersonalizationGoals from 'screens/onboarding/FamilyPersonalizationGoals';
import ManageFamily from 'screens/CompanionApp/ManageFamily/ManageFamily';
import EditFamilyMember from 'screens/CompanionApp/ManageFamily/EditFamilyMember';
import EditInvitedMember from 'screens/CompanionApp/ManageFamily/EditInvitedMember';
import RoutinesSubRouter from 'routers/SubRouters/RoutinesSubRouter';
import FeatureFlagRoutes from 'routers/SubRouters/FeatureFlagRoutes';
import { routinesFeatureFlag } from 'utils/variables';
import { RoutinesProvider } from 'contexts/RoutinesContext';
import { TodosProvider } from 'contexts/TodosContext';
import { FamilyProvider } from 'contexts/FamilyContext';
import { FeatureGateProvider } from 'contexts/FeatureGateContext';
import ConnectCalendars from 'screens/CompanionApp/ManageCalendars/ConnectCalendars';
import {
	AddAppleCalendar,
	AddIcsCalendar,
	AddOutlookCalendar,
} from 'screens/CompanionApp/ManageCalendars/AddCalendar';
import DisplaySettings from 'screens/CompanionApp/DisplaySettings/DisplaySettings';
import Account from 'screens/CompanionApp/Account/Account';
import SetupBoot from 'components/setup/Boot';
import CompanionAppStoreHeader from 'components/Headers/CompanionAppStoreHeader';

export default function Onboard() {
	return (
		<BrowserRouter>
			<CompanionAppStoreHeader />
			<Routes>
				<Route path="setup" element={<SetupLayout />}>
					<Route index element={<SetupBoot />} />
				</Route>

				<Route path="onboard" element={<OnboardingLayout />}>
					<Route
						path="verify-email-address/:id"
						element={<VerifyEmailAddress />}
					/>
					<Route path="authenticate" element={<AuthenticateWithGoogle />} />
					<Route
						path="authenticate-error"
						element={<AuthenticateWithGoogleError />}
					/>
					<Route path="create-account" element={<CreateAccount />} />
					<Route path="activation" element={<SubscriptionActivation />} />
					<Route path="profile" element={<ProfileInfo />} />
					<Route path="check-timezone" element={<AddTimeZone />} />
					<Route
						path="family-personalization"
						element={<FamilyPersonalizationGoals key="family" type="family" />}
					/>
					<Route
						path="individual-personalization"
						element={
							<FamilyPersonalizationGoals key="individual" type="individual" />
						}
					/>
					<Route path="phone-number" element={<AddPhoneNumber />} />
					<Route
						path="verify-phone-number/:id"
						element={<VerifyPhoneNumber />}
					/>
					<Route path="create-family" element={<CreateFamily />} />
					<Route
						path="members"
						element={<AddMembers key="onboarding" type="onboarding" />}
					/>
					<Route
						path="complete"
						element={<OnboardingComplete isSignIn={false} />}
					/>

					<Route index element={<Landing />} />
				</Route>

				<Route path="reset-password">
					<Route index element={<ResetPassword />} />
					<Route path="create-new-password" element={<CreateNewPassword />} />
					<Route path="success" element={<NewPasswordSuccess />} />
				</Route>

				<Route path="join-family" element={<OnboardingLayout />}>
					<Route path="authenticate" element={<AuthenticateWithGoogle />} />
					<Route
						path="authenticate-error"
						element={<AuthenticateWithGoogleError />}
					/>
					<Route path="create-account" element={<CreateAccount />} />
					<Route
						path="verify-email-address/:id"
						element={<VerifyEmailAddress />}
					/>
					<Route path="profile" element={<ProfileInfo />} />
					<Route path="phone-number" element={<AddPhoneNumber />} />
					<Route
						path="verify-phone-number/:id"
						element={<VerifyPhoneNumber />}
					/>
					<Route
						path="complete"
						element={<OnboardingComplete isSignIn={false} />}
					/>

					<Route index element={<Landing />} />
				</Route>

				<Route path="manage-calendars" element={<OnboardingLayout />}>
					<Route
						path="connect-google-calendar"
						element={<ConnectGoogleCalendar />}
					/>
					<Route
						path="authenticate-error"
						element={<ConnectGoogleCalendar showErrorText={true} />}
					/>
					<Route path="select-calendars" element={<SelectCalendars />} />
					<Route index element={<ManageCalendarsLanding />} />
				</Route>

				<Route path="re-auth" element={<OnboardingLayout />}>
					<Route path="authenticate" element={<AuthenticateWithGoogle />} />
					<Route
						path="authenticate-error"
						element={<AuthenticateWithGoogleError />}
					/>
					<Route
						path="complete"
						element={<OnboardingComplete isSignIn={true} />}
					/>
					<Route index element={<Landing />} />
				</Route>

				<Route path="settings" element={<OnboardingLayout />}>
					<Route index element={<SettingsLanding />} />
				</Route>
				<Route path="login" element={<WebLogin />} />
				<Route path="menu" element={<Menu />} />
				<Route path="" element={<CompanionAppLayout />}>
					<Route path="calendar" element={<Calendar />} />
					<Route path="event/:id" element={<Event />} />
					<Route path="new-event" element={<AddEvent />} />
					<Route
						path="todos"
						element={
							<FeatureGateProvider>
								<TodosProvider>
									<Todos />
								</TodosProvider>
							</FeatureGateProvider>
						}
					/>
					<Route
						path="new-todo"
						element={
							<FeatureGateProvider>
								<AddTodo />
							</FeatureGateProvider>
						}
					/>
					<Route
						path="manage-calendar"
						element={
							<FamilyProvider>
								<ManageCalendars />
							</FamilyProvider>
						}
					/>
					<Route path="connect-calendars" element={<ConnectCalendars />} />
					<Route path="add-apple-calendar" element={<AddAppleCalendar />} />
					<Route path="add-outlook-calendar" element={<AddOutlookCalendar />} />
					<Route path="add-ics-calendar" element={<AddIcsCalendar />} />
					<Route
						path="edit-family-member/:id"
						element={
							<FeatureGateProvider>
								<EditFamilyMember />
							</FeatureGateProvider>
						}
					/>
					<Route
						path="edit-invited-member/:id"
						element={<EditInvitedMember />}
					/>
					<Route
						path="manage-family"
						element={
							<FamilyProvider>
								<ManageFamily />
							</FamilyProvider>
						}
					/>
					<Route
						path="add-family-member"
						element={<AddMembers key="web" type="web" />}
					/>
					<Route
						path="import-calendars"
						element={<ImportCalendars isOnboarding={false} />}
					/>
					<Route index element={<SettingsLanding />} />
					<Route path="display-settings" element={<DisplaySettings />} />
					<Route path="account" element={<Account />} />

					<Route
						path="routines/*"
						element={
							<FeatureFlagRoutes isEnabled={routinesFeatureFlag}>
								<FeatureGateProvider>
									<RoutinesProvider>
										<RoutinesSubRouter />
									</RoutinesProvider>
								</FeatureGateProvider>
							</FeatureFlagRoutes>
						}
					/>
				</Route>
				<Route path="device-login">
					<Route index element={<DeviceLogin />} />
					<Route
						path="complete"
						element={<OnboardingComplete isSignIn={true} />}
					/>
				</Route>

				<Route path="error" element={<OnboardingLayout />}>
					<Route index element={<Error404 />} />
				</Route>
				<Route path="*" element={<Error404 />} />
			</Routes>
		</BrowserRouter>
	);
}
