import FormHeading from './FormHeading';

interface IPropTypes {
	handleInputChange: (e: {
		currentTarget: { name: string; value: string };
	}) => void;
	value: string;
	placeholder?: string;
	error?: boolean | null;
}

export default function TitleInput({
	handleInputChange,
	value,
	placeholder = 'Enter title',
	error = false,
}: IPropTypes) {
	return (
		<FormHeading>
			<div className="flex flex-col mt-6 mb-2 justify-start w-full">
				<input
					name="title"
					placeholder={placeholder}
					onChange={handleInputChange}
					defaultValue={value}
					className="rounded-lg border-0 focus:outline-none font-bold text-lg"
				/>
				{error && <p className="text-error text-xxs">Required</p>}
			</div>
		</FormHeading>
	);
}
