import { useContext, useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from 'utils/axiosClient';
import { ITimezoneObject } from 'utils/types';
import TimezoneSelect from 'components/Forms/TimezoneSelect';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import { Button } from '@mui/material';
import PageTitle from 'components/Reusable/PageTitle';
import { ONBOARDING_SCREENS } from './onboardingFlow';

export default function AddTimeZone() {
	const [timezone, setTimezone] = useState<ITimezoneObject>();
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	async function handleSubmit() {
		if (!timezone) {
			updateNotification({
				message: 'Please select a timezone',
				showNotification: true,
			});

			return;
		}

		axiosClient
			.put('/onboarding/device/timezone', {
				timezone: timezone.timezone,
			})
			.then((response) => {
				if (response.status === 200) {
					navigate('/onboard/family-personalization');
				} else {
					updateNotification({
						message: 'Something went wrong. Please try again.',
						showNotification: true,
					});
				}
			})
			.catch((error) => {
				updateNotification({
					message: 'Something went wrong. Please try again.',
					showNotification: true,
				});
				console.log(error);
			});
	}

	return (
		<OnboardingStepsLayout progress={ONBOARDING_SCREENS.addTimezone.progress}>
			<PageTitle
				title="Confirm your timezone"
				subtitle="Hearth Display will show your calendar and reminders in this timezone."
			/>
			<form className="relative bg-white space-y-7 mt-6">
				<TimezoneSelect
					selectedTimezone={timezone}
					onChange={(timezone: ITimezoneObject) => setTimezone(timezone)}
				/>
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Confirm
				</Button>
			</form>
		</OnboardingStepsLayout>
	);
}
