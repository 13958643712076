import { useState } from 'react';
import dayjs from 'dayjs';

import { ITime } from 'utils/types';
import DateTimePicker from 'components/Forms/DateTimePicker';
import DateTimeDisplay from 'components/Forms/DateTimeDisplay';
import removeIcon from 'assets/Icons/Todos/Schedule/removeIcon.svg';
import { ReactComponent as CalendarIcon } from 'assets/rebrand/calendar.svg';
import ReusableModal from 'components/Modals/ReusableModal';

interface IPropTypes {
	setDueAt: (arg0?: dayjs.Dayjs) => void;
	dueAt?: dayjs.Dayjs;
	disabled?: boolean;
}

export default function SelectDueDate({
	setDueAt,
	dueAt,
	disabled = false,
}: IPropTypes) {
	const [showDueDatePicker, setShowDueDatePicker] = useState<boolean>(false);
	const showRemoveButton = dueAt && !disabled; // hidden if no date selected or if disabled

	return (
		<>
			<div className="flex items-center">
				<CalendarIcon className="mr-2 fill-current" />
				<DateTimeDisplay
					handleClick={() => setShowDueDatePicker(true)}
					isAllDayEvent={true}
					dateTime={dueAt}
					disabled={disabled}
				/>
				{showRemoveButton && (
					<button className="ml-3" onClick={() => setDueAt()}>
						<img src={removeIcon} alt={'remove'} />
					</button>
				)}
			</div>
			<ReusableModal
				open={showDueDatePicker}
				handleToggleModal={() => setShowDueDatePicker(false)}>
				<DateTimePicker
					onSubmit={(time: ITime, selectedDate: dayjs.Dayjs) => {
						setDueAt(selectedDate.startOf('day'));
						setShowDueDatePicker(false);
					}}
					onCancel={() => setShowDueDatePicker(false)}
					pickingStart={false}
					pickingEnd={true}
					startDate={dayjs()}
					endDate={dayjs(dueAt)}
					timePickerVisible={false}
					timeErrorState={false}
				/>
			</ReusableModal>
		</>
	);
}
