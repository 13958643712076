import NewConfirmationLayout from 'components/Modals/Confirmations/NewConfirmationLayout';

interface IPropTypes {
	open: boolean;
	isRecurring: boolean;
	isComplete: boolean;
	handleCancel: () => void;
	handleDelete: () => void;
}

const getTitle = (isRecurring: boolean): string => {
	if (isRecurring) {
		return 'Delete Recurring To-do';
	}
	return 'Delete Todo';
};

const getDescription = (isRecurring: boolean, isComplete: boolean): string => {
	if (isRecurring) {
		if (isComplete) {
			return 'This will not delete future instances of this to-do.';
		}
		return 'This will delete this and all future instances of this to-do.';
	}
	return 'Are you sure you want to delete this to-do?';
};

export default function DeleteTodoConfirmation({
	open,
	isRecurring,
	isComplete,
	handleCancel,
	handleDelete,
}: IPropTypes) {
	const title = getTitle(isRecurring);
	const description = getDescription(isRecurring, isComplete);

	return (
		<NewConfirmationLayout
			open={open}
			title={title}
			description={description}
			confirmButtonLabel="Delete"
			confirmButtonColor="text-rebrand-red"
			handleCancel={handleCancel}
			handleConfirm={handleDelete}
		/>
	);
}
