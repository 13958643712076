import React, { createContext, useCallback, useState } from 'react';
import FeatureGateModal, {
	FeatureGateType,
} from 'components/CompanionApp/Membership/FeatureGateModal';

interface IFeatureGate {
	showFeatureGate: boolean;
	type?: FeatureGateType;
}

interface IFeatureGateContext {
	featureGate: IFeatureGate;
}
type IUpdateFeatureGateContext = (featureGate: IFeatureGate) => void;

const defaultValue: IFeatureGateContext = {
	featureGate: {
		showFeatureGate: false,
	},
};

export const FeatureGateContext =
	createContext<IFeatureGateContext>(defaultValue);

export const UpdateFeatureGateContext =
	createContext<IUpdateFeatureGateContext>(() => {});

interface IFeatureGateProviderProps {
	children: React.ReactNode;
}

// Create a provider component that wraps your app and provides the error context value
export const FeatureGateProvider = ({
	children,
}: IFeatureGateProviderProps) => {
	const [featureGate, setFeatureGate] = useState(defaultValue.featureGate);

	const updateFeatureGate = useCallback((updatedFeatureGate: IFeatureGate) => {
		setFeatureGate(updatedFeatureGate);
	}, []);

	return (
		<UpdateFeatureGateContext.Provider value={updateFeatureGate}>
			<FeatureGateContext.Provider value={{ featureGate }}>
				<FeatureGateModal />
				{children}
			</FeatureGateContext.Provider>
		</UpdateFeatureGateContext.Provider>
	);
};
