import PageTitle from 'components/Reusable/PageTitle';
import googleLogo2 from 'assets/Icons/Calendar/google-logo-2.png';
import icalLogo from 'assets/Icons/Calendar/ical-logo.png';
import outlookLogo from 'assets/Icons/Calendar/outlook-logo.png';
import { apiHost, apiRoot } from 'utils/variables';
import { Link } from 'react-router-dom';
import { iCalSubscriptionFeatureFlag } from 'utils/variables';
import {
	useIsCompanionAppAccess,
	usePostMessageToCompanionApp,
} from 'hooks/companionAppHooks';

interface IProviderButtonContentProps {
	alt: string;
	text: string;
	imageSource?: string;
	imageSourceClass?: string;
}

const ProviderButtonContent = ({
	alt,
	text,
	imageSource,
	imageSourceClass = 'w-12 mr-3',
}: IProviderButtonContentProps) => (
	<div className="flex items-center">
		{imageSource && (
			<img src={imageSource} className={imageSourceClass} alt={alt} />
		)}
		{!imageSource && <span />}
		<p className="h-16 flex items-center text-blackGreen text-sm">{text}</p>
	</div>
);

export default function ConnectCalendars() {
	const isCompanionApp = useIsCompanionAppAccess();
	const postMessageToCompanionApp = usePostMessageToCompanionApp();

	return (
		<div>
			<div className="px-4 pt-6 pb-4">
				<Link to="/manage-calendar">
					<p className="font-bold text-rebrand-teal text-xs">Cancel</p>
				</Link>
			</div>
			<div className="px-6 my-4">
				<PageTitle
					title="Connect your calendars"
					subtitle="You can reassign calendars to other family members after connecting. Note: only one Google account can be synced at a time."
				/>
				<a
					className=" text-rebrand-teal underline"
					href="https://hearthdisplay.kustomer.help/en_us/best-practices-for-setting-up-your-calendars-HJr_gkVh2"
					rel="noreferrer"
					target="_blank">
					Learn More
				</a>
			</div>
			<div className="flex flex-col gap-3">
				{isCompanionApp ? (
					<button
						onClick={() => postMessageToCompanionApp('import_google_calendar')}
						className="px-6 py-3 border rounded-8 mx-6">
						<ProviderButtonContent
							imageSource={googleLogo2}
							alt="google logo"
							text="Google Calendar"
						/>
					</button>
				) : (
					<a
						onClick={() => postMessageToCompanionApp('import_google_calendar')}
						className="px-6 py-3 border rounded-8 mx-6"
						href={`${apiHost}${apiRoot}/web/user/link_google_account?is_onboarding=false`}>
						<ProviderButtonContent
							imageSource={googleLogo2}
							alt="google logo"
							text="Google Calendar"
						/>
					</a>
				)}

				{iCalSubscriptionFeatureFlag && (
					<>
						<div className="px-6 py-3 border rounded-8 mx-6">
							<Link to="/add-apple-calendar">
								<ProviderButtonContent
									imageSource={icalLogo}
									imageSourceClass="w-12 h-8 mr-3"
									alt="apple calendar logo"
									text="Apple Calendar"
								/>
							</Link>
						</div>
						<div className="px-6 py-3 border rounded-8 mx-6">
							<Link to="/add-outlook-calendar">
								<ProviderButtonContent
									imageSource={outlookLogo}
									alt="outlook calendar logo"
									text="Outlook Calendar"
								/>
							</Link>
						</div>
						<div className="px-6 py-3 border rounded-8 mx-6">
							<Link to="/add-ics-calendar">
								<ProviderButtonContent
									alt="ics calendar logo"
									text="Subscribe via URL"
								/>
							</Link>
						</div>
					</>
				)}
			</div>
			<p className="px-6 mt-7 text-xxs text-blackGreen">
				Not seeing your updated Google events?&nbsp;
				{isCompanionApp ? (
					<button
						onClick={() =>
							postMessageToCompanionApp('reconnect_google_calendars')
						}
						className="text-xxs text-rebrand-teal underline">
						Reconnect
					</button>
				) : (
					<a
						className="text-xxs text-rebrand-teal underline"
						href={`${apiHost}${apiRoot}/user/reauthenticate_with_google`}>
						Reconnect
					</a>
				)}
			</p>
		</div>
	);
}
