import { IAPIRecurrenceDetails } from 'utils/types';
import { formatRecurrenceDetails } from 'utils/recurrenceHelpers';
import { ReactComponent as Recurring } from 'assets/rebrand/recurring.svg';

interface IPropTypes {
	recurrenceDetails: IAPIRecurrenceDetails;
}

export default function RecurrenceTag({ recurrenceDetails }: IPropTypes) {
	const recurrenceDetailsFormatted = formatRecurrenceDetails(recurrenceDetails);

	return (
		<div className="my-2 px-3 py-1 rounded-full bg-rebrand-grey-light text-rebrand-grey-dark inline-flex items-center w-max-screen overflow-clip">
			<Recurring className="mr-2 fill-current" />
			<p className="whitespace-normal text-sm">{recurrenceDetailsFormatted}</p>
		</div>
	);
}
