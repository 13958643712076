import React, { useCallback, useContext, useEffect } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/Icons/error-icon.svg';
import { ReactComponent as WhiteCheck } from 'assets/rebrand/check-circle-white.svg';

import {
	NotificationContext,
	UpdateNotificationContext,
} from 'contexts/NotificationContext';
import { NotificationType } from 'utils/types';

interface INotificationStyle {
	background: string;
	textClassName: string;
	border: string;
	icon?: JSX.Element;
}

const NotificationStyle: Record<NotificationType, INotificationStyle> = {
	[NotificationType.ERROR]: {
		background: 'bg-error-light',
		textClassName: '',
		border: '',
		icon: (
			<ErrorIcon
				width="24"
				height="24"
				className="flex-none fill-current text-rebrand-error"
			/>
		),
	},
	[NotificationType.UPDATE]: {
		background: 'bg-primary-medium',
		textClassName: 'text-primary',
		border: '',
	},
	[NotificationType.SUCCESS]: {
		background: 'bg-white',
		textClassName: '',
		border: '',
		icon: (
			<WhiteCheck
				width="24"
				height="24"
				className="flex-none fill-current text-rebrand-teal"
			/>
		),
	},
};

export default function Notification() {
	const {
		notification,
		notification: {
			showNotification,
			message,
			type = NotificationType.ERROR,
			displayTime = 5000,
		},
	} = useContext(NotificationContext);

	const updateNotification = useContext(UpdateNotificationContext);

	const hideNotification = useCallback(() => {
		updateNotification({ ...notification, showNotification: false });
	}, [notification, updateNotification]);

	useEffect(() => {
		if (showNotification) {
			setTimeout(() => {
				hideNotification();
			}, displayTime);
		}
	}, [hideNotification, showNotification, displayTime]);

	const { background, border, textClassName, icon } = NotificationStyle[type];

	return (
		<div
			className={`z-1500 fixed -bottom-20 w-full transform duration-1000 ${
				showNotification ? '-translate-y-24' : 'translate-y-24'
			}`}>
			<div
				className={`mx-8 mb-6 p-4 ${background} ${border} rounded-lg flex flex-row justify-left items-center align-middle shadow-2xl`}
				onClick={() => hideNotification()}>
				{icon}
				<div
					className={`ml-3 text-xs flex flex-row space-x-1 bg-gold ${textClassName}`}>
					{type === NotificationType.ERROR && (
						<span className="font-bold">Error</span>
					)}
					<p>{message}</p>
				</div>
			</div>
		</div>
	);
}
