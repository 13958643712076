import React, { FC, useState } from 'react';
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface PasswordInputProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	error: boolean;
	helperText?: string;
	label?: string;
	name?: string;
	inputMaxLength?: number;
	inputMinLength?: number;
}

const PasswordInput: FC<PasswordInputProps> = ({
	value,
	onChange,
	onBlur,
	error,
	helperText,
	label = 'Password',
	name = 'password',
	inputMaxLength = 40,
	inputMinLength = 8,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	return (
		<FormControl variant="outlined" fullWidth error={error}>
			<InputLabel htmlFor={name}>{label}</InputLabel>
			<OutlinedInput
				id={name}
				name={name}
				type={showPassword ? 'text' : 'password'}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				label={label}
				inputProps={{
					maxLength: inputMaxLength,
					minLength: inputMinLength,
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
							color="default">
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export default PasswordInput;
