import { useContext } from 'react';
import { actionTypes, useRoutinesDispatch } from 'contexts/RoutinesContext';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { IAPIFamilyMember, NotificationType } from 'utils/types';
import axiosClient from 'utils/axiosClient';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { mapApiFamilyMemberToFamilyMember } from 'utils/routineHelpers';
import { EventName, trackEvent } from 'utils/segmentClient';

export function useFetchMembersWithRoutines() {
	const dispatch = useRoutinesDispatch();

	return async (callback?: () => void) => {
		try {
			const response: AxiosResponse<IAPIFamilyMember[]> = await axiosClient.get(
				'/web/routines',
				{
					params: {
						start_time: dayjs().subtract(1, 'day').toISOString(),
						end_time: dayjs().add(1, 'day').toISOString(),
					},
				},
			);

			const memberRoutines = response.data;
			const mappedData = mapApiFamilyMemberToFamilyMember(memberRoutines);

			dispatch({
				type: actionTypes.SET_MEMBER_ROUTINES,
				payload: mappedData,
			});

			if (callback) {
				callback();
			}
		} catch (error) {
			console.error('Failed to fetch member routines:', error);
		}
	};
}

export const useDeleteRoutineApi = () => {
	const updateNotification = useContext(UpdateNotificationContext);

	return async (routineId: number): Promise<void> => {
		return axiosClient
			.delete(`/web/routines/${routineId}`)
			.then(() => {
				updateNotification({
					message: 'Deleted the routine successfully.',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});
				trackEvent(EventName.RoutineDeleted, { id: routineId });
			})
			.catch(() => {
				updateNotification({
					message: 'Problem deleting the routine, please try again.',
					showNotification: true,
				});
			});
	};
};
