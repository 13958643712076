import { COLORS } from 'styles/colors';
import { ReactComponent as Checkmark } from 'assets/Icons/Checkmarks/color-picker-white-check.svg';

interface IPropTypes {
	handleCancel: () => void;
	handleSave: () => void;
	handleSelect: (arg0: string) => void;
	selectedColor: string;
	isNewLayout?: boolean;
}
export default function ColorPicker({
	handleCancel,
	handleSave,
	handleSelect,
	selectedColor,
	isNewLayout = false,
}: IPropTypes) {
	return (
		<div className="mx-2 my-2 flex flex-col justify-center">
			<div
				className={`grid grid-cols-4 gap-6 justify-center ${
					!isNewLayout ? 'mb-6' : 'my-4 mx-auto'
				}`}>
				{COLORS.map((color) => (
					<div
						key={`${color}-of-color-picker`}
						className="flex justify-center items-center">
						<button
							onClick={() => handleSelect(color)}
							className="flex h-9 w-9 rounded-5 justify-center items-center"
							style={{ backgroundColor: color }}>
							{selectedColor === color && <Checkmark />}
						</button>
					</div>
				))}
			</div>
			{!isNewLayout && (
				<div className="flex w-full justify-between">
					<button onClick={handleCancel} className="flex py-1 items-center">
						<p className="text-xs text-rebrand-teal font-bold">Cancel</p>
					</button>
					<button
						onClick={handleSave}
						className="flex py-1 px-4 items-center border border-rebrand-teal rounded-full">
						<p className="text-xs text-rebrand-teal font-bold">Save</p>
					</button>
				</div>
			)}
		</div>
	);
}
