import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'assets/rebrand/back-arrow.svg';

interface IBackButtonProps {
	backRoute?: string;
	className?: string;
}

export default function BackButton({
	backRoute = '',
	className = '',
}: IBackButtonProps) {
	const navigate = useNavigate();
	const navigatePageBack = () =>
		backRoute ? navigate(backRoute) : navigate(-1);

	return (
		<button
			onClick={navigatePageBack}
			className={`flex items-center ${className}`}>
			<BackArrow className="fill-current h-6 w-6" />
			<span>Back</span>
		</button>
	);
}
