import React, { ReactNode } from 'react';
import AnimatedContainer from 'components/Reusable/AnimateContainer';
import HeaderProgressBar from 'components/ProgressBars/HeaderProgressBar';
import SetupBackButton from 'components/Buttons/SetupBackButton';
import { useSetupContext } from 'components/setup/SetupLayout';

interface ISetupLayout {
	children: ReactNode;
	disableBackButton?: boolean;
	backOverride?: () => void;
}

export default function SetupContainer({
	children,
	disableBackButton,
	backOverride,
}: ISetupLayout) {
	const { progress, prevStep, backStep, skipStep } = useSetupContext();
	const backAction = backOverride
		? backOverride
		: backStep
		? backStep
		: prevStep;
	const backSection = !disableBackButton ? (
		<SetupBackButton onClick={() => backAction()} />
	) : (
		<span className="fill-current h-6 w-6"></span>
	);
	return (
		<div className="flex flex-col h-full w-full items-center">
			<div className="w-full">
				<div className="mt-12 mb-6 flex flex-row justify-between">
					{backSection}
					{skipStep && (
						<button onClick={() => skipStep()}>
							<p className="flex text-xs font-semibold mr-6">Skip</p>
						</button>
					)}
				</div>
				<HeaderProgressBar width={progress} />
			</div>
			<div className="flex-grow flex justify-center mt-12 mx-6 max-w-md">
				<AnimatedContainer>{children}</AnimatedContainer>
			</div>
		</div>
	);
}
