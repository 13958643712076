import React from 'react';

interface IIconProgressBarProps {
	pastProgress: {
		[key: number | string]: boolean;
	};
	icon: React.ReactElement;
	backgroundClassName?: string;
}

const IconProgressBar = ({
	pastProgress,
	icon,
	backgroundClassName = 'bg-grey-light',
}: IIconProgressBarProps) => {
	const icons = Object.keys(pastProgress).map((key, index) => {
		if (pastProgress[key]) {
			return React.cloneElement(icon, {
				key: index,
				className: `w-7 h-7 mx-1 fill-current ${icon.props.className || ''}`,
			});
		}

		return (
			<div
				key={index}
				className={`w-7 h-7 rounded-full mx-1 ${backgroundClassName}`}
			/>
		);
	});

	return <div className="flex flex-row">{icons}</div>;
};

export default IconProgressBar;
