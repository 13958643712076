import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
	FeatureGateContext,
	UpdateFeatureGateContext,
} from 'contexts/FeatureGateContext';
import useFeatures from 'stores/feature';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import { ReactComponent as DefaultSVG } from 'assets/Icons/Membership/defaultFeatureGateModalIcon.svg';
import { ReactComponent as TodosSVG } from 'assets/Icons/Membership/todosFeatureGateModalIcon.svg';
import { ReactComponent as RoutinesSVG } from 'assets/Icons/Membership/routinesFeatureGateModalIcon.svg';
import { ReactComponent as HearthHelperSVG } from 'assets/Icons/Membership/hearthHelperFeatureGateModalIcon.svg';
import { ReactComponent as RoutinesPointSVG } from 'assets/Icons/Membership/routinesFeatureGateModalPointIcon.svg';
import { ReactComponent as TodosPointSVG } from 'assets/Icons/Membership/todosFeatureGateModalPointIcon.svg';
import { ReactComponent as HearthHelperPointSVG } from 'assets/Icons/Membership/hearthHelperFeatureGateModalPointIcon.svg';
import { ReactComponent as SoftwareUpdatePointSVG } from 'assets/Icons/Membership/softwareUpdateFeatureGateModalPointIcon.svg';
import { ReactComponent as CheckmarkSVG } from 'assets/Icons/Membership/featureGateCheckmark.svg';

export enum FeatureGateType {
	DEFAULT = 'default',
	TODOS = 'todos',
	ROUTINES = 'routines',
	HEARTH_HELPER = 'hearth_helper',
}

interface IFeatureGateStyle {
	icon: JSX.Element;
	title: JSX.Element;
	body: JSX.Element;
	points: {
		icon?: JSX.Element;
		description: JSX.Element;
	}[];
}

const FeatureGateStyle: Record<FeatureGateType, IFeatureGateStyle> = {
	[FeatureGateType.DEFAULT]: {
		icon: <DefaultSVG className="h-24" />,
		title: (
			<>
				Upgrade Your Hearth
				<br />
				Display Experience
			</>
		),
		body: (
			<>
				Activate your Family Membership now
				<br />
				to start using premium features.
			</>
		),
		points: [
			{
				icon: <RoutinesPointSVG className="h-8" />,
				description: <>Customizable routines</>,
			},
			{
				icon: <TodosPointSVG className="h-8" />,
				description: <>To-dos for task management</>,
			},
			{
				icon: <HearthHelperPointSVG className="h-8" />,
				description: (
					<>
						Hearth Helper,
						<br />
						our SMS AI-Assistant
					</>
				),
			},
			{
				icon: <SoftwareUpdatePointSVG className="h-8" />,
				description: <>Future software releases</>,
			},
		],
	},
	[FeatureGateType.TODOS]: {
		icon: <TodosSVG />,
		title: (
			<>
				To-dos are a<br />
				premium feature
			</>
		),
		body: (
			<>
				Activate your Family Membership now
				<br />
				to simplify family organization.
			</>
		),
		points: [
			{ description: <>Create to-dos for anyone</> },
			{ description: <>Schedule future to-dos</> },
			{ description: <>Set recurring to-dos</> },
		],
	},
	[FeatureGateType.ROUTINES]: {
		icon: <RoutinesSVG />,
		title: (
			<>
				Routines are a<br />
				premium feature
			</>
		),
		body: (
			<>
				Activate your Family Membership now
				<br />
				to simplify family organization.
			</>
		),
		points: [
			{
				description: (
					<>
						Customize routines for
						<br />
						everyone
					</>
				),
			},
			{
				description: (
					<>
						Set morning and evening
						<br />
						routines
					</>
				),
			},
			{
				description: (
					<>
						Gamify routines that used to
						<br />
						feel like chores
					</>
				),
			},
		],
	},
	[FeatureGateType.HEARTH_HELPER]: {
		icon: <HearthHelperSVG />,
		title: (
			<>
				Hearth Helper is
				<br />a premium feature
			</>
		),
		body: (
			<>
				Activate your Family Membership now
				<br />
				to simplify family organization.
			</>
		),
		points: [
			{
				description: (
					<>
						Digitize paper invites and
						<br />
						calendars
					</>
				),
			},
			{
				description: (
					<>
						Text your new home organization
						<br />
						assistant from anywhere
					</>
				),
			},
			{
				description: (
					<>
						Automatically creates calendar
						<br />
						events
					</>
				),
			},
		],
	},
};

export default function FeatureGateModal() {
	const location = useLocation();
	const navigate = useNavigate();

	const {
		featureGate: { showFeatureGate, type = FeatureGateType.DEFAULT },
	} = useContext(FeatureGateContext);
	const updateFeatureGate = useContext(UpdateFeatureGateContext);
	const features = useFeatures((store) => store.features);

	const { icon, title, body, points } = FeatureGateStyle[type];

	const shouldShowFeatureGateForTodos =
		location.pathname.includes('todo') && !features.todos.isAvailable;
	const shouldShowFeatureGateForRoutines =
		location.pathname.includes('routines') && !features.routines.isAvailable;

	useEffect(() => {
		if (shouldShowFeatureGateForTodos) {
			updateFeatureGate({
				type: FeatureGateType.TODOS,
				showFeatureGate: true,
			});
		}
		if (shouldShowFeatureGateForRoutines) {
			updateFeatureGate({
				type: FeatureGateType.ROUTINES,
				showFeatureGate: true,
			});
		}
	}, [shouldShowFeatureGateForTodos, shouldShowFeatureGateForRoutines]);

	return (
		<div
			className={`z-10 fixed -bottom-20 w-full transform duration-1000 ${
				showFeatureGate ? '-translate-y-24' : 'translate-y-24'
			}`}>
			<SlideUpDrawer open={showFeatureGate} height="95%" maxHeight="100%">
				<div className="flex flex-col w-full h-full items-center justify-between pt-6 px-6">
					<div className="flex flex-col w-full h-full items-center pt-8">
						{icon}
						<p className="text-lg text-rebrand-teal-text text-center font-medium mt-3">
							{title}
						</p>
						<p className="text-sm text-rebrand-black text-center my-5">
							{body}
						</p>
						<div>
							{points.map((point, i) => (
								<div
									key={`premium-feature-point-${i}`}
									className="flex flex-row items-center mb-3">
									{point.icon || <CheckmarkSVG />}
									<p className="text-sm text-rebrand-black ml-2">
										{point.description}
									</p>
								</div>
							))}
						</div>
					</div>
					<div className="flex flex-col w-full text-center">
						<button
							className="text-xs text-white font-semibold py-3 mt-2 rounded-full bg-rebrand-teal"
							onClick={() => navigate('/account')}>
							Activate Family Membership
						</button>
						<button
							className="text-xs text-rebrand-teal font-semibold my-5"
							onClick={() => navigate(-1)}>
							Not now
						</button>
					</div>
				</div>
			</SlideUpDrawer>
		</div>
	);
}
