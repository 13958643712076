import dayjs, { Dayjs } from 'dayjs';

import TextBox from 'components/Forms/TextBox';
import { ReactComponent as RecurringIcon } from 'assets/rebrand/recurring.svg';

var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface IPropTypes {
	handleClick?: () => void;
	freq: string;
	interval?: number;
	dtStart: Dayjs;
	byDay?: string;
	byMonthDay?: string;
	until?: Date | string | undefined;
	count?: number | undefined;
	appendTime?: boolean;
}

export default function RepeatsOn({
	handleClick,
	freq,
	interval,
	dtStart,
	byDay,
	byMonthDay,
	until,
	count,
	appendTime = false,
}: IPropTypes) {
	let freqString = freq.charAt(0).toUpperCase() + freq.slice(1);
	if (freq === 'yearly') {
		freqString = freqString.replace('Yearly', 'Annually');
	}
	if (!!interval && interval > 2 && freq !== 'daily') {
		freqString = freq.slice(0, -2) + 's';
	} else if (!!interval && interval > 2 && freq === 'daily') {
		freqString = 'days';
	} else if (!!interval && interval === 2 && freq !== 'daily') {
		freqString = freq.slice(0, -2);
	} else if (!!interval && interval === 2 && freq === 'daily') {
		freqString = 'day';
	} else if (byDay === 'SU,MO,TU,WE,TH,FR,SA') {
		freqString = '';
	} else if (byDay === 'SU,SA') {
		freqString = '';
	} else if (byDay === 'MO,TU,WE,TH,FR') {
		freqString = '';
	}

	let intervalString = '';
	if (!!interval && interval > 2) {
		intervalString = `Every ${interval} `;
	} else if (!!interval && interval === 2) {
		intervalString = `Every other `;
	}

	let repeatsOn = '';
	if (freq === 'weekly' && byDay) {
		const daysOfWeekArray = byDay.split(',');
		const daysOfWeek = daysOfWeekArray.map((day) => {
			switch (day) {
				case 'SU':
					return 'Sundays';
				case 'MO':
					return 'Mondays';
				case 'TU':
					return 'Tuesdays';
				case 'WE':
					return 'Wednesdays';
				case 'TH':
					return 'Thursdays';
				case 'FR':
					return 'Fridays';
				case 'SA':
					return 'Saturdays';
				default:
					return '';
			}
		});
		const daysOfWeekShort = daysOfWeekArray.map((day) => {
			switch (day) {
				case 'SU':
					return 'Sun';
				case 'MO':
					return 'Mon';
				case 'TU':
					return 'Tue';
				case 'WE':
					return 'Wed';
				case 'TH':
					return 'Thu';
				case 'FR':
					return 'Fri';
				case 'SA':
					return 'Sat';
				default:
					return '';
			}
		});
		if (byDay === 'SU,SA') {
			if (!!interval && interval > 1) {
				repeatsOn = ' on weekends';
			} else {
				repeatsOn = 'Repeats on weekends';
			}
		} else if (byDay === 'MO,TU,WE,TH,FR') {
			if (!!interval && interval > 1) {
				repeatsOn = ' on weekdays';
			} else {
				repeatsOn = 'Repeats on weekdays';
			}
		} else if (byDay === 'SU,MO,TU,WE,TH,FR,SA') {
			if (!!interval && interval > 1) {
				repeatsOn = ', daily';
			} else {
				repeatsOn = 'Daily';
			}
		} else if (daysOfWeekArray.length < 3) {
			repeatsOn = ` on ${daysOfWeek.join(', ')}`;
		} else if (daysOfWeekArray.length < 4) {
			repeatsOn = ` on ${daysOfWeekShort.join(', ')}`;
		} else {
			repeatsOn = ` on ${daysOfWeekArray
				.map((day) => day.substring(0, 1))
				.join(', ')}`;
		}
	} else if (freq === 'yearly') {
		repeatsOn = ` on ${dayjs(dtStart).format('MMM D')}`;
	} else if (freq === 'monthly' && !!byDay) {
		const weekDay = byDay
			.slice(-2)
			.replace('SU', 'Sunday')
			.replace('MO', 'Monday')
			.replace('TU', 'Tuesday')
			.replace('WE', 'Wednesday')
			.replace('TH', 'Thursday')
			.replace('FR', 'Friday')
			.replace('SA', 'Saturday');
		const weekNumber = byDay
			.slice(0, -2)
			.replace('-1', 'last')
			.replace('1', '1st')
			.replace('2', '2nd')
			.replace('3', '3rd')
			.replace('4', '4th');
		repeatsOn = ` on the ${weekNumber} ${weekDay}`;
	} else if (freq === 'monthly' && !!byMonthDay) {
		let monthDate = dayjs().format('YYYY-MM') + `${byMonthDay}`;
		repeatsOn = ` on the ${dayjs(monthDate).format('Do')}`;
	}

	let endsOn = '';
	if (!!until) {
		endsOn = ` until ${dayjs(until).format('MMMM D, YYYY')}`;
	} else if (!!count) {
		endsOn = `, ${count} time${count > 1 ? 's' : ''}`;
	}

	let recurrenceText = `${intervalString}${freqString}${repeatsOn}${endsOn}`;

	if (appendTime) {
		recurrenceText += ` at ${dayjs(dtStart).format('h:mm A')}`;
	}

	return (
		<div>
			{handleClick ? (
				<div className="w-full mt-2 mb-3 text-rebrand-gray-dark">
					<button onClick={handleClick}>
						<TextBox text={recurrenceText} />
					</button>
				</div>
			) : (
				<div className="my-2 px-2 py-1 rounded-full bg-grey-teal inline-flex justify-center items-center overflow-clip text-rebrand-grey-dark">
					<RecurringIcon className="fill-current" />
					<p className="whitespace-normal text-sm pr-2">{recurrenceText}</p>
				</div>
			)}
		</div>
	);
}
