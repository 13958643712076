import PageTitle from 'components/Reusable/PageTitle';
import { ReactComponent as LostDino } from 'assets/rebrand/lost-dino.svg';
import { Button } from '@mui/material';

interface IPropTypes {
	title: string;
	subtitle: string;
	buttonLabel: string;
	onClick: () => void;
}

export default function ErrorLayout({
	title,
	subtitle,
	buttonLabel,
	onClick,
}: IPropTypes) {
	return (
		<div className="w-full h-screen flex items-center justify-center">
			<div className="max-w-md flex flex-col items-center mx-6">
				<LostDino />
				<PageTitle
					title={title}
					subtitle={subtitle}
					className="mb-6 text-center items-center"
				/>
				<Button variant="outlined" onClick={onClick} fullWidth>
					{buttonLabel}
				</Button>
			</div>
		</div>
	);
}
