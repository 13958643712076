import React, { FC, ReactNode } from 'react';
import { ReactComponent as CloseButton } from 'assets/Icons/Closing/black-closing-x.svg';
import { ReactComponent as TealDino } from 'assets/Icons/teal-dino.svg';
import ReusableModalLayout from './ReusableModalLayout';

interface IReusableModalProps {
	handleToggleModal?: () => void;
	open: boolean;
	title?: string;
	showIcon?: boolean;
	showCloseButton?: boolean;
	iconSource?: ReactNode;
	children: ReactNode;
}

const ReusableModal: FC<IReusableModalProps> = ({
	handleToggleModal,
	open,
	title,
	showIcon,
	showCloseButton,
	iconSource,
	children,
}) => (
	<ReusableModalLayout open={open} onClose={handleToggleModal}>
		<div className="flex flex-row items-start mb-2">
			{title && <h6 className="font-medium">{title}</h6>}
			{showCloseButton && (
				<button onClick={handleToggleModal}>
					<CloseButton />
				</button>
			)}
		</div>
		{children}
		{showIcon && (
			<div className="flex self-center justify-center mb-6">
				{iconSource ? iconSource : <TealDino />}
			</div>
		)}
	</ReusableModalLayout>
);

export default ReusableModal;
