import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import axiosClient from 'utils/axiosClient';
import {
	IAPIEvent,
	IAPIExternalCalendar,
	IAPIFamilyMember,
	IAPIRecurrenceRule,
} from 'utils/types';
import Exit from 'components/Buttons/Exit';
import useCurrentUser from 'stores/currentUser';
import google from 'assets/Icons/Calendar/googleLogo.png';
import DeleteConfirmation from './DeleteConfirmation';
import WithoutHeaderLayout from 'components/Layouts/WithoutHeaderLayout';
import RepeatsOn from '../Recurrence/RepeatsOn';
import { recurringEventsFeatureFlag } from 'utils/variables';
import ExceptionConfirmation from 'components/Modals/ExceptionConfirmation';
import { ReactComponent as ICalIcon } from 'assets/Icons/ical.svg';
import { ReactComponent as OutlookIcon } from 'assets/Icons/outlook.svg';
import MemberAvatar from '../Todos/Assignees/MemberAvatar';
import { SelectAvatarLayout } from '../Avatars/SelectAvatarLayout';
import { Button } from '@mui/material';

interface IPropTypes {
	handleEdit: () => void;
	event: IAPIEvent;
	recurrenceRule?: IAPIRecurrenceRule;
}

export default function EventDetails({
	handleEdit,
	event,
	recurrenceRule,
}: IPropTypes) {
	const navigate = useNavigate();

	const isAuthenticated = useCurrentUser((store) => store.isAuthenticated);

	const [members, setMembers] = useState<IAPIFamilyMember[]>([]);
	const [calendars, setCalendars] = useState<IAPIExternalCalendar[]>([]);

	const [toggleDeleteEventModal, setToggleDeleteEventModal] =
		useState<boolean>(false);
	const [showExceptionConfirmation, setShowExceptionConfirmation] =
		useState<boolean>(false);
	const [isExcludedEvent, setIsExcludedEvent] = useState<boolean>(
		!!event?.recurrence_rule_id,
	);

	const eventAttendees = event.attendee_list.map(
		(attendee) => attendee.user_id,
	);
	const membersOfHearthEvent = members.filter((member) =>
		eventAttendees.includes(member.id),
	);
	const notMembersOfHearthEvent = members.filter(
		(member) => !eventAttendees.includes(member.id),
	);
	const allMembers = membersOfHearthEvent.concat(notMembersOfHearthEvent);
	const calendarOfUser = calendars
		.filter((calendar) => calendar.id === event.calendar_id)
		.map((calendar) => calendar.user_id);
	const membersOfGoogleEvent = members.filter((member) =>
		calendarOfUser.includes(member.id),
	);

	const calendarOfEvent = calendars.find(
		(calendar) => calendar.id === event.calendar_id,
	);

	let profileUser: IAPIFamilyMember | undefined;
	if (
		membersOfHearthEvent.length === 1 &&
		calendarOfEvent?.assigned_user_id &&
		calendarOfEvent?.assigned_user_id !== calendarOfEvent?.user_id
	) {
		profileUser = members.find(
			(member) => member.id === calendarOfEvent.assigned_user_id,
		);
		membersOfHearthEvent[0] = profileUser!;
	}

	let externalCalendarIcon;
	switch (calendarOfEvent?.provider) {
		case 'ical':
			externalCalendarIcon = <ICalIcon className="h-4 w-4 mr-2" />;
			break;
		case 'outlook':
			externalCalendarIcon = <OutlookIcon className="h-4 w-4 mr-2" />;
			break;
		case 'google':
			externalCalendarIcon = (
				<img src={google} className="h-18px w-18px mr-2" alt="google logo" />
			);
			break;
		default:
			externalCalendarIcon = <></>;
			break;
	}

	const externalCalendarName = calendarOfEvent?.name || 'Google event';

	// Formats string to display event start time
	let displayStartTime = '';
	if (dayjs(event.start_at).format('a') !== dayjs(event.end_at).format('a')) {
		if (dayjs(event.start_at).format('mm') === '00') {
			displayStartTime = `${dayjs(event.start_at).format('ha')}`;
		} else {
			displayStartTime = `${dayjs(event.start_at).format('h:mma')}`;
		}
	} else {
		if (dayjs(event.start_at).format('mm') === '00') {
			displayStartTime = `${dayjs(event.start_at).format('h')}`;
		} else {
			displayStartTime = `${dayjs(event.start_at).format('h:mm')}`;
		}
	}

	// Formats string to display event end time
	let displayEndTime = '';
	if (dayjs(event.end_at).format('mm') === '00') {
		displayEndTime = `${dayjs(event.end_at).format('ha')}`;
	} else {
		displayEndTime = `${dayjs(event.end_at).format('h:mma')}`;
	}

	// Formats string to display event start datetime to event end datetime
	let displayStartDateTimeToEndDateTime = '';
	if (event.is_all_day_event) {
		if (
			dayjs(event.start_date).format('dddd, MMMM D') ===
			dayjs(event.end_date).subtract(1, 'day').format('dddd, MMMM D')
		) {
			displayStartDateTimeToEndDateTime = `${dayjs(event.start_date).format(
				'dddd, MMMM D',
			)} • All-day`;
		} else {
			displayStartDateTimeToEndDateTime = `${dayjs(event.start_date).format(
				'dddd, MMMM D',
			)} – ${dayjs(event.end_date)
				.subtract(1, 'day')
				.format('dddd, MMMM D')} • All-day`;
		}
	} else {
		if (
			dayjs(event.start_at).format('dddd, MMMM D') ===
			dayjs(event.end_at).format('dddd, MMMM D')
		) {
			displayStartDateTimeToEndDateTime = `${dayjs(event.start_at).format(
				'dddd, MMMM D',
			)} • ${displayStartTime} – ${displayEndTime}`;
		} else {
			displayStartDateTimeToEndDateTime = `${dayjs(event.start_at).format(
				'ddd, MMM D',
			)} at ${displayStartTime} – ${dayjs(event.end_at).format(
				'ddd, MMM D',
			)} at ${displayEndTime}`;
		}
	}

	// Formats event description
	let note = event.description;
	let noteSubstrings = !!note
		? note
				.replace(/&amp;/g, '&')
				.replace(/<a href="(.*?)">/g, '')
				.replace(/<html-blob>/g, '')
				.replace(/&nbsp;/g, '')
				.split(note.includes('<br>') ? '<br>' : '\n')
		: [''];

	var doc = document.createElement('html');
	doc.innerHTML = !!note ? note : '';
	var links = doc.getElementsByTagName('a');
	let urls: any[] = [];

	for (var i = 0; i < links.length; i++) {
		urls.push(links[i].getAttribute('href'));
	}

	function fetchMembers() {
		axiosClient.get('/web/family/our/member').then((res) => {
			setMembers(res.data);
		});
	}

	function fetchCalendars() {
		axiosClient
			.get('/web/calendar', {
				params: {
					for_family: true,
				},
			})
			.then((res) => {
				setCalendars(res.data);
			});
	}

	useEffect(() => {
		if (isAuthenticated) {
			fetchMembers();
			fetchCalendars();
		}
	}, [isAuthenticated]);

	const memberIsAttendee = (member: IAPIFamilyMember) =>
		membersOfHearthEvent.map((attendee) => attendee.id).includes(member.id) ||
		membersOfGoogleEvent.map((attendee) => attendee.id).includes(member.id);

	return (
		<WithoutHeaderLayout>
			<ExceptionConfirmation
				open={showExceptionConfirmation}
				action="Delete"
				isExcludedEvent={isExcludedEvent}
				handleSelect={(selected: boolean) => setIsExcludedEvent(selected)}
				handleCancel={() => setShowExceptionConfirmation(false)}
				event={event}
			/>
			<DeleteConfirmation
				open={toggleDeleteEventModal}
				handleToggleModal={() =>
					setToggleDeleteEventModal(!toggleDeleteEventModal)
				}
				eventId={event.id}
			/>

			<div className="w-11/12 flex flex-col">
				{/* X to exit screen */}
				<Exit onExit={() => navigate(`/calendar`)} />

				{/* Title */}
				<p className="text-md text-grey-extraDark font-semibold">
					{event.subject}
				</p>

				{/* Start/End datetimes */}
				<p
					className={`text-sm text-grey-extraDark ${
						!event.recurrence_rule_id && 'mb-2'
					}`}>
					{displayStartDateTimeToEndDateTime}
				</p>
				{recurrenceRule && (
					<RepeatsOn
						freq={recurrenceRule.freq}
						interval={recurrenceRule.interval}
						dtStart={dayjs(recurrenceRule.dt_start)}
						byDay={recurrenceRule.by_day}
						byMonthDay={recurrenceRule.by_month_day}
						count={recurrenceRule.count}
						until={recurrenceRule.until}
					/>
				)}

				{/* Event attendee avatars */}
				<div className="my-4 flex flex-row space-x-4">
					{!!membersOfHearthEvent &&
						membersOfHearthEvent.map((member) => (
							<div key={member.id}>
								<SelectAvatarLayout
									isSelected={memberIsAttendee(member)}
									firstName={
										profileUser?.id === member.id
											? profileUser.first_name
											: member.first_name
									}
									showCheck={false}>
									<MemberAvatar
										avatarFilename={
											profileUser?.id === member.id
												? profileUser?.avatar_file_name
												: member.avatar_file_name
										}
									/>
								</SelectAvatarLayout>
							</div>
						))}
				</div>

				{/* Notes */}
				{!!event.description && (
					<>
						<p className="text-sm text-grey-extraDark font-semibold">Notes</p>
						<div
							style={{ maxHeight: 'calc(100vh / 2.5' }}
							className="overflow-auto flex flex-col mb-2">
							{noteSubstrings.map((substring) => {
								return (
									<div key={substring}>
										{substring[0] ? (
											<p className="text-sm text-grey-extraDark w-full">
												{substring.replace(/(<([^>]+)>)/gi, '')}
											</p>
										) : (
											<>
												<p style={{ fontSize: 16, color: 'transparent' }}>
													{'line break'}
												</p>
												<p className="text-sm text-grey-extraDark w-full">
													{substring.replace(/(<([^>]+)>)/gi, '')}
												</p>
											</>
										)}
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>

			{/* Edit/Delete buttons */}
			{/* If event is a hearth event the `Edit` and `Delete` Buttons will be visible  */}
			{/* Only Hearth Events can be edited for now */}
			<div
				className="w-full justify-center flex mt-2 pt-2"
				style={{ borderTopWidth: 0.5, borderTopColor: '#C4C4C4' }}>
				{event.is_hearth_event ? (
					<div className="flex w-11/12 mt-2 justify-between">
						<button
							className="text-xs text-rebrand-teal font-semibold"
							onClick={() =>
								isExcludedEvent && recurringEventsFeatureFlag
									? setShowExceptionConfirmation(true)
									: setToggleDeleteEventModal(true)
							}>
							Delete
						</button>
						<Button variant="outlined" onClick={handleEdit} className="h-8">
							Edit
						</Button>
					</div>
				) : (
					<div className="flex flex-row mt-2 items-center w-11/12">
						{externalCalendarIcon}
						<p className="text-sm text-tertiary">{externalCalendarName}</p>
					</div>
				)}
			</div>
		</WithoutHeaderLayout>
	);
}
