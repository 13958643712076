import { ReactComponent as ScreenTimeIcon } from 'assets/Icons/screen-time.svg';
import { ReactComponent as Party2Icon } from 'assets/Icons/party2.svg';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { ReactComponent as SadDinoImage } from 'assets/Images/sad-dino.svg';
import { useEffect, useState } from 'react';
import axiosClient from 'utils/axiosClient';
import loadingLotte from '../../components/lotties/circular-loading-bar.json';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import Link from 'components/Links/Link';
import {
	Button,
	Dialog,
	DialogContent,
	IconButton,
	TextField,
	Typography,
} from '@mui/material';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import useCurrentUser from 'stores/currentUser';
import { ICurrentUser } from 'utils/types';
import SlideUpDrawer from '../../components/Reusable/SlideUpDrawer';
import { ONBOARDING_SCREENS } from './onboardingFlow';
import OnboardingStepsLayout from '../../components/Layouts/OnboardingStepsLayout';

enum SubscriptionStatus {
	complete = 'complete',
	has_subscription = 'has_subscription',
	missing_subscription = 'missing_subscription',
}

interface ISubscriptionResponse {
	activate_at: null;
	activation_code: string;
	created_at: string;
	customer_id: string;
	end_at: string | null;
	external_subscription_id: string;
	family_id: number | null;
	id: string;
	status: string;
	updated_at: string;
}

interface ISubscriptionStatusResponse {
	user_status: SubscriptionStatus;
	family_status: SubscriptionStatus | null;
}

interface ICustomerSearchResponse {
	id: string;
	external_customer_id: string;
	email: string;
	first_name: string | null;
	last_name: string | null;
	phone: string | null;
	created_at: string;
	updated_at: string;
	history: any;
}

enum ActiveComponent {
	welcome,
	existing,
}

enum DrawerComponent {
	auto,
	code,
	miss,
	load,
	done,
}

enum NextAction {
	find,
	match,
	activate,
	missing,
}

enum CompleteAction {
	family,
	home,
}

enum SubscriptionErrorCode {
	ALEADY_ACTIVE = 10913,
	NOT_FOUND = 10910,
	ALREADY_USED = 10911,
	NOT_VALID = 10912,
}

interface IAction {
	action: Function;
}

interface ICompleteAction extends IAction {
	actionText: string;
}

function supportEmailWithUser(user: ICurrentUser | undefined): string {
	const content = user ? encodeURIComponent(`${user.email}`) : '';
	return `mailto:support@hearthdisplay.com?subject=Onboarding%20Activation%20Issue&body=I%20am%20having%20trouble%20activating%20my%20Hearth%20Display.%0A%0AThe%20account%20was%20created%20with%20${content}.%0A%0APLEASE%20INCLUDE%20ANY%20ADDITIONAL%20INFO.`;
}

function SubscriptionActivationLoading() {
	return (
		<div className="h-full flex flex-col text-center justify-center items-center">
			<h4 className="my-2 font-medium mt-auto">
				Activating your membership...
			</h4>
			<div className="w-3/5">
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: loadingLotte,
						rendererSettings: {
							preserveAspectRatio: 'xMidYMid slice',
						},
					}}
					height={'100%'}
					width={'100%'}
					isClickToPauseDisabled={true}
				/>
			</div>
			<div className="mt-auto"></div>
		</div>
	);
}

function SubscriptionActivationCodeEntry({ action }: IAction) {
	const user = useCurrentUser((store) => store.user);
	const [code, setCode] = useState('');
	const [open, setOpen] = useState(false);
	const [valid, setValid] = useState<boolean | null>(null);
	const [error, setError] = useState('');

	function validate(): boolean {
		const okLength = code.length === 8;
		const okChars = /^[A-NP-Z0-9]+$/.test(code);
		if (okLength && okChars) {
			setValid(true);
		} else {
			if (!okLength) {
				setError('Activation code must be 8 characters');
			} else {
				setError('Activation code is invalid');
			}
			setValid(false);
		}
		return okLength && okChars;
	}

	function continueOnClick() {
		setError('');
		if (!validate()) {
			return;
		}
		action(code, (err: string) => {
			setValid(false);
			setError(err);
		});
	}

	return (
		<>
			<div className="flex flex-col justify-center">
				<h4 className="mt-10 mb-2 font-medium text-center">
					Activate your Hearth
				</h4>
				<p className="text-center w-full">
					To activate your membership, enter the membership activation code sent
					to the purchase email address upon shipping.
				</p>

				<TextField
					name="name"
					placeholder="8-character code, e.g. 1A890XFG"
					onChange={(e) => setCode(e.target.value.toUpperCase())}
					value={code}
					inputProps={{
						minLength: 5,
						maxLength: 10,
					}}
					error={valid === false}
					helperText={valid === false ? error : undefined}
					style={{ marginTop: 16, marginBottom: 16 }}
				/>
			</div>
			<Button variant="contained" onClick={continueOnClick} fullWidth>
				Continue
			</Button>
			<div className="mt-4">
				<p className="text-xxs text-center">
					<Dialog onClose={() => setOpen(false)} open={open}>
						<DialogContent dividers style={{ padding: 24, paddingTop: 32 }}>
							<h6 className="font-medium">Where do I find the code?</h6>
							<Typography gutterBottom>
								You'll find your activation code in an email from &nbsp;
								<a
									href="mailto:hello@hearthdisplay.com"
									target="_blank"
									className="underline">
									hello@hearthdisplay.com
								</a>
								. This code was sent the day your order shipped, or after the
								purchase of a standalone membership.
							</Typography>
							<h6 className="font-medium mt-4">Was this a gift?</h6>
							<Typography gutterBottom>
								Email{' '}
								<a
									href={supportEmailWithUser(user)}
									target="_blank"
									className="underline">
									support@hearthdisplay.com
								</a>
								. – please include your Hearth account email.
							</Typography>
						</DialogContent>
						<IconButton
							aria-label="close"
							onClick={() => setOpen(false)}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								padding: 0,
								width: 32,
								height: 32,
							}}>
							<CloseIcon />
						</IconButton>
					</Dialog>
					<Link
						label="Need help?"
						openInNewTab={false}
						onClick={() => setOpen(true)}
					/>
				</p>
			</div>
		</>
	);
}

function SubscriptionActivationAuto({ action }: IAction) {
	return (
		<div className="flex flex-col h-full">
			<h4 className="my-2 font-semibold">
				We found a membership associated with this email
			</h4>
			<p className="mb-4">
				Your Hearth comes with a 3-month free trial of our Family Membership
				features. Tap continue to activate your trial and set up your family.
			</p>
			<Button variant="contained" onClick={() => action()} fullWidth>
				Continue
			</Button>
		</div>
	);
}

function SubscriptionActivationComplete({
	action,
	actionText,
}: ICompleteAction) {
	return (
		<div className="h-full flex flex-col text-center justify-center items-center">
			<Party2Icon className="mt-auto" />
			<h4 className="mt-5 mb-2 font-medium">Success!</h4>
			<p className="mb-auto">
				Your Hearth Membership is now active. You can cancel this at any time.
				Now, let’s set up your family.
			</p>
			<Button variant="contained" onClick={() => action()} fullWidth>
				{actionText}
			</Button>
		</div>
	);
}

function SubscriptionActivationMissing({ action }: IAction) {
	const user = useCurrentUser((store) => store.user);

	return (
		<div className="h-full flex flex-col text-center justify-center items-center">
			<SadDinoImage className="mt-auto mb-5" />
			<div className="text-center mb-auto">
				<p className="mb-3">
					We were unable to activate your membership. Make sure you've added the
					correct code by tapping Continue to try again. If the issue persists,
					email{' '}
					<a
						href={supportEmailWithUser(user)}
						target="_blank"
						className="underline">
						support@hearthdisplay.com
					</a>
					.
				</p>
				<a className="text-primary text-xs font-semibold" href="">
					Contact Support
				</a>
			</div>
			<Button variant="contained" onClick={() => action()} fullWidth>
				Continue
			</Button>
		</div>
	);
}

function SubscriptionActivationWelcome({ action }: IAction) {
	return (
		<>
			<div className="h-full flex flex-col items-center text-center justify-center">
				<ScreenTimeIcon />
				<h4 className="mt-8 mb-2 font-medium">Account created</h4>
				<p className="mb-7">
					Your Hearth Display is now linked to your account. This will also be
					your login for the Companion App.
				</p>
			</div>
			<div className="w-full mt-auto mb-3">
				<Button variant="contained" onClick={() => action()} fullWidth>
					Continue
				</Button>
			</div>
		</>
	);
}

export default function SubscriptionActivation() {
	const navigate = useNavigate();
	const { isAuthed, isFetched } = useCurrentUser((store) => ({
		isAuthed: store.isAuthenticated,
		isFetched: store.isCurrentUserFetched,
	}));

	const [nextAction, setContinueAction] = useState<NextAction>(NextAction.find);
	const [completeAction, setCompleteAction] = useState<CompleteAction>(
		CompleteAction.family,
	);
	const [activeComponent, setActiveComponent] = useState<ActiveComponent>(
		ActiveComponent.welcome,
	);
	const [drawerComponent, setDrawerComponent] = useState<DrawerComponent>();

	async function getSubscriptionStatus(): Promise<ISubscriptionStatusResponse | null> {
		try {
			const response = await axiosClient.get<ISubscriptionStatusResponse>(
				'/onboarding/subscription/status',
			);
			switch (response.data.user_status) {
				case SubscriptionStatus.complete:
					if (response.data.family_status) {
						setCompleteAction(CompleteAction.family);
					}
					setDrawerComponent(DrawerComponent.done);
					break;
			}
			return response.data;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	async function completeActivation() {
		// separate preactivation check
		const status = await getSubscriptionStatus();
		if (status?.family_status) {
			navigate('/onboard/complete');
		} else {
			navigate('/onboard/profile');
		}
	}

	function findCustomer() {
		axiosClient
			.get<ICustomerSearchResponse>('/onboarding/subscription/search-user')
			.then((res) => {
				setActiveComponent(ActiveComponent.existing);
				setContinueAction(NextAction.match);
			})
			.catch((err) => {
				setDrawerComponent(DrawerComponent.code);
				setContinueAction(NextAction.activate);
			});
	}

	function activateUser() {
		setDrawerComponent(DrawerComponent.load);
		axiosClient
			.post('/onboarding/subscription/activate-user')
			.then(() =>
				setTimeout(() => setDrawerComponent(DrawerComponent.done), 1000),
			)
			.catch((err) => {
				// TODO: report to sentry
				console.error(err);
				setDrawerComponent(DrawerComponent.miss);
			});
	}

	function activateUserByCode(code: string, setError: Function) {
		axiosClient
			.post('/onboarding/subscription/activate-code', { activation_code: code })
			.then(() => {
				setDrawerComponent(DrawerComponent.load);
				setTimeout(() => {
					setDrawerComponent(DrawerComponent.done);
				}, 1000);
			})
			.catch(async (err) => {
				const error = err.response?.data;
				if (!error?.error_code) {
					console.error(err);
					setDrawerComponent(DrawerComponent.miss);
				} else {
					switch (error.error_code) {
						case SubscriptionErrorCode.ALEADY_ACTIVE:
							await getSubscriptionStatus();
							break;

						case SubscriptionErrorCode.ALREADY_USED:
						case SubscriptionErrorCode.NOT_FOUND:
						case SubscriptionErrorCode.NOT_VALID:
							setError(error.detail);
							break;

						default:
							// TODO: report to sentry
							console.error(err);
							setDrawerComponent(DrawerComponent.miss);
					}
				}
			});
	}

	const actionMap: { [key in NextAction]: Function } = {
		[NextAction.find]: findCustomer,
		[NextAction.match]: activateUser,
		[NextAction.activate]: (code: string, setError: Function) =>
			activateUserByCode(code, setError),
		[NextAction.missing]: () => setDrawerComponent(DrawerComponent.code),
	};

	const completeMap: { [key in CompleteAction]: ICompleteAction } = {
		[CompleteAction.family]: {
			actionText: 'Continue with family setup',
			action: async () => completeActivation(),
		},
		[CompleteAction.home]: {
			actionText: 'Complete',
			action: async () => completeActivation(),
		},
	};

	useEffect(() => {
		if (isFetched && !isAuthed) {
			navigate('/onboard/authenticate');
		}
	}, [isAuthed, isFetched]);

	useEffect(() => {
		getSubscriptionStatus();
		setLastOnboardingURLCookie();
	}, []);

	return (
		<OnboardingStepsLayout
			progress={
				ONBOARDING_SCREENS[
					ActiveComponent.welcome ? 'createAccount' : 'activation'
				].progress
			}>
			{activeComponent === ActiveComponent.welcome &&
				drawerComponent == undefined && (
					<SubscriptionActivationWelcome action={actionMap[nextAction]} />
				)}
			{activeComponent === ActiveComponent.existing &&
				drawerComponent == undefined && (
					<SubscriptionActivationAuto action={actionMap[nextAction]} />
				)}

			<SlideUpDrawer
				maxHeight="95%"
				height="95%"
				open={drawerComponent !== undefined}>
				<div className="w-full h-full p-6">
					{drawerComponent === DrawerComponent.load && (
						<SubscriptionActivationLoading />
					)}
					{drawerComponent === DrawerComponent.code && (
						<SubscriptionActivationCodeEntry action={actionMap[nextAction]} />
					)}
					{drawerComponent === DrawerComponent.miss && (
						<SubscriptionActivationMissing
							action={actionMap[NextAction.missing]}
						/>
					)}
					{drawerComponent === DrawerComponent.done && (
						<SubscriptionActivationComplete
							action={completeMap[completeAction].action}
							actionText={completeMap[completeAction].actionText}
						/>
					)}
				</div>
			</SlideUpDrawer>
		</OnboardingStepsLayout>
	);
}
