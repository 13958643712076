import { useState } from 'react';
import dayjs from 'dayjs';

import DateTimePicker from 'components/Forms/DateTimePicker';
import DateTimeDisplay from 'components/Forms/DateTimeDisplay';
import { formatEventTime } from 'utils/dateTimeHelper';
import ReusableModal from '../../Modals/ReusableModal';

interface IPropTypes {
	handleChange: (arg0: string, arg1: string | number | undefined) => void;
	dtStart: dayjs.Dayjs;
}

interface ITime {
	hours: number;
	minutes: number;
	amPm: string;
}

export default function RecurrenceStart({ handleChange, dtStart }: IPropTypes) {
	const [startDatePickerVisible, setStartDatePickerVisible] =
		useState<boolean>(false);

	function handleSubmit(selectedDate: dayjs.Dayjs) {
		handleChange('dt_start', formatEventTime(selectedDate, true));
		setStartDatePickerVisible(false);
	}

	return (
		<>
			<ReusableModal
				open={startDatePickerVisible}
				handleToggleModal={() =>
					setStartDatePickerVisible(!startDatePickerVisible)
				}>
				<DateTimePicker
					onSubmit={(time: ITime, selectedDate: dayjs.Dayjs) =>
						handleSubmit(selectedDate)
					}
					onCancel={() => setStartDatePickerVisible(false)}
					pickingStart={false}
					pickingEnd={true}
					startDate={dayjs()}
					endDate={dtStart}
					timePickerVisible={false}
					timeErrorState={false}
				/>
			</ReusableModal>

			<div className="mt-4 flex items-center">
				<p className="text-md font-semibold w-60">Starts</p>
				<DateTimeDisplay
					handleClick={() => setStartDatePickerVisible(true)}
					isAllDayEvent={true}
					dateTime={dtStart}
				/>
			</div>
		</>
	);
}
