import { IAPIEvent, IAPIFamilyMember } from 'utils/types';
import MemberAvatar from '../Todos/Assignees/MemberAvatar';

interface IProps {
	event: IAPIEvent;
	members: IAPIFamilyMember[];
	profileAvatar: string | null;
}

export default function Attendees({ event, members, profileAvatar }: IProps) {
	const eventAttendees = event.attendee_list.map(
		(attendee) => attendee.user_id,
	);

	const membersOfHearthEvent = members.filter((member) =>
		eventAttendees.includes(member.id),
	);

	return (
		<div className="flex -space-x-3">
			{!!profileAvatar && (
				<div>
					<MemberAvatar
						avatarFilename={profileAvatar}
						className="w-6 h-6 border-white border-1 bg-white"
					/>
				</div>
			)}
			{!profileAvatar &&
				membersOfHearthEvent.map((member) => (
					<div key={member.id}>
						<MemberAvatar
							avatarFilename={member.avatar_file_name}
							className="w-6 h-6 border-white border-1 bg-white"
						/>
					</div>
				))}
		</div>
	);
}
