import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';

import InfoPopup from './InfoPopup';
import {
	validateSimpleDay,
	validateSimpleMonth,
	validateSimpleYear,
} from 'utils/dateTimeHelper';
import { InputPopupContext } from 'components/Reusable/InputPopupContext';
import { IMember } from 'utils/types';

interface IBirthdayInput {
	index?: number;
	handleChange: (arg0: number, agr1: Partial<IMember>) => void;
	shouldShowError?: boolean;
	birthday?: Date;
	textBelow?: string;
}

interface IBirthdayErrors {
	year: string;
	month: string;
	day: string;
}

export default function BirthdayInput({
	index = 0,
	handleChange,
	shouldShowError,
	birthday,
	textBelow,
}: IBirthdayInput) {
	const dayInput = useRef<any>();
	const monthInput = useRef<any>();
	const yearInput = useRef<any>();

	const initialBirthday = {
		day: '',
		month: '',
		year: '',
	};

	if (birthday) {
		initialBirthday.day = dayjs(birthday).date().toString();
		initialBirthday.month = (dayjs(birthday).month() + 1).toString();
		initialBirthday.year = dayjs(birthday).year().toString();
	}
	const { popUp, updateInputPopup } = useContext(InputPopupContext);
	const [day, setDay] = useState<string>(initialBirthday.day);
	const [month, setMonth] = useState<string>(initialBirthday.month);
	const [year, setYear] = useState<string>(initialBirthday.year);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [localError, setLocalError] = useState<boolean>(false);
	const [focused, setFocused] = useState(false);

	const label = birthday ? 'Birthday' : 'Birthday*';

	const getColor = () => {
		if (localError) {
			return 'rebrand-error';
		}

		if (focused) {
			return 'rebrand-teal';
		}

		return 'rebrand-gray-medium';
	};

	useEffect(() => {
		if (
			!!textBelow &&
			validateSimpleDay(day, month) !== '' &&
			validateSimpleMonth(month) !== '' &&
			validateSimpleYear(year)
		) {
			setLocalError(true);
			setErrorMessage('Please enter a valid birthday');
		}
	}, [textBelow, day, month, year]);

	function onDayInputChange(e: React.FormEvent<HTMLInputElement>) {
		setDay(e.currentTarget.value);

		setBirthday(
			validateBirthday(e.currentTarget.value, 'day'),
			year + '-' + month + '-' + e.currentTarget.value,
		);

		if (e.currentTarget.value.length === 2 && year === '') {
			yearInput.current.focus();
		} else if (e.currentTarget.value.length === 2 && year !== '') {
			dayInput.current.blur();
		}
	}

	function onMonthInputChange(e: React.FormEvent<HTMLInputElement>) {
		setMonth(e.currentTarget.value);

		setBirthday(
			validateBirthday(e.currentTarget.value, 'month'),
			year + '-' + e.currentTarget.value + '-' + day,
		);

		if (e.currentTarget.value.length === 2 && day === '') {
			dayInput.current.focus();
		} else if (e.currentTarget.value.length === 2 && day !== '') {
			monthInput.current.blur();
		}
	}

	function onYearInputChange(e: React.FormEvent<HTMLInputElement>) {
		setYear(e.currentTarget.value);

		setBirthday(
			validateBirthday(e.currentTarget.value, 'year'),
			e.currentTarget.value + '-' + month + '-' + day,
		);

		if (e.currentTarget.value.length === 4) {
			yearInput.current.blur();
		}
	}

	function handleBirthdayPopup() {
		updateInputPopup({
			index: index,
			type: 'birthday',
			message:
				'We ask for birthdays to provide age-based recommendations on our To-dos and Routines.',
			showNotification: popUp.showNotification,
		});
	}

	function validateBirthday(value: string, datePart: keyof IBirthdayErrors) {
		const errors: IBirthdayErrors = {
			year: '',
			month: '',
			day: '',
		};
		let error = '';

		errors.month =
			datePart === 'month'
				? validateSimpleMonth(value)
				: validateSimpleMonth(month);
		errors.day =
			datePart === 'day'
				? validateSimpleDay(value, month)
				: validateSimpleDay(day, month);
		errors.year =
			datePart === 'year'
				? validateSimpleYear(value)
				: validateSimpleYear(year);

		let errorKey: keyof IBirthdayErrors;
		for (errorKey in errors) {
			if (!!errors[errorKey]) {
				error = errors[errorKey];
				if (errorKey === datePart) {
					break;
				}
			}
		}

		setErrorMessage(error);
		setLocalError(error !== '');

		return error;
	}

	function setBirthday(error: string, dateString: string) {
		if (!!error) {
			handleChange(index, { errors: { birthday: error }, birthday: undefined });
		} else {
			handleChange(index, {
				birthday: dayjs(dateString).format('YYYY-MM-DDT00:00:00Z'),
			});
		}
	}

	return (
		<div className="relative">
			<p
				className={`z-10 absolute -top-2 leading-4 left-3 px-1 bg-white text-xxs text-${getColor()}`}>
				{label}
			</p>
			<div className="relative">
				<div
					className={`text-sm justify-start items-center flex flex-row w-full px-3 py-4 rounded border-1 border-${getColor()}`}>
					<input
						ref={monthInput}
						size={3}
						className="text-center focus:text-left overflow-visible focus:outline-none border-none"
						placeholder="MM"
						onFocus={() => setFocused(true)}
						onBlur={(e) => setFocused(false)}
						inputMode="numeric"
						type="text"
						minLength={2}
						maxLength={2}
						value={month}
						onChange={onMonthInputChange}
						pattern="[0-9]{2}"
					/>

					<span
						className={`text-sm mx-1 justify-center text-${
							month == '' ? 'grey-blue' : 'rebrand-teal-text'
						}`}>
						/
					</span>

					<input
						ref={dayInput}
						size={3}
						className="text-center focus:outline-none border-none"
						placeholder="DD"
						onFocus={() => setFocused(true)}
						onBlur={(e) => setFocused(false)}
						inputMode="numeric"
						type="text"
						minLength={2}
						maxLength={2}
						value={day}
						onChange={onDayInputChange}
						pattern="[0-9]{2}"
					/>

					<span
						className={`text-sm mx-1 justify-center text-${
							day == '' ? 'grey-blue' : 'rebrand-teal-text'
						}`}>
						/
					</span>

					<input
						ref={yearInput}
						size={5}
						className="text-center focus:outline-none border-none"
						placeholder="YYYY"
						onFocus={() => setFocused(true)}
						onBlur={(e) => setFocused(false)}
						inputMode="numeric"
						type="text"
						minLength={4}
						maxLength={4}
						value={year}
						onChange={onYearInputChange}
						pattern="[0-9]{4}"
					/>
				</div>
				<InfoPopup
					info={popUp.message}
					popup={
						popUp.index === index &&
						popUp.type === 'birthday' &&
						popUp.showNotification
					}
					handleClick={handleBirthdayPopup}
				/>
			</div>

			{localError && errorMessage && (
				<p className="ml-4 mt-1 text-xxs text-error">{errorMessage}</p>
			)}
		</div>
	);
}
