import React, { useContext } from 'react';
import { IRoutine, IRoutineTemplate, NotificationType } from 'utils/types';
import RoutineForm, {
	IRoutineApiParams,
} from 'components/Routines/RoutineForm';
import axiosClient from 'utils/axiosClient';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import { convertKeys, toSnakeCase } from 'utils/responseMapper';
import { actionTypes, useRoutinesDispatch } from 'contexts/RoutinesContext';
import useCurrentUser from 'stores/currentUser';
import { generateRoutinePayload } from 'utils/routineTracking';
import { EventName, trackEvent } from 'utils/segmentClient';
import { addLocalTimezone } from 'utils/dateTimeHelper';

interface RoutineCreateProps {
	routine?: IRoutineTemplate | IRoutine | undefined;
}

const CreateRoutine: React.FC<RoutineCreateProps> = ({ routine }) => {
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);
	const dispatch = useRoutinesDispatch();
	const { user } = useCurrentUser((store) => ({
		user: store.user,
	}));

	const createRoutineApi = ({
		routineFormData,
		routineSteps = [],
		routineUsers = [],
		templateName = 'custom',
	}: IRoutineApiParams) => {
		const { name, recurrenceDetails, image } = routineFormData;

		const userIds = routineUsers.map((user) => user.id);

		if (recurrenceDetails) {
			recurrenceDetails.dtStart = addLocalTimezone(
				dayjs(recurrenceDetails.dtStart),
			);
		}

		axiosClient
			.post('/web/routines', {
				name: name,
				image: image,
				start_at: dayjs().subtract(1, 'week').toISOString(),
				end_at: dayjs().add(1, 'week').toISOString(),
				user_ids: userIds,
				steps: convertKeys(routineSteps, toSnakeCase),
				recurrence_details: convertKeys(recurrenceDetails, toSnakeCase),
			})
			.then(() => {
				dispatch({ type: actionTypes.RESET_ROUTINE_CONTEXT });

				trackEvent(
					EventName.RoutineCreated,
					generateRoutinePayload(
						user,
						routineUsers,
						routineFormData,
						routineSteps,
						templateName,
					),
				);

				updateNotification({
					message: 'Routine added!',
					showNotification: true,
					type: NotificationType.SUCCESS,
				});

				navigate('/routines');
			})
			.catch(() => {
				updateNotification({
					message: 'Problem adding the routine. Please try again.',
					type: NotificationType.ERROR,
					showNotification: true,
				});
			});
	};

	return <RoutineForm routine={routine} onSubmit={createRoutineApi} />;
};

export default CreateRoutine;
