import React from 'react';
import MemberAvatar from 'components/CompanionApp/Todos/Assignees/MemberAvatar';
import MemberName from 'components/CompanionApp/Todos/Assignees/MemberName';

interface IPropTypes {
	onSelectionChange: (arg0: boolean) => void;
	isSelected: boolean;
	avatar_file_name: string;
	first_name: string;
}

const AddAssignee: React.FC<IPropTypes> = ({
	onSelectionChange,
	isSelected,
	avatar_file_name,
	first_name,
}) => (
	<div className="flex flex-col justify-center align-top h-full">
		<button onClick={() => onSelectionChange(!isSelected)}>
			<div
				className={`transition-opacity ${
					isSelected ? 'opacity-100' : 'opacity-40'
				}`}>
				<MemberAvatar
					avatarFilename={avatar_file_name}
					className="w-12 h-12"
					showCheckmark={isSelected}
				/>
			</div>
		</button>
		{first_name && <MemberName first_name={first_name} />}
	</div>
);

export default AddAssignee;
