import create from 'zustand';
import axiosClient from 'utils/axiosClient';
import {
	IAPIFeatureStatus,
	IFeatureStatus,
	IAPIFeatures,
	IFeatures,
} from 'utils/types';

interface IFeatureStore {
	features: IFeatures;
	isFetching: boolean;
	lastFetchedAt?: Date;
	fetchFeatures: () => Promise<void>;
}

const defaultFeatures: IFeatures = {
	calendar: {
		isEnabled: true,
		isAvailable: true,
	},
	sleepMode: {
		isEnabled: true,
		isAvailable: true,
	},
	todos: {
		isEnabled: true,
		isAvailable: true,
	},
	hearthHelper: {
		isEnabled: true,
		isAvailable: true,
	},
	routines: {
		isEnabled: true,
		isAvailable: true,
	},
	privacyMode: {
		isEnabled: true,
		isAvailable: true,
	},
	weatherWidget: {
		isEnabled: true,
		isAvailable: true,
	},
	profileColor: {
		isEnabled: true,
		isAvailable: true,
	},
	profileNotes: {
		isEnabled: true,
		isAvailable: true,
	},
};

const convertAPIFeatureStatus = (
	apiFeatureStatus: IAPIFeatureStatus,
): IFeatureStatus => {
	return {
		isEnabled: apiFeatureStatus.is_enabled,
		isAvailable: apiFeatureStatus.is_available,
	};
};

const convertAPIFeatures = (apiFeatures: IAPIFeatures): IFeatures => {
	return {
		calendar: convertAPIFeatureStatus(apiFeatures.calendar),
		todos: convertAPIFeatureStatus(apiFeatures.todos),
		hearthHelper: convertAPIFeatureStatus(apiFeatures.hearth_helper),
		routines: convertAPIFeatureStatus(apiFeatures.routines),
		sleepMode: convertAPIFeatureStatus(apiFeatures.sleep_mode),
		privacyMode: convertAPIFeatureStatus(apiFeatures.privacy_mode),
		weatherWidget: convertAPIFeatureStatus(apiFeatures.weather_widget),
		profileColor: convertAPIFeatureStatus(apiFeatures.profile_color),
		profileNotes: convertAPIFeatureStatus(apiFeatures.profile_notes),
		appStoreLink: convertAPIFeatureStatus(apiFeatures.app_store_link),
	};
};

const restoreFeatures = (): IFeatures => {
	const features = localStorage.getItem('features');
	return features ? JSON.parse(features) : defaultFeatures;
};

const storeFeatures = (features: IFeatures) => {
	localStorage.setItem('features', JSON.stringify(features));
};

const useFeatures = create<IFeatureStore>((set, get) => ({
	features: restoreFeatures(),
	isFetching: false,

	fetchFeatures: async () => {
		if (get().isFetching) return;

		set((state) => ({ ...state, isFetching: true }));

		await axiosClient
			.get('/web/features')
			.then((response) => {
				if (response.status === 200) {
					const features = convertAPIFeatures(response.data.features);
					storeFeatures(features);
					set((state) => ({
						...state,
						features: features,
						isFetching: false,
						lastFetchedAt: new Date(),
					}));
				}
			})
			.catch(() => {
				set((state) => ({
					...state,
					lastFetchedAt: new Date(),
					isFetching: false,
				}));
			});
	},
}));

export default useFeatures;
