import { ChangeEvent, FocusEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import Input from 'components/Forms/Input';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import OnboardingStepsLayout from 'components/Layouts/OnboardingStepsLayout';
import { ONBOARDING_SCREENS } from '../onboardingFlow';
import PageTitle from 'components/Reusable/PageTitle';
import { Button, TextField } from '@mui/material';
import PasswordInput from 'components/Forms/PasswordInput';

enum EmailErrorStates {
	INVALID = 'Invalid Email',
	REQUIRED = 'Required',
	ALREADY_USED = 'This email is already in use.',
}

enum PasswordErrorStates {
	REQUIRED = 'Required',
	LENGTH = 'Password needs to be between 8-40 characters.',
}

interface IFormState {
	email: string;
	password: string;
}

export default function CreateAccount() {
	const [formState, setFormState] = useState<IFormState>({
		email: '',
		password: '',
	});
	const [emailErrorState, setEmailErrorState] = useState<EmailErrorStates>();
	const [passwordErrorState, setPasswordErrorState] =
		useState<PasswordErrorStates>();
	const fetchCurrentUser = useCurrentUser((store) => store.fetchCurrentUser);
	const navigate = useNavigate();
	const location = useLocation();

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState((state) => ({
			...state,
			[event.target.name]: event.target.value,
		}));
		if (event.target.name === 'email') {
			setEmailErrorState(undefined);
		} else {
			setPasswordErrorState(undefined);
		}
	}

	function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
		if (event.target.name === 'email' && formState.email === '') {
			setEmailErrorState(EmailErrorStates.REQUIRED);
		} else if (event.target.name === 'password' && formState.password === '') {
			setPasswordErrorState(PasswordErrorStates.REQUIRED);
		}
	}

	async function handleSubmitForOnboarding() {
		return await axiosClient.post('/onboarding/session/register_with_email', {
			email: formState.email,
			password: formState.password,
			onboarding_token: window.localStorage.getItem('ot'),
		});
	}

	async function handleSubmitForInvitation() {
		return await axiosClient.post(
			'/onboarding/session/invite/register_with_email',
			{
				email: formState.email,
				password: formState.password,
				invitation_token: window.localStorage.getItem('it'),
			},
		);
	}

	async function handleSubmit() {
		if (formState.password.length < 8 || formState.password.length > 40) {
			setPasswordErrorState(PasswordErrorStates.LENGTH);
			return;
		}

		let response;
		try {
			if (location.pathname.startsWith('/onboard')) {
				response = await handleSubmitForOnboarding();
			} else if (location.pathname.startsWith('/join-family')) {
				response = await handleSubmitForInvitation();
			} else {
				throw new Error('Unexpected URL path');
			}

			if (response.status === 201) {
				console.log('success 201', response);
				await fetchCurrentUser();
				console.log('fetched current user', response.data.email_pk);
				navigate(
					`${
						location.pathname.startsWith('/onboard')
							? `/onboard/verify-email-address/${response.data.email_pk}`
							: `/join-family/verify-email-address/${response.data.email_pk}`
					}`,
				);
			} else {
				throw new Error('Unexpected API Response');
			}
		} catch (e) {
			const errorStatus = (e as AxiosError)?.response?.status;

			if (errorStatus === 422) {
				setEmailErrorState(EmailErrorStates.INVALID);
			} else if (errorStatus === 400) {
				setEmailErrorState(EmailErrorStates.ALREADY_USED);
			} else {
				navigate('/error');
			}
		}
	}

	return (
		<OnboardingStepsLayout progress={ONBOARDING_SCREENS.addEmail.progress}>
			<PageTitle title="Create an account" className="mb-6" />
			<form onSubmit={handleSubmit} className="space-y-6">
				<TextField
					label="Email"
					type="email"
					name="email"
					value={formState.email}
					onChange={handleChange}
					onBlur={handleOnBlur}
					fullWidth
					error={!!emailErrorState}
					helperText={emailErrorState}
					variant="outlined"
					required
				/>
				<PasswordInput
					value={formState.password}
					onChange={handleChange}
					error={Boolean(passwordErrorState)}
					onBlur={handleOnBlur}
					helperText={
						!!passwordErrorState
							? passwordErrorState
							: PasswordErrorStates.LENGTH
					}
				/>
				<Button
					variant="contained"
					fullWidth
					onClick={handleSubmit}
					disabled={!!emailErrorState || !!passwordErrorState}>
					Agree and continue
				</Button>
			</form>
		</OnboardingStepsLayout>
	);
}
