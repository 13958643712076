import { useEffect, useState } from 'react';
import { ReactComponent as Exit } from 'assets/rebrand/exit.svg';
import Cookies from 'js-cookie';
import { appStoreUrl, companionAppStoreLink } from 'utils/variables';
import { useLocation } from 'react-router-dom';
import { useIsCompanionAppAccess } from 'hooks/companionAppHooks';

export default function CompanionAppStoreHeader() {
	const accessingFromCompanionApp = useIsCompanionAppAccess();
	const cookieKey = 'companion_app_store_link_visibility';
	const [isVisible, setIsVisible] = useState(
		Cookies.get(cookieKey) !== 'false',
	);

	const location = useLocation();
	const hideOnPaths = ['/menu', '/onboard', '/setup', '/join-family']; // List of paths where the component should be hidden

	useEffect(() => {
		if (Cookies.get(cookieKey) === 'false') {
			setIsVisible(false);
		}
	}, []);

	const handleClose = () => {
		setIsVisible(false);
		Cookies.set(cookieKey, 'false', { expires: 365 }); //Cookie Expires in 1 year
	};

	const headerShouldSHow = () => {
		return (
			!accessingFromCompanionApp && // Check if the user is accessing the app from a webview
			companionAppStoreLink && // Check if the companion app store link is enabled
			isVisible && // Check if the cookie is set to hide the component
			!hideOnPaths.some((path) => location.pathname.includes(path)) // Check if the component should be hidden on the current path
		);
	};

	if (headerShouldSHow()) {
		return (
			<div
				className={`flex h-16 flex items-center justify-between w-full border-b border-rebrand-grey-light 
			 px-18 bg-yellow-bright`}>
				<div className="flex-1 text-center">
					<span className="place-self-center font-normal text-xs leading-4">
						Our mobile app is here!
						<a
							className="pl-1 underline"
							href={appStoreUrl}
							rel="noopener noreferrer"
							target="_blank">
							Download on iOS
						</a>
						{'\n'}(Android coming soon)
					</span>
				</div>
				<Exit onClick={handleClose} className="fill-current" />
			</div>
		);
	}

	return null;
}
