import { Button } from '@mui/material';

interface IContinueButtonProps {
	text?: string;
	handleClick?: () => void;
	disabled?: boolean;
}

const FooterButton = ({
	text = 'Continue',
	disabled,
	handleClick,
}: IContinueButtonProps) => (
	<Button
		variant="contained"
		fullWidth
		disabled={disabled}
		onClick={handleClick}>
		{text}
	</Button>
);

export default FooterButton;
