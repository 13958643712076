import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextContainer from 'components/onboarding/TextContainer';
import axiosClient from 'utils/axiosClient';
import PrivacyPolicy from 'components/onboarding/AddFamilyMembers/PrivacyPolicy';
import { ReactComponent as BackArrow } from 'assets/Icons/Arrows/back-arrow.svg';
import NewProfile from 'components/Profiles/NewProfile';
import { AvatarSrc, IMember, NotificationType } from 'utils/types';
import { InputPopupProvider } from 'components/Reusable/InputPopupContext';
import { validateEmailString, verifyUniqueEmail } from 'utils/errorCheck';
import { getRandomAvatar, updateProfilePicture } from 'utils/avatars';
import { UpdateNotificationContext } from 'contexts/NotificationContext';
import axios from 'axios';
import { setLastOnboardingURLCookie } from 'utils/onboardingHelpers';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';
import { Button } from '@mui/material';
import OnboardingStepsLayout from '../../components/Layouts/OnboardingStepsLayout';
import { ONBOARDING_SCREENS } from './onboardingFlow';
import {
	useIsCompanionAppAccess,
	usePostMessageToCompanionApp,
} from 'hooks/companionAppHooks';

interface IProps {
	type: 'onboarding' | 'web';
}

export default function AddMembers({ type }: IProps) {
	const navigate = useNavigate();
	const updateNotification = useContext(UpdateNotificationContext);
	const postMessageToCompanionApp = usePostMessageToCompanionApp();

	const [allowSubmission, setAllowSubmission] = useState<boolean>(false);
	const [memberList, setMemberList] = useState<IMember[]>([]);

	const [agreed, setAgreed] = useState<boolean>(false);
	const [privacyPolicyError, setPrivacyPolicyError] = useState<boolean>(false);
	const shouldShowPrivacyPolicy = memberList.some(
		(member) => !member.isResponsibleAdult,
	);

	const usedAvatars = memberList.map((member) => member.avatar);

	useEffect(() => {
		setLastOnboardingURLCookie();
	}, []);

	const addMember = () => {
		setAllowSubmission(true);

		setMemberList([
			...memberList,
			{
				name: '',
				avatar: '',
				email: '',
				birthday: undefined,
				isResponsibleAdult: true,
				isError: false,
				shouldShowError: false,
				errors: {},
				touched: false,
				profileType: '',
				profilePicture: {
					filename: getRandomAvatar(usedAvatars),
					avatarSrc: AvatarSrc.LOCAL,
				},
			},
		]);
	};

	function navigatePageBack() {
		navigate(-1);
	}

	function removeMember(index: number) {
		setMemberList(() => {
			const newState = [...memberList];
			newState.splice(index, 1);
			if (newState.length === 0) setAllowSubmission(false);
			return newState;
		});
	}

	async function handleSubmit() {
		try {
			for (const member of memberList) {
				if (!!member.email) {
					await axiosClient
						.post(`/${type}/family/our/invite`, {
							contact_type: 'email',
							value: member.email,
							first_name: member.name,
							is_responsible_adult: member.isResponsibleAdult,
							birthday: member.birthday,
						})
						.then((res) => {
							if (res.status === 200) {
								postMessageToCompanionApp('family_members_updated');
							}
						});
				} else {
					const { filename, avatarSrc } = member.profilePicture;

					let payloadData = {
						first_name: member.name,
						birthday: member.birthday,
						is_hearth_dino: avatarSrc === AvatarSrc.LOCAL,
					};

					if (avatarSrc === AvatarSrc.LOCAL && filename) {
						payloadData = { ...payloadData, ...{ avatar_file_name: filename } };
					}

					await axiosClient
						.post(`/${type}/family/our/child`, payloadData)
						.then(async (res) => {
							const dataUrlFilename = member.profilePicture.filename;
							// Upload profile picture if the user has added one. This nested API call is necessary
							// to properly encode the image data within FastAPI and to retrieve the user id.
							if (avatarSrc === AvatarSrc.DATA_URL && dataUrlFilename) {
								updateProfilePicture(res.data.member_id, dataUrlFilename)
									.then((res) => {
										if (res.status === 200) {
											postMessageToCompanionApp('family_members_updated');
										}

										console.log('attempting file upload', res);
									})
									.catch((err) => {
										console.log('error uploading file', err);
									});
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			}
			{
				type === 'onboarding'
					? navigate('/onboard/complete')
					: navigate('/manage-family');
			}
		} catch (e) {
			let errorMsg: string = 'Error adding members';
			if (axios.isAxiosError(e)) {
				try {
					if (e.response?.status && e.response.status < 500) {
						errorMsg = e.response?.data.message || errorMsg;
					}
				} catch {}
			}
			updateNotification({
				message: errorMsg,
				showNotification: true,
			});
		}
	}

	function validateMember(member: IMember) {
		let errors = {};

		// Validate Member Name
		if (member.name === '') {
			errors = {
				...errors,
				name: 'Name Required',
			};
		} else if (member.name.length < 2) {
			errors = {
				...errors,
				name: 'Name must be greater than two characters',
			};
		}

		// Validate Profile Type
		if (member.profileType === '') {
			errors = {
				...errors,
				profileType: 'Profile Type Required',
			};
		}

		// Validate Member Email
		if (member.profileType === 'Adult' && member.email === '') {
			errors = {
				...errors,
				email: 'Email Required',
			};
		}

		if (member.email && !validateEmailString(member.email)) {
			errors = {
				...errors,
				email: 'Invalid Email',
			};
		}

		if (member.profileType === 'Kid' && !member.birthday) {
			errors = {
				...errors,
				birthday: 'Birthday required',
			};
		}

		return errors;
	}

	function handleChange(index: number, updatedFields: Partial<IMember>) {
		setMemberList((prevMemberList) =>
			prevMemberList.map((member, i) =>
				i === index
					? {
							...member,
							shouldShowError: false,
							touched: true,
							...updatedFields,
					  }
					: member,
			),
		);
	}

	async function handleContinue() {
		let shouldSubmit = true;

		if (shouldShowPrivacyPolicy && !agreed) {
			setPrivacyPolicyError(true);
			updateNotification({
				message: 'Agreement required to proceed',
				type: NotificationType.ERROR,
				showNotification: true,
			});
			shouldSubmit = false;
		}

		for (const member of memberList) {
			const index = memberList.indexOf(member);
			const errors = validateMember(member);

			if (errors && Object.keys(errors).length > 0) {
				handleChange(index, {
					shouldShowError: true,
					errors: errors,
				});
				shouldSubmit = false;
			}

			if (member.email) {
				const { ok, error } = await verifyUniqueEmail(member.email, type);
				if (!ok) {
					handleChange(index, {
						errors: { email: error },
						shouldShowError: true,
					});
					shouldSubmit = false;
				}
			}
		}

		if (shouldSubmit) await handleSubmit();
	}

	return (
		<InputPopupProvider>
			<WebAddMemberWrapper
				navigatePageBack={navigatePageBack}
				shouldWrap={type !== 'onboarding'}>
				<div className="pb-5">
					<TextContainer
						textTitle="Add family members"
						textBody="Invite your family to create an account and connect their existing calendars. (Adding an email for kids is optional!)"
					/>

					{memberList.length > 0 && (
						<div className="mb-4">
							{memberList.map((member, index) => (
								<NewProfile
									key={`member-${index}`}
									member={member}
									index={index}
									handleChange={handleChange}
									removeMember={removeMember}
									type={type}
									errorData={member.errors}
									isOnboarding
								/>
							))}
						</div>
					)}

					<div className="w-full flex justify-center">
						<Button
							onClick={addMember}
							variant="outlined"
							style={{
								height: 32,
								fontSize: 14,
								lineHeight: '24px',
								paddingBottom: 8,
								paddingTop: 8,
							}}>
							+ Add Profile
						</Button>
					</div>

					{shouldShowPrivacyPolicy && (
						<PrivacyPolicy
							handleCheck={() => setAgreed(!agreed)}
							isChecked={agreed}
							shouldShowError={privacyPolicyError && !agreed}
						/>
					)}
					<div
						className={`mt-6 ${
							memberList.some((member) => member.shouldShowError) ||
							(!allowSubmission && 'opacity-40')
						}`}>
						<Button
							variant="contained"
							fullWidth
							onClick={handleContinue}
							disabled={
								memberList.some((member) => member.shouldShowError) ||
								!allowSubmission
							}>
							{type === 'onboarding' ? 'Invite and continue' : 'Invite and add'}
						</Button>
					</div>
				</div>
			</WebAddMemberWrapper>
		</InputPopupProvider>
	);
}

interface WebAddMemberWrapperProps {
	shouldWrap?: boolean;
	children: React.ReactNode;
	navigatePageBack: () => void;
}

const WebAddMemberWrapper: React.FC<WebAddMemberWrapperProps> = ({
	shouldWrap,
	navigatePageBack,
	children,
}: WebAddMemberWrapperProps) => {
	const isCompanionApp = useIsCompanionAppAccess();
	return (
		<>
			{shouldWrap ? (
				<WithHeaderLayout>
					{!isCompanionApp && (
						<button
							onClick={navigatePageBack}
							className="flex flex-row items-center mt-4 ml-3">
							<BackArrow className="w-6 fill-current" />
							<span>Back</span>
						</button>
					)}
					<div className="mx-6 my-10">{children}</div>
				</WithHeaderLayout>
			) : (
				<OnboardingStepsLayout
					progress={ONBOARDING_SCREENS.members.progress}
					skipRoute="/onboard/complete">
					{children}
				</OnboardingStepsLayout>
			)}
		</>
	);
};
