import { ReactNode } from 'react';

interface IPropTypes {
	handleToggleModal?: () => void;
	showScrim?: boolean;
	children?: ReactNode;
}

export default function Scrim({
	handleToggleModal,
	children,
	showScrim = true,
}: IPropTypes) {
	return (
		<div className="flex w-full h-full fixed z-20 right-0 top-0 lg:w-3/4">
			<button
				onClick={handleToggleModal}
				style={{ opacity: showScrim ? 0.5 : 0 }}
				className={'w-full h-full absolute bg-grey-extraDark'}
			/>
			<div className="flex w-full h-full justify-center items-center z-20">
				{children}
			</div>
		</div>
	);
}
