import { Navigate } from 'react-router-dom';
import React from 'react';

interface FeatureFlagRouteProps {
	isEnabled: boolean;
	children: React.ReactNode;
	errorPath?: string;
}

const FeatureFlagRoute: React.FC<FeatureFlagRouteProps> = ({
	isEnabled,
	children,
	errorPath = '/error',
}) => {
	if (!isEnabled) {
		return <Navigate to={errorPath} />;
	}

	return children as React.ReactElement;
};

export default FeatureFlagRoute;
