import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { FieldErrors, SubmitHandler, UseFormSetValue } from 'react-hook-form';
import EmojiDrawer, { IEmoji } from 'components/Reusable/EmojiDrawer';
import SlideUpDrawer from 'components/Reusable/SlideUpDrawer';
import IconWrapper from 'components/Reusable/IconWrapper';
import { IRecurrenceDetails } from 'utils/types';
import WeeklyFrequencyAnimated, {
	WEEK_DAYS,
} from 'components/CompanionApp/Recurrence/WeeklyFrequencyAnimated';

interface StepFormDrawerProps {
	open: boolean;
	onClose: () => void;
	register: any;
	errors: FieldErrors<any>;
	onSubmit: SubmitHandler<any>;
	setValue: UseFormSetValue<IRoutineStepForm>;
	watch: any;
	routineRecurrenceDetails?: IRecurrenceDetails;
}

export interface IRoutineStepForm {
	id?: number;
	name: string;
	image: string;
	order?: number;
	recurrenceDetails: IRecurrenceDetails;
}

const RoutineStepDrawer: React.FC<StepFormDrawerProps> = ({
	open,
	onClose,
	register,
	errors,
	onSubmit,
	setValue,
	watch,
	routineRecurrenceDetails,
}) => {
	const image = watch('image');
	const recurrenceDetails = watch('recurrenceDetails');
	const byDay = recurrenceDetails?.byDay;

	const [showPicker, setShowPicker] = useState(false);
	const togglePicker = () => setShowPicker(!showPicker);

	const onEmojiSelect = (emoji: IEmoji) => {
		setValue('image', emoji.native);
		togglePicker();
	};

	const onDayClick = (dayString: string) => {
		const byDayArray = byDay ? byDay.split(',') : [];
		const dayIndex = byDayArray.indexOf(dayString);

		if (dayIndex > -1) {
			byDayArray.splice(dayIndex, 1);
		} else {
			byDayArray.push(dayString);
		}

		const newRecurrenceDetails = {
			...recurrenceDetails,
			byDay: byDayArray
				.sort(
					(a: string, b: string) => WEEK_DAYS.indexOf(a) - WEEK_DAYS.indexOf(b),
				)
				.join(','),
		};

		setValue('recurrenceDetails', newRecurrenceDetails);
	};

	return (
		<>
			<SlideUpDrawer open={open} onClose={onClose}>
				<form
					className="mx-6 my-4 flex flex-col items-center"
					onSubmit={onSubmit}>
					<input id="id" type="number" className="hidden" {...register('id')} />
					<input
						id="id"
						type="number"
						className="hidden"
						{...register('order')}
					/>
					<input
						id="image"
						type="text"
						className="hidden"
						{...register('image', {
							required: 'Image is required',
							value: 'magic-moment-stars',
						})}
					/>

					<button
						onClick={(e) => {
							e.preventDefault();
							togglePicker();
						}}>
						<IconWrapper imageValue={image} />
					</button>

					<div className="mt-6 w-full">
						<TextField
							label="Title"
							fullWidth
							{...register('name', { required: 'Title is required' })}
							error={!!errors.name}
							helperText={errors.name?.message}
						/>
					</div>

					<p className="mt-5 mb-3 text-left w-full text-md font-semibold text-primary-extraDark tracking-normal">
						Repeat on select days only
					</p>

					<div className="flex w-full flex-col">
						<WeeklyFrequencyAnimated
							byDay={recurrenceDetails?.byDay}
							onDayClick={onDayClick}
							dayOptions={routineRecurrenceDetails?.byDay.split(',')}
						/>
						{byDay === '' && (
							<p className="mt-2 text-error text-xs">
								At least one day must be selected
							</p>
						)}
					</div>

					<hr className="w-screen mt-5" />

					<div className="w-full flex flex-row justify-between items-center mt-4">
						<Button
							variant="text"
							onClick={onClose}
							sx={{ fontWeight: 700, paddingLeft: 0, paddingRight: 0 }}>
							Cancel
						</Button>
						<Button
							variant="outlined"
							type="submit"
							className="h-8"
							disabled={byDay === ''}>
							Save
						</Button>
					</div>
				</form>
			</SlideUpDrawer>
			<EmojiDrawer
				open={showPicker}
				onClose={togglePicker}
				onEmojiSelect={onEmojiSelect}
			/>
		</>
	);
};

export default RoutineStepDrawer;
