import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import useCurrentUser from 'stores/currentUser';
import useFeatures from 'stores/feature';
import {
	companionAppStoreLink,
	routinesFeatureFlag,
	screensaverFeatureFlag,
} from 'utils/variables';
import analytics from 'utils/segmentClient';
import { ReactComponent as HearthLogo } from 'assets/rebrand/new-logo.svg';
import { ReactComponent as Exit } from 'assets/rebrand/exit.svg';
import { ReactComponent as StarSVG } from 'assets/Icons/Membership/featureGateStar.svg';
import CompanionAppStoreButton from 'components/Buttons/CompanionAppStoreButton';
import { useIsCompanionAppAccess } from 'hooks/companionAppHooks';

interface IPropTypes {
	label: string;
	isCurrentScreen: boolean;
	isAvailable?: boolean;
	handleClick: () => void;
}

const SUPPORT_LINK =
	'https://hearthdisplay.kustomer.help/contact/contact-us-BkWKzBR_j';

const isCurrentPath = (paths: string[]): boolean => {
	return paths.some((path) => useMatch(path));
};

const MenuOption = ({
	label,
	isCurrentScreen,
	isAvailable = true,
	handleClick,
}: IPropTypes) => (
	<div className="lg:ml-12">
		<hr className="border-t border-rebrand-teal-grey" />
		<div className="flex ml-6 lg:ml-0 py-4 items-center">
			<button
				onClick={handleClick}
				className={`pr-2 ${isCurrentScreen ? 'font-bold' : ''}`}>
				{label}
			</button>
			{!isAvailable && <StarSVG />}
		</div>
	</div>
);

export default function Menu() {
	const { user, signUserOut } = useCurrentUser((store) => ({
		user: store.user,
		signUserOut: store.signUserOut,
	}));
	const features = useFeatures((store) => store.features);
	const accessingFromCompanionApp = useIsCompanionAppAccess();

	const navigate = useNavigate();
	const location = useLocation();

	async function handleSignOut() {
		await signUserOut();
		navigate('/login');
	}

	return (
		<div className="h-screen w-full lg:w-372 bg-rebrand-grey-light text-rebrand-teal-text flex flex-col justify-start">
			<div className="flex flex-row justify-between mt-9 mb-12 mx-6 lg:m-12">
				<HearthLogo className="w-80 max-w-xs fill-current" />
				<Exit onClick={() => navigate(-1)} className="fill-current lg:hidden" />
			</div>

			<MenuOption
				label="Calendar"
				isCurrentScreen={location.pathname.endsWith('/calendar')}
				handleClick={() => {
					navigate('/calendar');
					analytics.track('calendar_view', {
						calendar_view: 'web_view',
					});
				}}
			/>
			<MenuOption
				label="To-dos"
				isCurrentScreen={
					location.pathname.endsWith('/todos') ||
					location.pathname.endsWith('/new-todo')
				}
				isAvailable={features.todos.isAvailable}
				handleClick={() => navigate('/todos')}
			/>
			{routinesFeatureFlag && (
				<MenuOption
					label="Routines"
					isCurrentScreen={isCurrentPath(['/routines'])}
					isAvailable={features.routines.isAvailable}
					handleClick={() => navigate('/routines')}
				/>
			)}
			<MenuOption
				label="Manage Family"
				isCurrentScreen={location.pathname.endsWith('/manage-family')}
				handleClick={() => navigate('/manage-family')}
			/>
			<MenuOption
				label="Manage Calendars"
				isCurrentScreen={location.pathname.endsWith('/manage-calendar')}
				handleClick={() => {
					navigate('/manage-calendar');
					analytics.track('manage_calendars', {
						user_id: user?.id,
						from_web: true,
					});
				}}
			/>
			{screensaverFeatureFlag && (
				<MenuOption
					label="Display Settings"
					isCurrentScreen={location.pathname.endsWith('/display-settings')}
					isAvailable={features.privacyMode.isAvailable}
					handleClick={() => navigate('/display-settings')}
				/>
			)}
			<MenuOption
				label="Account"
				isCurrentScreen={location.pathname.endsWith('/account')}
				handleClick={() => navigate('/account')}
			/>
			<MenuOption
				label="Sign out"
				isCurrentScreen={false}
				handleClick={handleSignOut}
			/>
			{companionAppStoreLink && !accessingFromCompanionApp && (
				<CompanionAppStoreButton />
			)}

			<a
				className="mt-4 ml-6 lg:ml-12 underline text-xxs"
				href={SUPPORT_LINK}
				target="_blank"
				rel="noreferrer">
				Need Help?
			</a>
		</div>
	);
}
