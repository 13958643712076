import Avatars from 'assets/Images/Avatars.png';
import Union from 'assets/Images/Union.png';
import Button from 'components/Buttons/Button';
import TextContainer from 'components/onboarding/TextContainer';
import { useLocation } from 'react-router-dom';
import { apiHost, apiRoot } from 'utils/variables';

export default function AuthenticateWithGoogleError() {
	const location = useLocation();

	let authLink = '';
	if (location.pathname.startsWith('/onboard')) {
		authLink = `${apiHost}${apiRoot}/onboarding/session/authenticate_with_google?ot=${window.localStorage.getItem(
			'ot',
		)}`;
	} else if (location.pathname.startsWith('/join-family')) {
		authLink = `${apiHost}${apiRoot}/onboarding/session/invite/authenticate_with_google?it=${window.localStorage.getItem(
			'it',
		)}`;
	}

	return (
		<>
			{/* Hearth logo */}
			<div className="px-6 pb-2">
				<img src={Union} alt="hearth logo" style={{ paddingBottom: '2rem' }} />
			</div>
			<TextContainer
				textTitle="Your family all in one place."
				textBody="Manage your family,
                 let them share their progress on chores and
                 routines, and coordinate day to day events."
			/>
			<p className="text-base pb-4 text-red-700">
				It seems that you have already authenticated with Hearth Display. Please{' '}
				<a
					href="https://myaccount.google.com/u/0/permissions?pli=1"
					target="_blank"
					className="underline">
					remove existing Google authentication for Hearth Display
				</a>{' '}
				and try again.
			</p>
			{/* 3 avatar images */}
			<div className="pt-1 pb-8">
				<img src={Avatars} alt="avatars" />
			</div>
			{/* Line behind "Get started" */}
			<div className="py-2 text-sm w-full md:w-1/4">
				<h6>
					<span>Get started</span>
				</h6>
			</div>
			<div className="pb-8">
				<Button
					label="Continue with Google"
					linkTo={authLink}
					isFilled={false}
					isBorder={true}
					colorScheme="primary"
					image={true}
				/>
			</div>
		</>
	);
}
