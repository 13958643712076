import MonthView from 'components/CompanionApp/Calendar/MonthView';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { ITime } from 'utils/types';
import { handleAmPmConversion } from 'utils/dateTimeHelper';
import { Button } from '@mui/material';

interface IPropTypes {
	onSubmit: (time: ITime, selectDate: dayjs.Dayjs) => void;
	onCancel: () => void;
	selectedTime?: dayjs.Dayjs;
	timePickerVisible?: boolean;
	pickingStart: boolean;
	pickingEnd: boolean;
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	timeErrorState?: boolean;
}

export default function DateTimePicker({
	onSubmit,
	onCancel,
	selectedTime = dayjs(),
	timePickerVisible = true,
	pickingStart,
	pickingEnd,
	startDate,
	endDate,
	timeErrorState,
}: IPropTypes) {
	// Hour and minute Refs
	const hourInputElement = useRef<any>();
	const minuteInputElement = useRef<any>();

	// Core state management
	const [timeHours, setTimeHours] = useState<string>(
		selectedTime?.format('hh') || '',
	);
	const [timeMinutes, setTimeMinutes] = useState<string>(
		selectedTime?.format('mm') || '',
	);
	const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(selectedTime);

	// AM/PM UI Toggle
	const [toggleAMPM, setToggleAMPM] = useState({
		toggleBackground: false,
		value: selectedTime?.format('A') || '',
	});

	//Error Handling
	const [invalidTimeErrorVisible, setInvalidTimeErrorVisible] =
		useState<boolean>(false);

	/**
	 * Function to handle passing Hour and Minute Time input data to parent component as a call back function
	 * onSubmit() passes hour and minute time data to parent after formatting
	 * Checks that Hour and Minute Inputs are 0-12 and 0-59 respectively.
	 */
	function submitTimeAndDateHandler() {
		const time = {
			hours: parseInt(timeHours, 10),
			minutes: parseInt(timeMinutes, 10),
			amPm: toggleAMPM.value,
		};

		const convertedHours = handleAmPmConversion(time);

		// Add hours and minutes to selected date
		const updatedDateTime = selectedDate
			.set('hour', convertedHours)
			.set('minute', time.minutes);

		if (time.hours <= 12 && time.minutes <= 59) {
			onSubmit(time, updatedDateTime);
		}
	}

	function onHourInputChange(e: React.FormEvent<HTMLInputElement>) {
		setTimeHours(e.currentTarget.value);

		if (parseInt(e.currentTarget.value, 10) > 12) {
			setInvalidTimeErrorVisible(true);
			return;
		}

		setInvalidTimeErrorVisible(false);

		//Focus on Minute input element after the Hour input element value is 2 characters long
		if (e.currentTarget.value.length === 2) {
			minuteInputElement.current.focus();
		}
	}

	function onMinuteInputChange(e: React.FormEvent<HTMLInputElement>) {
		setTimeMinutes(e.currentTarget.value);

		if (parseInt(e.currentTarget.value, 10) > 59) {
			console.log('error');
			setInvalidTimeErrorVisible(true);
		}

		setInvalidTimeErrorVisible(false);
	}

	return (
		<>
			<div style={{ height: 375 }} className="w-full mb-4">
				<MonthView
					pickingStart={pickingStart}
					pickingEnd={pickingEnd}
					startDate={startDate}
					endDate={endDate}
					onSelect={(selectedDate: dayjs.Dayjs) =>
						setSelectedDate(selectedDate)
					}
					dateTimePicker={true}
				/>
			</div>
			{timePickerVisible && (
				<div className="flex flex-row justify-between items-center">
					<h6
						className={`font-semibold ${
							timeErrorState ? 'mt-1' : 'self-center'
						}`}>
						Time
					</h6>
					<div className="flex flex-row">
						<input
							ref={hourInputElement}
							onFocus={() => setTimeHours('')}
							inputMode="numeric"
							type="text"
							maxLength={2}
							className="w-10 text-center rounded-md focus:outline-none bg-rebrand-grey-light focus:bg-white focus:border border-rebrand-primary"
							value={timeHours}
							onChange={onHourInputChange}
							pattern="[0-9]{2}"
						/>
						<span className="mx-1">:</span>
						<input
							ref={minuteInputElement}
							inputMode="numeric"
							onFocus={() => setTimeMinutes('')}
							type="text"
							maxLength={2}
							className="w-10 text-center rounded-md focus:outline-none bg-rebrand-grey-light focus:bg-white focus:border border-rebrand-primary"
							value={`${timeMinutes}`}
							onChange={onMinuteInputChange}
							pattern="[0-9]{2}"
						/>
						<div>
							{timeErrorState && !invalidTimeErrorVisible && (
								<p className="text-error text-xxs">Ends before start time</p>
							)}
							{invalidTimeErrorVisible && (
								<p className="text-rebrand-error text-xxs">
									Invalid Time Input
								</p>
							)}
						</div>
						<div className="ml-3 flex flex-row rounded-md items-center bg-rebrand-grey-light">
							<button
								className={`w-10 rounded-md ${
									toggleAMPM.value === 'AM' && 'm-[1px] bg-white font-semibold'
								}`}
								onClick={() =>
									setToggleAMPM({
										toggleBackground: !toggleAMPM.toggleBackground,
										value: 'AM',
									})
								}>
								<span>AM</span>
							</button>
							<button
								className={`w-10 rounded-md ${
									toggleAMPM.value === 'PM' && 'm-[1px] bg-white font-semibold'
								}`}
								onClick={() =>
									setToggleAMPM({
										toggleBackground: !toggleAMPM.toggleBackground,
										value: 'PM',
									})
								}>
								<span>PM</span>
							</button>
						</div>
					</div>
				</div>
			)}
			<div className="flex flex-row justify-between mt-4 mb-4">
				<button
					className="text-rebrand-teal text-sm font-semibold"
					onClick={onCancel}>
					Cancel
				</button>
				<Button
					variant="outlined"
					onClick={submitTimeAndDateHandler}
					className="h-8">
					Save
				</Button>
			</div>
		</>
	);
}
