import { useEffect } from 'react';
import { useRedirectScreenBasedOnAuth } from 'hooks/useRedirectScreenBasedOnAuth';
import { apiHost, apiRoot } from 'utils/variables';
import useCurrentUser from 'stores/currentUser';
import axiosClient from 'utils/axiosClient';
import SignIn from 'components/SignIn/SignIn';
import WithHeaderLayout from 'components/Layouts/WithHeaderLayout';

export default function WebLogin() {
	const { isAuthenticated, isCurrentUserFetched, fetchCurrentUser } =
		useCurrentUser((store) => ({
			isAuthenticated: store.isAuthenticated,
			isCurrentUserFetched: store.isCurrentUserFetched,
			fetchCurrentUser: store.fetchCurrentUser,
		}));
	const redirectScreen = useRedirectScreenBasedOnAuth(isAuthenticated);

	useEffect(() => {
		if (isCurrentUserFetched && isAuthenticated) {
			redirectScreen();
		}
	}, [isCurrentUserFetched, isAuthenticated, redirectScreen]);

	const authLink = `${apiHost}${apiRoot}/web/session/authenticate_with_google`;

	async function handlePasswordLogin(email: string, password: string) {
		const response = await axiosClient.post('/web/session/login_with_email', {
			email: email,
			password: password,
		});

		if (response.status === 200) {
			await fetchCurrentUser();
		}
	}

	return (
		<WithHeaderLayout menuButtonVisible={false} className="flex justify-center">
			<SignIn
				googleAuthLink={authLink}
				handleLoginCallback={handlePasswordLogin}
				isDeviceLogin={false}
			/>
		</WithHeaderLayout>
	);
}
