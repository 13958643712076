/**
 * ****************** User and Family Types ******************
 */
export interface IAPIUser {
	id: number;
	first_name: string;
	last_name: string;
}

export interface ICurrentUser {
	id: number;
	firstName: string;
	lastName: string;
	filename?: string;
	color?: string;
	email?: string;
	isOnboarding: boolean;
	isVerifiedEmail: boolean;
}

export interface IAPIUserContact {
	id?: number;
	contact_type?: 'email' | 'phone';
	value: string;
}

export interface IUserContact {
	id: number;
	contactType: 'email' | 'phone';
	value: string;
}

export interface IUserData {
	first_name: string;
	last_name: string;
	color: string;
	birthday?: Date;
	notes?: string;
	email?: string;
	phone_number?: {
		value: string;
	};
	avatar_file_name?: string;
}

export enum AvatarSrc {
	LOCAL = 'LOCAL',
	REMOTE = 'REMOTE',
	FILE = 'FILE',
	NONE = 'NONE',
	DATA_URL = 'DATA_URL',
}

export interface IProfilePicture {
	rawImage?: File;
	image?: string;
	filename?: string;
	avatarSrc?: AvatarSrc;
}

export interface IAvatarUpdateResponse {
	avatar_file_name: string;
}

export interface IAPIFeatureStatus {
	is_enabled: boolean;
	is_available: boolean;
}

export interface IFeatureStatus {
	isEnabled: boolean;
	isAvailable: boolean;
}

export interface IAPIFeatures {
	[key: string]: IAPIFeatureStatus;
}

export interface IFeatures {
	[key: string]: IFeatureStatus;
}

// Family interface field names are same in API and TypeScript
export interface IFamily {
	id: number;
	name: string;
	isOnboarded?: boolean;
}

/**
 * ****************** Profile and Member Types ******************
 */
export interface IMemberError {
	profileType?: string;
	name?: string;
	avatar?: string;
	email?: string;
	birthday?: string;
}

export interface IMember {
	id?: number;
	profileType: string;
	name: string;
	avatar: string;
	email: string | undefined;
	birthday: string | undefined;
	isResponsibleAdult: boolean;
	isError: boolean;
	shouldShowError: boolean;
	errors: IMemberError;
	touched: boolean;
	profilePicture: IProfilePicture;
}

export interface IMemberProfile {
	member: IMember;
	index: number;
	handleChange: (arg0: number, agr1: Partial<IMember>) => void;
	removeMember: (arg0: number) => void;
	isOnboarding: boolean;
	errorData: any;
	type: string;
}

export interface IAPIFamilyInvite {
	id: number;
	contact_type: string;
	value: string;
	first_name: string;
	is_responsible_adult: boolean;
}

/**
 * ****************** Calendar Types ******************
 */
export interface IAPIExternalCalendar {
	id: number;
	user_id: number;
	assigned_user_id?: number;
	calendar_id: string;
	description: string;
	name: string;
	timezone: string;
	provider_calendar_id?: string;
	is_used_in_hearth: boolean;
	include_event_details?: boolean;
	color: string;
	provider?: string;
}

export enum CalendarProviderTypes {
	HEARTH = 'hearth',
	GOOGLE = 'google',
	OUTLOOK = 'outlook',
	ICAL = 'ical',
	ICS = 'ics',
}

export interface ICalendar {
	id: number;
	includeEventDetails: boolean;
	isPublic: boolean;
	isUsedInHearth: boolean;
	name: string;
	publicKey?: string;
	userId: number | null;
	assignedUserId: number | null;
	color: string | null;
	provider: string;
}

export interface IProfileUser {
	user_id: number;
	first_name: string;
	full_name: string | null;
	avatar_file_name: string;
	color: string | null;
	custom_order: number | null;
	is_responsible_adult: boolean;
	joined_at: string;
	tasks: any[] | null;
	calendars: IProfileCalendar[];
}

export interface IProfileCalendar {
	name: string;
	description: string | null;
	timezone: string | null;
	include_event_details: boolean;
	is_used_in_hearth: boolean;
	color: string | null;
	id: number;
	family_id: number | null;
	user_id: number | null;
	user_contact_id: number | null;
	assigned_user_id: number | null;
	provider: string;
	provider_calendar_id: number | null;
	is_public: boolean;
	public_key?: string;
	created_at: Date;
	avatar_file_name: string | null;
}

export enum ICalendarUpdateType {
	color = 'color',
	toggle = 'toggle',
	assign = 'assign',
	name = 'name',
}

export interface ICalendarUpdateParams {
	type: ICalendarUpdateType;
	id: number;
	name: string;
	isPublic: boolean;
	checked: boolean;
	includeEventDetails: boolean;
	assignedUserId: number | null;
	color: string | null;
}

export interface IAPICalendar {
	id: number;
	include_event_details: boolean;
	is_public: boolean;
	is_used_in_hearth: boolean;
	name: string;
	public_key?: string;
	user_id?: number;
	color?: string;
	provider: string;
}

export interface IUserContact {
	id: number;
	contactType: 'email' | 'phone';
	value: string;
	includeEventDetails: boolean;
}

export interface IAPIFamilyMember {
	id: number;
	family_member_id: number;
	family_id: number;
	first_name: string;
	last_name: string;
	email: string;
	avatar_file_name: string;
	avatar_upload_id: number;
	is_responsible_adult: boolean;
	contacts: IAPIUserContact[];
	birthday?: Date;
	notes?: string;
	routines?: IAPIRoutine[];
	color?: string;
	custom_order?: number;
}

export interface IFamilyMember {
	id: number;
	familyMemberId: number;
	familyId: string;
	firstName: string;
	lastName: string;
	avatarFileName: string;
	avatarUploadId: string;
	isResponsibleAdult: boolean;
	independenceLevelId: number;
	contacts: IUserContact[];
	routines?: IRoutine[];
}

/**
 * ****************** Event Types ******************
 */
export interface IAPIEvent {
	id: number;
	is_hearth_event: boolean;
	icaluid?: string;
	event_provider_id?: string;
	family_id: number;
	calendar_id: number;

	subject?: string;
	description?: string;

	is_all_day_event: boolean;
	start_at?: Date;
	start_date?: Date;
	end_at?: Date;
	end_date?: Date;

	recurrence_rule_id?: number;
	original_start_at?: number;

	created_by?: number;
	created_at?: Date;

	color: string;
	attendee_list: IAPIEventAttentee[];
}

export interface IAPIEventAttentee {
	user_id: number;
	response_status: string;
}

export interface IEvent {
	id: number;
	isHearthEvent: boolean;
	iCalUID?: string;
	providerId?: string;
	familyId?: number;
	calendarId?: number;
	isAllDayEvent: boolean;
	subject: string;
	description?: string;
	start?: {
		date?: Date;
		dateTime?: Date;
	};
	end?: {
		date?: Date;
		dateTime?: Date;
	};
	recurrenceRuleId?: number;
	createdAt?: Date;
	createdBy?: number;
	color: string;
	attendeeList: IEventAttendee[];
}

export interface IEventAttendee {
	userId: number;
	responseStatus: string;
}

/**
 * ****************** Task Types ******************
 */
export interface IAPITask {
	id: number;
	family_id?: number;

	subject?: string;
	description?: string;

	is_priority: boolean;

	completed_by?: number;
	completed_at?: Date;

	created_by?: number;
	created_at?: Date;

	start_at?: Date;
	due_at?: Date;
	recurrence_rule_id?: number;
	completion_streak?: number;
	recurrence_details?: IAPIRecurrenceDetails;

	user_id?: number;
	is_filtered?: boolean;
}
export interface IAPIRecurrenceRule {
	id: number;
	freq: string;
	interval: number;
	dt_start: Date;
	by_day?: string;
	by_month_day?: string;
	count?: number;
	until?: Date;
	completion_streak: number;
}

export interface IAPIRecurrenceDetails {
	freq: string;
	dt_start: Date | string;
	interval: number;
	by_day?: string;
	by_month_day?: string;
	count?: number;
	until?: Date | string;
	completion_streak?: number;
}

export interface IRecurrenceDetails {
	freq: string;
	dtStart: Date | string;
	interval: number;
	byDay: string;
	byMonthDay?: string;
	count?: number;
	until?: Date | string;
	completionStreak?: number;
}

/**
 * ****************** Time and Timezone Types ******************
 */
export interface ITime {
	hours: number;
	minutes: number;
	amPm: string;
}

export interface ITimezones {
	[key: string]: ITimezoneObject;
}

export interface ITimezoneObject {
	name: string;
	shortName?: string;
	timezone: string;
}

/**
 * ****************** Routine Types ******************
 */
export interface IRoutine {
	id?: number;
	name: string;
	frequency: 'daily' | 'monthly';
	completedSteps: number;
	totalSteps: number;
	image: string;
	pastProgress: {
		[key: number | string]: boolean;
	};
	users: IFamilyMember[];
	steps: IRoutineStep[];
	recurrenceDetails?: IRecurrenceDetails;
}

export interface IAPIRoutine {
	id: number;
	name: string;
	description: string;
	frequency: 'daily' | 'monthly';
	completed_steps: number;
	total_steps: number;
	image: string;
	past_progress: {
		[key: number | string]: boolean;
	};
	recurrence_rule: IAPIRecurrenceRule;
	recurrence_details: IAPIRecurrenceDetails;
}

export interface IRoutineTemplate {
	name: string;
	image: string;
	frequency?: 'daily' | 'monthly';
	steps?: IRoutineStep[];
	recurrenceDetails?: IRecurrenceDetails;
}

export interface IRoutineStep {
	id?: number;
	name: string;
	order: number;
	image: string;
	recurrenceDetails?: IRecurrenceDetails;
	isComplete?: boolean;
}

/**
 * ****************** Notification Types ******************
 */
export enum NotificationType {
	ERROR = 'Error',
	UPDATE = 'Update',
	SUCCESS = 'Success',
}

export interface INotification {
	message?: string;
	type?: NotificationType;
	displayTime?: number;
	showNotification: boolean;
}
