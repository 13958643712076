import { useState } from 'react';
import CalendarDetailsPopupLayout from './CalendarDetailsPopupLayout';
import { getCalendarColor } from 'utils/colorHelpers';
import {
	ICurrentUser,
	ICalendar,
	ICalendarUpdateParams,
	CalendarProviderTypes,
	IProfileUser,
	ICalendarUpdateType,
} from 'utils/types';
import { ReactComponent as ICalIcon } from 'assets/Icons/ical.svg';
import { ReactComponent as OutlookIcon } from 'assets/Icons/outlook.svg';
import UnsubscribeModal from './UnsubscribeModal';
import UpdateCalendarNameModal from './UpdateCalendarNameModal';

enum CalendarDisplayModalTypes {
	DETAILS = 'details',
	UNSUBSCRIBE = 'unsubscribe',
	CHANGE_NAME = 'change name',
}

interface IProps {
	handleCloseModal: () => void;
	calendar: ICalendar;
	updateCalendar: (params: ICalendarUpdateParams) => Promise<void>;
	user?: ICurrentUser;
	assignedUser?: IProfileUser;
	profiles: Map<number | null, IProfileUser>;
	unsubscribeCalendar: (arg0: number) => void;
	provider: CalendarProviderTypes;
}

export default function GenericCalendarDetailsPopup({
	handleCloseModal,
	calendar,
	updateCalendar,
	user,
	assignedUser,
	profiles,
	unsubscribeCalendar,
	provider,
}: IProps) {
	const [displayModalType, setDisplayModalType] =
		useState<CalendarDisplayModalTypes>(CalendarDisplayModalTypes.DETAILS);
	const currentColor = getCalendarColor(
		calendar.color,
		assignedUser?.color,
		user?.id,
	);

	let providerIcon;
	switch (provider) {
		case CalendarProviderTypes.ICAL:
			providerIcon = <ICalIcon className="h-3.5 w-3.5 mr-2" />;
			break;
		case CalendarProviderTypes.OUTLOOK:
			providerIcon = <OutlookIcon className="h-3.5 w-3.5 mr-2" />;
			break;
		default:
			providerIcon = null;
	}

	const handleColorChange = (newColor: string) =>
		updateCalendar({
			type: ICalendarUpdateType.color,
			id: calendar.id,
			name: calendar.name,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: calendar.includeEventDetails,
			color: newColor,
			assignedUserId: calendar.assignedUserId,
		});

	const handleToggleSwitch = () =>
		updateCalendar({
			type: ICalendarUpdateType.toggle,
			id: calendar.id,
			name: calendar.name,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: !calendar.includeEventDetails,
			color: calendar.color,
			assignedUserId: calendar.assignedUserId,
		});

	const handleNameChange = (newCalendarName: string) => {
		updateCalendar({
			type: ICalendarUpdateType.name,
			id: calendar.id,
			name: newCalendarName,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: calendar.includeEventDetails,
			assignedUserId: calendar.assignedUserId,
			color: calendar.color,
		}).then(handleCloseModal);
	};

	const handleAssignUser = (profile: IProfileUser) => {
		updateCalendar({
			type: ICalendarUpdateType.assign,
			id: calendar.id,
			name: calendar.name,
			isPublic: calendar.isPublic,
			checked: calendar.isUsedInHearth,
			includeEventDetails: calendar.includeEventDetails,
			color: null,
			assignedUserId: profile.user_id,
		}).then(handleCloseModal);
	};

	switch (displayModalType) {
		case CalendarDisplayModalTypes.DETAILS:
			return (
				<CalendarDetailsPopupLayout
					handleCloseModal={handleCloseModal}
					calendarName={calendar.name}
					currentColor={currentColor}
					assignedUser={assignedUser}
					profiles={profiles}
					handleUserAssign={handleAssignUser}
					handleColorChange={handleColorChange}
					handleEditOnClick={() =>
						setDisplayModalType(CalendarDisplayModalTypes.CHANGE_NAME)
					}
					handleToggleSwitch={handleToggleSwitch}
					isToggledOn={calendar.includeEventDetails}
					switchTitle="Share event details"
					providerIcon={providerIcon}>
					<button
						className="mt-4"
						onClick={() =>
							setDisplayModalType(CalendarDisplayModalTypes.UNSUBSCRIBE)
						}>
						<p className="text-rebrand-red font-semibold text-xs">
							Unsubscribe
						</p>
					</button>
				</CalendarDetailsPopupLayout>
			);
		case CalendarDisplayModalTypes.UNSUBSCRIBE:
			return (
				<UnsubscribeModal
					handleCloseModal={handleCloseModal}
					handleUnsubscribe={() => unsubscribeCalendar(calendar.id)}
				/>
			);
		case CalendarDisplayModalTypes.CHANGE_NAME:
			return (
				<UpdateCalendarNameModal
					currentCalendarName={calendar.name}
					handleCloseModal={handleCloseModal}
					handleNameChange={handleNameChange}
				/>
			);
		default:
			return null;
	}
}
